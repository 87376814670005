import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { initializeApp } from 'firebase/app'
import { getFirestore, getDoc, doc, updateDoc } from '@firebase/firestore'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { getToken, getUser } from '../Login/ManageUser'
import { newsArticleDataObject } from '../../constants/enumTypes.constants'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { notify } from '../Notify/Notify'
import { MdOutlineDragIndicator } from 'react-icons/md'
import { RiDeleteBin5Fill } from 'react-icons/ri'
import SideBar from '../common/Sidebar'
import FormField from '../common/FormField'
import Button from '../common/Button/Button'
import axios from 'axios'
import './News.scss'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

const news = () => {
  // *  useNavigate used for redirect to login
  let navigate = useNavigate()
  // * Authentication variables
  const V2_URL = process.env.REACT_APP_API_V2_URL

  const token = getToken()
  const user = getUser()

  // * Component state
  const [articleID, setArticleID] = useState('')
  const [newsButtonValidation, setNewsButtonValidation] = useState(true)
  const [addArticleButtonValidation, setAddArticleButtonValidation] =
    useState(true)
  const [newsArticleData, setNewsArticleData] = useState(newsArticleDataObject)
  const [loadingAPI, setLoadingAPI] = useState(false)
  const [newsData, setNewsData] = useState([])
  const [newsIds, setNewsIds] = useState([])
  const [error, setError] = useState(false)
  const [disableSave, setDisableSave] = useState(true)

  let fetchedIds = []

  // imported to fetch stats's category
  const app = initializeApp(firebaseConfig)
  const auth = getAuth(app)
  const db = getFirestore(app)

  //regex for numbers only
  const expression = new RegExp('^[0-9]+$')

  useEffect(() => {
    signInWithEmailAndPassword(
      auth,
      process.env.REACT_APP_FIREBASE_EMAIL,
      process.env.REACT_APP_FIREBASE_PASSWORD
    ).then((userCredential) => {
      // * Signed in to firebase
      const user = userCredential?.user
      user?.accessToken && fetchNewsIds()
    })
  }, [])

  useEffect(() => {
    setNewsIds(
      [...newsData].map((news, index) => ({
        position: index,
        newsId: news.id,
      }))
    )
  }, [newsData])

  useEffect(() => {
    if (newsData.length < 10) {
      setError(false)
    } else {
      setError(true)
    }
  }, [newsData])

  const newsIdsRef = doc(db, 'TopStories', 'NewsDoc')

  const fetchNewsIds = async () => {
    // Fetch the document and get the newsIds array
    getDoc(newsIdsRef)
      .then((docSnapshot) => {
        if (docSnapshot.exists()) {
          const newsDocData = docSnapshot.data()
          if (newsDocData.newsIds && Array.isArray(newsDocData.newsIds)) {
            const newsIds = newsDocData.newsIds
            notify(
              'Success',
              'Document Fetched Succssfully from Firebase',
              'success'
            )
            let fetched = [...newsIds].map((item) =>
              fetchedIds.push(item.newsId)
            )
            getNewsDataInit(fetchedIds)
          }
        }
      })
      .catch((error) => {
        notify('Error', 'Error fetching document', 'error')
      })
  }

  const getNewsDataInit = (ids) => {
    const articleIds = ids.map((item) => {
      if (typeof item == 'object') {
        return item.id
      }
      return item
    })
    const url = `${V2_URL}newsfeeds/newshighlightsv2?highlight_ids=${articleIds}`
    axios
      .get(
        url,
        {},
        {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res?.data?.success) {
          let response = res?.data?.data
          response.forEach((obj) => {
            // Check if the "id" field is a number
            if (typeof obj.id === 'number') {
              // Convert the "id" field to a string
              obj.id = obj.id.toString()
            }
          })
          const orderedResponse = orderResponse(articleIds, response)
          setDisableSave(false)
          setNewsData(orderedResponse)
        }
      })
  }

  const handleSave = async () => {
    await updateDoc(newsIdsRef, {
      newsIds: newsIds,
    })
      .then(() => {
        notify(
          'Success',
          'Document updated successfully to Firebase',
          'success'
        )
      })
      .catch((error) => {
        notify('Error', 'Error fetching document', 'error')
      })
  }

  const handleInputChange = (e) => {
    if (expression.test(e.target.value) || !e.target.value?.length) {
      setArticleID(e.target.value)
      setNewsButtonValidation(true)
      e.target.value && setNewsButtonValidation(false)
    }
  }

  function truncateString(str, maxLength) {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + '...'
    }
    return str
  }

  const addArticle = () => {
    const foundArticle = newsData.find((item) => item.id === articleID)
    if (foundArticle) {
      notify('Error', 'articleId is in Stories!', 'error')
    } else {
      if (newsData.length < 10) {
        setNewsData((prevNews) => [
          ...prevNews,
          {
            id: articleID,
            article_title: newsArticleData?.article_title
              ? newsArticleData?.article_title
              : 'No Article Title Found',
            article_summary: newsArticleData?.article_summary
              ? newsArticleData?.article_summary
              : 'No Article Summary Found',
          },
        ])
        setAddArticleButtonValidation(true)
      }
    }
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    ...draggableStyle,
  })

  const deleteRow = (indexToDelete) => {
    setNewsData((prevNews) =>
      prevNews.filter((row, index) => index !== indexToDelete)
    )
  }

  function orderResponse(originalArray, backendResponse) {
    // Create a map to store the indices of ids in the original array
    const idMap = new Map()
    originalArray.forEach((id, index) => {
      idMap.set(id, index)
    })

    // Sort the backend response based on the indices in the original array
    backendResponse.sort((a, b) => {
      const indexA = idMap.get(a.id)
      const indexB = idMap.get(b.id)
      return indexA - indexB
    })
    return backendResponse
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const updatedItems = reorder(
      newsData,
      result.source.index,
      result.destination.index
    )

    setNewsData(updatedItems)
  }

  const getListStyle = (isDraggingOver) => ({})

  // gets news-article data
  const getNewsArticleData = (articleID) => {
    setLoadingAPI(true)
    const url = `${V2_URL}newsfeeds/newshighlightsv2?highlight_ids=${articleID}`
    axios
      .get(
        url,
        {},
        {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res?.data?.data?.length > 0) {
          const resNewsData = res?.data?.data?.[0]
          const title = 'Success'
          const detail = res.data?.message
          const type = 'success'
          notify(title, detail, type)
          setNewsArticleData({
            twitter_account_name: resNewsData?.twitter_account_name ?? '',
            article_title: resNewsData?.article_title ?? '',
            article_summary: resNewsData?.article_summary ?? '',
            article_link: resNewsData?.article_link ?? '',
            id: String(resNewsData?.id) ?? '',
            preview_image_link: resNewsData?.preview_image_link ?? '',
          })
          setAddArticleButtonValidation(false)
        } else {
          const title = 'Error'
          const detail = 'News data not found'
          const type = 'error'
          notify(title, detail, type)
        }
      })
      .catch(() => {
        setArticleID('')
        setNewsArticleData({
          twitter_account_name: '',
          article_title: '',
          article_summary: '',
          article_link: '',
          id: '',
          preview_image_link: '',
        })
      })
      .finally(() => {
        setLoadingAPI(false)
        setNewsButtonValidation(true)
      })
  }

  return (
    <>
      {token && user ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-3 col-lg-2">
              <SideBar currentSelected="News" />
            </div>
            <div className="col-6 col-lg-8 Event_Container moderation-list">
              <p className="Roboto_Black d-flex moderation-list-heading">
                {`News `}&gt;{` Top stories`}
              </p>
              <div className="row mt-3 search-moderation">
                <div className="input-group col-md-4 moderation-list-search"></div>
              </div>
              <div className="moderation-list-center-container p-3 mt-3">
                <div className="mt-3">
                  <label>
                    <b>Top stories</b>
                  </label>
                  <p>
                    Select up to 10 articles in a specified order to have them
                    appear at the top of the News feed for all Users
                  </p>
                </div>
                <div className="row">
                  <div className="col-4">
                    <div className="">
                      <FormField
                        className="col-8 col-lg-7 m-0"
                        value={articleID ?? ''}
                        name="Article"
                        type="text"
                        label="Article"
                        para="Enter the article's ID found in news database"
                        placeholder="News Article ID#"
                        paraVisibility={true}
                        onChange={(e) => handleInputChange(e)}
                        characterLeftVisible={false}
                      />
                      <Button
                        type="button"
                        className="send-button mt-3"
                        name="Check Article"
                        onClick={() => getNewsArticleData(articleID)}
                        disabled={
                          newsButtonValidation || loadingAPI === true
                            ? true
                            : false
                        }
                      />
                      <Button
                        type="button"
                        className="send-button mt-3 ml-3"
                        name="Add Article"
                        onClick={() => addArticle()}
                        disabled={
                          addArticleButtonValidation ||
                          loadingAPI === true ||
                          error
                            ? true
                            : false
                        }
                      />
                    </div>
                    {error && (
                      <div className="input-feedback w-75">
                        You can only add upto 10 Articles.
                      </div>
                    )}
                    <div className="mt-3">
                      <div>
                        <b>Twitter account: </b>
                        {newsArticleData?.twitter_account_name ?? ''}
                      </div>
                      <div
                        className="thirty-chars twitter-div"
                        title={newsArticleData?.article_title}
                      >
                        <b>Article Title: </b>
                        {newsArticleData?.article_title ?? ''}
                      </div>
                      <div
                        className="thirty-chars twitter-div"
                        title={newsArticleData?.article_summary}
                      >
                        <b>Article summary: </b>
                        {newsArticleData?.article_summary ?? ''}
                        <div>
                          <b>URL</b>:
                          {newsArticleData?.article_link ? (
                            <a
                              href={newsArticleData?.article_link ?? '#'}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Article URL
                            </a>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="row table-header font-weight-bold">
                      <div className="col-2 pl-3 mr-5">NewsId</div>
                      <div className="col-3 pl-2 mr-5">Article Title</div>
                      <div className="col-5 pl-2">Article summary</div>
                    </div>
                    <hr className="row mt-3" />
                    <div>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                            >
                              {newsData.map((item, index) => (
                                <Draggable
                                  key={`activity-${index}`}
                                  draggableId={`activity-${index}`}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <div
                                        className="row p-0 mx-0 my-2 align-items-center"
                                        key={index}
                                      >
                                        <div className="col-2 row m-0 p-0">
                                          <div className="col-3 p-0">
                                            <MdOutlineDragIndicator className="default-icon" />
                                          </div>
                                          <div className="col-8 p-0 m-0">
                                            [{item?.id}]
                                          </div>
                                        </div>
                                        <div className="col-5 row">
                                          <div className="col-2">-</div>
                                          <div className="col-10 p-0 m-0">
                                            {item?.article_title
                                              ? truncateString(
                                                  item?.article_title,
                                                  27
                                                )
                                              : 'No Title Found'}
                                          </div>
                                        </div>
                                        <div className="col-5 row">
                                          <div className="col-10 p-0 m-0">
                                            {item?.article_summary
                                              ? truncateString(
                                                  item?.article_summary,
                                                  26
                                                )
                                              : 'No Summary Found'}
                                          </div>
                                          <div className="col-2">
                                            <RiDeleteBin5Fill
                                              className="default-icon ml-3"
                                              onClick={() => {
                                                deleteRow(index)
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  </div>
                </div>
                <div className="d-flex mt-5 mb-1">
                  <div className="flex-grow-1"></div>
                  <div className="w-25 ms-3">
                    <Button
                      name="Save"
                      onClick={() => handleSave()}
                      disabled={disableSave}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        navigate('/login')
      )}
    </>
  )
}
export default news
