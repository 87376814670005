import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { getToken } from '../Login/ManageUser'
import FormField from '../common/FormField'
import { notify } from '../Notify/Notify'
import axios from 'axios'
import Button from '../common/Button/Button'

const deleteModal = (props) => {
  const {
    deleteModal,
    onHide,
    userId,
    setEditData,
    setIsEdit,
    setIsModalVisible,
    selectedModerationLabel,
    getUserList,
    setKeyWords,
  } = props
  const [confirmText, setConfirmText] = useState('')

  // * Authentication token
  const V2_URL = process.env.REACT_APP_API_V2_URL
  let token = getToken()

  useEffect(() => {
    setConfirmText('')
  }, [deleteModal])

  const deleteUser = (id) => {
    let url = `${V2_URL}user/${id}`
    axios
      .delete(url, {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          const title = 'Success'
          const detail = res?.data?.message
          const type = 'success'
          notify(title, detail, type)
          setEditData({})
          setIsEdit(false)
          setIsModalVisible(false)
          getUserList(1, selectedModerationLabel?.type)
          setKeyWords('')
        }
      })
  }

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      show={deleteModal}
      onHide={onHide}
      centered
    >
      <Modal.Body className="text-center">
        <div className="notification-alert">
          <b>WARNING! THIS IS IRREVERSIBLE</b>
        </div>
        <div>Are you sure you want to delete this user permanently?</div>
        <div>
          <p className="mt-4">
            Type the message shown in the box below and then click “Yes” to
            continue.
          </p>
          <FormField
            className="w-50 mx-auto"
            value={confirmText ?? ''}
            hideLabel={true}
            name="Confirm Text"
            type="text"
            placeholder="delete user"
            autoComplete="off"
            onChange={(e) => setConfirmText(e?.target?.value)}
          />
        </div>
        <div className="d-flex justify-content-center mt-4">
          <Button
            type="button"
            className="send-button me-4"
            name="No"
            onClick={onHide}
          />
          <Button
            type="button"
            className="send-button"
            name="Yes"
            onClick={() => deleteUser(userId)}
            disabled={confirmText !== 'delete user'}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default deleteModal
