import React from 'react'
import { Modal } from 'react-bootstrap'

import Button from '../common/Button/Button'

const deleteTicketModal = (props) => {
  const { deleteModal, onHide, deleteTicketHandler } = props

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      show={deleteModal}
      onHide={onHide}
      centered
    >
      <Modal.Body className="text-center">
        <div className="mt-4">
          <b>Are you sure you want to delete this Ticket?</b>
        </div>
        <div className="d-flex justify-content-center mt-5">
          <Button
            type="button"
            className="send-button me-4"
            name="No"
            onClick={onHide}
          />
          <Button
            type="button"
            className="send-button"
            name="Yes"
            onClick={deleteTicketHandler}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default deleteTicketModal
