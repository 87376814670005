import React from 'react'
import PropTypes from 'prop-types'

import { IconContext } from 'react-icons'
import { MdAdd, MdEdit, MdDelete } from 'react-icons/md'
import './Button.scss'

const Button = (props) => {
  return (
    <button
      type="submit"
      className={`text-center Display_Btn Font_Black Submit_Button ${
        props.className
      } ${props.disabled ? 'disabled' : ''}`}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <IconContext.Provider value={{ size: '20px', className: 'icons' }}>
        {props.btnType == 'add' && <MdAdd />}
        {props.btnType == 'edit' && <MdEdit />}
        {props.btnType == 'delete' && <MdDelete />}
      </IconContext.Provider>
      {props.name}
    </button>
  )
}

Button.propTypes = {
  name: PropTypes.string,
  // disabled: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default Button
