import React, { useState, useEffect } from 'react'
import { initializeApp } from 'firebase/app'
import { getFirestore, collection, getDocs } from '@firebase/firestore'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import {
  intialObjects,
  PriorityEventGroupObj,
} from '../../constants/enumTypes.constants'
import { getToken } from '../Login/ManageUser'
import { notify } from '../Notify/Notify'
import axios from 'axios'
import Switch from 'react-switch'
import Select from 'react-select'
import Spinner from 'react-bootstrap/Spinner'
import FormField from '../common/FormField'
import Button from '../common/Button/Button'
import EventGroupPriorityModal from './EventGroupPriorityModal'
import ImageUploader from '../common/ImageUploader/ImageUploader'
import EGFinalizeModal from './EGFinalizeModal'
import './EventGroups.scss'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

const CreateEditEventGroups = (props) => {
  const {
    setIsModalVisible,
    getEventGroupList,
    setPageNumber,
    selectedEventGroupCategory,
    setSelectedEventGroupCategory,
    editData,
    setEditData,
    setIsEdit,
    isEdit,
  } = props
  const [seasonsList, setSeasonsList] = useState([])
  const [sectorList, setSectorList] = useState([])
  const [selectedSeason, setSelectedSeason] = useState('')
  const [selectedSector, setSelectedSector] = useState('')
  const [eventGroupName, setEventGroupName] = useState('')
  const [eventGroupShortName, setEventGroupShortName] = useState('')
  const [selectedCategory, setSelectedCategory] = useState(intialObjects)
  const [universal, setUniversal] = useState(intialObjects)
  const [categories, setCategories] = useState([])
  const [isPriority, setIsPriority] = useState(false)
  const [loader, setLoader] = useState(true)
  const [priorityModal, setPriorityModal] = useState(false)
  const [modalData, setModalData] = useState(PriorityEventGroupObj)
  const [overwrite, setOverwrite] = useState(false)
  const [categoryName, setCategoryName] = useState('')
  const [seasonName, setSeasonName] = useState('')
  const [isEventGroup, setIsEventGroup] = useState(false)
  const [disableSave, setDisableSave] = useState(false)
  const [buttonClass, setButtonClass] = useState({})
  const [errors, setErrors] = useState({})
  const [finalizeModal, setFinalizeModal] = useState(false)

  //Image Support
  const [imgUrl, setImgUrl] = useState()
  const [imgState, setImgState] = useState(null)
  const [imageName, setImageName] = useState()
  const [imageDisplay, setImageDisplay] = useState()

  const [imgUrlTrack, setImgUrlTrack] = useState()
  const [imgStateTrack, setImgStateTrack] = useState(null)
  const [imageNameTrack, setImageNameTrack] = useState()
  const [imageDisplayTrack, setImageDisplayTrack] = useState()

  // * Authentication token
  const BASE_URL = process.env.REACT_APP_API_BASE_URL
  const V2_URL = process.env.REACT_APP_API_V2_URL
  let token = getToken()

  // imported to fetch stats's category
  const app = initializeApp(firebaseConfig)
  const auth = getAuth(app)
  const db = getFirestore(app)

  useEffect(() => {
    signInWithEmailAndPassword(
      auth,
      process.env.REACT_APP_FIREBASE_EMAIL,
      process.env.REACT_APP_FIREBASE_PASSWORD
    ).then((userCredential) => {
      // * Signed in to firebase
      const user = userCredential?.user
      user?.accessToken && fetchCategory()
    })
  }, [])

  useEffect(() => {
    handlePriority()
  }, [isPriority])

  useEffect(() => {
    if (editData?.isLocked === true) {
      setButtonClass({ class: 'final', name: 'Finalized' })
    }
    if (editData?.isLocked === false) {
      setButtonClass({ class: '', name: 'Finalize Event Group' })
    }
  }, [editData])

  useEffect(() => {
    getSeasonsList(selectedCategory?.id)
  }, [selectedCategory])

  useEffect(() => {
    getSectorList(selectedSeason?.value)
  }, [selectedSeason])

  useEffect(() => {
    if (isEdit) {
      setEventGroupName(editData?.name)
      setEventGroupShortName(editData?.shortName)
      setSelectedCategory(selectedEventGroupCategory)
      setSelectedSeason(
        [...seasonsList].filter(
          (obj) => parseInt(obj.value) == parseInt(editData?.seasonId)
        )?.[0]
      )
      setImgUrl(editData?.eventGroupImage)
      setImgState(editData?.eventGroupImage)
      setImgUrlTrack(editData?.eventGroupMap)
      setImgStateTrack(editData?.eventGroupMap)
      setIsPriority(editData?.isPriority)
    }
  }, [categories])

  const categoryListRef = collection(db, 'Categories')
  const fetchCategory = async () => {
    const data = await getDocs(categoryListRef)
    const categoryData = data.docs.map((doc) => ({
      ...doc.data(),
      ...intialObjects,
    }))
    // * Get all the stats file
    getStatsFiles([...categoryData])
  }

  const getSeasonsList = (categoryId) => {
    let url = `${V2_URL}eventSeasons`
    if (categoryId) {
      axios
        .get(url, {
          params: {
            categoryId: categoryId,
          },
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.data != null) {
            const SeasonsList = res.data.data.map((res) => ({
              label: res.name,
              value: res.id.toString(),
            }))
            setSeasonsList(SeasonsList)
            getSectorList(selectedSeason?.id)
          }
        })
    }
  }

  const getSectorList = (seasonId) => {
    let url = `${V2_URL}sectors/${seasonId}`
    if (seasonId) {
      axios
        .get(url, {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.data != null) {
            const SectorList = res.data.data.map((res) => ({
              label: res.name,
              value: res.id.toString(),
            }))
            setSectorList(SectorList)
            if (isEdit) {
              setSelectedSector(
                [...SectorList].filter(
                  (obj) => parseInt(obj.value) === parseInt(editData?.sectorId)
                )?.[0]
              )
            }
          }
        })
    }
  }

  const handleSelectSeason = (data) => {
    setSelectedSeason(data)
    if (errors) {
      errors.selectedSeason = ''
      setErrors(errors)
    }
    setIsPriority(false)
    setSectorList([])
    setSelectedSector([])
    setOverwrite(false)
  }

  const handleSelectSector = (data) => {
    setSelectedSector(data)
  }

  //Image Support
  const handleRemove = () => {
    setImgState(null)
    setImgUrl('')
    setImageName('')
  }

  //Image Support
  const onImageChange = (picture) => {
    var maxLength = 5
    if (errors) {
      errors.eventGroupImage = ''
      setErrors(errors)
    }
    if (picture[0].file) {
      setImgState(picture[0].file)
      setImageName(picture[0].file.name)
      if (picture[0].file.name.length > maxLength) {
        setImageDisplay(picture[0].file.name.substr(0, maxLength) + '...')
      } else {
        setImageDisplay(picture[0].file.name)
      }
    }
  }

  const handleRemoveTrack = () => {
    setImgStateTrack(null)
    setImgUrlTrack('')
    setImageNameTrack('')
  }

  const onImageChangeTrack = (picture) => {
    var maxLength = 5
    if (errors) {
      errors.eventGroupImageTrack = ''
      setErrors(errors)
    }
    if (picture[0].file) {
      setImgStateTrack(picture[0].file)
      setImageNameTrack(picture[0].file.name)
      if (picture[0].file.name.length > maxLength) {
        setImageDisplayTrack(picture[0].file.name.substr(0, maxLength) + '...')
      } else {
        setImageDisplayTrack(picture[0].file.name)
      }
    }
  }

  const handleValidation = (
    eventGroupName,
    eventGroupShortName,
    selectedSeason,
    eventGroupImage,
    eventGroupImageTrack
  ) => {
    let error = {}
    if (!eventGroupName || eventGroupName == '') {
      error.eventGroupName = 'Event Group name is required'
    }
    if (!eventGroupShortName || eventGroupShortName == '') {
      error.eventGroupShortName = 'Event Group short name is required'
    }
    if (!selectedSeason.value | (selectedSeason.value == '')) {
      error.selectedSeason = 'Season Selection is required'
    }
    if (!eventGroupImage) {
      error.eventGroupImage = 'Event Group Image is required'
    }
    if (!eventGroupImageTrack) {
      error.eventGroupImageTrack = 'Event Group Track Image is required'
    }

    return error
  }

  const handleExit = () => {
    setEditData({})
    setIsEdit(false)
    setIsModalVisible(false)
    getEventGroupList(1, selectedCategory?.id)
    setSelectedEventGroupCategory(selectedCategory)
    setPageNumber(1)
  }

  const FinalizeEventGroup = () => {
    setFinalizeModal(false)
    let url = `${V2_URL}eventGroup/finalize`
    const params = {
      eventGroupId: editData.id,
    }
    axios
      .post(url, params, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setButtonClass({ class: 'final', name: 'Finalized' })
        const title = 'Success'
        const detail = res.data?.message
        const type = 'success'
        notify(title, detail, type)
      })
  }

  const handleSave = (flag) => {
    var fieldErrors = handleValidation(
      eventGroupName,
      eventGroupShortName,
      selectedSeason,
      imgState,
      imgStateTrack
    )
    setErrors(fieldErrors)
    if (Object.keys(fieldErrors)?.length === 0) {
      if (overwrite === false || flag === false) {
        let form_data = new FormData()
        form_data.append('name', eventGroupName)
        form_data.append('seasonId', parseInt(selectedSeason?.value))
        form_data.append('isPriority', isPriority)
        form_data.append('shortName', eventGroupShortName)

        if (selectedSector?.value?.length > 0) {
          form_data.append('sectorId', parseInt(selectedSector?.value))
        }
        if (!isEdit) {
          form_data.append('eventGroupImage', imgState)
          form_data.append('eventGroupMap', imgStateTrack)

          let url = `${V2_URL}eventGroups`
          setDisableSave(true)
          axios
            .post(url, form_data, {
              headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              if (res?.data?.success === true) {
                const title = 'Success'
                const detail = res?.data?.message
                const type = 'success'
                notify(title, detail, type)
                setIsModalVisible(false)
              }
            })
            .finally(() => {
              setDisableSave(false)
              getEventGroupList(1, selectedCategory?.id)
              setSelectedEventGroupCategory(selectedCategory)
              setPageNumber(1)
            })
        } else {
          if (imgState != imgUrl) {
            form_data.append('eventGroupImage', imgState)
          }
          if (imgStateTrack != imgUrlTrack) {
            form_data.append('eventGroupMap', imgStateTrack)
          }
          let url = `${V2_URL}eventGroup/${editData?.id}`
          setDisableSave(true)
          axios
            .put(url, form_data, {
              headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              if (res?.data?.success === true) {
                const title = 'Success'
                const detail = res?.data?.message
                const type = 'success'
                notify(title, detail, type)
                setIsModalVisible(false)
              }
            })
            .finally(() => {
              setDisableSave(false)
              getEventGroupList(1, selectedCategory?.id)
              setSelectedEventGroupCategory(selectedCategory)
              setPageNumber(1)
            })
        }
      }
      if (overwrite === true && isPriority === true) {
        setPriorityModal(true)
      }
    }
  }
  const handleCategoryName = (categoryId) => {
    const catObj = [...categories].filter(
      (obj) => parseInt(obj.id) == parseInt(categoryId)
    )
    setCategoryName(catObj?.[0]?.category)
  }
  const handleSeasonName = (seasonIdId) => {
    const seasonObj = [...seasonsList].filter(
      (obj) => parseInt(obj.value) == parseInt(seasonIdId)
    )
    setSeasonName(seasonObj?.[0]?.label)
  }
  const handlePriority = () => {
    if (isPriority === true && selectedSeason) {
      const url = `${V2_URL}priorityEventOrEventGroup`
      axios
        .get(url, {
          params: {
            seasonId: selectedSeason?.value,
          },
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res?.data?.success === false) {
            const title = 'error'
            const detail = res.data.message
            const type = 'error'
            notify(title, detail, type)
          }
          res?.data?.data == null ? setOverwrite(false) : setOverwrite(true)
          setModalData({
            eventGroupName: res?.data?.data?.title
              ? res?.data?.data?.title
              : res?.data?.data?.name,
          })
          setIsEventGroup(res?.data?.data?.title ? false : true)
          handleCategoryName(res?.data?.data?.category)
          handleSeasonName(res?.data?.data?.seasonId)
        })
    } else {
      setOverwrite(false)
    }
  }

  const handleNameChange = (e) => {
    const maxLength = 45
    const { value } = e.target
    if (value.length > maxLength) {
      setEventGroupName(value.substring(0, maxLength))
    } else {
      setEventGroupName(value)
    }
    if (errors) {
      errors.eventGroupName = ''
      setErrors(errors)
    }
  }

  const handleShortNameChange = (e) => {
    const maxLength = 45
    const { value } = e.target
    if (value.length > maxLength) {
      setEventGroupShortName(value.substring(0, maxLength))
    } else {
      setEventGroupShortName(value)
    }
    if (errors) {
      errors.eventGroupShortName = ''
      setErrors(errors)
    }
  }

  const handleCategoryClick = (id) => {
    const newSelectedCategory = [...categories].find(
      (item) => Number(item?.id) === Number(id)
    )
    getSeasonsList(newSelectedCategory?.id)
    setSelectedCategory(newSelectedCategory)
    setSelectedSeason('')
    setSelectedSector('')
    setIsPriority(false)
    setOverwrite(false)
  }

  const getStatsFiles = (categories) => {
    const url = `${BASE_URL}stats-file-download`
    axios
      .post(
        url,
        {},
        {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res?.data?.success) {
          const resData = res?.data?.data
          // * Set universal data
          setUniversal({
            ...universal,
            fileName: resData?.universal?.fileName ?? '',
            lastUpdated: resData?.universal?.lastUpdated ?? null,
            downloadFilePath: resData?.universal?.downloadFilePath ?? '',
            isDisable: true,
          })
          // * Set category data
          let updatedResult = [...categories].map((o1) => {
            let category = resData?.category.find((o2) => {
              return o1?.id === o2?.categoryId
            })
            if (category) {
              return {
                ...o1,
                ...category,
              }
            }
            return o1
          })
          setCategories([...updatedResult])
          setSelectedCategory([...updatedResult]?.[0])
          setLoader(false)
        }
      })
  }

  return (
    <div className="col-6 col-lg-8">
      <div className="row mt-4">
        <div className="breadcrumb">Event Groups &gt; {eventGroupName}</div>
        <div className="eventGroup-wrapper p-5">
          <div className="row mb-5">
            <div className="col-5">
              <FormField
                value={eventGroupName}
                id="eventGroupName"
                label="Name"
                name="eventGroupName"
                type="text"
                placeholder="Enter Event Group Name"
                onChange={(e) => {
                  handleNameChange(e)
                }}
                disabled={false}
              />
              {errors && (
                <div className="input-feedback">{errors.eventGroupName}</div>
              )}
            </div>
            <div className="col-5">
              <FormField
                value={eventGroupShortName}
                id="eventGroupShortName"
                label="Short Name"
                name="eventGroupShortName"
                type="text"
                placeholder="Enter Event Group Short Name"
                onChange={(e) => {
                  handleShortNameChange(e)
                }}
                disabled={false}
              />
              {errors && (
                <div className="input-feedback">
                  {errors.eventGroupShortName}
                </div>
              )}
            </div>
            <div className="row">
              <label htmlFor="categories" className="Font_Black">
                Category
              </label>
              <div>
                {loader ? (
                  <div className="text-center overflow-hidden">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  [...categories]?.map((key) => (
                    <div
                      className={`categories-label btn ${
                        Number(selectedCategory?.id) === Number(key?.id)
                          ? 'active'
                          : ''
                      }`}
                      key={`category-${key?.id}`}
                      onClick={() => handleCategoryClick(Number(key?.id))}
                    >
                      <div>
                        <img src={key?.imgURL} alt="" height={20} />
                      </div>
                      <div className="mt-2">{key?.category}</div>
                    </div>
                  ))
                )}
              </div>
            </div>
            <div className="row col-10 mt-4 d-flex">
              <div className="col-6">
                <label className="Font_Black d-flex">Season</label>
                <Select
                  options={seasonsList}
                  placeholder="Select Season"
                  value={selectedSeason}
                  isSearchable={true}
                  onChange={handleSelectSeason}
                  isOptionSelected={true}
                />
                {errors && (
                  <div className="input-feedback">{errors.selectedSeason}</div>
                )}
              </div>
              <div className="col-6">
                <label className="Font_Black d-flex">Sector (Optional)</label>
                <Select
                  options={sectorList}
                  placeholder="Select Sector"
                  value={selectedSector}
                  isSearchable={true}
                  onChange={handleSelectSector}
                  isOptionSelected={true}
                  isClearable={true}
                />
              </div>
            </div>
            <div className="row col-5">
              <label htmlFor="image" className="Font_Black d-flex">
                Event Group Image
              </label>
              <p>
                Upload an image 400 X 400 for use when viewing the Event Group
                in the app
              </p>
              <ImageUploader
                value={imgState}
                imgUrl={imgUrl}
                imageName={imageName}
                imageDisplay={imageDisplay}
                onChange={onImageChange}
                onClick={handleRemove}
                // isOptionalClose={imgUrl?.length > 0 ? true : false}
              />
              {errors && (
                <div className="input-feedback">{errors.eventGroupImage}</div>
              )}
            </div>

            <div className="row col-5">
              <label htmlFor="image" className="Font_Black d-flex">
                Track Image
              </label>
              <p>
                Upload an image 100 X 65 for use when viewing the Track Map in
                the app
              </p>
              <ImageUploader
                value={imgStateTrack}
                imgUrl={imgUrlTrack}
                imageName={imageNameTrack}
                imageDisplay={imageDisplayTrack}
                onChange={onImageChangeTrack}
                onClick={handleRemoveTrack}
                // isOptionalClose={imgUrlTrack?.length > 0 ? true : false}
              />
              {errors && (
                <div className="input-feedback">
                  {errors.eventGroupImageTrack}
                </div>
              )}
            </div>

            <div>
              <label htmlFor="hidden" className="Font_Black mt-3">
                Priority Event or Event Group?
              </label>
              <p className="Font_Regular ">
                If ENABLED, this Event or Event Group is shown as the default on
                the Stats tab for its Category, regardless of whether another
                Event or Event Group have more recent Start Time.
                <br></br>
              </p>
              <p className=" Error_Color Font_Regular ">
                <b>Note: </b>For each Category, there can only be one Priority
                Event or Event Group per season at a time.
              </p>
              <div className="row">
                <div className="col-2">
                  <Switch
                    onChange={() => setIsPriority(!isPriority)}
                    checked={isPriority}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={30}
                    width={58}
                    className="react-switch"
                    id="material-switch"
                    disabled={false}
                  />
                </div>
                <div className="col-4 col-xl-3 col-xxl-4">
                  {isPriority ? 'Priority' : 'Basic'}
                </div>
                <EventGroupPriorityModal
                  priorityModalVisible={priorityModal}
                  setPriorityModal={setPriorityModal}
                  setIsPriority={setIsPriority}
                  setOverwrite={setOverwrite}
                  modalData={modalData}
                  categoryName={selectedCategory?.category}
                  seasonName={seasonName}
                  isEventGroup={isEventGroup}
                  handleSave={handleSave}
                />
              </div>
              {Object.keys(editData).length > 0 && (
                <div>
                  <label htmlFor="hidden" className="Font_Black mt-3">
                    Gamification
                  </label>
                  <div className="col-2 p-0">
                    <Button
                      className={buttonClass.class}
                      name={buttonClass.name}
                      onClick={() =>
                        buttonClass?.name === 'Finalize Event Group' &&
                        setFinalizeModal(true)
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="d-flex mt-5 mb-1">
            <div className="flex-grow-1">
              <Button
                name="Exit"
                className="w-25"
                onClick={() => handleExit()}
              />
            </div>
            <div className="w-25 ms-3">
              <Button
                name="Save"
                onClick={() => handleSave()}
                disabled={disableSave}
              />
            </div>
          </div>
        </div>
      </div>
      <EGFinalizeModal
        finalizeModal={finalizeModal}
        onHide={() => setFinalizeModal(false)}
        finalizeSector={FinalizeEventGroup}
      />
    </div>
  )
}
export default CreateEditEventGroups
