import React from 'react'
import FormField from '../common/FormField'
import Button from '../common/Button/Button'
import Spinner from 'react-bootstrap/Spinner'
import { convertLocalTimeZone } from '../../constants/helper'

const EventInfoNotification = (props) => {
  const {
    eventInfoID,
    selectedNotification,
    eventInfoLabel,
    handleInputChange,
    selectedEventInfo,
    selectedEventInfoCategory,
    categories,
    selectedCategory,
    handleCategoryClick,
    getEventInfoPage,
    eventInfoPage,
    loader,
    eventButtonValidation,
    loadingAPI,
    categoryName,
  } = props

  return (
    <>
      {selectedNotification?.id == 4 && (
        <div className="mt-4">
          <div>
            <h6>
              <b>Notification image</b>
            </h6>
            <p>
              All Event Info Pages notification take Users to the same screen in
              the app. &apos;Category&apos; should be used when sending a
              notification that encompasses multiple Pages. It includes a
              standard image showing multiple track locales. &apos;Event Info
              Page&apos; should be used when sharing updates on a specific Page,
              as it includes the saved image for whichever Page is selected.
            </p>
          </div>
          <div className="row">
            {[...eventInfoLabel]?.map((item) => (
              <div
                className={`notification-label btn m-2 ${
                  Number(selectedEventInfo?.id) === Number(item.id)
                    ? 'active'
                    : ''
                }`}
                key={`category-${item.id}`}
                onClick={() => selectedEventInfoCategory(item.id)}
              >
                <div className="mt-2">{item?.title}</div>
              </div>
            ))}
          </div>
          {/* category eventInfo */}
          {selectedEventInfo?.id == 0 && (
            <div className="row mt-5">
              {loader ? (
                <div className="text-center overflow-hidden">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                [...categories]?.map((key) => (
                  <div
                    className={`notification-label btn m-2 ${
                      Number(selectedCategory?.id) === Number(key?.id)
                        ? 'active'
                        : ''
                    }`}
                    key={`category-${key?.id}`}
                    onClick={() => handleCategoryClick(Number(key?.id))}
                  >
                    <div>
                      <img src={key?.imgURL} alt="" height={20} />
                    </div>
                    <div className="mt-2">{key?.category}</div>
                  </div>
                ))
              )}
            </div>
          )}
          {/* Eventinfo page */}
          {selectedEventInfo?.id == 1 && (
            <div className="mt-4">
              <div className="row">
                <div className="col-5">
                  <FormField
                    className="col-8 col-lg-7 m-0"
                    value={eventInfoID ?? ''}
                    type="text"
                    placeholder="Page ID#"
                    paraVisibility={true}
                    characterLeftVisible={false}
                    onChange={(e) => handleInputChange(e, 'eventInfoID')}
                  />

                  <Button
                    type="button"
                    className="send-button mt-4"
                    name="Check Event Info"
                    onClick={() => getEventInfoPage(eventInfoID)}
                    disabled={
                      eventButtonValidation || loadingAPI === true
                        ? true
                        : false
                    }
                  />
                </div>
                <div className="col-7 mt-4">
                  <div>
                    <b>Category: </b>
                    {categoryName}
                  </div>
                  <div>
                    <b>Page name: </b>
                    {eventInfoPage?.name ?? ''}
                  </div>
                  <div>
                    <b>Start time: </b>
                    {eventInfoPage?.startTime
                      ? convertLocalTimeZone(eventInfoPage.startTime)
                      : ''}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}
export default EventInfoNotification
