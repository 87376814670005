import IntegrationsIcon from './integrations.svg'
import IntegrationsActiveIcon from './integrations_active.svg'
import FulfillmentIcon from './fulfillment.svg'
import FulfillmentActiveIcon from './fulfillment_active.svg'
import DashboardIcon from './dashboard.svg'
import DashboardActiveIcon from './dashboard_active.svg'
import NewsIcon from './news.svg'
import NewsActiveIcon from './news_active.svg'
import MessageActiveIcon from './message_active.svg'
import MessageIcon from './message.svg'
import SettingsIcon from './settings.svg'
import SettingsActiveIcon from './settings_active.svg'
import DropDownIcon from './chevron.svg'
import UploadIcon from './upload.svg'
import RemoveIcon from './remove_image.svg'
import NotificationIcon from './notification.svg'
import NotificationActiveIcon from './notification_active.svg'

export const EventsIcons = {
  IntegrationsIcon,
  IntegrationsActiveIcon,
  FulfillmentIcon,
  FulfillmentActiveIcon,
  DashboardIcon,
  DashboardActiveIcon,
  SettingsIcon,
  NewsIcon,
  NewsActiveIcon,
  MessageActiveIcon,
  MessageIcon,
  SettingsActiveIcon,
  DropDownIcon,
  UploadIcon,
  RemoveIcon,
  NotificationIcon,
  NotificationActiveIcon,
}
