import React from 'react'
import { Modal } from 'react-bootstrap'

import {
  userButtonColorList,
  statusInfoMapping,
  userRoleMapping,
} from '../../../constants/enumTypes.constants'

const permissionModel = (props) => {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
      <Modal.Body className="show-grid">
        <div className="container status-info-modal">
          {Object.keys(statusInfoMapping).map((item, index) => (
            <div className="row py-2" key={`user-${index}`}>
              <div
                className="info-button col-2"
                style={{
                  backgroundColor: userButtonColorList[item],
                }}
              >
                {userRoleMapping[item]}
              </div>
              <div className="col-10">{statusInfoMapping[item]}</div>
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default permissionModel
