import React from 'react'

import CheckCrossIcon from '../CheckCrossIcon/CheckCrossIcon'
import './DropDown.scss'

const DropDown = (props) => {
  return (
    <div className={`dropdown ${props.className ? props.className : ''}`}>
      <select
        key={props.defaultValue}
        className="form-select Bg_Senary_Color dropdown-label"
        aria-label="Providers list"
        onChange={props.onChange}
        value={props.defaultValue}
        disabled={props.disabled}
      >
        {props.dropDownValues.map((dropDownItem, dindex) => {
          return (
            <option
              key={dindex}
              value={dropDownItem}
              className="dropdown-item Bg_Senary_Color"
            >
              {dropDownItem}
            </option>
          )
        })}
      </select>
      {(props.displayValue === true || props.displayValue === false) && (
        <span>
          <CheckCrossIcon isCheck={props.displayValue === true} />
        </span>
      )}
    </div>
  )
}

export default DropDown
