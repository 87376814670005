import React from 'react'
import { Button, Modal } from 'react-bootstrap'

const confirmationModal = (props) => {
  const {header,show,...rest}=props
  return (
    <Modal
      show={show}
      className="delete-poll-modal"
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <p className="text-center Font_Black">{header}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.handleNoClick}>No</Button>
        <Button onClick={props.handleYesClick}>Yes</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default confirmationModal
