import React, { useState, useEffect, useRef } from 'react'
import { initializeApp } from 'firebase/app'
import { getFirestore, collection, getDocs } from '@firebase/firestore'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import axios from 'axios'
import Spinner from 'react-bootstrap/Spinner'

import { getToken, getUser } from '../Login/ManageUser'
import { notify } from '../Notify/Notify'
import SideBar from '../common/Sidebar'
import FileUpload from './FileUpload'
import './Statistics.scss'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)

const statistics = (props) => {
  const universalLabel = [
    'Categories',
    'Event Types',
    'Nationalities',
    'Teams',
    'People',
  ]

  const intialObjects = {
    file: '',
    fileName: '',
    lastUpdated: null,
    downloadFilePath: '',
    isDisable: true,
  }
  // * Ref for file upload
  const universalRef = useRef()
  const categoryRef = useRef()

  // * Authentication token
  const BASE_URL = process.env.REACT_APP_API_BASE_URL
  const token = getToken()
  const user = getUser()

  // * stats
  const [universal, setUniversal] = useState(intialObjects)
  const [selectedCategory, setSelectedCategory] = useState(intialObjects)
  const [categories, setCategories] = useState([])
  const [loader, setLoader] = useState(true)
  // * Get all the files (universal and category)
  const getStatsFiles = (categories) => {
    const url = `${BASE_URL}stats-file-download`
    axios
      .post(
        url,
        {},
        {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res?.data?.success) {
          const resData = res?.data?.data
          // * Set universal data
          setUniversal({
            ...universal,
            fileName: resData?.universal?.fileName ?? '',
            lastUpdated: resData?.universal?.lastUpdated ?? null,
            downloadFilePath: resData?.universal?.downloadFilePath ?? '',
            isDisable: true,
          })
          // * Set category data
          let updatedResult = [...categories].map((o1) => {
            let category = resData?.category.find((o2) => {
              return o1?.id === o2?.categoryId
            })
            if (category) {
              return {
                ...o1,
                ...category,
              }
            }
            return o1
          })
          setCategories([...updatedResult])
          setSelectedCategory([...updatedResult]?.[0])
          setLoader(false)
        }
      })
  }

  // * Get category list from the firebase
  const categoryListRef = collection(db, 'Categories')

  const fetchCategory = async () => {
    const data = await getDocs(categoryListRef)
    const categoryData = data.docs.map((doc) => ({
      ...doc.data(),
      ...intialObjects,
    }))
    // * Get all the stats file
    getStatsFiles([...categoryData])
  }

  useEffect(() => {
    signInWithEmailAndPassword(
      auth,
      process.env.REACT_APP_FIREBASE_EMAIL,
      process.env.REACT_APP_FIREBASE_PASSWORD
    ).then((userCredential) => {
      // * Signed in to firebase
      const user = userCredential?.user
      user?.accessToken && fetchCategory()
    })
  }, [])

  // * Update the category data change
  const updateCategoryData = (updatedObj) => {
    const updatedCategoryList = [...categories].map((item) => {
      let result = item
      if (Number(item?.id) === Number(selectedCategory?.id)) {
        result = { ...selectedCategory, ...updatedObj }
      }
      return result
    })
    setCategories([...updatedCategoryList])
    setSelectedCategory({ ...selectedCategory, ...updatedObj })
  }

  // * File upload handler
  const handleFileClick = (event) => {
    event.target.value = ''
  }

  const handleFileUpload = (isUniversal) => {
    const uploadedFile = isUniversal
      ? universalRef.current?.files?.[0]
      : categoryRef.current?.files?.[0]
    const updatedObj = {
      file: uploadedFile,
      fileName: uploadedFile?.name,
      lastUpdated: new Date(),
      isDisable: false,
    }

    if (isUniversal) {
      setUniversal({ ...universal, ...updatedObj })
    } else {
      updateCategoryData(updatedObj)
    }
  }

  // * File removal handler
  const handleFileRemoval = (isUniversal) =>
    isUniversal
      ? setUniversal(intialObjects)
      : setSelectedCategory({ ...selectedCategory, ...intialObjects })

  // * Category change handler
  const handleCategoryClick = (id) => {
    const newSelectedCategory = [...categories].find(
      (item) => Number(item?.id) === Number(id)
    )
    setSelectedCategory(newSelectedCategory)
  }

  const FileUploadHandle = (isUniversal) => {
    let form_data = new FormData()
    const url = `${BASE_URL}event-stats-data`
    if (isUniversal) {
      // * Universal file
      form_data.append('type', 'universal')
      form_data.append('file', universal?.file)
    } else {
      // * Category file
      form_data.append('type', 'category-level')
      form_data.append('file', selectedCategory?.file)
      form_data.append('category_id', selectedCategory?.id)
    }
    axios
      .post(url, form_data, {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.data?.success) {
          const newUpload = {
            fileName: res.data.data?.fileName ?? '',
            lastUpdated: res.data.data?.lastUpdated ?? null,
            downloadFilePath: res.data.data?.downloadFilePath ?? '',
            isDisable: true,
          }
          if (isUniversal) {
            setUniversal({
              ...universal,
              ...newUpload,
            })
          } else {
            updateCategoryData(newUpload)
          }
          const title = 'Success'
          const detail = res.data?.message
          const type = 'success'
          notify(title, detail, type)
        }
      })
  }

  return (
    <>
      {token && user ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-3 col-lg-2">
              <SideBar
                currentSelected="Events"
                currentsubSelected="Statistics"
              />
            </div>
            <div className="col-8">
              <div className="row breadcrumb ps-3">Statistics</div>
              <div className="statistics-container p-3">
                {/* Universal Data*/}
                <div className="row">
                  <h5 className="ps-2 mb-3">Universal data</h5>
                  {[...universalLabel].map((item, index) => (
                    <div className="stats-label m-2" key={`${item}-${index}`}>
                      {item}
                    </div>
                  ))}

                  <FileUpload
                    fileType="universal"
                    inputFileName={universal?.fileName}
                    inputFileURL={universal?.downloadFilePath}
                    inputFileModifiedTs={universal?.lastUpdated}
                    isUploadBtnDisable={universal?.isDisable}
                    handleFileClick={handleFileClick}
                    handleFileUpload={handleFileUpload}
                    FileUploadHandle={FileUploadHandle}
                    handleFileRemoval={handleFileRemoval}
                    ref={universalRef}
                  />
                </div>

                {/* Categry-level data */}
                <div className="row mt-5">
                  <h5 className="ps-2 mb-3">Category</h5>
                  {loader ? (
                    <div className="text-center overflow-hidden">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  ) : (
                    [...categories].map((key) => (
                      <div
                        className={`stats-label btn m-2 ${
                          Number(selectedCategory?.id) === Number(key?.id)
                            ? 'active'
                            : ''
                        }`}
                        key={`category-${key?.id}`}
                        onClick={() => handleCategoryClick(Number(key?.id))}
                      >
                        <div>
                          <img src={key?.imgURL} alt="" height={20} />
                        </div>
                        <div className="mt-2">{key?.category}</div>
                      </div>
                    ))
                  )}
                </div>

                {/* Category Labels */}
                <div className="row mt-5">
                  <h5 className="ps-2 mb-3">Category tables</h5>
                  {loader ? (
                    <div className="text-center overflow-hidden">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  ) : selectedCategory?.labels?.length > 0 ? (
                    <>
                      {[...selectedCategory.labels].map((item) => (
                        <div className="stats-label m-2" key={item}>
                          {item}
                        </div>
                      ))}
                      <FileUpload
                        fileType="category"
                        inputFileName={selectedCategory?.fileName}
                        inputFileURL={selectedCategory?.downloadFilePath}
                        inputFileModifiedTs={selectedCategory?.lastUpdated}
                        isUploadBtnDisable={selectedCategory?.isDisable}
                        handleFileClick={handleFileClick}
                        handleFileUpload={handleFileUpload}
                        FileUploadHandle={FileUploadHandle}
                        handleFileRemoval={handleFileRemoval}
                        ref={categoryRef}
                      />
                    </>
                  ) : (
                    <label> No tables for this Category.</label>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        navigate('/login')
      )}
    </>
  )
}

export default statistics
