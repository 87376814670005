import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { IconContext } from 'react-icons'
import { initializeApp } from 'firebase/app'
import { AiOutlineSearch } from 'react-icons/ai'
import { MdModeEditOutline } from 'react-icons/md'
import { getToken, getUser } from '../Login/ManageUser'
import { convertLocalTimeZone } from '../../constants/helper'
import {
  moderationLabel,
  moderationStatusCode,
} from '../../constants/enumTypes.constants'
import Pagination from '../common/Pagination/Pagination'
import SideBar from '../common/Sidebar'
import Button from '../common/Button/Button'
import Spinner from 'react-bootstrap/Spinner'
import axios from 'axios'
import moment from 'moment'
import './Moderation.scss'
import EditUser from './EditUser'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

const moderation = () => {
  // *  useNavigate used for redirect to login
  let navigate = useNavigate()
  // * Authentication variables
  const BASE_URL = process.env.REACT_APP_API_BASE_URL
  const V2_URL = process.env.REACT_APP_API_V2_URL

  const token = getToken()
  const user = getUser()

  // * Component state
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [userList, setUserList] = useState([])
  const [userCount, setUserCount] = useState('TBD')
  const [activeCount, setActiveCount] = useState('TBD')
  const [bannedCount, setBannedCount] = useState('TBD')
  const [suspendedCount, setSuspendedCount] = useState('TBD')
  const [deletionCount, setDeletionCount] = useState('TBD')
  const [keyWord, setKeyWords] = useState('')
  const [totalRecords, setTotalRecords] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  const [selectedModerationLabel, setSelectedModerationLabel] = useState(
    moderationLabel[0]
  )
  const [loader, setLoader] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [editData, setEditData] = useState({})

  const perPageRecords = 25

  // imported to fetch stats's category
  const app = initializeApp(firebaseConfig)

  useEffect(() => {
    getUserList(1, 'all')
  }, [])

  // * Open Edit Modal
  const handleEditModal = () => {
    setIsModalVisible(true)
  }

  //Edit User
  const editUser = (value) => {
    setIsEdit(true)
    setEditData(value)
    setIsModalVisible(true)
  }

  //get user from Search keywords
  const hanldeSearchInput = (e) => {
    setKeyWords(e.target.value)
  }

  //for pagination
  const handlePageChange = (e) => {
    setPageNumber(e.selected + 1)
    getUserList(e.selected + 1, selectedModerationLabel.type)
  }

  const handleLabelClick = (id) => {
    setKeyWords('')
    const newSelectedCategory = [...moderationLabel].find(
      (item) => Number(item?.id) === Number(id)
    )
    setSelectedModerationLabel(newSelectedCategory)
    getUserList(1, newSelectedCategory?.type)
  }

  const SearchUser = (isSearch) => {
    if (isSearch === true && keyWord.length > 0) {
      let url = `${V2_URL}getUserList`
      axios
        .get(url, {
          params: {
            search: keyWord,
            perPage: perPageRecords,
            type: selectedModerationLabel?.type,
          },
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.data != null) {
            setUserList(res.data.data)
            setTotalRecords(res?.data?.extra?.total)
          }
        })
        .finally(() => {
          setLoader(false)
        })
    }
    if (keyWord.length === 0) {
      getUserList(1, selectedModerationLabel?.type)
    }
  }

  const getUserList = (page, type) => {
    setLoader(true)
    let url = `${V2_URL}getUserList`
    axios
      .get(url, {
        params: {
          page: page > 1 ? page : 1,
          perPage: perPageRecords,
          type: type,
        },
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.data != null) {
          let total =
            res.data.extra.counts.active +
            res.data.extra.counts.banned +
            res.data.extra.counts.suspended +
            res.data.extra.counts.deletionPending
          setUserList(res.data.data)
          setUserCount(total)
          setActiveCount(res.data.extra.counts.active)
          setBannedCount(res.data.extra.counts.banned)
          setSuspendedCount(res.data.extra.counts.suspended)
          setDeletionCount(res.data.extra.counts.deletionPending)
          setTotalRecords(res?.data?.extra?.total)
        }
      })
      .finally(() => {
        setLoader(false)
      })
  }

  return (
    <>
      {token && user ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-3 col-lg-2">
              <SideBar currentSelected="Moderation" />
            </div>

            {isModalVisible ? (
              <EditUser
                setIsModalVisible={setIsModalVisible}
                editData={editData}
                setEditData={setEditData}
                setIsEdit={setIsEdit}
                isEdit={isEdit}
                getUserList={getUserList}
                selectedModerationLabel={selectedModerationLabel}
                setKeyWords={setKeyWords}
              />
            ) : (
              <>
                <div className="col-6 col-lg-8 Event_Container moderation-list">
                  <p className="Roboto_Black d-flex moderation-list-heading">{`Moderation `}</p>
                  <div className="row ml-1">
                    {[...moderationLabel]?.map((key) => (
                      <div
                        className={`categories-label btn ${
                          Number(selectedModerationLabel?.id) ===
                          Number(key?.id)
                            ? 'activeTab'
                            : ''
                        }`}
                        key={`category-${key?.id}`}
                        onClick={() => handleLabelClick(Number(key?.id))}
                      >
                        <div className="mt-2">{key?.title}</div>
                      </div>
                    ))}
                  </div>
                  <div className="row mt-3 search-moderation">
                    <div className="input-group col-md-4 moderation-list-search">
                      <input
                        className="form-control py-3 border-right-0 border moderation-list-search-box"
                        type="search"
                        placeholder="Search for an Name, Username or UserId"
                        id="example-search-input"
                        value={keyWord}
                        onChange={(e) => hanldeSearchInput(e)}
                        onKeyDown={(e) =>
                          SearchUser(e.key.toLowerCase() === 'enter')
                        }
                      />
                      <span className="input-group-append Bg_Quaternary_Color moderation-list-search-box">
                        <button
                          className="btn Font_Quinary_Color border-left-0 border"
                          type="button"
                          onClick={() => SearchUser(true)}
                        >
                          <IconContext.Provider
                            value={{ color: 'Font_Quinary_Color' }}
                          >
                            <AiOutlineSearch />
                          </IconContext.Provider>
                        </button>
                      </span>
                    </div>
                  </div>
                  <div className="moderation-list-center-container p-3 mt-3">
                    <div className="row moderation-list-header Font_Black Font_Quinary_Color">
                      <div className="col-1">UserId</div>
                      <div className="col-3">Username</div>
                      <div className="col-3">Name</div>
                      <div className="col-2">Status</div>
                      <div className="col-2">Scheduled deletion</div>
                      <div className="col-1">Modify</div>
                    </div>
                    <hr className="row mt-3 mb-3"></hr>
                    <div className="moderation-list-table">
                      {loader ? (
                        <div className="text-center overflow-hidden">
                          <Spinner animation="border" variant="primary" />
                        </div>
                      ) : userList.length > 0 ? (
                        userList?.length > 0 &&
                        userList.map((user, index) => {
                          let statusMap = moderationStatusCode.find(
                            (element) => element.id === user.status
                          )
                          const date = moment(user.deleteRequestedAt).utc() // construct date here in UTC
                          const NdaysAdded = moment(date).add(90, 'days')
                          const tenAMDateAfter90Days = moment(date)
                            .add(90, 'days')
                            .set({ hour: 10, minute: 0, second: 0 })
                          let finalDate
                          if (NdaysAdded.isBefore(tenAMDateAfter90Days)) {
                            finalDate = tenAMDateAfter90Days
                          } else {
                            finalDate = moment(tenAMDateAfter90Days).add(
                              1,
                              'day'
                            )
                          }
                          return (
                            <div
                              key={index}
                              className={`moderation-list-table-row d-flex align-items-center ml-1`}
                            >
                              <div className="col-1">
                                <b>{user.id}</b>
                              </div>
                              <div
                                className={`col-3 ${
                                  !user.is_profile_completed &&
                                  !user.username &&
                                  'text-italic'
                                }`}
                              >
                                <b>
                                  {!user.is_profile_completed && !user.username
                                    ? 'No profile set'
                                    : user.username}
                                </b>
                              </div>
                              <div
                                className={`col-3 ${
                                  !user.is_profile_completed &&
                                  !user.name &&
                                  'text-italic'
                                }`}
                              >
                                {!user.is_profile_completed && !user.name
                                  ? 'No profile set'
                                  : user.name}
                              </div>
                              <div
                                className={`col-2 ${
                                  userList.length >= 10 ? 'pl-4' : ''
                                }`}
                              >
                                {statusMap && statusMap.status}
                              </div>
                              <div className="col-2">
                                {user.deleteRequestedAt
                                  ? convertLocalTimeZone(finalDate)
                                  : null}
                              </div>
                              <div className="col-1">
                                <MdModeEditOutline
                                  className="default-icon"
                                  onClick={() => editUser(user)}
                                />
                              </div>
                            </div>
                          )
                        })
                      ) : (
                        <div className="row justify-content-center mt-3 m-0">
                          No user found
                        </div>
                      )}
                    </div>
                    <div className="row m-0 pt-4">
                      <div className="col ps-2">
                        {totalRecords > 25 && (
                          <Pagination
                            onPageChange={handlePageChange}
                            pageCount={Math.ceil(totalRecords / perPageRecords)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-3 col-lg-2 Preview_Container ">
                  <div className="row pb-3 user-preview">
                    <div className="preview-text py-4 ml-2">
                      Total Users: {userCount}
                    </div>
                    <div className="preview-helper-text">
                      <b>Active: {activeCount}</b>
                    </div>
                    <div className="preview-helper-text">
                      <b>Banned: {bannedCount}</b>
                    </div>
                    <div className="preview-helper-text">
                      <b>Suspended: {suspendedCount}</b>
                    </div>
                    <div className="preview-helper-text">
                      <b>Deletion Pending: {deletionCount}</b>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        navigate('/login')
      )}
    </>
  )
}
export default moderation
