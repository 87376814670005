import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

import Button from '../../../common/Button/Button'
import FormField from '../../../common/FormField/FormField'
import ColorPicker from '../../../common/ColorPicker'
import ImageUploader from '../../../common/ImageUploader'
import DropDown from '../../../common/DropDown/DropDown'
import Clipboard from '../../../common/Clipboard/Clipboard'
import { getToken, getUser } from '../../../Login/ManageUser'
import ProviderPreviewSection from './../ProviderPreviewSection'
import { notify } from '../../../Notify/Notify'
import './CreateEditProvider.scss'

const CreateEditProvider = (props) => {
  const { provider, isCreate, setIsModalVisible, getProviderList, setLoader } =
    props
  // * useNavigate used for redirect to login
  let navigate = useNavigate()

  // * Authentication variables
  const BASE_URL = process.env.REACT_APP_API_BASE_URL

  const token = getToken()
  const user = getUser()

  // * Component state
  const [providerName, setProviderName] = useState('')
  const [fontColor, setFontColor] = useState('#ffffff')
  const [barColor, setBarColor] = useState('#ffffff')
  const [w2wLanguage, setW2WLanguage] = useState('')
  const [providerLogo, setProviderLogo] = useState(null)
  const [imgName, setImgName] = useState()
  const [imageDisplay, setImageDisplay] = useState()
  const [errors, setErrors] = useState({})
  const [referralUrl, setReferralUrl] = useState('')
  const [copied, setCopied] = useState(false)
  const [isAffiliateProgram, setAffiliateProgram] = useState(true)
  const [isTrialOffer, setTrialOffer] = useState(true)
  const [errorMessage, setErrorMessage] = useState()
  const [isProviderLogoChange, setProviderLogoChange] = useState(false)
  const [providerLogoUrl, setProviderLogoUrl] = useState()
  const dropDownValues = ['Yes', 'No']

  useEffect(() => {
    // *   * Fecth Provider Details if avaiable on component mount
    if (!isCreate) {
      setProviderFields(provider)
    }
  }, [])

  const handleCopyClick = () => {
    setCopied(true)
    setTimeout(() => setCopied(false), 1000)
  }

  // * Populate provider fields for Edit
  const setProviderFields = (data) => {
    if (data.providerName) {
      setProviderName(data.providerName)
    }
    if (data.providerLogo) {
      setProviderLogo(data.providerLogo)
      setProviderLogoUrl(data.providerLogo)
    }
    if (data.w2wLanguage) {
      setW2WLanguage(data.w2wLanguage)
    }
    if (data.referralURL) {
      setReferralUrl(data.referralURL)
    }
    if (data.fontColor) {
      setFontColor(data.fontColor)
    }
    if (data.barColor) {
      setBarColor(data.barColor)
    }
    if (data.isAffiliateProgram != null) {
      setAffiliateProgram(data.isAffiliateProgram)
    }
    if (data.isTrialOffer != null) {
      setTrialOffer(data.isTrialOffer)
    }
  }

  // * Handle Submit Validation
  const handleValidation = (
    providerName,
    providerLogo,
    w2wLanguage,
    referralUrl
  ) => {
    let errors = {}
    if (!providerName || providerName == '') {
      errors.providerName = 'Provider name is required'
    }
    if (!providerLogo) {
      errors.providerLogo = 'Image is required'
    }
    if (!w2wLanguage || w2wLanguage == '') {
      errors.w2wLanguage = 'W2W Language is required'
    }
    if (!referralUrl || referralUrl == '') {
      errors.referralUrl = 'Referral URL is required'
    }
    return errors
  }

  // * Create or Update Provider
  const submitProvider = () => {
    var fieldErrors = handleValidation(
      providerName,
      providerLogo,
      w2wLanguage,
      referralUrl
    )
    setErrors(fieldErrors)
    if (Object.keys(fieldErrors).length === 0) {
      let form_data = new FormData()
      form_data.append('providerName', providerName)
      form_data.append('isAffiliateProgram', isAffiliateProgram)
      form_data.append('isTrialOffer', isTrialOffer)
      form_data.append('w2wLanguage', w2wLanguage)
      form_data.append('barColor', barColor)
      form_data.append('fontColor', fontColor)
      form_data.append('referralURL', referralUrl)
      form_data.append('providerLogo', providerLogo)
      setLoader(true)
      // * Update Provider based on provider Id
      if (!isCreate) {
        form_data.append('isProviderLogoChange', isProviderLogoChange)
        const url = `${BASE_URL}w2w-provider/${provider?.id}`
        axios
          .put(url, form_data, {
            headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (res?.data?.success) {
              const title = 'Success'
              const detail = res?.data?.message
              const type = 'success'
              notify(title, detail, type)
              getProviderList()
            }
            setErrorMessage('')
          })
          .catch((err) => {
            setErrorMessage(err?.response?.data?.message)
          })
      } else {
        // * Create new Provider
        const url = `${BASE_URL}w2w-providers/`
        axios
          .post(url, form_data, {
            headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (res?.data?.success) {
              const title = 'Success'
              const detail = res?.data?.message
              const type = 'success'
              notify(title, detail, type)
              getProviderList()
            }
            setErrorMessage('')
          })
          .catch((err) => {
            setErrorMessage(err?.response?.data?.message)
          })
      }
      setIsModalVisible(false)
    }
  }

  // * Handle Affilate Program
  const handleAffiliateProgram = (dropDownItem) => {
    setAffiliateProgram(dropDownItem.target.value.toUpperCase() == 'YES')
  }
  // * Handle Trial Offer
  const handleTrialOffer = (dropDownItem) => {
    setTrialOffer(dropDownItem.target.value.toUpperCase() == 'YES')
  }

  // * Handle Provider Name
  const handleProviderName = (e) => {
    if (errors) {
      errors.providerName = ''
      setErrors(errors)
    }
    var maxLengthInput = 45
    if (e.target.value.length >= 0) {
      if (e.target.value.length > maxLengthInput) {
        e.target.value = e.target.value.substr(0, maxLengthInput)
      }
      setProviderName(e.target.value)
    } else {
      setProviderName('')
    }
  }

  // * Handle Provider Logo
  const providerLogoChange = (picture) => {
    var maxLength = 5
    if (errors) {
      errors.providerLogo = ''
      setErrors(errors)
    }
    if (picture[0].file) {
      setProviderLogoChange(true)
      setProviderLogo(picture[0].file)
      setImgName(picture[0].file.name)
      if (picture[0].file.name.length > maxLength) {
        setImageDisplay(picture[0].file.name.substr(0, maxLength) + '...')
      } else {
        setImageDisplay(picture[0].file.name)
      }
    }
  }

  // * Handle Provider Logo remove
  const handleRemove = () => {
    setProviderLogoChange(false)
    setProviderLogo(null)
    setImgName(null)
  }

  useEffect(() => {}, [isAffiliateProgram, isTrialOffer, providerLogo])

  // * Hanlde Referral URL
  const handleReferralUrl = (e) => {
    if (errors) {
      errors.referralUrl = ''
      setErrors(errors)
    }
    setReferralUrl(e.target.value)
    setCopied(false)
  }

  // * Handle W2W Language change to clip w2wLanguage to 24 characters
  const handleW2WLanguage = (e) => {
    if (errors) {
      errors.w2wLanguage = ''
      setErrors(errors)
    }
    var maxLengthInput = 24
    if (e.target.value.length >= 0) {
      if (e.target.value.length > maxLengthInput) {
        e.target.value = e.target.value.substr(0, maxLengthInput)
      }
      setW2WLanguage(e.target.value)
    } else {
      setW2WLanguage('')
    }
  }

  return (
    <>
      {token && user ? (
        <>
          <div className="col-6 col-lg-8 ">
            <div className="Event_Container ms-1xl-5 ms-xl-0">
              <div>
                <div className="row d-flex">
                  <div className="col-12">
                    <p className="Roboto_Black Provider_Name">
                      {providerName.length > 0 &&
                        (provider != null
                          ? `${providerName} > Edit Provider`
                          : `${providerName} > Create Provider`)}
                    </p>
                  </div>
                </div>
                <div className="Center_Container p-5">
                  <div className="row d-flex">
                    <div className="col-12 col-lg-6">
                      <FormField
                        value={providerName}
                        id="providerName"
                        label="Provider name"
                        name="providerName"
                        type="text"
                        placeholder="Enter provider name"
                        onChange={(e) => {
                          handleProviderName(e)
                        }}
                      />
                      <div>
                        {errors && (
                          <div className="input-feedback">
                            {errors.providerName}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-lg-6">
                      <label htmlFor="image" className="Font_Black mb-0 d-flex">
                        Logo Image
                      </label>
                      <label htmlFor="image">(Images are 54x28)</label>
                      <ImageUploader
                        value={providerLogo}
                        onChange={providerLogoChange}
                        imageName={imgName}
                        imageDisplay={imageDisplay}
                        onClick={handleRemove}
                        imgUrl={providerLogoUrl}
                      />
                      <div>
                        {errors && (
                          <div className="input-feedback">
                            {errors.providerLogo}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row d-flex mt-4">
                    <div className="col-12 col-lg-6">
                      <label htmlFor="image" className="Font_Black d-flex">
                        Affiliate program
                      </label>
                      <p>Do they offer commission for referrals?</p>
                      <DropDown
                        dropDownValues={dropDownValues}
                        onChange={handleAffiliateProgram}
                        displayValue={isAffiliateProgram}
                        defaultValue={isAffiliateProgram ? 'Yes' : 'No'}
                        className="w-75"
                      />
                    </div>
                    <div className="col-12 col-lg-6">
                      <label htmlFor="image" className="Font_Black d-flex">
                        Trial offer
                      </label>
                      <p>Do they offer a trial promotion for new users?</p>
                      <DropDown
                        dropDownValues={dropDownValues}
                        onChange={handleTrialOffer}
                        displayValue={isTrialOffer}
                        defaultValue={isTrialOffer ? 'Yes' : 'No'}
                        className="w-75"
                      />
                    </div>
                  </div>

                  <div className="row d-flex mt-4">
                    <div className="col-12 col-lg-6">
                      <FormField
                        id="W2WLanguage"
                        value={w2wLanguage}
                        label="W2W Language"
                        para="Capped at 24 characters"
                        paraVisibility={true}
                        name="W2WLanguage"
                        type="text"
                        className="col-8  "
                        onChange={handleW2WLanguage}
                      />
                      <div>
                        {errors && (
                          <div className="input-feedback">
                            {errors.w2wLanguage}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-lg-2">
                      <label
                        htmlFor="bar_icon"
                        className="Font_Black Color_Picker_Bar"
                      >
                        Bar color
                      </label>
                      <ColorPicker
                        fontColor={barColor}
                        passFontColor={setBarColor}
                      />
                    </div>
                    <div className="col-12 col-lg-2">
                      <label
                        htmlFor="font_icon"
                        className="Font_Black Color_Picker_Font"
                      >
                        Font color
                      </label>
                      <ColorPicker
                        fontColor={fontColor}
                        passFontColor={setFontColor}
                      />
                    </div>
                  </div>

                  <div className="row d-flex mt-4">
                    <div className="col-6">
                      <FormField
                        id="referralUrl"
                        value={referralUrl}
                        label="Referral URL"
                        name="referralUrl"
                        type="text"
                        onChange={(e) => handleReferralUrl(e)}
                      />
                      <div>
                        {errors && (
                          <div className="input-feedback">
                            {errors.referralUrl}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-1 mt-5">
                      <Clipboard
                        urlValue={referralUrl}
                        onCopy={() => handleCopyClick()}
                        copied={copied}
                      />
                    </div>
                  </div>
                  {errorMessage && (
                    <div className="d-flex error-message justify-content-center align-items-center Font_Black text-center text-danger">
                      {errorMessage}
                    </div>
                  )}
                  <div className="d-flex mt-5 mb-1">
                    <div className="flex-grow-1">
                      <Button
                        name="Exit"
                        className="w-25"
                        onClick={() => setIsModalVisible(false)}
                      />
                    </div>
                    <div className="w-25 ms-3">
                      <Button name="Save" onClick={() => submitProvider()} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-3 col-lg-2 Preview_Container">
            <ProviderPreviewSection
              showCreateProviderBtn={false}
              providerList={[
                {
                  w2wLanguage: w2wLanguage,
                  barColor: barColor,
                  fontColor: fontColor,
                  providerLogo: providerLogo,
                },
              ]}
            />
          </div>
        </>
      ) : (
        navigate('/login')
      )}
    </>
  )
}
export default CreateEditProvider
