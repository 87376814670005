import React from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import alertIcon from '../../assets/icons/ic_alert.svg'
import successIcon from '../../assets/icons/ic_success.svg'
import warningIcon from '../../assets/icons/ic_warning.svg'
import './Notify.scss'

export const notify = (title, detail, type) => {
  const typeIconMapping = {
    alert: alertIcon,
    success: successIcon,
    warning: warningIcon,
    error: warningIcon,
  }

  const ToastMessage = ({ title, detail, type }) => (
    <div className="toast-container">
      <img src={typeIconMapping[type]} alt="Icon" width="16" height="16" />
      <div className="toast-content">
        <h2>{title}</h2>
        <p>{detail}</p>
      </div>
    </div>
  )

  toast(<ToastMessage title={title} detail={detail} type={type} />, {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  })
}
