import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as EmailValidator from 'email-validator'
import axios from 'axios'

import PasswordReset from '../PasswordModals/PasswordReset'
import logo from '../../../assets/img/logo.png'
import { notify } from '../../Notify/Notify'
require('../../Login/Login.scss')

const forgetPassword = () => {
  const BASE_URL = process.env.REACT_APP_API_BASE_URL
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [resetModal, setResetModal] = useState(false)

  const navigate = useNavigate()
  const handleEmailChange = (event) => {
    const newEmail = event?.target?.value?.trim() ?? ''
    setEmail(newEmail)
    const emailTest = EmailValidator.validate(newEmail)
    emailTest && setError('')
    !emailTest && setError('Invalid email address.')
    newEmail.length === 0 && setError('Email is required.')
  }

  const onLogin = () => navigate('/login')

  const onSubmit = () => {
    if (error.length === 0 && email.length > 0) {
      let url = `${BASE_URL}forgot-password`

      const params = {
        email: email.toLowerCase(),
      }
      axios.post(url, params).then((res) => {
        if (res?.data?.success) {
          setResetModal(true)
        } else {
          setError(res.data.message)
        }
      })
    } else {
      setError('Email is required.')
    }
  }

  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center container">
        <div className="text-center mb-4">
          <img src={logo} alt="logo" />
        </div>
        <div className="form-group password-group my-1">
          <label className="Font_Black">Account Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => handleEmailChange(e)}
            name={email}
            placeholder="Enter your email address"
            className="form-control"
          />
          <p className="text-danger">{error}</p>
        </div>
        <div className="text-center Font_Black Submit_Button mt-2 ">
          <button
            type="submit"
            className="login-button w-100"
            onClick={onSubmit}
          >
            Proceed
          </button>
        </div>
      </div>
      <PasswordReset
        show={resetModal}
        onHide={() => onLogin()}
        title="Password reset requested"
        body="If there is an account associated with that email address, the user will receive an email with instructions to reset their password."
        buttonlabel="OK"
      />
    </>
  )
}

export default forgetPassword
