import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import { usePubNub } from 'pubnub-react'
import Sidebar from '../common/Sidebar/Sidebar'
import ConfirmationModal from '../common/ConfirmationModal/ConfirmationModal'
import StatusInfoModal from './GamificationModals/StatusInfoModal'
import AnswerStatusInfoModal from './GamificationModals/AnswerStatusInfoModal'
import PreEventAnswer from './GamificationModals/PreEventAnswer'
import CreateEditPollQuestion from './CreateEditPollQuestion/CreateEditPollQuestion'
import GamificationList from './GamificationList/GamificationList'
import { getToken, getUser } from '../Login/ManageUser'
import GamificationPreview from '../Gamification/GamificationPeview/GamificationPreview'
import { notify } from '../Notify/Notify'
import './Gamification.scss'

const gamification = (props) => {
  // useNavigate used for redirect
  let navigate = useNavigate()
  // useLocation used to get the props
  const { state } = useLocation()

  // Authentication variables
  const BASE_URL = process.env.REACT_APP_API_BASE_URL
  const V2_URL = process.env.REACT_APP_API_V2_URL

  const token = getToken()
  const user = getUser()

  // State
  const [questionList, setQuestionList] = useState([])
  const [isGamificationEnabled, setGamificationEnabled] = useState(false)
  const [currentPollId, setCurrentPollId] = useState()
  const [deleteModalShow, setDeleteModalShow] = useState(false)
  const [statusInfoModalShow, setStatusInfoModalShow] = useState(false)
  const [answerStatusInfoModal, setAnswerStatusInfoModal] = useState(false)
  const [preEventAnswerShow, setPreEventAnswerShow] = useState(false)
  const [preEventRowData, setPreEventRowData] = useState([])
  const [submitButton, setSubmitButton] = useState(false)
  const [isCreate, setIsCreate] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [editQuestion, setEditQuestion] = useState()
  const [totalValue, setTotalValue] = useState()
  const [breadcrumb, setBreadCrumb] = useState()
  const [eventDetails, setEventDetails] = useState()
  const [eventLoader, setEventLoader] = useState(true)
  const [isAddNewDisable, setAddNewDisable] = useState(false)
  const [activeQuestion, setActiveQuestion] = useState(undefined)
  const [channelTimestamp, setchannelTimestamp] = useState()
  // Pubnub Integration
  const pubnub = usePubNub()
  const [channels, setChannels] = useState([''])
  const questionListRef = useRef()
  const activeQuestionRef = useRef()
  const channelsRef = useRef()

  const handleMessage = (event) => {
    let result = {}
    const updatedList = questionListRef.current.map((item) => {
      if (item.id === event.message.questionId) {
        result = item
        if (event.channel === channelsRef.current[1]) {
          result.questionStatus = event.message.status ?? result.status
          getPreEventQuestionList()
        }
        if (event.channel === channelsRef.current[0]) {
          result['count'] = event.message.count ?? {}
          result['percentage'] = event.message.percentage ?? {}
        }
        return result
      }
      return item
    })
    setQuestionList([...updatedList])
  }

  const changeActiveQuestion = (id, qList = questionList) => {
    let newActiveQuestion = [...qList].find((item) => item.id === id)
    let updatedList = [...qList]
    if (
      newActiveQuestion &&
      parseInt(newActiveQuestion?.questionStatus) === 2
    ) {
      const url = `https://ps.pndsn.com/v1/blocks/sub-key/${process.env.REACT_APP_SUBSCRIBE}/real-time-data`
      const reqData = {
        eventId: parseInt(state.eventId),
        questionId: parseInt(id),
        timeStamp: channelTimestamp,
      }

      axios.post(url, reqData).then((res) => {
        updatedList = [...qList].map((item) => {
          if (item.id === id) {
            let result = item
            result['count'] = res.data?.count ?? {}
            result['percentage'] = res.data?.percentage ?? {}
            return result
          }
          return item
        })
        setQuestionList([...updatedList])
      })
    } else if (
      newActiveQuestion &&
      parseInt(newActiveQuestion?.questionStatus) === 1
    ) {
      updatedList = [...qList].map((item) => {
        if (item.id === id) {
          let result = item
          result['count'] = item.finalPollData?.count ?? {}
          result['percentage'] = item.finalPollData?.percentage ?? {}
          return result
        }
        return item
      })
      setQuestionList([...updatedList])
    } else {
      setQuestionList([...updatedList])
    }
    newActiveQuestion = [...updatedList].find((item) => item.id === id)
    activeQuestionRef.current = newActiveQuestion
    setActiveQuestion(newActiveQuestion)
  }

  useEffect(() => {
    channels?.length > 0 ? pubnub.subscribe({ channels }) : null
  }, [pubnub, channels])

  // use Effect

  useEffect(() => {
    getEventDetail()
    sortQuestionList()
    pubnub.addListener({ message: handleMessage })
  }, [])

  useEffect(() => {
    updateBreadCrumb()
  }, [isCreate, isEdit])

  useEffect(() => {
    updateTotalValue()
  }, [questionList])

  // Fetch Pre Event Question List
  const getPreEventQuestionList = (eventDetails) => {
    eventLoader && setEventLoader(true)
    if (state.eventId) {
      let url = `${BASE_URL}/events/${state.eventId}/gamification/questions`
      axios
        .get(url, {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          let updatedPreEventQuestionList = res.data.data.map((item) => {
            let result = item
            result['endTime'] = eventDetails?.startTime
            return result
          })

          changeActiveQuestion(
            updatedPreEventQuestionList[0]?.id,
            updatedPreEventQuestionList
          )
          questionListRef.current = updatedPreEventQuestionList
        })
        .finally(() => {
          setEventLoader(false)
        })
    }
  }

  // Fetch event details
  const getEventDetail = () => {
    if (state.eventId) {
      let url = `${BASE_URL}event/${state.eventId}`
      axios
        .get(url, {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setEventDetails(res.data.data)
          const isDisable = res.data.data.eventStatus == 'Final' ? true : false
          setAddNewDisable(isDisable)
          setGamificationEnabled(res.data.data.gamificationEnabled)
          const newChannles = [
            res.data.data.gamificationOutputChannelName,
            res.data.data.adminGamificationStatusChannel,
          ]
          channelsRef.current = newChannles
          setChannels(newChannles)
          setchannelTimestamp(res?.data?.data?.eventChannelName.split('-')[2])
          getPreEventQuestionList(res.data.data)
        })
    }
  }

  const sortQuestionList = () => {
    const sortedQuestionList = [...questionList].sort(
      (a, b) => a.questionStatus - b.questionStatus
    )
    setQuestionList(sortedQuestionList)
  }

  const updateBreadCrumb = () => {
    let updatedbreadcrumb = state.eventName + ' > Gamification'
    if (isCreate) {
      updatedbreadcrumb = state.eventName + ' > Gamification > Create'
    }
    if (isEdit) {
      updatedbreadcrumb =
        state.eventName + ' > Gamification > Edit Question' + editQuestion.id
    }
    setBreadCrumb(updatedbreadcrumb)
  }

  const updateTotalValue = () => {
    let total = 0
    questionList.map((item) => (total = total + parseInt(item.points)))
    setTotalValue(total)
  }

  const updateQuestionList = (questionData) => {
    if (isCreate) {
      let params = {
        eventId: state.eventId,
        question: questionData.question,
        questionTemplate: questionData.questionTemplate,
        startTime: questionData.startTime,
        answerOptions: questionData.answerOptions,
        questionStatus: parseInt(questionData.questionStatus),
        questionType: questionData.questionType,
      }
      if (questionData?.isEdit === false) {
        const url = `${BASE_URL}gamification/questions`
        axios
          .post(url, params, {
            headers: {
              'content-type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (res?.data?.success) {
              const title = 'Success'
              const detail = res?.data?.message
              const type = 'success'
              notify(title, detail, type)
            }
          })
          .finally(() => {
            setEventLoader(false)
            getPreEventQuestionList(eventDetails)
          })
        setIsCreate(false)
      }
    }
    if (isEdit) {
      let params = {
        eventId: state.eventId,
        question: questionData.question,
        questionTemplate: questionData.questionTemplate,
        startTime: questionData.startTime,
        answerOptions: questionData.answerOptions,
        questionStatus: parseInt(questionData.questionStatus),
        questionType: questionData.questionType,
      }
      if (questionData?.isEdit === true) {
        const url = `${V2_URL}/gamification/questions/${editQuestion.id}`
        axios
          .put(url, params, {
            headers: {
              'content-type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (res?.data?.success) {
              const title = 'Success'
              const detail = res?.data?.message
              const type = 'success'
              notify(title, detail, type)
            }
          })
          .finally(() => {
            setEventLoader(false)
            getPreEventQuestionList(eventDetails)
          })
        setIsCreate(false)
      }
    }
  }

  const handleGamificationEnabled = () => {
    const url = `${BASE_URL}events/${state.eventId}/gamification-status`

    const headers = {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
    const param = {
      status: !isGamificationEnabled,
    }

    axios
      .post(url, param, {
        headers,
      })
      .then((res) => {
        if (res?.data?.success) {
          const title = 'Success'
          const detail = !isGamificationEnabled
            ? 'Gamification enabled'
            : 'Gamification disabled'
          const type = 'success'
          notify(title, detail, type)
          setGamificationEnabled(
            (isGamificationEnabled) => !isGamificationEnabled
          )
        }
      })
  }

  const handleExitClick = () => {
    navigate('/events/eventdetails/')
  }

  const handleButtonClick = (page) => {
    navigate(`/events/eventdetails/edit-event/${page}`, {
      state: {
        eventId: state.eventId,
        eventName: state.eventName,
      },
    })
  }

  const deletePollRow = () => {
    setCurrentPollId('')
    setDeleteModalShow(false)

    let url = `${V2_URL}gamification/questions/${currentPollId}`
    axios
      .delete(url, {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.data?.success) {
          const title = 'Success'
          const detail = res?.data?.message
          const type = 'success'
          notify(title, detail, type)
        }
      })
      .finally(() => getPreEventQuestionList(eventDetails))
  }

  const exitCreateEdit = () => {
    setIsEdit(false)
    setIsCreate(false)
    setEditQuestion()
  }

  const editPollQuestion = (id) => {
    const selectedQuestion = [...questionList].filter((x) => x.id == id)[0]
    setEditQuestion(selectedQuestion)
    setIsEdit(true)
  }

  const openDeletePollModal = (id) => {
    setCurrentPollId(id)
    setDeleteModalShow(true)
  }

  return (
    <>
      {token && user ? (
        <>
          <div className="container-fluid gamification">
            <div className="row">
              {/* Sidebar */}
              <div className="col-3 col-lg-2">
                <Sidebar
                  currentSelected="Events"
                  currentsubSelected="Event details"
                />
              </div>

              {isCreate || isEdit ? (
                <CreateEditPollQuestion
                  eventId={state.eventId}
                  editQuestion={isEdit ? editQuestion : ''}
                  updateQuestionList={updateQuestionList}
                  exitCreateEdit={exitCreateEdit}
                  breadcrumb={breadcrumb}
                  setEventLoader={setEventLoader}
                  eventStartTime={eventDetails?.startTime}
                  eventEndTime={eventDetails?.endTime}
                />
              ) : (
                <>
                  <GamificationList
                    questionList={questionList}
                    eventDetails={eventDetails}
                    setQuestionList={setQuestionList}
                    isGamificationEnabled={isGamificationEnabled}
                    totalValue={totalValue}
                    breadcrumb={breadcrumb}
                    handleGamificationEnabled={handleGamificationEnabled}
                    handleExitClick={handleExitClick}
                    handleButtonClick={handleButtonClick}
                    editPollQuestion={editPollQuestion}
                    openDeletePollModal={openDeletePollModal}
                    setStatusInfoModalShow={setStatusInfoModalShow}
                    setAnswerStatusInfoModal={setAnswerStatusInfoModal}
                    setPreEventAnswerShow={setPreEventAnswerShow}
                    setPreEventRowData={setPreEventRowData}
                    setSubmitButton={submitButton}
                    setIsCreate={setIsCreate}
                    changeActiveQuestion={changeActiveQuestion}
                    activeQuestion={activeQuestion}
                    eventLoader={eventLoader}
                    isAddNewDisable={isAddNewDisable}
                  />
                  <GamificationPreview
                    activeQuestion={activeQuestion}
                    eventDetails={eventDetails}
                  />
                </>
              )}
            </div>
          </div>
          <ConfirmationModal
            header="Are you sure you want to delete this question?"
            show={deleteModalShow}
            handleNoClick={() => setDeleteModalShow(false)}
            handleYesClick={() => deletePollRow()}
          />
          <StatusInfoModal
            show={statusInfoModalShow}
            onHide={() => setStatusInfoModalShow(false)}
          />
          <PreEventAnswer
            show={preEventAnswerShow}
            onHide={() => setPreEventAnswerShow(false)}
            questionTypeName={preEventRowData?.questionTypeName}
            question={preEventRowData.question}
            fetchedOptionList={preEventRowData?.fetchedOptionList}
            value={preEventRowData.value}
            setPreEventAnswerShow={setPreEventAnswerShow}
            setSubmitButton={setSubmitButton}
            getPreEventQuestionList={() =>
              getPreEventQuestionList(eventDetails)
            }
          />
          <AnswerStatusInfoModal
            show={answerStatusInfoModal}
            onHide={() => setAnswerStatusInfoModal(false)}
          />
        </>
      ) : (
        navigate('/login')
      )}
    </>
  )
}

export default gamification
