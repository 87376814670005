import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../../assets/img/logo.png'
import { EventsIcons } from '../../../assets/icons/events'
import { getUser } from '../../Login/ManageUser'
require('./Sidebar.scss')

const Sidebar = (props) => {
  const [currentcatSelected, setSelected] =
    props.currentSelected != undefined
      ? useState(props.currentSelected)
      : useState('Events')

  const [currentsubcatSelected, setsubSelected] =
    props.currentsubSelected != undefined
      ? useState(props.currentsubSelected)
      : useState('Event Details')

  const user = getUser()
  const SidebarData = [
    {
      title: 'Events',
      path: '/events/eventdetails',
      img: EventsIcons.IntegrationsIcon,
      cName: 'nav-text',
      imgHover: EventsIcons.IntegrationsActiveIcon,
      subPages: [
        { title: 'Event details', path: '/events/eventdetails' },
        { title: 'Event Info Pages', path: '/events/eventinfo' },
        { title: 'Tickets', path: '/events/tickets' },
        { title: 'Event Groups', path: '/events/eventgroups' },
        { title: 'Sectors', path: '/events/sectors' },
        { title: 'Seasons', path: '/events/seasons' },
        { title: 'W2W providers', path: '/events/w2wproviders' },
        { title: 'Statistics', path: '/events/statistics' },
      ],
      dropdownImage: EventsIcons.DropDownIcon,
    },
    {
      title: 'Notifications',
      path: [1, 2].includes(user?.type) ? '/notifications' : '#',
      img: EventsIcons.NotificationIcon,
      cName: 'nav-text',
      imgHover: [1, 2].includes(user?.type)
        ? EventsIcons.NotificationActiveIcon
        : EventsIcons.NotificationIcon,
    },
    {
      title: 'User Messaging',
      path: [1, 2].includes(user?.type) ? '/userMessaging' : '#',
      img: EventsIcons.MessageIcon,
      cName: 'nav-text',
      imgHover: [1, 2].includes(user?.type)
        ? EventsIcons.MessageActiveIcon
        : EventsIcons.MessageIcon,
    },
    {
      title: 'Top Stories',
      path: [1, 2].includes(user?.type) ? '/news' : '#',
      img: EventsIcons.NewsIcon,
      cName: 'nav-text',
      imgHover: [1, 2].includes(user?.type)
        ? EventsIcons.NewsActiveIcon
        : EventsIcons.NewsIcon,
    },
    {
      title: 'Moderation',
      path: [1].includes(user?.type) ? '/moderation' : '#',
      img: EventsIcons.FulfillmentIcon,
      cName: 'nav-text',
      imgHover: [1, 2].includes(user?.type)
        ? EventsIcons.FulfillmentActiveIcon
        : EventsIcons.FulfillmentIcon,
    },
    {
      title: 'Activity',
      path: '#',
      img: EventsIcons.DashboardIcon,
      cName: 'nav-text',
      imgHover: EventsIcons.DashboardIcon,
    },
    {
      title: 'Settings',
      path: '/settings',
      img: EventsIcons.SettingsIcon,
      cName: 'nav-text',
      imgHover: EventsIcons.SettingsActiveIcon,
    },
  ]

  return (
    <>
      <nav className="nav-menu active">
        <ul className="nav-menu-items">
          <img src={logo} className="logo mt-2 " />
          <hr className="mt-4" />
          <div id="accordion">
            {SidebarData.map((item, index) => {
              return (
                <div key={index}>
                  <div
                    key={index}
                    data-toggle="collapse"
                    id="accordion"
                    href={'#sidebarcollapse' + index}
                    className={` ${item.cName} `}
                    onMouseEnter={(e) => {
                      currentcatSelected != item.title
                        ? document
                            .getElementById(item.title)
                            .setAttribute('src', item.imgHover)
                        : null
                    }}
                    onMouseLeave={(e) => {
                      currentcatSelected != item.title
                        ? document
                            .getElementById(item.title)
                            .setAttribute('src', item.img)
                        : null
                    }}
                  >
                    <Link
                      to={item?.path}
                      className={item?.path === '#' ? 'disabled-link' : ''}
                      onClick={() => {
                        item?.path !== '#' && setSelected(item.title)
                      }}
                    >
                      <img
                        id={item.title}
                        src={
                          currentcatSelected === item.title
                            ? item.imgHover
                            : item.img
                        }
                        alt="img"
                        width={20}
                        height={20}
                      />
                      <span
                        className={
                          currentcatSelected === item.title &&
                          item?.path !== '#'
                            ? 'Active'
                            : ''
                        }
                      >
                        {item.title}
                      </span>
                      {item.subPages ? (
                        <img
                          className="ms-5"
                          id={item.title}
                          src={item.dropdownImage}
                          alt="img2"
                          width={20}
                          height={20}
                        />
                      ) : null}
                    </Link>
                  </div>
                  {item.subPages ? (
                    <div
                      id={'sidebarcollapse' + index}
                      className={`collapse ${
                        currentcatSelected == item.title ? 'show' : ''
                      }`}
                      data-parent="#accordion"
                    >
                      <ul className="list-unstyled ">
                        {item.subPages.map((subpage, index) => {
                          return (
                            <li
                              key={index}
                              className="Sidebar_Submenu"
                              onClick={() => setsubSelected(subpage.title)}
                            >
                              <Link to={subpage.path}>
                                <span
                                  className={`Sidebar_Submenu ${
                                    currentsubcatSelected === subpage.title
                                      ? 'Active'
                                      : ''
                                  }`}
                                >
                                  {subpage.title}
                                </span>
                              </Link>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  ) : null}
                </div>
              )
            })}
          </div>
        </ul>
      </nav>
    </>
  )
}

export default Sidebar
