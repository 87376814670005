import React from 'react'
import { Modal } from 'react-bootstrap'

import {
  statusMapping,
  buttonColorList,
} from '../../../constants/enumTypes.constants'

const answerStatusInfoModal = (props) => {
  const statusInfoMapping = [
    {
      id: 7,
      text: 'Correct answer selected and scores are calculated.',
    },
    {
      id: 6,
      text: 'Awaiting admin’s final correct answer before user scores are calculated. ',
    },
  ]
  const blankInfo = {
    label: '<Blank>',
    text: 'Question not yet asked (i.e., status not set to “Published”.)',
  }

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
      <Modal.Body className="show-grid">
        <div className="container status-info-modal">
          {statusInfoMapping.map((item, index) => (
            <div className="row pt-2" key={index}>
              <div
                className="info-button col-2"
                style={{
                  backgroundColor: buttonColorList[item.id],
                }}
              >
                {statusMapping[item.id]}
              </div>
              <div className="col-10">{item.text}</div>
            </div>
          ))}
          <div className="row pt-2">
            <div
              className="info-button col-2"
              style={{
                backgroundColor: '#fff',
                color: '#000',
              }}
            >
              {blankInfo.label}
            </div>
            <div className="col-10">{blankInfo.text}</div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default answerStatusInfoModal
