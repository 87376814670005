import React, { forwardRef } from 'react'
import { FiUpload } from 'react-icons/fi'
import { AiFillCloseCircle } from 'react-icons/ai'
import { convertLocalTimeZone } from '../../constants/helper'

const fileUpload = forwardRef((props, ref) => {
  const {
    fileType,
    inputFileName,
    inputFileURL,
    inputFileModifiedTs,
    isUploadBtnDisable,
    handleFileClick,
    handleFileUpload,
    FileUploadHandle,
    handleFileRemoval,
    ...rest
  } = props

  return (
    <>
      <div
        className={`mt-3 ps-2 d-flex  ${
          fileType === 'event'
            ? 'justify-content-center'
            : 'justify-content-start'
        }`}
      >
        <div className="align-self-center">
          <input
            ref={ref}
            onClick={(e) => handleFileClick(e)}
            onChange={() =>
              handleFileUpload(fileType === 'universal' ? true : false)
            }
            className="d-none"
            type="file"
            accept=".xlsx"
            id={`${fileType}-input`}
          />

          {inputFileName?.length === 0 ? (
            <FiUpload
              onClick={() => ref.current && ref.current.click()}
              className="upload-icon me-2"
              height={32}
              width={32}
            />
          ) : (
            <AiFillCloseCircle
              className="upload-icon me-2"
              key={`${fileType}-close`}
              onClick={() =>
                handleFileRemoval(fileType === 'universal' ? true : false)
              }
              height={32}
              width={32}
            />
          )}
        </div>
        <div>
          {inputFileURL ? (
            <a
              target="_blank"
              rel="noreferrer"
              href={inputFileURL}
              id={`${fileType}-url`}
            >
              {inputFileName ?? ''}
            </a>
          ) : (
            inputFileName ?? ''
          )}
          <label>
            {inputFileModifiedTs
              ? convertLocalTimeZone(inputFileModifiedTs)
              : ''}
          </label>
        </div>
      </div>
      <div className="mt-3 ps-2">
        <button
          className="btn btn-success me-2"
          disabled={isUploadBtnDisable}
          onClick={() =>
            FileUploadHandle(fileType === 'universal' ? true : false)
          }
        >
          Upload
        </button>
      </div>
    </>
  )
})

export default fileUpload
