import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import axios from 'axios'

import PasswordInputField from './PasswordInputField'
import logo from '../../assets/img/logo.png'
import PasswordReset from './PasswordModals/PasswordReset'
import { notify } from '../Notify/Notify'
require('../Login/Login.scss')

const PasswordValidation = () => {
  const BASE_URL = process.env.REACT_APP_API_BASE_URL
  const [isDisable, setIsDisable] = useState(true)
  const [passwordError, setPasswordErr] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState('')
  const [passwordInput, setPasswordInput] = useState({
    password: '',
    confirmPassword: '',
  })
  const fieldList = ['password', 'confirmPassword']
  const [searchParams, setSearchParams] = useSearchParams()
  const [resetModal, setResetModal] = useState(false)
  let navigate = useNavigate()
  useEffect(() => {
    const token = searchParams.get('resetToken')
    const email = searchParams.get('email')

    let url = `${BASE_URL}email-link-validation`
    axios(url, {
      params: {
        resetToken: token,
        email: email,
      },
    }).then((res) => {
      if (!res?.data?.success) {
        navigate('/login')
        const title = 'Error'
        const detail = res.data.message
        const type = 'error'
        notify(title, detail, type)
      }
    })
  }, [])
  const onLogin = () => navigate('/login')

  const handlePasswordChange = (evnt) => {
    const passwordInputFieldName = evnt?.target?.name
    const passwordInputValue =
      evnt.target?.value?.trim()?.length < 21
        ? evnt.target?.value?.trim()
        : passwordInput[passwordInputFieldName]
    const NewPasswordInput = {
      ...passwordInput,
      [passwordInputFieldName]: passwordInputValue,
    }
    setPasswordInput(NewPasswordInput)
    let hasError = false

    //for password
    if (passwordInputFieldName === 'password') {
      const uppercaseRegExp = /(?=.*?[A-Z])/
      const lowercaseRegExp = /(?=.*?[a-z])/
      const digitsRegExp = /(?=.*?[0-9])/
      const specialCharRegExp = /(?=.*?[!@#$%^&*()_])/
      const minLengthRegExp = /.{8,}/
      const maxLengthRegExp = /.{20,}/

      const passwordLength = passwordInputValue?.length
      const uppercasePassword = uppercaseRegExp.test(passwordInputValue)
      const lowercasePassword = lowercaseRegExp.test(passwordInputValue)
      const digitsPassword = digitsRegExp.test(passwordInputValue)
      const specialCharPassword = specialCharRegExp.test(passwordInputValue)
      const minLengthPassword = minLengthRegExp.test(passwordInputValue)
      const maxLengthPassword = maxLengthRegExp.test(passwordInputValue)

      let errMsg = ''

      if (passwordLength === 0) {
        errMsg = 'Password is empty'
      } else if (!minLengthPassword) {
        errMsg = 'Password must be at least 8 characters'
      } else if (!uppercasePassword) {
        errMsg = 'Password must include an uppercase character'
      } else if (!lowercasePassword) {
        errMsg = 'Password must include a lowercase character'
      } else if (!digitsPassword) {
        errMsg = 'Password must include a number'
      } else if (!specialCharPassword) {
        errMsg = 'Password must include a special character (see list below)'
      } else {
        errMsg = ''
      }
      if (errMsg !== '') {
        hasError = true
      }
      setPasswordErr(errMsg)
    }

    // for confirm password
    if (
      passwordInputFieldName === 'confirmPassword' ||
      (passwordInputFieldName === 'password' &&
        NewPasswordInput?.confirmPassword?.length > 0)
    ) {
      if (NewPasswordInput.confirmPassword !== NewPasswordInput.password) {
        setConfirmPasswordError('Passwords must match to proceed')
        hasError = true
      } else {
        setConfirmPasswordError('')
        hasError = false
      }
    }

    const isValid =
      !hasError &&
      NewPasswordInput?.password?.trim()?.length > 0 &&
      NewPasswordInput?.confirmPassword?.trim()?.length > 0
    setIsDisable(!isValid)
  }

  const onSubmit = () => {
    if (!isDisable) {
      let url = `${BASE_URL}reset-password`
      const token = searchParams.get('resetToken')
      const email = searchParams.get('email')
      const params = {
        email: email,
        resetToken: token,
        password: passwordInput.password,
      }
      axios.post(url, params).then((res) => {
        if (res?.data?.success) {
          const title = 'Success'
          const detail = res.data.message
          const type = 'success'
          notify(title, detail, type)
          setResetModal(true)
        }
      })
    }
  }

  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center container">
        <div className="text-center mb-4">
          <img src={logo} alt="logo" />
        </div>

        {fieldList.map((item, index) => (
          <PasswordInputField
            key={index}
            handlePasswordChange={handlePasswordChange}
            passwordValue={passwordInput?.[item]}
            passwordError={
              item === 'password' ? passwordError : confirmPasswordError
            }
            inputFieldName={item}
          />
        ))}
        <div className="w-50 mt-2 ">
          <b>Password requirements:</b>
          <ul>
            <li> Between 8 and 20 characters</li>
            <li>
              Can only include lower and uppercase letters, numbers, and special
              characters including spaces as follows ! @ # $ % ^ & * ( ) - _
            </li>
            <li>
              Include at least one of each: 1) letter, 2) number, 3) special
              character
            </li>
          </ul>
        </div>

        <div className="text-center Font_Black Submit_Button mt-2 ">
          <button
            type="submit"
            disabled={isDisable}
            className={
              isDisable ? 'login-button Disabled w-100' : 'login-button w-100'
            }
            onClick={onSubmit}
          >
            Proceed
          </button>
        </div>
      </div>
      <PasswordReset
        show={resetModal}
        onHide={() => onLogin()}
        title="Password set succefully"
        body="Click below to log to your account."
        buttonlabel="Login"
      />
    </>
  )
}

export default PasswordValidation
