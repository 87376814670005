import React from 'react'
import { Modal } from 'react-bootstrap'

import Button from '../common/Button/Button'
import { convertLocalTimeZone } from '../../constants/helper'

const priorityModal = (props) => {
  const {
    priorityModalVisible,
    setPriorityModal,
    setIsPriority,
    setOverwrite,
    modalData,
    categoryName,
    handleSave,
  } = props

  const handleYes = () => {
    var flag = 0
    setPriorityModal(false)
    setIsPriority(true)
    setOverwrite(false)
    handleSave(flag == 1)
  }

  const handleNO = () => {
    setPriorityModal(false)
    setIsPriority(false)
  }
  const handleOnHide = () => {
    setPriorityModal(false)
  }
  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      show={priorityModalVisible}
      onHide={handleOnHide}
      centered
    >
      <Modal.Body className="text-center">
        <div>There can be ony one Priority Season.</div>
        <div>
          <b>Are you sure you want to overwrite the current one?</b>
        </div>
        <div className="mt-2">
          <b>{modalData?.seasonName}</b>
        </div>
        <div>{categoryName}</div>
        <div>{convertLocalTimeZone(modalData?.startTime)}</div>
        <div>{convertLocalTimeZone(modalData?.endTime)}</div>

        <div className="d-flex justify-content-center mt-4">
          <Button className="send-button me-4" onClick={handleNO} name="No" />
          <Button
            className="send-button"
            onClick={handleYes}
            name="Yes, Save"
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default priorityModal
