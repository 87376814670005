import React, { useState, forwardRef, useEffect } from 'react'
import { initializeApp } from 'firebase/app'
import { getFirestore, collection, getDocs } from '@firebase/firestore'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { useNavigate, useLocation } from 'react-router-dom'
import moment from 'moment'
import axios from 'axios'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Switch from 'react-switch'
import Select from 'react-select'
import { getToken, getUser } from './../../Login/ManageUser'
import { handleValidation } from './ManageEvent'
import FormField from '../../common/FormField/FormField'
import Button from '../../common/Button/Button'
import ColorPicker from '../../common/ColorPicker'
import ImageUploader from '../../common/ImageUploader'
import SideBar from '../../common/Sidebar'
import Spinner from 'react-bootstrap/Spinner'
import {
  intialObjects,
  PriorityEventObj,
} from '../../../constants/enumTypes.constants'
import PreviewSection from '../PreviewSection'
import CreateEventPriorirtyModal from './CreateEventPriorirtyModal'
import { notify } from '../../Notify/Notify'
import './CreateEvent.scss'

const CreateEvent = () => {
  // useLocation used to get the props
  const { state } = useLocation()
  // Authentication variables
  const BASE_URL = process.env.REACT_APP_API_BASE_URL
  const V2_URL = process.env.REACT_APP_API_V2_URL

  const token = getToken()
  const user = getUser()

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  }

  const [startDate, setStartDate] = useState(new Date())
  const [actualDate, setActualDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [isHidden, setHidden] = useState(true)
  const [isEnabled, setIsEnabled] = useState(true)
  const [isPriority, setIsPriority] = useState(false)
  const [eventName, setEventName] = useState('')
  const [eventNickname, setEventNickname] = useState('')
  const [disableEdit, setDisableEdit] = useState(false)
  const [eventDescription, setEventDescription] = useState('')
  const [symbolList, setSymbolList] = useState([])
  const [selectedSymbol, setSelectedSymbol] = useState()
  const [selectedSeason, setSelectedSeason] = useState()
  const [selectedSector, setSelectedSector] = useState('')
  const [sectorList, setSectorList] = useState([])
  const [sectorId, setSectorId] = useState('')
  const [selectedEventGroup, setSelectedEventGroup] = useState()
  const [fontColor, setFontColor] = useState('#ffffff')
  const [imgStateVertical, setImgStateVertical] = useState(null)
  const [imageName, setImageName] = useState()
  const [imageDisplay, setImageDisplay] = useState()
  const [imgStateHorizontal, setImgStateHorizontal] = useState(null)
  const [imageNameHorizontal, setImageNameHorizontal] = useState()
  const [imageDisplayHorizontal, setImageDisplayHorizontal] = useState()
  const [selectedCategory, setSelectedCategory] = useState()
  const [categoryLoader, setcategoryLoader] = useState(true)
  const [categories, setCategories] = useState([])
  const [universal, setUniversal] = useState(intialObjects)
  const [seasonsList, setSeasonsList] = useState([])
  const [eventGroupList, setEventGroupList] = useState([])
  const [disablePreviewBtn, setDisablePreviewBtn] = useState(true)
  const [errorMessage, setErrorMessage] = useState()
  const [errors, setErrors] = useState({})
  const [isHorizontalImageChange, setHorizontalImageChange] = useState(false)
  const [isVerticalImageChange, setVerticalImageChange] = useState(false)
  const [previewImgSrc, setPreviewImgSrc] = useState(null)
  const [previewVisibility, setPreviewVisibility] = useState('hidden')
  const [verticalImgUrl, setVerticalImgUrl] = useState()
  const [horizontalImgUrl, setHorizontalImgUrl] = useState()
  const [eventId, setEventId] = useState(null)
  const [whereToWatchProviders, setWhereToWatchProviders] = useState([])
  const [disableNext, setDisableNext] = useState(true)
  const [disableSave, setDisableSave] = useState(false)
  const [loadingAPI, setLoadingAPI] = useState(false)
  const [gamificationEnabled, setGamificationEnabled] = useState(true)
  const [priorityModal, setPriorityModal] = useState(false)
  const [modalData, setModalData] = useState(PriorityEventObj)
  const [overwrite, setOverwrite] = useState(false)
  const [sectorDisabled, setSectorDisabled] = useState(false)
  const [categoryName, setCategoryName] = useState('')
  const [seasonName, setSeasonName] = useState('')
  const [isEventGroup, setIsEventGroup] = useState(false)
  let navigate = useNavigate()

  // firebase authentication
  const app = initializeApp(firebaseConfig)
  const auth = getAuth(app)
  const db = getFirestore(app)

  useEffect(() => {}, [
    fontColor,
    disablePreviewBtn,
    imgStateVertical,
    imgStateVertical,
    imgStateHorizontal,
    previewImgSrc,
    previewVisibility,
    isHidden,
    errors,
    whereToWatchProviders,
    eventId,
  ])

  useEffect(() => {
    if (state != null && state.eventId != null) {
      setDisableNext(false)
    }
    signInWithEmailAndPassword(
      auth,
      process.env.REACT_APP_FIREBASE_EMAIL,
      process.env.REACT_APP_FIREBASE_PASSWORD
    ).then(async (userCredential) => {
      // * Signed in to firebase
      const user = userCredential?.user
      if (user?.accessToken) {
        Promise.all([fetchSymbolList(), fetchCategory()])
      }
    })
  }, [])
  useEffect(() => {
    if (symbolList && categories) {
      getEventDetail()
    }
  }, [symbolList, categories])

  useEffect(() => {
    getSeasonsList(selectedCategory?.id)
  }, [selectedCategory])

  useEffect(() => {
    if (selectedEventGroup?.value) {
      setSectorDisabled(true)
    } else {
      setSectorDisabled(false)
    }
  }, [selectedEventGroup])

  useEffect(() => {
    getEventGroupList(selectedSeason?.value)
    getSectorList(selectedSeason?.value)
  }, [selectedSeason])
  useEffect(() => {
    handlePriority()
  }, [isPriority])

  // * Get Symbol list from the firebase
  const SymbolListef = collection(db, 'StatsSymbols')
  const fetchSymbolList = async () => {
    const data = await getDocs(SymbolListef)
    const symbolData = data?.docs?.map((doc) => ({
      ...doc.data(),
    }))
    const fetchedSymbol = symbolData.sort((a, b) =>
      a.label < b.label ? -1 : a.label > b.label ? 1 : 0
    )
    setSymbolList(fetchedSymbol)
  }

  const categoryListRef = collection(db, 'Categories')
  const fetchCategory = async () => {
    const data = await getDocs(categoryListRef)
    const categoryData = data.docs.map((doc) => ({
      ...doc.data(),
      ...intialObjects,
    }))
    // * Get all the stats file
    getStatsFiles([...categoryData])
  }

  const getStatsFiles = (categories) => {
    const url = `${BASE_URL}stats-file-download`
    axios
      .post(
        url,
        {},
        {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res?.data?.success) {
          const resData = res?.data?.data
          // * Set universal data
          setUniversal({
            ...universal,
            fileName: resData?.universal?.fileName ?? '',
            lastUpdated: resData?.universal?.lastUpdated ?? null,
            downloadFilePath: resData?.universal?.downloadFilePath ?? '',
            isDisable: true,
          })
          // * Set category data
          let updatedResult = [...categories].map((o1) => {
            let category = resData?.category.find((o2) => {
              return o1?.id === o2?.categoryId
            })
            if (category) {
              return {
                ...o1,
                ...category,
              }
            }
            return o1
          })
          setCategories([...updatedResult])
          setSelectedCategory([...updatedResult]?.[0])
          getSeasonsList([...updatedResult]?.[0]?.id)
          setcategoryLoader(false)
        }
      })
  }

  const getSeasonsList = (categoryId) => {
    let url = `${V2_URL}eventSeasons`
    axios
      .get(url, {
        params: {
          categoryId: categoryId,
        },
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.data != null) {
          const SeasonsList = res.data.data.map((res) => ({
            label: res.name,
            value: res.id.toString(),
          }))
          setSeasonsList(SeasonsList)
        }
      })
  }

  const getSectorList = (seasonId) => {
    let url = `${V2_URL}sectors/${seasonId}`
    if (seasonId) {
      axios
        .get(url, {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.data != null) {
            const SectorList = res.data.data.map((res) => ({
              label: res.name,
              value: res.id.toString(),
            }))
            setSectorList(SectorList)
            getSelectedSector(SectorList, sectorId)
          }
          if (res.data.data == []) {
            setSectorList([])
          }
        })
    }
  }

  const handleSelectSector = (data) => {
    setSelectedSector(data)
  }

  // * Fetch EventGroup List
  const getEventGroupList = (seasonId) => {
    let url = `${V2_URL}eventGroups`
    getSectorList(seasonId)
    axios
      .get(url, {
        params: {
          seasonId: seasonId,
        },
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.data != null) {
          const EventGroupList = res.data.data.map((res) => ({
            label: res.name,
            value: res.id.toString(),
            sectorId: res.sectorId,
          }))
          setEventGroupList(EventGroupList)
        }
        if (res.data.data == []) {
          setEventGroupList([])
        }
      })
      .finally(() => {})
  }

  const handleCategoryClick = (id) => {
    const newSelectedCategory = [...categories].find(
      (item) => parseInt(item?.id) === parseInt(id)
    )
    setSelectedCategory(newSelectedCategory)
    getSeasonsList(newSelectedCategory?.id)
    setSelectedSeason('')
    setSelectedEventGroup('')
    setSelectedSector('')
    setEventGroupList([])
    setSectorList([])
    setIsPriority(false)
    setOverwrite(false)
  }

  const startThresholdTime = moment(startDate).utc().subtract(1, 'minutes')

  const endThresholdTime = moment(endDate).utc().subtract(1, 'minutes')

  const filterPassedTime = (time) =>
    new Date().getTime() < new Date(time).getTime()

  const filterEndTime = (time) =>
    new Date(startDate).getTime() < new Date(time).getTime()

  const filterActualTime = (time) =>
    new Date(startThresholdTime).getTime() < new Date(time).getTime() &&
    new Date(endThresholdTime).getTime() >= new Date(time).getTime()

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" onClick={onClick} ref={ref}>
      {value + ' ' + /\((.*)\)/.exec(new Date().toString())[1]}
    </button>
  ))

  const handleNextClick = (page) => {
    let parentPage = state?.eventId || eventId ? 'edit-event' : 'create-event'
    navigate(`/events/eventdetails/${parentPage}/${page}`, {
      state: {
        eventId:
          state != null && state.eventId != null ? state.eventId : eventId,
        eventName: eventName,
      },
    })
  }

  const handleExit = () => {
    navigate(`/events/eventdetails/`)
  }

  const handleStartChangeDate = (e) => {
    setStartDate(e)
    if (errors) {
      errors.startDate = ''
      setErrors(errors)
    }
  }

  const handleActualChangeDate = (e) => {
    setActualDate(e)
    if (errors) {
      errors.actualDate = ''
      setErrors(errors)
    }
  }

  const handleEndChangeDate = (e) => {
    setEndDate(e)
    if (errors) {
      errors.endDate = ''
      setErrors(errors)
    }
  }

  const handleChange = (e) => {
    if (errors) {
      errors.eventName = ''
      setErrors(errors)
    }
    var maxLengthInput = 45
    if (e.target.value.length >= 0) {
      if (e.target.value.length > maxLengthInput) {
        e.target.value = e.target.value.substr(0, maxLengthInput)
      }
      setEventName(e.target.value)
    } else {
      setEventName('')
    }
  }

  const handleNickname = (e) => {
    if (errors) {
      errors.eventNickname = ''
      setErrors(errors)
    }
    var maxLengthInput = 45
    if (e.target.value.length >= 0) {
      if (e.target.value.length > maxLengthInput) {
        e.target.value = e.target.value.substr(0, maxLengthInput)
      }
      setEventNickname(e.target.value)
    } else {
      setEventNickname('')
    }
  }

  const handleDescription = (e) => {
    if (errors) {
      errors.eventDescription = ''
      setErrors(errors)
    }
    var maxLengthArea = 80
    if (e.target.value.length >= 0) {
      if (e.target.value.length > maxLengthArea && e.target.value.length > 0) {
        e.target.value = e.target.value.substr(0, maxLengthArea)
      }
      setEventDescription(e.target.value)
    }
  }

  const handleSelect = (data) => {
    if (errors) {
      errors.selectedSymbol = ''
      setErrors(errors)
    }
    setSelectedSymbol(data)
  }

  const handleSelectSeason = (data) => {
    if (errors) {
      errors.selectedSeason = ''
      setErrors(errors)
    }
    setSelectedSeason(data)
    getEventGroupList(data?.value)
    setSelectedEventGroup('')
    setSelectedSector([])
    setIsPriority(false)
    setOverwrite(false)
  }

  const handleSelectEventGroup = (data) => {
    setSelectedEventGroup(data)
    const SelectedSector = [...sectorList].filter(
      (obj) => parseInt(obj.value) === parseInt(data?.sectorId)
    )
    if (SelectedSector.length > 0) {
      setSelectedSector(SelectedSector)
    } else {
      setSelectedSector([])
    }
  }

  const onImageChangeVertical = (picture) => {
    var maxLength = 5
    setVerticalImageChange(true)
    if (errors) {
      errors.imgStateVertical = ''
      setErrors(errors)
    }
    if (picture[0].file) {
      setImgStateVertical(picture[0].file)
      setDisablePreviewBtn(false)
      setImageName(picture[0].file.name)
      if (picture[0].file.name.length > maxLength) {
        setImageDisplay(picture[0].file.name.substr(0, maxLength) + '...')
      } else {
        setImageDisplay(picture[0].file.name)
      }
    }
  }

  const onImageChangeHorizontal = (picture) => {
    setHorizontalImageChange(true)
    if (errors) {
      errors.imgStateHorizontal = ''
      setErrors(errors)
    }
    var maxLength = 5
    if (picture[0].file) {
      setImgStateHorizontal(picture[0].file)
      setImageNameHorizontal(picture[0].file.name)

      if (picture[0].file.name.length > maxLength) {
        setImageDisplayHorizontal(
          picture[0].file.name.substr(0, maxLength) + '...'
        )
      } else {
        setImageDisplayHorizontal(picture[0].file.name)
      }
    }
  }

  const handleRemoveVertical = () => {
    setImgStateVertical(null)
    setImageName('')
    setDisablePreviewBtn(true)
    setVerticalImageChange(false)
    setPreviewImgSrc(null)
    setPreviewVisibility('hidden')
  }

  const handleRemoveHorizontal = () => {
    setImgStateHorizontal(null)
    setImageNameHorizontal('')
    setHorizontalImageChange(false)
  }

  const handlePreviewImage = (image) => {
    if (image) {
      let form_data = new FormData()
      form_data.append('image', image, image.name)
      form_data.append('height', 1560)
      form_data.append('width', 720)
      let url = `${BASE_URL}preview/`
      axios
        .post(url, form_data, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        })
        .then((res) => {
          if (res?.data?.data !== null) {
            setPreviewImgSrc(res?.data?.data)
          }
          setPreviewVisibility('visible')
        })
    }
  }

  const getEventDetail = () => {
    if (state != null && state.eventId != null) {
      let url = `${BASE_URL}event/${state.eventId}`
      axios
        .get(url, {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setEventFields(res)
        })
    }
  }

  const setEventFields = (res) => {
    let data = res?.data?.data
    setEventId(data?.id)
    setEventName(data?.title)
    setEventDescription(data?.description)
    const currentDisableEdit = ['LIVE', 'FINAL', 'PENDING'].includes(
      data?.eventStatus?.toUpperCase()
    )

    setDisableEdit(currentDisableEdit)
    setStartDate(new Date(data?.startTime))
    setEndDate(new Date(data?.endTime))
    setGamificationEnabled(data?.gamificationEnabled)
    setFontColor(data?.fontAndIconColor)
    setHidden(data?.isHidden)
    setWhereToWatchProviders(data?.whereToWatchProviders)

    let eventPreviewUrl = data?.eventImages?.verticalImage['19.5x9']
    setImgStateVertical(eventPreviewUrl)
    setPreviewImgSrc(eventPreviewUrl)
    setPreviewVisibility('visible')
    setVerticalImgUrl(eventPreviewUrl)

    let horizontalImg = data?.eventImages?.horizontalImage
    setImgStateHorizontal(horizontalImg)
    setHorizontalImgUrl(horizontalImg)

    setEventNickname(data?.nickname)
    setActualDate(new Date(data?.actualStartTime))
    setIsEnabled(data?.eventType == '1' ? true : false)
    setIsPriority(data?.isPriority)
    //set Symbol fetched for editing
    setSymbol(data?.eventSymbol)
    //set categories fetched for editing
    setCat(data?.categories?.categories?.categoryId)
    //set SeasonList fetched for editing
    setSeasonList(data?.seasonId)
    //set EventGorup list Fetched for editing
    setEventGroupsList(data?.eventGroupId)
    //set Sector list Fetched for editing
    setSectorId(data?.sectorId)
  }

  const setSymbol = (url) => {
    const symbolObj = [...symbolList].filter((obj) => obj.imgURL == url)
    setSelectedSymbol(symbolObj?.[0])
  }
  const setCat = (categoryId) => {
    const catObj = [...categories].filter(
      (obj) => parseInt(obj.id) == parseInt(categoryId)
    )
    setSelectedCategory(catObj?.[0])
  }
  const setSeasonList = (seasonId) => {
    const seasonObj = [...seasonsList].filter(
      (obj) => parseInt(obj.value) == parseInt(seasonId)
    )
    setSelectedSeason(seasonObj?.[0])
  }

  const setEventGroupsList = (eventGroupId) => {
    if (eventGroupId) {
      const eventGroup = [...eventGroupList].filter(
        (obj) => parseInt(obj.value) == parseInt(eventGroupId)
      )
      setSelectedEventGroup(eventGroup?.[0])
    }
  }

  const getSelectedSector = (SectorList, sectorId) => {
    if (sectorId) {
      const SelectedSector = [...SectorList].filter(
        (obj) => parseInt(obj.value) === parseInt(sectorId)
      )
      if (SelectedSector.length > 0) {
        setSelectedSector(SelectedSector)
      } else {
        setSelectedSector([])
      }
    }
  }

  const handleSave = (flag) => {
    var fieldErrors = handleValidation(
      state?.eventId ?? null,
      eventName,
      eventNickname,
      eventDescription,
      startDate,
      actualDate,
      endDate,
      startThresholdTime,
      imgStateVertical,
      imgStateHorizontal,
      selectedSymbol,
      selectedSeason,
      disableEdit
    )
    setErrors(fieldErrors)
    setPriorityModal(false)
    if (Object.keys(fieldErrors)?.length === 0) {
      if (overwrite === false || flag === false) {
        var categoryRequestNew = {
          topicId: 1,
          topic: 'Sports',
          categories: {
            categoryId: parseInt(selectedCategory?.id),
            categoryName: selectedCategory?.category,
            subCategories: [],
          },
        }
        let form_data = new FormData()
        form_data.append('title', eventName)
        form_data.append('description', eventDescription)
        form_data.append('gamificationEnabled', gamificationEnabled)
        form_data.append('fontAndIconColor', fontColor)
        form_data.append(
          'whereToWatchProviders',
          JSON.stringify(whereToWatchProviders)
        )
        form_data.append('categories', JSON.stringify(categoryRequestNew))
        form_data.append('startTime', startDate.toISOString())
        form_data.append('endTime', endDate.toISOString())
        form_data.append('isHidden', isHidden)
        form_data.append('horizontalImage', imgStateHorizontal)
        form_data.append('verticalImage', imgStateVertical)
        form_data.append('isPriority', isPriority)
        form_data.append('nickname', eventNickname)
        form_data.append('actualStartTime', actualDate.toISOString())
        form_data.append('seasonId', parseInt(selectedSeason?.value))
        form_data.append('eventType', isEnabled ? 1 : 2)
        form_data.append('eventSymbol', selectedSymbol?.imgURL)
        if (selectedEventGroup?.value?.length > 0) {
          form_data.append('eventGroupId', parseInt(selectedEventGroup?.value))
        }
        if (selectedSector?.value?.length > 0) {
          form_data.append('sectorId', parseInt(selectedSector?.value))
        }
        if (eventId != null) {
          form_data.append('isHorizontalImageChange', isHorizontalImageChange)
          form_data.append('isVerticalImageChange', isVerticalImageChange)
          setLoadingAPI(true)
          setDisableSave(true)
          let url = `${V2_URL}event/${eventId}`
          axios
            .put(url, form_data, {
              headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              if (res?.data?.success) {
                const title = 'Success'
                const detail = res.data.message
                const type = 'success'
                notify(title, detail, type)
              }
              setErrorMessage('')
              setDisableSave(false)
            })
            .catch((err) => {
              setErrorMessage(
                err.response?.data?.message ?? 'Something went wrong!'
              )
              setDisableSave(false)
            })
          setLoadingAPI(false)
        } else {
          setDisableSave(true)
          let url = `${V2_URL}events/`
          setLoadingAPI(true)
          axios
            .post(url, form_data, {
              headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              if (res?.data?.success) {
                setDisableNext(!res?.data?.success)
                setEventId(res.data.data.id)
                const title = 'Success'
                const detail = res.data.message
                const type = 'success'
                notify(title, detail, type)
              }
              setErrorMessage('')
              setDisableSave(false)
            })
            .catch((err) => {
              setErrorMessage(
                err.response?.data?.message ?? 'Something went wrong!'
              )
              setDisableSave(false)
            })
          setLoadingAPI(false)
        }
      }
      if (overwrite === true && isPriority === true) {
        setPriorityModal(true)
      }
    }
  }
  const handleCategoryName = (categoryId) => {
    const catObj = [...categories].filter(
      (obj) => parseInt(obj.id) == parseInt(categoryId)
    )
    setCategoryName(catObj?.[0]?.category)
  }
  const handleSeasonName = (seasonIdId) => {
    const seasonObj = [...seasonsList].filter(
      (obj) => parseInt(obj.value) == parseInt(seasonIdId)
    )
    setSeasonName(seasonObj?.[0]?.label)
  }
  const handlePriority = () => {
    if (isPriority === true && selectedSeason) {
      const url = `${V2_URL}priorityEventOrEventGroup`
      axios
        .get(url, {
          params: {
            seasonId: selectedSeason?.value,
          },
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res?.data?.success === false) {
            const title = 'error'
            const detail = res.data.message
            const type = 'error'
            notify(title, detail, type)
          }
          res?.data?.data == null ? setOverwrite(false) : setOverwrite(true)
          setModalData({
            eventName: res?.data?.data?.title
              ? res?.data?.data?.title
              : res?.data?.data?.name,
          })
          setIsEventGroup(res?.data?.data?.title ? false : true)
          handleCategoryName(res?.data?.data?.category)
          handleSeasonName(res?.data?.data?.seasonId)
        })
    } else {
      setOverwrite(false)
    }
  }

  return (
    <>
      {token && user ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-3 col-lg-2">
              <SideBar
                currentSelected="Events"
                currentsubSelected="Event details"
              />
            </div>
            <div className="col-6 col-lg-8">
              <div className="Event_Container ms-1xl-5 ms-xl-0">
                <div>
                  <div className="row d-flex">
                    <div className="col-12">
                      <p className="Roboto_Black Event_Name">{`${
                        eventName.length > 0
                          ? `${eventName} > Main Event Details`
                          : ''
                      }  `}</p>
                    </div>
                  </div>
                  <div className="Center_Container ps-5 pe-5 pe-1xl-5 pe-xl-2 py-5">
                    <div className="row">
                      <div className="col-12 col-1xl-5">
                        <FormField
                          value={eventName}
                          id="eventName"
                          label="Event name"
                          name="eventName"
                          type="text"
                          placeholder="Enter event name"
                          onChange={(e) => {
                            handleChange(e)
                          }}
                          disabled={disableEdit}
                        />
                        <div>
                          {errors && (
                            <div className="input-feedback">
                              {errors.eventName}
                            </div>
                          )}
                        </div>
                        <FormField
                          value={eventNickname}
                          id="eventNickname"
                          label="Event nickname"
                          name="eventNickname"
                          type="text"
                          placeholder="Enter event nickname"
                          onChange={(e) => {
                            handleNickname(e)
                          }}
                          disabled={disableEdit}
                        />
                        <div>
                          {errors && (
                            <div className="input-feedback">
                              {errors.eventNickname}
                            </div>
                          )}
                        </div>
                        <FormField
                          value={eventDescription}
                          id="event_description"
                          label="Event description  "
                          name="event_description"
                          type="textarea"
                          placeholder="Enter description"
                          onChange={handleDescription}
                          disabled={disableEdit}
                        />
                        <div>
                          {errors && (
                            <div className="input-feedback">
                              {errors.eventDescription}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-12 col-1xl-7">
                        <label htmlFor="hidden" className="Font_Black ms-1xl-5">
                          Event hidden?
                        </label>
                        <p className="Error_Color Font_Regular ms-1xl-5">
                          <span className="Font_Black m-0">WARNING:</span> To
                          avoid events being shown to app users prematurely,
                          they are hidden by default. Hidden events are NEVER
                          shown to users, even if the Start Time is reached.
                          Disable and save to make the event visible.
                        </p>

                        <div className="row">
                          <div className="col-2 col-1xl-4 col-xl-4 col-xxl-3">
                            <Switch
                              onChange={() => setHidden(!isHidden)}
                              checked={isHidden}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                              height={30}
                              width={58}
                              className="react-switch ms-1xl-5"
                              id="material-switch"
                              disabled={disableEdit}
                            />
                          </div>
                          <div className="col-6 col-xl-3 col-xxl-4">
                            {isHidden ? 'Hidden' : 'Visible'}
                          </div>
                        </div>

                        <label htmlFor="hidden" className="Font_Black ms-1xl-5">
                          Standard Event?
                        </label>
                        <p className="Font_Regular ms-1xl-5">
                          If ENABLED, this Event has live chat, gamification,
                          and stats.<br></br>If DISABLED, this Event is
                          Stats-only, meaning it will not show on the <br></br>
                          Events Tab, nor have a live chat and gamification.It
                          will have stats.
                        </p>
                        <div className="row">
                          <div className="col-2 col-1xl-4 col-xl-4 col-xxl-3">
                            <Switch
                              onChange={() => setIsEnabled(!isEnabled)}
                              checked={isEnabled}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                              height={30}
                              width={58}
                              className="react-switch ms-1xl-5"
                              id="material-switch"
                              disabled={disableEdit}
                            />
                          </div>
                          <div className="col-6 col-xl-3 col-xxl-4">
                            {isEnabled ? 'Standard' : 'Stats-only'}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-1xl-5">
                        <div className="image-upload">
                          <div className="col-lg-6 col-1xl-2 mt-lg-3 mt-1xl-0 order-lg-2 order-1xl-0">
                            <label
                              htmlFor="image"
                              className="Font_Black d-flex"
                            >
                              Upload Vertical
                            </label>
                            <label
                              htmlFor="image"
                              className="h-25 mb-xl-4 d-flex image-label-size"
                            >
                              (Images are 720*1560)
                            </label>
                            <ImageUploader
                              value={imgStateVertical}
                              imgUrl={verticalImgUrl}
                              onChange={onImageChangeVertical}
                              imageName={imageName}
                              imageDisplay={imageDisplay}
                              onClick={handleRemoveVertical}
                            />
                            <div>
                              {errors && (
                                <div className="input-feedback w-75">
                                  {errors.imgStateVertical}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6 col-1xl-2 col-xl-3 mt-lg-3 mt-1xl-0 order-lg-3 order-1xl-0">
                            <label
                              htmlFor="image"
                              className="Font_Black d-flex"
                            >
                              Upload Horizontal
                            </label>
                            <label
                              htmlFor="image"
                              className="h-25 mb-xl-4 d-flex image-label-size"
                            ></label>
                            <ImageUploader
                              value={imgStateHorizontal}
                              imgUrl={horizontalImgUrl}
                              onChange={onImageChangeHorizontal}
                              imageName={imageNameHorizontal}
                              imageDisplay={imageDisplayHorizontal}
                              onClick={handleRemoveHorizontal}
                            />
                            <div>
                              {errors && (
                                <div className="input-feedback w-50">
                                  {errors.imgStateHorizontal}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-6 mt-4">
                          <Button
                            name="Show preview"
                            onClick={() => handlePreviewImage(imgStateVertical)}
                            disabled={disablePreviewBtn || disableEdit}
                          />
                        </div>
                        <div className="col-6 mt-5">
                          <label className="Font_Black d-flex">Symbol</label>
                          <Select
                            options={symbolList}
                            placeholder="Select Symbol"
                            value={selectedSymbol}
                            isSearchable={true}
                            onChange={handleSelect}
                            isOptionSelected={true}
                          />
                          <div>
                            {errors && (
                              <div className="input-feedback">
                                {errors.selectedSymbol}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-6">
                          <label htmlFor="font_icon" className="Font_Black">
                            Font and icon color
                          </label>
                          <ColorPicker
                            fontColor={fontColor}
                            passFontColor={setFontColor}
                            disabled={disableEdit}
                          />
                        </div>
                      </div>

                      <div className="ms-1xl-5 col-12 col-1xl-6">
                        <label htmlFor="starttime" className="Font_Black">
                          Start Time
                        </label>
                        <p className="Font_Regular">
                          {' '}
                          The time the Event goes live in the application and
                          schedules open the chat, close pre-event gamification,
                          and trigger required notification. Recommend opening
                          event 15-30 minutes before the Actual Event TIme.
                        </p>
                        <div className="DateTime">
                          <DatePicker
                            selected={startDate}
                            onChange={handleStartChangeDate}
                            showTimeSelect
                            minDate={new Date()}
                            filterTime={filterPassedTime}
                            timeIntervals={15}
                            dateFormat="MM/dd/yyyy hh:mm:ss a"
                            timeCaption="time"
                            className="Bg_Quinary_Color"
                            customInput={<ExampleCustomInput />}
                            disabled={disableEdit}
                          />
                        </div>
                        <div>
                          {errors && (
                            <div className="input-feedback">
                              {errors.startDate}
                            </div>
                          )}
                        </div>
                        <label htmlFor="starttime" className="Font_Black">
                          End Time
                        </label>
                        <p className="Font_Regular">
                          {' '}
                          Note: specify exact time to close chat (e.g., end time
                          + buffer)
                        </p>
                        <div className="DateTime">
                          <DatePicker
                            selected={endDate}
                            onChange={handleEndChangeDate}
                            showTimeSelect
                            minDate={new Date(startDate)}
                            filterTime={filterEndTime}
                            timeIntervals={15}
                            dateFormat="MM/dd/yyyy hh:mm:ss a"
                            timeCaption="time"
                            className="Bg_Quinary_Color"
                            customInput={<ExampleCustomInput />}
                            disabled={disableEdit}
                          />
                        </div>
                        <div>
                          {errors && (
                            <div className="input-feedback">
                              {errors.endDate}
                            </div>
                          )}
                        </div>
                        <label htmlFor="starttime" className="Font_Black">
                          Actual Event Time
                        </label>
                        <p className="Font_Regular">
                          {' '}
                          When the real Event actually begins (separate from
                          FanAmp).Displayed to users so they can plan and
                          communicate around the Event per the official schedule
                          that would be posted online, at the venue, etc.
                        </p>
                        <div className="DateTime">
                          <DatePicker
                            selected={actualDate}
                            onChange={handleActualChangeDate}
                            showTimeSelect
                            minDate={new Date(startDate)}
                            maxDate={new Date(endDate)}
                            filterTime={filterActualTime}
                            timeIntervals={1}
                            dateFormat="MM/dd/yyyy hh:mm:ss a"
                            timeCaption="time"
                            className="Bg_Quinary_Color"
                            customInput={<ExampleCustomInput />}
                            disabled={disableEdit}
                          />
                        </div>
                        <div>
                          {errors && (
                            <div className="input-feedback">
                              {errors.actualDate}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row d-flex mt-4  mt-1xl-3">
                      <div className="row ml-2">
                        {categoryLoader ? (
                          <div className="text-center overflow-hidden">
                            <Spinner animation="border" variant="primary" />
                          </div>
                        ) : (
                          [...categories]?.map((key) => (
                            <div
                              className={`categories-label btn ${
                                parseInt(selectedCategory?.id) ===
                                parseInt(key?.id)
                                  ? 'activeTab'
                                  : ''
                              }`}
                              key={`category-${key?.id}`}
                              onClick={() =>
                                handleCategoryClick(parseInt(key?.id))
                              }
                            >
                              <div>
                                <img src={key?.imgURL} alt="" height={20} />
                              </div>
                              <div className="mt-2">{key?.category}</div>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                    <div className="row d-flex mt-5 ml-1">
                      <div className="col-5 mr-3">
                        <label className="Font_Black d-flex">Season</label>
                        <Select
                          options={seasonsList}
                          placeholder="Select Season"
                          value={selectedSeason}
                          isSearchable={true}
                          onChange={handleSelectSeason}
                          isOptionSelected={true}
                        />
                        <div>
                          {errors && (
                            <div className="input-feedback">
                              {errors.selectedSeason}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-5 ml-4">
                        <label className="Font_Black d-flex">
                          Event Group (Optional)
                        </label>
                        <Select
                          options={eventGroupList}
                          placeholder="Select Event Group"
                          value={selectedEventGroup}
                          isSearchable={true}
                          onChange={handleSelectEventGroup}
                          isOptionSelected={true}
                          isClearable={true}
                        />
                      </div>
                    </div>
                    {errorMessage && (
                      <div className="d-flex error-message justify-content-center align-items-center Font_Black text-center text-danger">
                        {errorMessage}
                      </div>
                    )}
                    <div className="row d-flex ml-1">
                      <div className="col-5 mr-3">
                        <label htmlFor="hidden" className="Font_Black">
                          Priority Event or Event Group?
                        </label>
                        <p className="Font_Regular">
                          If ENABLED, this Event or Event Group is shown as the
                          default on the Stats tab for its Category, regardless
                          of whether another Event or Event Group have more
                          recent Start Time.
                          <br></br>
                        </p>
                        <p className=" Error_Color Font_Regular">
                          <b>Note: </b>There can only be one Priority Event or
                          Event Group per Season at a time.
                        </p>
                        <div className="row">
                          <div className="col-2">
                            <Switch
                              onChange={() => setIsPriority(!isPriority)}
                              checked={isPriority}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                              height={30}
                              width={58}
                              className="react-switch"
                              id="material-switch"
                              disabled={disableEdit}
                            />
                          </div>
                          <div className="col-6">
                            {isPriority ? 'Priority' : 'Basic'}
                            <CreateEventPriorirtyModal
                              priorityModalVisible={priorityModal}
                              setPriorityModal={setPriorityModal}
                              setIsPriority={setIsPriority}
                              setOverwrite={setOverwrite}
                              modalData={modalData}
                              categoryName={selectedCategory?.category}
                              seasonName={seasonName}
                              isEventGroup={isEventGroup}
                              handleSave={handleSave}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-5 ml-4">
                        <label className="Font_Black d-flex">
                          Sector (Optional)
                        </label>
                        <Select
                          options={sectorList}
                          placeholder="Select Sector"
                          value={selectedSector}
                          isSearchable={true}
                          isDisabled={sectorDisabled}
                          onChange={handleSelectSector}
                          isOptionSelected={true}
                          isClearable={true}
                        />
                      </div>
                    </div>
                    <div className="d-flex mt-4 mb-1">
                      <div className="flex-grow-1">
                        <Button
                          name="Exit"
                          className="w-50"
                          onClick={() => {
                            handleExit()
                          }}
                        />
                      </div>
                      <div className="w-25">
                        <Button
                          name="Next"
                          disabled={disableNext}
                          onClick={() => handleNextClick('associate-w2w')}
                        />
                      </div>
                      <div className="w-25 ms-3">
                        <Button
                          name="Save"
                          onClick={() => handleSave()}
                          disabled={
                            disableSave || loadingAPI === true ? true : false
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-3 col-lg-2 Preview_Container">
              <PreviewSection
                showCreateEventBtn={false}
                eventName={eventName}
                eventDescription={eventDescription}
                fontColor={fontColor}
                wizardNumber={1}
                src={previewImgSrc}
                visibility={previewVisibility}
              />
            </div>
          </div>
        </div>
      ) : (
        navigate('/login')
      )}
    </>
  )
}
export default CreateEvent
