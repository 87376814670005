import React, { useState, forwardRef, useEffect } from 'react'
import { initializeApp } from 'firebase/app'
import { getFirestore, collection, getDocs } from '@firebase/firestore'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { intialObjects } from '../../constants/enumTypes.constants'
import { getToken } from '../Login/ManageUser'
import { notify } from '../Notify/Notify'
import axios from 'axios'
import Spinner from 'react-bootstrap/Spinner'
import Switch from 'react-switch'
import DatePicker from 'react-datepicker'
import FormField from '../common/FormField'
import ImageUploader from '../common/ImageUploader/ImageUploader'
import Button from '../common/Button/Button'
import 'react-datepicker/dist/react-datepicker.css'
import './Tickets.scss'
import Clipboard from '../common/Clipboard/Clipboard'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

const CreateEditTickets = (props) => {
  const {
    setIsModalVisible,
    setSelectedTicketsCategory,
    getTicketsList,
    editData,
    setEditData,
    setIsEdit,
    isEdit,
  } = props
  const [ticketName, setTicketName] = useState('')
  const [ticketDescription, setTicketDescription] = useState('')
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [selectedCategory, setSelectedCategory] = useState(intialObjects)
  const [categories, setCategories] = useState([])
  const [loader, setLoader] = useState(true)
  const [errors, setErrors] = useState({})
  const [imgState, setImgState] = useState(null)
  const [imgUrl, setImgUrl] = useState()
  const [imageName, setImageName] = useState()
  const [imageDisplay, setImageDisplay] = useState()
  const [ticketUrl, setTicketUrl] = useState('')
  const [copied, setCopied] = useState(false)
  const [isHidden, setIsHidden] = useState(false)
  const [disableSave, setDisableSave] = useState(false)

  // * Authentication token
  const V2_URL = process.env.REACT_APP_API_V2_URL
  let token = getToken()

  // imported to fetch stats's category
  const app = initializeApp(firebaseConfig)
  const auth = getAuth(app)
  const db = getFirestore(app)

  useEffect(() => {
    signInWithEmailAndPassword(
      auth,
      process.env.REACT_APP_FIREBASE_EMAIL,
      process.env.REACT_APP_FIREBASE_PASSWORD
    ).then((userCredential) => {
      // * Signed in to firebase
      const user = userCredential?.user
      user?.accessToken && fetchCategory()
    })
  }, [])

  useEffect(() => {
    if (isEdit) {
      setTicketName(editData?.name)
      setTicketDescription(editData?.description)
      setTicketUrl(editData?.url)
      setImgState(editData?.image)
      setImgUrl(editData?.image)
      setIsHidden(editData?.isHidden)
      setStartDate(new Date(editData?.startTime))
      setEndDate(new Date(editData?.endTime))
      setSelectedCategory(
        [...categories].filter(
          (obj) => parseInt(obj.id) == parseInt(editData?.category)
        )?.[0]
      )
    }
  }, [categories])

  const categoryListRef = collection(db, 'Categories')
  const fetchCategory = async () => {
    const data = await getDocs(categoryListRef)
    const categoryData = data.docs.map((doc) => ({
      ...doc.data(),
      ...intialObjects,
    }))
    setCategories([...categoryData])
    setSelectedCategory([...categoryData]?.[0])
    setLoader(false)
  }

  const filterEndTime = (time) =>
    new Date(startDate).getTime() < new Date(time).getTime()

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" onClick={onClick} ref={ref}>
      {value + ' ' + /\((.*)\)/.exec(new Date().toString())[1]}
    </button>
  ))

  const handleStartChangeDate = (e) => {
    setStartDate(e)
    if (errors) {
      errors.startDate = ''
      setErrors(errors)
    }
  }

  // * Hanlde Referral URL
  const handleTicketUrl = (e) => {
    if (errors) {
      errors.ticketUrl = ''
      setErrors(errors)
    }
    setTicketUrl(e.target.value)
    setCopied(false)
  }

  const handleCopyClick = () => {
    setCopied(true)
    setTimeout(() => setCopied(false), 1000)
  }

  const onImageChange = (picture) => {
    var maxLength = 5
    if (errors) {
      errors.imgState = ''
      setErrors(errors)
    }
    if (picture[0].file) {
      setImgState(picture[0].file)
      setImageName(picture[0].file.name)
      if (picture[0].file.name.length > maxLength) {
        setImageDisplay(picture[0].file.name.substr(0, maxLength) + '...')
      } else {
        setImageDisplay(picture[0].file.name)
      }
    }
  }

  const handleRemove = () => {
    setImgState(null)
    setImageName('')
  }

  const handleEndChangeDate = (e) => {
    setEndDate(e)
    if (errors) {
      errors.endDate = ''
      setErrors(errors)
    }
  }
  const handleDescription = (e) => {
    if (errors) {
      errors.ticketDescription = ''
      setErrors(errors)
    }
    var maxLengthArea = 80
    if (e.target.value.length >= 0) {
      if (e.target.value.length > maxLengthArea && e.target.value.length > 0) {
        e.target.value = e.target.value.substr(0, maxLengthArea)
      }
      setTicketDescription(e.target.value)
    }
  }
  const handleNameChange = (e) => {
    const maxLength = 45
    const { value } = e.target

    if (value.length > maxLength) {
      setTicketName(value.substring(0, maxLength))
    } else {
      setTicketName(value)
    }
    if (errors) {
      errors.ticketName = ''
      setErrors(errors)
    }
  }
  const handleValidation = (
    ticketName,
    ticketDescription,
    ticketUrl,
    imgState,
    startDate,
    endDate
  ) => {
    let error = {}
    if (!ticketName || ticketName == '') {
      error.ticketName = 'Tickets name is required'
    }
    if (!ticketDescription || ticketDescription == '') {
      error.ticketDescription = 'Tickets Description is required'
    }
    if (!ticketUrl || ticketUrl == '') {
      error.ticketUrl = 'Tickets Url is required'
    }
    if (imgState == null) {
      error.imgState = 'Image is required'
    }
    if (startDate.toISOString() >= endDate.toISOString()) {
      error.endDate = 'End datetime should be greater than start datetime'
    }
    return error
  }

  const handleSave = (flag) => {
    var fieldErrors = handleValidation(
      ticketName,
      ticketDescription,
      ticketUrl,
      imgState,
      startDate,
      endDate
    )
    setErrors(fieldErrors)
    if (Object.keys(fieldErrors)?.length === 0) {
      let form_data = new FormData()
      form_data.append('name', ticketName)
      form_data.append('startTime', startDate.toISOString())
      form_data.append('endTime', endDate.toISOString())
      form_data.append('category', selectedCategory?.id)
      form_data.append('isHidden', isHidden)
      form_data.append('description', ticketDescription)
      form_data.append('url', ticketUrl)
      if (imgState != imgUrl) {
        form_data.append('image', imgState)
      }
      if (isEdit) {
        let url = `${V2_URL}tickets/${editData?.id}`
        setDisableSave(true)
        axios
          .put(url, form_data, {
            headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (res?.data?.success === true) {
              const title = 'Success'
              const detail = res?.data?.message
              const type = 'success'
              notify(title, detail, type)
              setIsModalVisible(false)
              getTicketsList(1, selectedCategory?.id)
              setEditData({})
              setIsEdit(false)
            }
          })
      } else {
        let url = `${V2_URL}createTicket`
        setDisableSave(true)
        axios
          .post(url, form_data, {
            headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (res?.data?.success === true) {
              const title = 'Success'
              const detail = res?.data?.message
              const type = 'success'
              notify(title, detail, type)
              getTicketsList(1, selectedCategory?.id)
              setIsModalVisible(false)
              setEditData({})
              setIsEdit(false)
            }
          })
          .finally(() => {
            setDisableSave(false)
          })
      }
    }
  }

  const handleExit = () => {
    setEditData({})
    setIsEdit(false)
    setIsModalVisible(false)
    setSelectedTicketsCategory(selectedCategory)
    getTicketsList(1, selectedCategory?.id)
  }

  const handleCategoryClick = (id) => {
    const newSelectedCategory = [...categories].find(
      (item) => Number(item?.id) === Number(id)
    )
    setSelectedCategory(newSelectedCategory)
  }

  return (
    <div className="col-6 col-lg-8">
      <div className="row mt-4">
        <div className="breadcrumb">Tickets &gt; {ticketName}</div>
        <div className="seasons-wrapper p-5">
          <div className="d-flex tickets-top-section">
            <div className="col-6 pe-5">
              <FormField
                value={ticketName}
                id="ticketName"
                label="Name"
                name="ticketName"
                type="text"
                placeholder="Enter Ticket Name"
                onChange={(e) => {
                  handleNameChange(e)
                }}
                disabled={false}
              />
              {errors && (
                <div className="input-feedback">{errors.ticketName}</div>
              )}
              <FormField
                value={ticketDescription}
                id="ticket_description"
                label="Ticket description"
                name="event_description"
                type="textarea"
                placeholder="Enter description"
                onChange={handleDescription}
              />
              <div>
                {errors && (
                  <div className="input-feedback">
                    {errors.ticketDescription}
                  </div>
                )}
              </div>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <FormField
                    id="url"
                    value={ticketUrl}
                    label="URL"
                    name="url"
                    type="text"
                    onChange={(e) => handleTicketUrl(e)}
                  />
                </div>
                <div className="ms-4 mt-4 pt-3">
                  <Clipboard
                    urlValue={ticketUrl}
                    onCopy={() => handleCopyClick()}
                    copied={copied}
                  />
                </div>
              </div>
              <div>
                {errors && (
                  <div className="input-feedback">{errors.ticketUrl}</div>
                )}
              </div>
              <div>
                <label htmlFor="image" className="Font_Black d-flex">
                  Image
                </label>
                <label htmlFor="image" className="">
                  Upload a horizontal image with a 16x9 aspect ratio
                </label>
                <ImageUploader
                  value={imgState}
                  imgUrl={imgUrl}
                  onChange={onImageChange}
                  imageName={imageName}
                  imageDisplay={imageDisplay}
                  onClick={handleRemove}
                />
                <div>
                  {errors && (
                    <div className="input-feedback w-50">{errors.imgState}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="ticket-hidden mb-3">
                <label htmlFor="hidden" className="Font_Black">
                  Tickets hidden?
                </label>
                <p className=" Error_Color Font_Regular">
                  <b>WARNING: </b>To Avoid Tickets being shown to app users
                  prematurely, they are hidden by default. Hidden Tickets are
                  NEVER shown to users, even if the Start Time is reached.
                  Disable and save to make the Tickets visible.
                </p>

                <div className="row">
                  <div className="col-2 col-1xl-4 col-xl-4 col-xxl-3">
                    <Switch
                      onChange={() => setIsHidden(!isHidden)}
                      checked={isHidden}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={30}
                      width={58}
                      className="react-switch"
                      id="material-switch"
                      disabled={false}
                    />
                  </div>
                  <div className="col-6 col-xl-3 col-xxl-4">
                    {isHidden ? 'Hidden' : 'Visible'}
                  </div>
                </div>
              </div>
              <div className="time-selectors">
                <label htmlFor="starttime" className="Font_Black">
                  Start Time
                </label>
                <p className="Font_Regular">
                  {' '}
                  The begining of the time range when the Tickets are usable.
                  This helps determine the display order amongst the other
                  tickets.
                </p>
                <div className="DateTime">
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartChangeDate}
                    showTimeSelect
                    timeIntervals={15}
                    dateFormat="MM/dd/yyyy hh:mm:ss a"
                    timeCaption="time"
                    className="Bg_Quinary_Color"
                    customInput={<ExampleCustomInput />}
                    disabled={false}
                  />
                </div>
                {errors && (
                  <div className="input-feedback">{errors.startDate}</div>
                )}
                <label htmlFor="starttime" className="Font_Black">
                  End Time
                </label>
                <p className="Font_Regular">
                  {' '}
                  The end of the time range when the Tickets are usable. After
                  this time passes the corresponding information will no longer
                  be visible.
                </p>
                <div className="DateTime">
                  <DatePicker
                    selected={endDate}
                    onChange={handleEndChangeDate}
                    showTimeSelect
                    minDate={new Date(startDate)}
                    filterTime={filterEndTime}
                    timeIntervals={15}
                    dateFormat="MM/dd/yyyy hh:mm:ss a"
                    timeCaption="time"
                    className="Bg_Quinary_Color"
                    customInput={<ExampleCustomInput />}
                    disabled={false}
                  />
                </div>
                {errors && (
                  <div className="input-feedback">{errors.endDate}</div>
                )}
              </div>
            </div>
          </div>
          <div className="row px-3 ticket-bottom-section">
            <label htmlFor="categories" className="Font_Black">
              Category
            </label>
            <div>
              {loader ? (
                <div className="text-center overflow-hidden">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                [...categories]?.map((key) => (
                  <div
                    className={`categories-label btn ${
                      Number(selectedCategory?.id) === Number(key?.id)
                        ? 'active'
                        : ''
                    }`}
                    key={`category-${key?.id}`}
                    onClick={() => handleCategoryClick(Number(key?.id))}
                  >
                    <div>
                      <img src={key?.imgURL} alt="" height={20} />
                    </div>
                    <div className="mt-2">{key?.category}</div>
                  </div>
                ))
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-6"></div>
          </div>
          <div className="d-flex mt-5 mb-1">
            <div className="flex-grow-1">
              <Button
                name="Exit"
                className="w-25"
                onClick={() => handleExit()}
              />
            </div>
            <div className="w-25 ms-3">
              <Button
                name="Save"
                onClick={() => handleSave()}
                disabled={disableSave}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CreateEditTickets
