import React from 'react';
import Form from 'react-bootstrap/Form';
import FormField from '../common/FormField/FormField';

export function RangeSlider(props) {
  return (
    <div className="row pt-4">
      <>
        <label className="data-feed-form-label m-0" htmlFor="redditCount">
          Comment volume cap
        </label>
        <p className="mb-1">
          Max number of comments fetched every 5 seconds (1-100, default = 100)
        </p>
      </>
      <div className="col-2">
        <FormField
          value={props.redditCount ?? 1}
          id="redditCount"
          hideLabel={true}
          name="redditCount"
          type="number"
          onChange={(e) => {
            props.changeRedditCount(e);
          }}
          disabled={props.disableAllEdit} />
      </div>
      <div className="col-8">
        <Form.Range
          value={props.redditCount}
          onChange={(e) => props.changeRedditCount(e)}
          className="mt-3 pe-3 border-0"
          disabled={props.disableAllEdit} />
      </div>
    </div>
  );
}
