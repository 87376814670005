export const handleValidation = (
  eventId,
  eventName,
  eventNickname,
  eventDescription,
  startDate,
  actualDate,
  endDate,
  startThresholdTime,
  imgStateVertical,
  imgStateHorizontal,
  selectedSymbol,
  selectedSeason,
  disableEdit
) => {
  let errors = {}
  if (!eventName || eventName == '') {
    errors.eventName = 'Event name is required'
  }
  if (!eventDescription || eventDescription == '') {
    errors.eventDescription = 'Event description is required'
  }
  if (!eventNickname || eventNickname == '') {
    errors.eventNickname = 'Event Nickname is required'
  }
  if (!eventId && !imgStateVertical) {
    errors.imgStateVertical = 'Image vertical is required'
  }
  if (!eventId && !imgStateHorizontal) {
    errors.imgStateHorizontal = 'Image horizontal is required'
  }
  if (!selectedSymbol) {
    errors.selectedSymbol = 'Symbol is required'
  }
  if (!selectedSeason) {
    errors.selectedSeason = 'Season is required'
  }
  if (!disableEdit) {
    if (startDate.toISOString() < new Date().toISOString()) {
      errors.startDate =
        'Start datetime should be greater than current datetime'
    }
    if (actualDate.toISOString() < new Date().toISOString()) {
      errors.actualDate =
        'Actual Event Time should be between start time and end time'
    }
    if (endDate.toISOString() < new Date().toISOString()) {
      errors.endDate = 'End datetime should be greater than current datetime'
    }
  }
  if (startThresholdTime.toISOString() >= actualDate.toISOString()) {
    errors.actualDate =
      'Actual Event Time should be between start time and end time'
  }
  if (startDate.toISOString() >= endDate.toISOString()) {
    errors.endDate = 'End datetime should be greater than start datetime'
  }
  return errors
}
