import React from 'react'

const statusInfo = (props) => {
  const colorsList = [
    {
      id: 1,
      color: '#c4c4c4',
      label: 'Published and final',
    },
    {
      id: 2,
      color: 'rgba(242, 66, 29, 0.2)',
      label: 'Live for users',
    },
    {
      id: 3,
      color: 'rgba(112, 140, 241, 0.18)',
      label: 'Published; Pending final answer',
    },
    {
      id: 4,
      color: '#ffffff',
      label: 'Not yet published to users',
    },
  ]

  return (
    <div className="col-6 status-info">
      <div className="row">
        <label className="status-info-title">Legend</label>
      </div>
      <div className="row">
        {colorsList.map((item) => (
          <div className="col-6 d-flex" key={item.id}>
            <div
              className="color-box mt-1 me-2"
              style={{ background: item.color }}
            ></div>
            {item.label}
          </div>
        ))}
      </div>
    </div>
  )
}

export default statusInfo
