import React from 'react'
const passwordInputField = (props) => {
  const { inputFieldName, handlePasswordChange, passwordValue, passwordError } =
    props
  return (
    <div className="form-group password-group my-1">
      <label id={inputFieldName} className="Font_Black">
        {inputFieldName === 'password'
          ? 'Set your password'
          : 'Confirm your password'}
      </label>
      <input
        id={inputFieldName}
        type="password"
        value={passwordValue}
        onChange={(e) => handlePasswordChange(e)}
        name={inputFieldName}
        placeholder={
          inputFieldName === 'password'
            ? 'Enter your new password'
            : 'Re-enter your new password'
        }
        className="form-control"
      />
      <p className="text-danger">{passwordError}</p>
    </div>
  )
}

export default passwordInputField
