import React from 'react'
import Button from '../../../common/Button/Button'

const createEditFooter = (props) => {
  return (
    <div className="row event-footer p-4">
      <div className="col-3">
        <Button name="Exit" onClick={props.onExitClick} />
      </div>

      <div className="col-3">
        <Button
          name="Save"
          onClick={props.onSubmitClick}
          disabled={props.isSaveDisable}
        />
      </div>
    </div>
  )
}

export default createEditFooter
