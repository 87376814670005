import React, { useEffect, useState } from 'react'
import axios from 'axios'
import GamificationSwitch from '../GamificationSwitch/GamificationSwitch'
import StatusInfo from '../StatusInfo/StatusInfo'
import CustomTable from '../CustomTable/CustomTable'
import EventFooter from '../../common/EventFooter/EventFooter'
import Button from '../../common/Button/Button'
import { headerList } from '../../../constants/enumTypes.constants'
import { getToken } from '../../Login/ManageUser'
import { notify } from '../../Notify/Notify'
import EventFinalizeModal from './EventFinalizeModal'

const gamificationList = (props) => {
  const {
    questionList,
    eventDetails,
    setQuestionList,
    isGamificationEnabled,
    totalValue,
    breadcrumb,
    handleGamificationEnabled,
    handleExitClick,
    handleButtonClick,
    editPollQuestion,
    openDeletePollModal,
    setStatusInfoModalShow,
    setAnswerStatusInfoModal,
    setPreEventAnswerShow,
    setPreEventRowData,
    setIsCreate,
    changeActiveQuestion,
    activeQuestion,
    eventLoader,
    isAddNewDisable,
    setSubmitButton,
  } = props

  const V2_URL = process.env.REACT_APP_API_V2_URL
  const token = getToken()

  const [buttonClass, setButtonClass] = useState({})
  const [finalizeModal, setFinalizeModal] = useState(false)

  useEffect(() => {
    if (eventDetails?.eventType === 1) {
      if (eventDetails?.isGamificationEnabled === false) {
        setButtonClass({ class: 'not_active', name: "Can't Finalize" })
        return
      }
      if (eventDetails?.isLocked === true) {
        setButtonClass({ class: 'final', name: 'Finalized' })
      }
      if (eventDetails?.isLocked === false) {
        setButtonClass({ class: '', name: 'Finalize Event' })
      }
    }
  }, [eventDetails])

  useEffect(() => {}, [])

  const FinalizeEvent = () => {
    setFinalizeModal(false)
    if (buttonClass.name === 'Finalize Event') {
      let url = `${V2_URL}event/finalize`
      const params = {
        eventId: eventDetails.id,
      }
      axios
        .post(url, params, {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setButtonClass({ class: 'final', name: 'Finalized' })
          const title = 'Success'
          const detail = res.data?.message
          const type = 'success'
          notify(title, detail, type)
        })
    }
  }
  return (
    <div className="col-7 col-lg-8">
      <div className="py-4 m-0 breadcrumb"> {breadcrumb}</div>

      <div className="poll-section">
        <div className="row p-3 d-flex">
          <div className="d-flex col-6 align-items-center">
            <GamificationSwitch
              isGamificationEnabled={isGamificationEnabled}
              handleGamificationEnabled={handleGamificationEnabled}
            />
            {isGamificationEnabled === true && eventDetails?.eventType === 1 ? (
              <Button
                className={buttonClass.class}
                name={buttonClass.name}
                onClick={() =>
                  buttonClass?.name === 'Finalize Event' &&
                  setFinalizeModal(true)
                }
              />
            ) : (
              <Button className={'not_active'} name={"Can't Finalize"} />
            )}
          </div>
          <StatusInfo />
        </div>
        <div className="row bold-text p-3">Questions list</div>

        <CustomTable
          headerList={headerList}
          questionList={questionList}
          setQuestionList={setQuestionList}
          editQuestion={editPollQuestion}
          deleteRow={openDeletePollModal}
          eventLoader={eventLoader}
          changeActiveQuestion={changeActiveQuestion}
          activeQuestion={activeQuestion}
          setStatusInfoModalShow={() => setStatusInfoModalShow(true)}
          setAnswerStatusInfoModal={() => setAnswerStatusInfoModal(true)}
          setPreEventAnswerShow={() => setPreEventAnswerShow(true)}
          setPreEventRowData={setPreEventRowData}
          setSubmitButton={setSubmitButton}
        />

        <hr />
        <div className="row pb-3">
          <div className="col-6 ps-4">
            <Button
              className="add-new"
              name="Add new"
              onClick={() => setIsCreate(true)}
              disabled={isAddNewDisable}
            >
              + Add New
            </Button>
          </div>
          <div className="col-6">
            Total
            <span className="bold-text ps-4 ms-4"></span>
          </div>
        </div>
        <EventFooter
          onNextClick={() => handleButtonClick('data-feeds')}
          onBackClick={() => handleButtonClick('associate-w2w')}
          onExitClick={() => handleExitClick()}
          isSaveHidden={true}
        />
      </div>
      <EventFinalizeModal
        finalizeModal={finalizeModal}
        onHide={() => setFinalizeModal(false)}
        finalizeSector={FinalizeEvent}
      />
    </div>
  )
}

export default gamificationList
