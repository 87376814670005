import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner'
import axios from 'axios'

import { IconContext } from 'react-icons'
import { TiTick } from 'react-icons/ti'
import { ImCross } from 'react-icons/im'
import { AiOutlineSearch } from 'react-icons/ai'
import { MdEdit, MdDelete } from 'react-icons/md'
import SideBar from '../../../common/Sidebar'
import ConfirmationModal from '../../../common/ConfirmationModal/ConfirmationModal'
import ProviderPreviewSection from '../ProviderPreviewSection'
import { getToken, getUser } from '../../../Login/ManageUser'
import { notify } from '../../../Notify/Notify'
import CreateEditProvider from '../CreateEditProvider/CreateEditProvider'
import './W2WProvidersList.scss'

const w2WProvidersList = (props) => {
  // *  useNavigate used for redirect to login
  let navigate = useNavigate()
  // * Authentication variables
  const BASE_URL = process.env.REACT_APP_API_BASE_URL

  const token = getToken()
  const user = getUser()

  // * Component state
  const [providerList, setProviderList] = useState([])
  const [currentProvider, setCurrentProvider] = useState({})
  const [deleteModalShow, setDeleteModalShow] = useState(false)
  const [loader, setLoader] = useState(true)
  const [isCreate, setCreate] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)

  // * Fetch Provider List
  const getProviderList = () => {
    let url = `${BASE_URL}w2w-providers`
    axios
      .get(url, {
        params: {
          per_page: 1000,
          is_deleted: false,
        },
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.data != null) {
          setProviderList(res?.data?.data)
          setCurrentProvider(res?.data?.data?.[0])
        }
      })
      .finally(() => {
        setLoader(false)
      })
  }

  useEffect(() => {
    // * Fetch provider list on component mount
    getProviderList()
  }, [])

  // * Redirect To CreateEditProvider
  function handleEditClick(provider) {
    setCurrentProvider(provider)
    setIsModalVisible(true)
    setCreate(false)
  }

  const openDeleteProviderModal = (provider) => {
    setCurrentProvider(provider)
    setDeleteModalShow(true)
  }

  const deleteProviderRow = () => {
    let url = `${BASE_URL}w2w-provider/${Number(currentProvider?.id)}`
    setDeleteModalShow(false)
    setLoader(true)
    axios
      .delete(url, {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.data?.message == 'Success') {
          const updatedProviderList = [...providerList].filter(
            (item) => Number(item.id) !== Number(currentProvider?.id)
          )
          setProviderList([...updatedProviderList])
          setCurrentProvider([...updatedProviderList]?.[0])
          const title = 'Success'
          const detail = res?.data?.message
          const type = 'success'
          notify(title, detail, type)
        }
      })

    getProviderList()
  }
  // * Change the active row
  const onProviderSelect = (provider) => {
    setCurrentProvider(provider)
  }

  // * Open Create/Edit Modal
  const handleCreateEditModal = () => {
    setIsModalVisible(true)
    setCreate(true)
  }

  return (
    <>
      {token && user ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-3 col-lg-2">
              <SideBar
                currentSelected="Events"
                currentsubSelected="W2W providers"
              />
            </div>

            {isModalVisible ? (
              <CreateEditProvider
                setIsModalVisible={setIsModalVisible}
                setLoader={setLoader}
                isCreate={isCreate}
                provider={currentProvider}
                getProviderList={getProviderList}
              />
            ) : (
              <>
                <div className="col-6 col-lg-8 Event_Container">
                  <div className="provider-list">
                    <p className="Roboto_Black d-flex provider-list-heading">{`Provider list `}</p>
                    <div className="row mt-5 search-provider">
                      <div className="input-group col-md-4 provider-list-search">
                        <input
                          className="form-control py-3 border-right-0 border provider-list-search-box"
                          type="search"
                          placeholder="Search for a provider"
                          id="example-search-input"
                        />
                        <span className="input-group-append Bg_Quaternary_Color provider-list-search-box">
                          <button
                            className="btn Font_Quinary_Color border-left-0 border"
                            type="button"
                          >
                            <IconContext.Provider
                              value={{ color: 'Font_Quinary_Color' }}
                            >
                              <AiOutlineSearch />
                            </IconContext.Provider>
                          </button>
                        </span>
                      </div>
                    </div>
                    <div className="provider-list-center-container p-3 mt-3">
                      <div className="row provider-list-header Font_Black Font_Quinary_Color">
                        <div className="col-2">Name</div>
                        <div className="col-2 text-center">Affiliate</div>
                        <div className="col-2 ps-4 text-center">Offer</div>
                        <div className="col-2">W2W language</div>
                        <div className="col">Referral URL</div>
                        {user.type !== 3 && <div className="col-1">Modify</div>}
                      </div>
                      <div className="provider-list-table mt-2 pb-3 row d-flex flex-wrap">
                        {loader ? (
                          <div className="text-center overflow-hidden">
                            <Spinner animation="border" variant="primary" />
                          </div>
                        ) : (
                          providerList?.length > 0 &&
                          providerList.map((provider, index) => {
                            return (
                              <div
                                key={index}
                                className={`provider-list-table-row px-0 d-flex align-items-center ${
                                  Number(provider.id) ===
                                  Number(currentProvider?.id)
                                    ? 'active'
                                    : ''
                                }`}
                                onClick={() => onProviderSelect(provider)}
                              >
                                <div className="provider-list-table-event-title col-2">
                                  {provider.providerName}
                                </div>
                                <div className="col-2 text-center">
                                  {provider.isAffiliateProgram ? (
                                    <IconContext.Provider
                                      value={{ size: '30px', color: 'green' }}
                                    >
                                      <TiTick />
                                    </IconContext.Provider>
                                  ) : (
                                    <IconContext.Provider
                                      value={{ color: 'red' }}
                                    >
                                      <ImCross />
                                    </IconContext.Provider>
                                  )}
                                </div>
                                <div className="col-2 text-center">
                                  {provider.isTrialOffer ? (
                                    <IconContext.Provider
                                      value={{ size: '30px', color: 'green' }}
                                    >
                                      <TiTick />
                                    </IconContext.Provider>
                                  ) : (
                                    <IconContext.Provider
                                      value={{ color: 'red' }}
                                    >
                                      <ImCross />
                                    </IconContext.Provider>
                                  )}
                                </div>
                                <div className="col-2 provider-list-table-event-title ps-0">
                                  {provider.w2wLanguage}
                                </div>
                                <div className="col provider-list-table-event-url ps-0">
                                  {provider.referralURL}
                                </div>
                                {user.type !== 3 && (
                                  <div className="col-1 p-0">
                                    <IconContext.Provider
                                      value={{ size: '20px' }}
                                    >
                                      <MdEdit
                                        className="icon-editdel"
                                        onClick={() =>
                                          handleEditClick(provider)
                                        }
                                      />
                                      <MdDelete
                                        className="icon-editdel ms-xl-1"
                                        onClick={() =>
                                          openDeleteProviderModal(provider)
                                        }
                                      />
                                    </IconContext.Provider>
                                  </div>
                                )}
                              </div>
                            )
                          })
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-3 col-lg-2 Preview_Container">
                  <ProviderPreviewSection
                    showCreateProviderBtn={user.type !== 3 ? true : false}
                    handleCreateEditModal={handleCreateEditModal}
                    providerList={[currentProvider]}
                  />
                </div>
              </>
            )}

            <ConfirmationModal
              header="Are you sure you want to delete this provider?"
              show={deleteModalShow}
              handleNoClick={() => setDeleteModalShow(false)}
              handleYesClick={() => deleteProviderRow()}
            />
          </div>
        </div>
      ) : (
        navigate('/login')
      )}
    </>
  )
}
export default w2WProvidersList
