import * as EmailValidator from 'email-validator'

export const handleValidation = (firstName, lastName, email) => {
  let errors = {}
  if (!firstName || firstName.trim().length == 0) {
    errors.firstName = 'FirstName is a required field.'
  }
  if (!lastName || lastName.trim().length == 0) {
    errors.lastName = 'LastName is a required field.'
  }
  if (!EmailValidator.validate(email.toLowerCase())) {
    errors.email = 'Invalid email address.'
  }
  if (!email || email.trim().length == 0) {
    errors.email = 'Email is a required field.'
  }
  return errors
}
