import React from 'react'

const PreviewFooter = (props) => {
  const { activeTabNo } = props
  const StepNumber = ['1', '2', '3', '4']
  const LabelNames = [
    'Main event details',
    'Where to watch',
    'Gamification',
    'Data feeds',
  ]
  return (
    <>
      <div className="Step_Number_Wrapper">
        {StepNumber.map((numbersId, index) => {
          return (
            <span
              key={index}
              className={`${
                activeTabNo === index + 1 ? 'Step_Number_Active' : 'Step_Number'
              }`}
            ></span>
          )
        })}
      </div>
      <div className="Pages_Labels_Wrapper">
        {LabelNames.map((labelname, index) => {
          return (
            <div
              key={index}
              className={`Pages_Labels m-3 ${
                activeTabNo === index + 1
                  ? 'Pages_Labels_Active'
                  : 'Pages_Labels'
              }`}
            >
              <span className="ms-2">{labelname}</span>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default PreviewFooter
