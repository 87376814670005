import React, { useEffect, useState } from 'react'

import nav_bar from '../../../../assets/img/NAV BAR.svg'
import Button from '../../../common/Button/Button'
import PreviewFooter from '../../PreviewSection/PreviewFooter'
import './ProviderPreviewSection.scss'

const ProviderPreviewSection = (props) => {
  const { providerList } = props
  const [updatedList, setUpdatedList] = useState([])

  useEffect(async () => {
    let tempProviderList = []
    if (providerList) {
      tempProviderList = providerList.map(async (obj) => {
        let tempObj = { ...obj }
        if (obj['providerLogo']) {
          if (typeof obj['providerLogo'] !== 'string') {
            let base64String = await toBase64(obj['providerLogo'])
            tempObj['providerLogoString'] = base64String
          } else {
            tempObj['providerLogoString'] = obj['providerLogo']
          }
        }
        return new Promise((resolve, _) => {
          resolve(tempObj)
        })
      })
      setUpdatedList(await Promise.all(tempProviderList))
    }
  }, [providerList])

  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  return (
    <>
      <div className="row Preview_Main_Container">
        {props.showCreateProviderBtn && (
          <Button
            name="Add Provider"
            btnType="add"
            onClick={() => props.handleCreateEditModal()}
            className="provider-button ms-auto me-3"
          />
        )}
      </div>
      <div
        className={`Preview_Center_Container ${!props.footerVisible && 'pb-4'}`}
      >
        <h3 className="Font_Black Font_Quinary_Color d-flex justify-content-center p-4">
          Preview
        </h3>
        <div className="Preview_Wrapper">
          <div className="Preview_Image_Wrapper">
            <div className="w2w-wraper text-center mt-4 mx-2 pb-4">
              <h5 className="pt-2">Where to watch</h5>
              <p className="help-text px-1">
                {
                  "If you don't already have an account. use links(s) below sign up with a special offer"
                }
              </p>
              {updatedList.length &&
                [...updatedList]
                  .filter((item) => Object.keys(item)?.length)
                  .map((item, index) => (
                    <div
                      key={index}
                      className="bar d-flex mx-auto mt-2"
                      style={{
                        backgroundColor: `${item?.['barColor'] ?? '#fff'}`,
                      }}
                    >
                      {item['providerLogoString'] ? (
                        <img
                          className="float-start ms-1 mt-1"
                          src={item['providerLogoString']}
                          alt=""
                        />
                      ) : null}
                      <h5
                        className="bar-text float-start pt-2 ps-1"
                        style={{ color: `${item['fontColor']}` }}
                      >
                        {item['w2wLanguage']}
                      </h5>
                    </div>
                  ))}
            </div>

            <img
              style={{ visibility: 'hidden' }}
              id="preview_images"
              className=" Preview_Image m-0 p-0"
              alt="Preview Image"
              loading="lazy"
            ></img>
          </div>
          <div>
            <img src={nav_bar} className="Bottom_Nav" alt="Bottom Nav"></img>
          </div>
        </div>
        {props.footerVisible && (
          <div className="row">
            <PreviewFooter activeTabNo={2} />
          </div>
        )}
      </div>
    </>
  )
}

export default ProviderPreviewSection
