import { AiOutlineSmallDash } from 'react-icons/ai'

export const FormFieldTypes = {
  TEXT: 'text',
  TEXTAREA: 'textarea',
  TEL: 'tel',
  CHECKBOX: 'checkbox',
  EMAIL: 'email',
  NUMBER: 'number',
  URL: 'url',
  FILE: 'file',
  DATETIME: 'datetime',
}
Object.freeze(FormFieldTypes)

export const statusMapping = {
  1: 'Published',
  2: 'Live',
  3: 'Scheduled',
  4: 'Queued',
  5: 'Standby',
  6: 'Pending',
  7: 'Final',
}

export const buttonColorList = {
  1: '#4b4b4b',
  2: '#f2421d',
  3: '#bebebe',
  4: '#3ccfa5',
  5: '#ffbd3c',
  6: '#708cf1',
  7: '#4b4b4b',
}

export const userRoleMapping = { 1: 'Owner', 2: 'Admin', 3: 'User' }

export const statusInfoMapping = {
  1: 'Full access.',
  2: 'Can add and delete Users. Can also create, edit, and delete events and W2W providers.',
  3: 'Can create, edit, and delete events.',
}

export const userButtonColorList = {
  1: '#F2421D',
  2: '#3CCFA5',
  3: '#BEBEBE',
}

export const userStatusMapping = {
  0: 'Inactive',
  1: 'Active',
  3: 'Invited',
  4: 'Expired',
}

export const userStatusColorList = {
  0: '#4B4B4B',
  1: '#708CF1',
  3: '#FFBD3C',
  4: '#db5400',
}

export const headerList = [
  '',
  '#',
  'Question',
  'Template',
  'Start',
  'TTC',
  'End',
  'Value',
  'Status',
  'Correct answer',
  'Answer',
  'Modify',
]

export const template = [
  {
    id: 1,
    name: 'A/B images',
    image: true,
    data: [
      {
        id: 1,
        name: 'Option A (left)',
        imgURL: '',
        imgName: '',
      },
      {
        id: 2,
        name: 'Option B (right)',
        imgURL: '',
        imgName: '',
      },
    ],
  },
  {
    id: 2,
    name: 'A/B words',
    image: false,
    data: [
      { id: 1, name: 'Option A (left)' },
      { id: 2, name: 'Option B (right)' },
    ],
  },
]

export const preEventTemplate = [
  {
    id: 3,
    name: 'F1-PODIUM',
    image: false,
    data: {
      minSelection: 3,
      maxSelection: 3,
    },
    note: ' This is a multi-select question. Users pick which drivers will finish P1,P2 and P3',
    optionHeading: 'Driver ',
    optionsText:
      'Person_id ties to the uni_people Stats DB table. Either keep the default or overwrite by entering a new value',
    headings: ['Person_id', 'Team', 'Nickname', 'Include'],
    checkbox: true,
  },
  {
    id: 4,
    name: 'F1-FASTEST_LAP',
    image: false,
    data: {
      minSelection: 1,
      maxSelection: 1,
    },
    note: ' This is a single-select question. Users pick which driver will earn the Fastest Lap award',
    optionHeading: 'Driver ',
    optionsText:
      'Person_id ties to the uni_people Stats DB table. Either keep the default or overwrite by entering a new value',
    headings: ['Person_id', 'Team', 'Nickname', 'Include'],
    checkbox: true,
  },
  {
    id: 5,
    name: 'F1-Single team',
    image: false,
    data: {
      minSelection: 1,
      maxSelection: 1,
    },
    note: ' This is a single-select question. Users pick which team they think answers the question. Must include at least 2 team options.',
    optionHeading: 'Team ',
    optionsText:
      'Team_id ties to the uni_teams Stats DB table. Either keep the default or overwrite by entering a new value.',
    headings: ['Team_id', 'Team', 'Team name', 'Include'],
    checkbox: true,
  },
  {
    id: 6,
    name: 'F1-Single driver',
    image: false,
    data: {
      minSelection: 1,
      maxSelection: 1,
    },
    note: ' This is a single-select question. Users pick which driver they think answers the question. Must include at least 2 driver options.',
    optionHeading: 'Driver ',
    optionsText:
      'Person_id ties to the uni_people Stats DB table. Either keep the default or overwrite by entering a new value',
    headings: ['Person_id', 'Team', 'Nickname', 'Include'],
    checkbox: true,
  },
  {
    id: 7,
    name: 'Text - single-select',
    image: false,
    data: {
      minSelection: 1,
      maxSelection: 1,
    },
    note: ' This is a single-select question with customized text options. Users read and pick only one of the choices they believe answers the question. Must include at least 2 options. The Include button will be disabled after 40 character in option Text',
    optionHeading: 'Driver ',
    optionsText: '',
    headings: ['Option text', 'Include'],
    checkbox: true,
  },
]

export const defaultOptionsList = Array.from({ length: 5 }, (_, i) => ({
  optionNumber: i + 1,
  ischecked: false,
  text: '',
}))

export const notificationLabel = [
  {
    id: 0,
    title: 'general',
    instruction:
      'share generic announcements. Tapping the notificaiton brings FanAmp into the foreground wherever the user last left off',
  },
  {
    id: 1,
    title: 'event',
    instruction:
      'share important information regarding upcoming or live events. Tapping the notification brings the user into the Events screen if there are pre-event picks or if the chat is live.Otherwise, they just land on the event tab image.',
  },
  {
    id: 2,
    title: 'news',
    instruction:
      'share important headlines. Tapping the notificaiton brings the user to the News tab and opens the in-app browser.',
  },
  {
    id: 3,
    title: 'stats',
    instruction:
      'flag updated data for categories or specific events. Tapping the notification brings the user to the stats tab to view either the category landing page or details of specific event.',
  },
  {
    id: 4,
    title: 'event Info Pages',
    instruction:
      'are used to communicate major updates like schedule changes, local activities, or some other important information regarding upcoming or live Events. Tapping the notificaiton brings the user to Event Info screen with the default Event Info Page content loading.If the notification relates to a future Event, Users must select it from the dropdown.',
  },
  {
    id: 5,
    title: 'tickets',
    instruction:
      'are used to communicate new ticket releases for one or many Events. Users will navigate to the Tickets tab and, depending on the destination chosen below, may open the in-app browser.',
  },
  {
    id: 6,
    title: 'picks',
    instruction:
      'are used for updates to the Sector, Season, and Lifetime points and leaderboards. Tapping the notification brings FanAmp into the foreground and directs the user to the Picks and Achievements tab.',
  },
]

export const moderationLabel = [
  {
    id: 0,
    title: 'All',
    type: 'all',
  },
  {
    id: 1,
    title: 'Banned',
    type: 'banned',
  },
  {
    id: 2,
    title: 'Suspended',
    type: 'suspended',
  },
  {
    id: 3,
    title: 'Deletion Pending',
    type: 'deletion pending',
  },
]
export const previewPodiumLabel = [
  { id: 0, title: 'P1' },
  { id: 1, title: 'P2' },
  { id: 2, title: 'P3' },
]

export const moderationStatusCode = [
  {
    id: 1,
    status: 'Active',
  },
  {
    id: 2,
    status: 'Delete',
  },
  {
    id: 3,
    status: 'Deletion Pending',
  },
  {
    id: 4,
    status: 'Suspend',
  },
  {
    id: 5,
    status: 'Banned',
  },
]

export const intialObjects = {
  file: '',
  fileName: '',
  lastUpdated: null,
  downloadFilePath: '',
  isDisable: true,
}

export const newsDataObject = {
  newsId: '',
  position: '',
}

export const newsArticleDataObject = {
  id: '',
  twitter_account_name: '',
  article_title: '',
  article_summary: '',
  article_link: '',
  preview_image_link: '',
}

export const statsEventObject = {
  id: '',
  title: '',
  startTime: '',
  categoryName: '',
  image: '',
  eventChannelName: '',
}

export const eventInfoPageObject = {
  id: '',
  name: '',
  startTime: '',
  endTime: '',
  pageImage: '',
  category: '',
}

export const ticketObject = {
  id: '',
  name: '',
  ticketName: '',
  ticketDesc: '',
  startTime: '',
  endTime: '',
  pageImage: '',
  category: '',
  url: '',
}

export const statsLabel = [
  { id: 0, title: 'Category' },
  { id: 1, title: 'Event' },
]

export const eventInfoLabel = [
  { id: 0, title: 'Category' },
  { id: 1, title: 'Page' },
]

export const ticketsLabel = [
  { id: 0, title: 'Category' },
  { id: 1, title: 'Ticket' },
]

export const PrioritySeasonObj = {
  seasonName: '',
  startTime: '',
  endTime: '',
}

export const PriorityEventGroupObj = {
  eventGroupName: '',
}

export const PriorityEventObj = {
  eventName: '',
}

export const ModerationList = [
  { label: 'Active', value: 'active' },
  { label: 'Banned', value: 'ban' },
  { label: 'Suspend', value: 'suspend' },
  { label: 'Deletion Pending', value: 'deletion-pending' },
]

export const mediaLabel = [
  { id: 0, title: 'None' },
  { id: 1, title: 'Photo' },
  { id: 2, title: 'Video' },
  { id: 3, title: 'GIF' },
]

export const messageButtonabel = [
  { id: 0, title: 'None' },
  { id: 1, title: 'Browser' },
  { id: 2, title: 'Chat' },
]

export const sectorData = [
  { id: 1, name: 'Sector 1', season: '2024', running: true },
  { id: 2, name: 'Sector 2', season: '2024', running: false },
  { id: 3, name: 'Sector 3', season: '2024', running: false },
  { id: 4, name: 'Sector 4', season: '2024', running: false },
]
