import React from 'react'
import PropTypes from 'prop-types'
import './FormField.scss'
import classNames from 'classnames'
import { enumPropTypeValidator } from '../../../prop-type-validators/enumPropType.validator'
import { FormFieldTypes } from '../../../constants/enumTypes.constants'
const formFieldLabelClasses = classNames([`FormField__Label`, 'Font_Black'])

const FormField = (props) => {
  const renderLabel = () => {
    const label = props.isRequired ? props.label + '*' : props.label
    return <label className={`mb-1 ${formFieldLabelClasses}`}> {label} </label>
  }
  const renderPara = () => {
    return <p className="mb-1">{props.paraVisibility ? props.para : ''}</p>
  }

  // * renderField
  const renderField = () => {
    switch (props.type) {
      case FormFieldTypes.EMAIL:
      case FormFieldTypes.NUMBER:
      case FormFieldTypes.TEXT: {
        const inputTextClasses = classNames(
          `FormField__Input__Text Font_Regular`,
          {
            Error: props.iserror,
          }
        )
        return (
          <div className="d-flex">
            <input
              id={props.id}
              type={props.type}
              className={`${inputTextClasses} ${props.className} `}
              name={props.name}
              onChange={props.onChange}
              onBlur={props.onBlur}
              placeholder={props.placeholder}
              onKeyPress={props.onKeyPress}
              value={props.value}
              disabled={props.disabled}
              readOnly={props.readOnly}
              autoComplete={props.autoComplete ?? 'on'}
            />
            {props.characterLeftVisible && (
              <div className="align-self-end ps-2">
                {' '}
                ({props.characterLeft})
              </div>
            )}
          </div>
        )
      }
      case FormFieldTypes.TEXTAREA: {
        return (
          <div className="d-flex">
            <textarea
              value={props.value}
              className={`form-control Bg_Senary_Color FormField_Textarea ${props.className} `}
              id="exampleFormControlTextarea1"
              rows="3"
              placeholder={props.placeholder}
              onChange={props.onChange}
              disabled={props.disabled}
            ></textarea>
            {props.characterLeftVisible && (
              <div className="align-self-end ps-2">
                {' '}
                ({props.characterLeft})
              </div>
            )}
          </div>
        )
      }
      default: {
        return null
      }
    }
  }
  // * return
  return (
    <div className="">
      {!props.hideLabel && renderLabel()}
      {renderPara()}
      {renderField()}
    </div>
  )
}

FormField.propTypes = {
  type: (props, propName, componentName) => {
    return enumPropTypeValidator(
      props,
      propName,
      componentName,
      FormFieldTypes,
      true
    )
  },
  id: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  iserror: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  hideLabel: PropTypes.bool,
  para: PropTypes.string,
  paraVisibility: PropTypes.bool,
  errorMessage: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  handleChange: PropTypes.func,
  checked: PropTypes.bool,
  handleBlur: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
}

FormField.default = {
  type: FormFieldTypes.TEXT,
  disabled: false,
  iserror: false,
  isRequired: false,
  errorMessage: '',
  hideLabel: false,
  checked: false,
  paraVisibility: false,
}

export default FormField
