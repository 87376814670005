import React, { useState, useEffect } from 'react'
import Countdown, { zeroPad } from 'react-countdown'
import { usePubNub } from 'pubnub-react'
import { EventParticipation } from './EventParticipation'
import PreviewFooter from '../../Events/PreviewSection/PreviewFooter'
import { LiveGamificationPreview } from './LiveGamificationPreview'
import { FastestLapPreview } from './FastestLapPreview'
import { PodiumPreview } from './PodiumPreview'
import {OptionQuestionPreview} from './OptionQuestionPreview'
import './GamificationPreview.scss'

const gamificationPreview = (props) => {
  const NoneValue = 'N/A'
  const DefaultValue = 'TBD'
  const [question, setQuestion] = useState(DefaultValue)
  const [AName, setAName] = useState(DefaultValue)
  const [BName, setBName] = useState(DefaultValue)
  const [APercent, setAPercent] = useState(DefaultValue)
  const [BPercent, setBPercent] = useState(DefaultValue)
  const [ACount, setACount] = useState(DefaultValue)
  const [BCount, setBCount] = useState(DefaultValue)
  const [totalResponse, setTotalResponse] = useState(DefaultValue)
  const [ttc, setTTC] = useState(DefaultValue)
  const [timerEnable, setTimerEnable] = useState(false)
  const [optionAImg, setOptionAImg] = useState('')
  const [optionBImg, setOptionBImg] = useState('')
  const [currentStatus, setCurrentStatus] = useState(0)
  const [currentUsers, setCurrentusers] = useState('TBD')
  const [peakUsers, setPeakUsers] = useState(0)
  //pubnub Integratoin for event participation monitoring
  const pubnub = usePubNub()

  const { activeQuestion, eventDetails } = props

  const Completionist = () => <>Closed</>

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return <Completionist />
    } else {
      // Render a countdown
      return (
        <>
          {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
        </>
      )
    }
  }

  //handling occupancy for event and setting current users on channel monitoring
  const handleOccupancy = (event) => {
    setCurrentusers(event?.occupancy)
  }
  //handling max users
  const maxUsers = (count) => {
    count > peakUsers && setPeakUsers(count)
  }

  //adding listner on first render for pubnub
  useEffect(() => {
    pubnub.addListener({
      presence: handleOccupancy,
    })
  }, [])

  //handling occupancy for event and setting current users on channel monitoring
  useEffect(() => {
    maxUsers(currentUsers)
  }, [currentUsers])

  //subscribing channel on event withPresence to pubnub
  useEffect(() => {
    setPeakUsers(eventDetails?.maxOccupancyCount)
    pubnub.subscribe({
      channels: [eventDetails?.eventChannelName + '-pnpres'],
    })
  }, [eventDetails])

  useEffect(() => {
    if (activeQuestion) {
      setQuestion(activeQuestion?.question)
      setAName(activeQuestion?.answerOptions?.[0]?.text ?? NoneValue)
      setBName(activeQuestion?.answerOptions?.[1]?.text ?? NoneValue)
      setAPercent(activeQuestion?.percentage?.[0] ?? NoneValue)
      setBPercent(activeQuestion?.percentage?.[1] ?? NoneValue)
      setACount(activeQuestion?.count?.[0] ?? NoneValue)
      setBCount(activeQuestion?.count?.[1] ?? NoneValue)
      setTotalResponse(activeQuestion?.count?.totalCount ?? NoneValue)
      setCurrentStatus(activeQuestion?.questionStatus)
      const newTTC =
        parseInt(activeQuestion.questionStatus) == 2
          ? activeQuestion.endTime
          : activeQuestion.ttc
      setTTC(newTTC)
      const updateTimer = activeQuestion.questionStatus == 2 ? true : false
      setTimerEnable(updateTimer)

      setOptionAImg(activeQuestion.questionTemplate.data[0]?.imgURL ?? '')
      setOptionBImg(activeQuestion.questionTemplate.data[1]?.imgURL ?? '')
    }
  }, [activeQuestion?.id, activeQuestion?.count, activeQuestion?.percentage])

  return (
    <div className="col-2 gamification-preview">
      <EventParticipation
        currentUsers={currentUsers}
        peakUsers={peakUsers}
      ></EventParticipation>

      <div className="row seprator"></div>
      <div className="row preview-text p-4">Preview</div>
      <div className="row justify-content-left bold-text p-3">{question}</div>
      <div className="row justify-content-left d-flex pt-2 pb-3">
        <div>
          <b className="pe-1">Total responses: </b>
          {totalResponse}
        </div>
        <div>
          <b className="pe-1">Time to close:</b>

          {currentStatus === 1 ? (
            <Completionist />
          ) : timerEnable ? (
            <Countdown
              date={Date.now() - (Date.now() - new Date(ttc))}
              renderer={renderer}
            />
          ) : (
            ttc
          )}
        </div>
      </div>

      <LiveGamificationPreview
        activeQuestion={activeQuestion}
        AName={AName}
        BName={BName}
        APercent={APercent}
        BPercent={BPercent}
        ACount={ACount}
        BCount={BCount}
        optionAImg={optionAImg}
        optionBImg={optionBImg}
      />
      {/* preview panel for fastestlap, single driver and single team are in below component */}
      <FastestLapPreview activeQuestion={activeQuestion} />
      <PodiumPreview activeQuestion={activeQuestion} />
      <OptionQuestionPreview activeQuestion={activeQuestion} />
    </div>
  )
}

export default gamificationPreview
