import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { getToken, getUser } from '../Login/ManageUser'
import Sidebar from '../common/Sidebar'
import Button from '../common/Button/Button'
import 'react-datepicker/dist/react-datepicker.css'
import './EventInfo.scss'
import { ArticleGroup } from './ArticleGroup'
import axios from 'axios'
import { notify } from '../Notify/Notify'

const Articles = (props) => {
  const BASE_URL = process.env.REACT_APP_API_V2_URL
  const V2_URL = process.env.REACT_APP_API_V2_URL

  // * useNavigate used for redirect to login.
  let navigate = useNavigate()

  // * useLocation used to get the props.
  const { state } = useLocation()
  const token = getToken()
  const user = getUser()

  const handleExit = () => {
    navigate(`/events/eventinfo/`)
  }
  const [disableSave, setDisableSave] = useState(false)
  const [groupName, setGroupName] = useState('')
  const [groupName2, setGroupName2] = useState('')
  const [newsData, setNewsData] = useState([])
  const [newsData2, setNewsData2] = useState([])
  const [articleGroup, setArticleGroup] = useState({
    groupName: '',
    articleIds: [],
  })
  const [articleGroup2, setArticleGroup2] = useState({
    groupName: '',
    articleIds: [],
  })
  const [errors, setErrors] = useState([
    { groupName: '', articleIds: '' },
    { groupName: '', articleIds: '' },
  ])

  useEffect(() => {
    getEventInfoDetail()
  }, [])

  const getEventInfoDetail = () => {
    if (state != null && state.eventInfoId != null) {
      let url = `${V2_URL}eventinfo/${state.eventInfoId}`
      axios
        .get(url, {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res?.data?.data?.articles?.[0]) {
            setArticleGroup((prevData) => ({
              ...prevData,
              groupName: res?.data?.data?.articles?.[0]?.groupName,
              articleIds: res?.data?.data?.articles?.[0]?.articleIds,
            }))
            setGroupName(res?.data?.data?.articles?.[0]?.groupName)
          }
          if (res?.data?.data?.articles?.[1]) {
            setArticleGroup2((prevData) => ({
              ...prevData,
              groupName: res?.data?.data?.articles?.[1]?.groupName,
              articleIds: res?.data?.data?.articles?.[1]?.articleIds,
            }))
            setGroupName2(res?.data?.data?.articles?.[1]?.groupName)
          }
        })
    }
  }

  const handleBackClick = (page) => {
    navigate(`/events/eventinfo/edit-eventinfo/${page}`, {
      state: {
        eventInfoId: state.eventInfoId,
        eventinfoName: state.eventinfoName,
      },
    })
  }

  // This function is responsible to convert the list of Ids from
  // [{id: '1212', name: 'No Article title'}, {id: '1232', name: 'No Article title'}]    ==to==>    ['1212', '1232']
  const getArticleIdsToSend = (articleIds) => {
    let newIds = articleIds.map((item) => String(item.id))
    return newIds
  }

  // This function validates the data entered in any article group
  const validateGroup = (data, name, index) => {
    const newErrors = [...errors]
    if (name === '' && data.length === 0) {
      newErrors[index].groupName = ''
      newErrors[index].articleIds = ''
    } else {
      newErrors[index].groupName =
        name === '' ? 'Group name is required.' : ''
      newErrors[index].articleIds =
        data.length === 0 ? 'At least one article ID is required.' : ''
    }
    setErrors(newErrors)
  }

  // This function is responsible to validate both the group before hitting the API
  const handleValidation = () => {
    validateGroup(newsData, groupName, 0)
    validateGroup(newsData2, groupName2, 1)
  }

  const handleSave = () => {
    handleValidation()
    const hasErrors = errors.some(
      (error) => error.groupName !== '' || error.articleIds !== ''
    )

    if (hasErrors) {
      return
    }
    let form_data = new FormData()
    let articles = []
    if (
      articleGroup.groupName.length > 0 &&
      articleGroup.articleIds.length > 0
    ) {
      articles.push({
        groupName: articleGroup.groupName,
        articleIds: getArticleIdsToSend(newsData),
      })
    }
    if (
      articleGroup2.groupName.length > 0 &&
      articleGroup2.articleIds.length > 0
    ) {
      articles.push({
        groupName: articleGroup2.groupName,
        articleIds: getArticleIdsToSend(newsData2),
      })
    }
    form_data.append('articles', JSON.stringify(articles))
    setDisableSave(true)
    // API Call
    let url = `${BASE_URL}eventInfo/${state.eventInfoId}`
    axios
      .put(url, form_data, {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.data?.success) {
          const title = 'Success'
          const detail = res?.data?.message
          const type = 'success'
          notify(title, detail, type)
        }
        setDisableSave(false)
        setDisableNext(false)
      })
      .catch((err) => {
        setDisableSave(false)
      })
  }

  return (
    <>
      {token && user ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-3 col-lg-2">
              <Sidebar
                currentSelected="Events"
                currentsubSelected="Event Info Pages"
              />
            </div>
            <div className="col-6 col-lg-8">
              <div className="row mt-4">
                <div className="breadcrumb">
                  <p className="Roboto_Black Event_Name">{`${
                    state?.eventinfoName.length > 0
                      ? `${state?.eventinfoName} > Article Groups`
                      : ''
                  }  `}</p>
                </div>
                <div className="EventInfo-wrapper p-5">
                  <div className="row">
                    <p>
                      Articles can be added and displayed on an Event Info Page
                      in scrollable groups within the application.Content is
                      sourced from the News Database and accessed through the
                      in-app browser. Each Article Group is hidden untill at
                      least 1 article is added.
                    </p>
                  </div>
                  <ArticleGroup
                    name={'Article Group #1'}
                    articleGroup={articleGroup}
                    setArticleGroup={setArticleGroup}
                    groupName={groupName}
                    setGroupName={setGroupName}
                    newsData={newsData}
                    setNewsData={setNewsData}
                    error={errors[0]}
                  />
                  <ArticleGroup
                    name={'Article Group #2'}
                    articleGroup={articleGroup2}
                    setArticleGroup={setArticleGroup2}
                    groupName={groupName2}
                    setGroupName={setGroupName2}
                    newsData={newsData2}
                    setNewsData={setNewsData2}
                    error={errors[1]}
                  />
                  <div className="row">
                    <div className="col-3 col-lg-2">
                      <Button name="Exit" onClick={() => handleExit()} />
                    </div>
                    <div className="col-3 col-lg-2 ms-auto">
                      <Button
                        name="Back"
                        onClick={() => handleBackClick('schedule')}
                      />
                    </div>
                    <div className="col-3 col-lg-2">
                      <Button
                        name="Save"
                        onClick={handleSave}
                        disabled={disableSave}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        navigate('/login')
      )}
    </>
  )
}
export default Articles
