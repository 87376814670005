import React from 'react'

export function RetrivingCommentsFrom(props) {
  return (
    <div className="col-3">
      <label className="data-feed-form-label">
        Retrieving live comments from:
      </label>
      <label className="data-feed-form-label m-0 pt-2">Title:</label>
      <div className="data-feed-form-data">
        {props.subredditFetchedData?.title
          ? props.subredditFetchedData?.title
          : 'Post not yet selected'}
      </div>
      <label className="data-feed-form-label m-0 pt-2">Post ID:</label>
      <div className="data-feed-form-data">
        {props.subredditFetchedData?.id
          ? props.subredditFetchedData?.id
          : 'Post not yet selected'}
      </div>
      <label className="data-feed-form-label m-0 pt-2">URL:</label>
      <div className="data-feed-form-data">
        {props.subredditFetchedData?.url ? (
          <a
            href={props.subredditFetchedData?.url}
            target="_blank"
            rel="noreferrer"
          >
            {props.subredditFetchedData?.url}
          </a>
        ) : (
          'Post not yet selected'
        )}
      </div>
    </div>
  )
}
