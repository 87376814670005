import React from 'react'
import Form from 'react-bootstrap/Form'
import DatePicker from 'react-datepicker'

export function QuestionStatusMaping(props) {
  const timeChanged = (date) => {
    props.setStartDate(new Date(date)) &&
      props.setEndDate(props.preEventQuestionThresholdTime)

    props.validateStartTime(date)
  }
  return (
    <div className="row start-time align-items-center">
      <div className="col-3 col-lg-2 d-flex align-items-center">
        <Form.Check
          inline
          name="group1"
          type="radio"
          value={props.item}
          onChange={(e) => props.handleInputChange(e, 'questionStatus')}
          checked={Number(props.defaultStatus) === Number(props.item)}
          id={`radio-${props.index}`}
          className="pt-2"
        />
        <label
          htmlFor={`radio-${props.index}`}
          className="status-label"
          style={{
            backgroundColor: props._item,
          }}
        >
          {props.__item}
        </label>
      </div>

      {props.item == 3 && (
        <>
          <div className="col-4 ps-0 pt-4">
            <DatePicker
              showTimeSelect
              selected={props.startTime}
              dateFormat="MM/dd/yyyy hh:mm:ss a"
              timeIntervals={1}
              maxDate={new Date(props.eventStartTime)}
              onChange={(date) => timeChanged(date)}
              disabled={
                props.isEdit ? new Date(props.startTime) < new Date() : false
              }
              required
            />

            <div>
              {props.startTimeError && (
                <div className="input-feedback">{props.startTimeError}</div>
              )}
            </div>
          </div>

          <div className="col-4 col-lg-5">
            <label className="row m-0 ttc-main-label">
              Time to close (TTC)
            </label>
            {props.selectedGamificationLabel?.id === 0 ? (
              <div className="mt-2">
                Automatic - question closes when event starts
              </div>
            ) : null}
            {props.selectedGamificationLabel?.id === 1 ? (
              <div className="row mt-3 m-0">
                <input
                  className="ttc col-md-7 col-lg-6"
                  value={props.ttc ?? '00:00:10'}
                  type="time"
                  id="ttc"
                  step={1}
                  name="ttc"
                  min="00:00:10"
                  max="01:00:00"
                  onChange={(e) => props.handleInputChange(e, 'ttc')}
                  required
                />
                <div className="ttc-label col-4">hh:mm:ss</div>
                <div className="ps-0">
                  {props.errors && (
                    <div className="input-feedback">{props.errors.ttc}</div>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </>
      )}

      {props.item == 5 && (
        <div className="col-9 col-lg-10 ps-0 pb-2">
          Listed with no start time
        </div>
      )}

      {props.item == 4 && (
        <div className="col-9 col-lg-10 ps-0 pb-2">
          Ready to go Live to users immediately following the completion of any
          preceding Queued question(s).
        </div>
      )}
    </div>
  )
}
