import React from 'react'
import { Button, Modal } from 'react-bootstrap'

const confirmAnswerModal = (props) => {
  return (
    <Modal
      {...props}
      className="delete-poll-modal"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h4 className="confirm-answer-modal pb-2">
          Are you sure you want to finalize the answer to this question?
        </h4>
        <p>
          The Correct Answer will become permanently locked and users will be
          awarded points if their choice(s) matched.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>No</Button>
        <Button onClick={props.onAnswerStatusChange}>Yes</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default confirmAnswerModal
