import React from 'react'

import Button from '../Button/Button'
import './EventFooter.scss'

const eventFooter = (props) => {
  return (
    <div className="row event-footer p-4 m-0">
      <div className="col-3 col-lg-2">
        <Button name="Exit" onClick={props.onExitClick} />
      </div>
      <div className="col-3 col-lg-2 ms-auto">
        <Button name="Back" onClick={props.onBackClick} />
      </div>
      {!props.isNextHidden && (
        <div className="col-3 col-lg-2">
          <Button
            name="Next"
            onClick={props.onNextClick}
            disabled={props.isNextDisable}
          />
        </div>
      )}
      {!props.isSaveHidden && (
        <div className="col-3 col-lg-2">
          <Button
            name="Save"
            onClick={props.onSubmitClick}
            disabled={props.isSaveDisable}
          />
        </div>
      )}
    </div>
  )
}

export default eventFooter
