import React, { useState, useEffect, useRef } from 'react'
require('./Pagination.scss')
import ReactPaginate from 'react-paginate'
import PropTypes from 'prop-types'

const Pagination = (props) => {
  // const pagination = useRef()

  return (
    <ReactPaginate
      // ref={pagination}
      pageCount={props.pageCount}
      pageRangeDisplayed={3}
      marginPagesDisplayed={1}
      containerClassName="pagination"
      activeClassName="active"
      pageLinkClassName="page-link"
      breakLinkClassName="page-link"
      nextLinkClassName="page-link"
      previousLinkClassName="page-link"
      pageClassName="page-item"
      breakClassName="page-item"
      nextClassName="page-item"
      previousClassName="page-item"
      previousLabel={<>&laquo;</>}
      nextLabel={<>&raquo;</>}
      onPageChange={props.onPageChange}
      className={props.className ? props.className : ''}
    />
  )
}
Pagination.propTypes = {
  adminUserList: PropTypes.array,
  onPageChange: PropTypes.func,
  pageCount: PropTypes.number,
  pageRangeDisplayed: PropTypes.number,
  className: PropTypes.string,
}

export default Pagination
