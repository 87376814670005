import React from 'react'
import { notify } from '../Notify/Notify'
import './MessageContainer.scss'

const ATTACHMENT_TYPES = {
  1: 'image',
  2: 'video',
  3: 'image',
}

const convertTimetokenToLocalTime = (timetoken) => {
  try {
    // Convert timetoken from nanoseconds to milliseconds
    const timestampInMs = parseInt(timetoken, 10) / 1e4

    // Create a Date object from the timestamp (this will be in local time)
    const date = new Date(timestampInMs)

    if (isNaN(date.getTime())) {
      throw new Error('Invalid date')
    }

    // Get local date components
    const day = date.getDate()
    const month = date.getMonth() + 1 // Months are zero-indexed
    const year = date.getFullYear()
    let hours = date.getHours()
    const minutes = date.getMinutes()

    // Determine AM/PM
    const ampm = hours >= 12 ? 'PM' : 'AM'

    // Convert to 12-hour format
    hours = hours % 12
    hours = hours ? hours : 12 // If hours is 0, set it to 12

    // Format minutes to always be two digits
    const minutesFormatted = minutes < 10 ? `0${minutes}` : minutes

    // Return formatted date and time
    return `${day}-${month}-${year} ${hours}:${minutesFormatted} ${ampm}`
  } catch (error) {
    console.error('Error converting timetoken:', error)
    return 'Invalid time'
  }
}

const formatDateLabel = (date) => {
  const options = { day: '2-digit', month: 'short', year: 'numeric' }
  return date.toLocaleDateString('en-US', options)
}

const MessageContainer = ({
  userName,
  userAvatar,
  item,
  content,
  timestamp,
  previousMessageTimestamp,
}) => {
  const renderAttachment = () => {
    const attachment = item?.meta?.attachments?.[0]

    if (!attachment) return null

    const type = ATTACHMENT_TYPES[attachment.type]
    if (!type) return null

    const commonStyle = {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    }

    const mediaProps = {
      image: {
        src: attachment?.url,
        alt: attachment?.type,
      },
      video: {
        src: attachment?.url,
        title: attachment?.type,
        controls: true,
      },
    }

    const MediaComponent = type === 'video' ? 'video' : 'img'

    return (
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: 0,
          paddingBottom: '56.25%',
        }}
      >
        <MediaComponent style={commonStyle} {...mediaProps[type]} />
      </div>
    )
  }

  const renderButton = () => {
    const userNotify = (id) => {
      const title = 'Success'
      const detail = 'This redirects user to user id: ' + id + ' in mobile app.'
      const type = 'success'
      notify(title, detail, type)
    }

    if (item?.meta?.messageTemplate === '6') {
      return (
        <>
          <hr className="my-2" />
          {item?.meta?.button?.action === 'browser' ? (
            <button
              onClick={() =>
                window.open(item?.meta?.button?.browserUrl, '_blank')
              }
              className="text-center Display_Btn Font_Black Submit_Button"
            >
              {item?.meta?.button?.buttonText}
            </button>
          ) : (
            <button
              className="text-center Display_Btn Font_Black Submit_Button"
              onClick={() => userNotify(item?.meta?.button?.id)}
            >
              {item?.meta?.button?.buttonText}
            </button>
          )}
        </>
      )
    }
    return null
  }

  const renderDateLabel = () => {
    const currentDate = new Date(parseInt(timestamp, 10) / 1e4)
    const previousDate = previousMessageTimestamp
      ? new Date(parseInt(previousMessageTimestamp, 10) / 1e4)
      : null

    if (
      !previousDate ||
      currentDate.toDateString() !== previousDate.toDateString()
    ) {
      return <div className="date-label">{formatDateLabel(currentDate)}</div>
    }
    return null
  }

  return (
    <div>
      <div className="date-label-div">{renderDateLabel()}</div>
      <div>
        <div className="message-container">
          <div className="user-info">
            <div>
              <img src={userAvatar} alt={userName} className="user-avatar" />
            </div>
            <div className="username">{userName}</div>
          </div>
          <div className="content">
            {renderAttachment()}
            {content?.text && <div>{content.text}</div>}
            {renderButton()}
          </div>
          <div className="timestamp">
            {convertTimetokenToLocalTime(timestamp)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MessageContainer
