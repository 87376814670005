import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import nav_bar from '../../../assets/img/NAV BAR.svg'
import PropTypes from 'prop-types'
import { MdTimer } from 'react-icons/md'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { IconContext } from 'react-icons'
import Button from '../../common/Button/Button'
import PreviewFooter from '../PreviewSection/PreviewFooter'
require('./PreviewSection.scss')

const PreviewSection = (props) => {
  let navigate = useNavigate()

  const [imgSrc, setImgSrc] = useState(null)
  const [imgVisibility, setImgVisibility] = useState('hidden')
  useEffect(() => {
    setImgSrc(props.src), setImgVisibility(props.visibility)
  }, [props.src, props.visibility])

  function redirectToCreateEvent() {
    navigate(`/events/eventdetails/create-event`)
  }

  return (
    <IconContext.Provider value={{ color: `${props.fontColor}`, size: '30px' }}>
      <div className="row m-0">
        {props.showCreateEventBtn ? (
          <div className="col-12 d-flex px-0 Preview_Main_Container">
            <Button
              className="me-2 p-0"
              name="Add Stats"
              onClick={() => props.setShowUploadModal(true)}
              disabled={props.hideStatsbutton}
            />

            <Button
              className="p-0"
              name="Create Event"
              onClick={() => redirectToCreateEvent()}
            />
          </div>
        ) : null}
      </div>
      <div
        className={`row Preview_Center_Container m-0 ${
          !props.showCreateEventBtn ? 'create-event-position' : ''
        }`}
      >
        <h3 className="Font_Black Font_Quinary_Color d-flex justify-content-center p-4">
          Preview
        </h3>
        <div className="Preview_Wrapper p-0">
          <div className="Preview_Image_Wrapper">
            <img
              src={imgSrc}
              style={{ visibility: imgVisibility }}
              id="preview_images"
              className=" Preview_Image m-0 p-0"
              alt="Preview Image"
              loading="lazy"
            ></img>
            <div
              className="Preview_Image_Title"
              style={{ color: `${props.fontColor}` }}
            >
              {props.eventName}
            </div>
            <div
              className="Preview_Image_Description"
              style={{ color: `${props.fontColor}` }}
            >
              {props.eventDescription}
            </div>
            <div className="Timer_Icon">
              <MdTimer />
            </div>
            <div className="Info_Icon">
              <AiOutlineInfoCircle />
            </div>
          </div>
          <div>
            <img src={nav_bar} className="Bottom_Nav" alt="hello2"></img>
          </div>
        </div>
        <PreviewFooter activeTabNo={props.wizardNumber} />
      </div>
    </IconContext.Provider>
  )
}

PreviewSection.propTypes = {
  eventName: PropTypes.string,
  eventDescription: PropTypes.string,
  fontColor: PropTypes.string,
}

PreviewSection.defaultProps = {
  eventName: 'hello',
  eventDescription: '',
  fontColor: 'white',
}

export default PreviewSection
