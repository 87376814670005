import React from 'react'
import { Modal } from 'react-bootstrap'

import {
  userStatusMapping,
  userStatusColorList,
} from '../../../constants/enumTypes.constants'

const userStatusModal = (props) => {
  const statusInfoMapping = {
    0: 'Unable to access the admin dashboard.',
    1: 'Can access the admin dashboard.',
    3: 'Invite sent; awaiting password creation.',
    4: 'Invitation expired.',
  }

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
      <Modal.Body className="show-grid">
        <div className="container status-info-modal">
          {Object.keys(statusInfoMapping).map((item, index) => (
            <div className="row py-2" key={`user-${index}`}>
              <div
                className="info-button col-2"
                style={{
                  backgroundColor: userStatusColorList[item],
                }}
              >
                {userStatusMapping[item]}
              </div>
              <div className="col-10">{statusInfoMapping[item]}</div>
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default userStatusModal
