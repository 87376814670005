import React, { useEffect, useState } from 'react'
import { getToken } from '../Login/ManageUser'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import { notify } from '../Notify/Notify'
import FormField from '../common/FormField'
import Button from '../common/Button/Button'

const statusModal = (props) => {
  const {
    statusModal,
    onHide,
    data,
    id,
    editData,
    setSelectedStatus,
    setStatusModal,
  } = props
  const [confirmText, setConfirmText] = useState('')

  // * Authentication token
  const V2_URL = process.env.REACT_APP_API_V2_URL
  let token = getToken()

  useEffect(() => {
    setConfirmText('')
  }, [statusModal])

  const yesHandler = (data) => {
    setSelectedStatus(data)
    if (data?.label === 'Deletion Pending') {
      let url = `${V2_URL}userDeletion/${id}`
      axios
        .get(url, {
          params: { action: 'deletionPending' },
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            const title = 'Success'
            const detail = res?.data?.message
            const type = 'success'
            notify(title, detail, type)
          }
        })
    } else {
      let url = `${V2_URL}userRestriction/${id}`
      axios
        .get(url, {
          params: { action: data?.value },
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            const title = 'Success'
            const detail = res?.data?.message
            const type = 'success'
            notify(title, detail, type)
          }
        })
    }
    setStatusModal(false)
  }

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      show={statusModal}
      onHide={onHide}
      centered
    >
      <Modal.Body className="text-center">
        <div className="mt-4">
          <b>
            Are you sure you want to change status to <u>{data?.label}?</u>
          </b>
        </div>
        <div>
          <p className="mt-4">
            Type the message shown in the box below and then click “Yes” to
            continue.
          </p>
          <FormField
            className="w-50 mx-auto"
            value={confirmText ?? ''}
            hideLabel={true}
            name="Confirm Text"
            type="text"
            placeholder={
              data?.label == 'Deletion Pending'
                ? 'cancel deletion'
                : 'change status'
            }
            autoComplete="off"
            onChange={(e) => setConfirmText(e?.target?.value)}
          />
        </div>
        <div className="d-flex justify-content-center mt-2">
          <Button
            type="button"
            className="send-button me-4"
            name="No"
            onClick={onHide}
          />
          <Button
            type="button"
            className="send-button"
            name="Yes"
            onClick={() => yesHandler(data)}
            disabled={
              confirmText !==
              (data?.label == 'Deletion Pending'
                ? 'cancel deletion'
                : 'change status')
            }
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default statusModal
