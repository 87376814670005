import React, { useEffect, useState } from 'react'
import FormField from '../common/FormField'
import Button from '../common/Button/Button'
import axios from 'axios'
import { getToken } from '../Login/ManageUser'
import { notify } from '../Notify/Notify'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { RiDeleteBin5Fill } from 'react-icons/ri'
import { MdOutlineDragIndicator } from 'react-icons/md'

export function ArticleGroup({
  name,
  articleGroup,
  setArticleGroup,
  groupName,
  setGroupName,
  newsData,
  setNewsData,
  error,
}) {
  const BASE_URL = process.env.REACT_APP_API_BASE_URL
  const V2_URL = process.env.REACT_APP_API_V2_URL

  const token = getToken()
  const [articleIdInput, setArticleIdInput] = useState('')
  const [dataFetched, setDataFetched] = useState(false)
  const [newsArticleData, setNewsArticleData] = useState()
  const [isArticleChecked, setIsArticleChecked] = useState(false)
  const [disableAddArticle, setDisableAddArticle] = useState(true)
  const [lengthError, setLengthError] = useState(false)
  const [disableCheckArticle, setDisableCheckArticle] = useState(false)

  useEffect(() => {
    if (articleGroup?.articleIds.length > 0 && dataFetched === false) {
      getNewsDataInit(articleGroup?.articleIds)
    }
  }, [articleGroup])

  useEffect(() => {
    if (newsData.length < 10) {
      setLengthError(false)
    } else {
      setLengthError(true)
    }
  }, [newsData])

  // function to handle add article
  const handleAddArticle = (id) => {
    const foundArticle = newsData.find((item) => item.id === id)
    if (foundArticle) {
      notify('Error', "Articles can't be added twice,", 'error')
    } else {
      if (newsData.length < 11) {
        setArticleGroup((prevData) => {
          return {
            ...prevData,
            articleIds: [
              ...prevData.articleIds,
              {
                id: id,
                name: newsArticleData?.article_title
                  ? newsArticleData?.article_title
                  : `NO ARTICLE TITLE | ${
                      newsArticleData?.article_summary
                        ? newsArticleData?.article_summary
                        : 'NO DESCRIPTION'
                    }`,
              },
            ],
          }
        })
        setNewsData((prevNews) => [
          ...prevNews,
          {
            id: id,
            article_title: newsArticleData?.article_title
              ? newsArticleData?.article_title
              : 'No Article Title Found',
            article_summary: newsArticleData?.article_summary
              ? newsArticleData?.article_summary
              : 'No Article Summary Found',
          },
        ])
      }
    }
    setArticleIdInput('')
    setNewsArticleData({})
  }

  //   function to handle check article
  const getNewsArticleData = (articleID) => {
    setDisableAddArticle(true)
    setDisableCheckArticle(true)
    const url = `${V2_URL}newsfeeds/newshighlightsv2?highlight_ids=${articleID}`
    axios
      .get(
        url,
        {},
        {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res?.data?.data?.length > 0) {
          const resNewsData = res?.data?.data?.[0]
          const title = 'Success'
          const detail = res.data?.message
          const type = 'success'
          notify(title, detail, type)
          setNewsArticleData({
            twitter_account_name: resNewsData?.twitter_account_name ?? '',
            article_title: resNewsData?.article_title ?? '',
            article_summary: resNewsData?.article_summary ?? '',
            article_link: resNewsData?.article_link ?? '',
            id: String(resNewsData?.id) ?? '',
            preview_image_link: resNewsData?.preview_image_link ?? '',
          })
          setDisableAddArticle(false)
          setDisableCheckArticle(false)
        } else {
          const title = 'Error'
          const detail = 'News data not found'
          const type = 'error'
          notify(title, detail, type)
          setDisableCheckArticle(false)
        }
      })
      .catch(() => {
        setDisableAddArticle(true)
        setArticleIdInput('')
        setNewsArticleData({
          twitter_account_name: '',
          article_title: '',
          article_summary: '',
          article_link: '',
          id: '',
          preview_image_link: '',
        })
        setDisableCheckArticle(false)
      })
    setIsArticleChecked(true)
  }

  const getNewsDataInit = (ids) => {
    const articleIds = ids.map((item) => {
      if (typeof item == 'object') {
        return item.id
      }
      return item
    })
    const url = `${V2_URL}newsfeeds/newshighlightsv2?highlight_ids=${articleIds}`
    axios
      .get(
        url,
        {},
        {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setDataFetched(true)
        setArticleGroup((prevData) => {
          return {
            ...prevData,
            articleIds: [],
          }
        })
        const fetchedIdsList = res?.data?.data
        const fetchedIds = fetchedIdsList.map((item) => {
          setArticleGroup((prevData) => {
            return {
              ...prevData,
              articleIds: [
                ...prevData.articleIds,
                {
                  id: item?.id,
                  name: item?.article_title
                    ? item?.article_title
                    : `NO ARTICLE TITLE | ${
                        item?.article_summary
                          ? item?.article_summary
                          : 'NO DESCRIPTION'
                      }`,
                },
              ],
            }
          })
        })
        const orderedResponse = orderResponse(articleIds, fetchedIdsList)
        setNewsData(orderedResponse)
      })
  }
  const handleNameChange = (e) => {
    const maxLength = 45
    const { value } = e.target

    if (value.length > maxLength) {
      setGroupName(value.substring(0, maxLength))
      setArticleGroup((prevData) => ({
        ...prevData,
        groupName: value.substring(0, maxLength),
      }))
    } else {
      setGroupName(value)
      setArticleGroup((prevData) => ({
        ...prevData,
        groupName: value,
      }))
    }
  }

  function truncateString(str, maxLength) {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + '...'
    }
    return str
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    ...draggableStyle,
  })

  const deleteRow = (indexToDelete) => {
    setNewsData((prevNews) =>
      prevNews.filter((row, index) => index !== indexToDelete)
    )
  }

  function orderResponse(originalArray, backendResponse) {
    // Create a map to store the indices of ids in the original array
    const idMap = new Map()
    originalArray.forEach((id, index) => {
      idMap.set(id, index)
    })

    // Sort the backend response based on the indices in the original array
    backendResponse.sort((a, b) => {
      const indexA = idMap.get(a.id)
      const indexB = idMap.get(b.id)
      return indexA - indexB
    })
    return backendResponse
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const updatedItems = reorder(
      newsData,
      result.source.index,
      result.destination.index
    )
    setNewsData(updatedItems)
  }

  const getListStyle = (isDraggingOver) => ({})

  return (
    <div className="my-3">
      <div className="d-flex">
        <div className="col-5 p-0 me-2">
          <h4 className="Font_Black mb-4">{name}</h4>
          <div className="col-6 p-0">
            <FormField
              value={groupName}
              id="articleGroupName"
              label="Group Name"
              para="This will set the header value for the Article Group in
                        the application."
              paraVisibility={true}
              name="articleGroupName"
              type="text"
              placeholder="Enter Article Group Name"
              onChange={(e) => {
                handleNameChange(e)
              }}
              disabled={false}
            />
            {<div className="input-feedback">{error.groupName}</div>}
          </div>
          <div>
            <FormField
              className="col-6 m-0"
              value={articleIdInput}
              name="Article"
              type="text"
              label="Article"
              para="Enter the article's ID found in news database"
              placeholder="News Article ID#"
              paraVisibility={true}
              onChange={(e) => {
                setArticleIdInput(e.target.value)
                setIsArticleChecked(false)
              }}
              characterLeftVisible={false}
            />

            <Button
              type="button"
              className="send-button mt-2"
              name="Check Article"
              onClick={() => getNewsArticleData(articleIdInput)}
              disabled={articleIdInput.length === 0 || disableCheckArticle}
            />
            <Button
              type="button"
              className="send-button mt-2 ml-3"
              name="Add Article"
              onClick={() => handleAddArticle(articleIdInput)}
              disabled={
                articleIdInput.length === 0 ||
                newsData.length >= 10 ||
                !isArticleChecked ||
                disableAddArticle
              }
            />
          </div>
          {lengthError && (
            <div className="input-feedback w-75">
              You can only add upto 10 Articles.
            </div>
          )}
          <div className="mt-4">
            <div>
              <b>Twitter account: </b>
              {newsArticleData?.twitter_account_name ?? ''}
            </div>
            <div
              className="thirty-chars twitter-div"
              title={newsArticleData?.article_title}
            >
              <b>Article Title: </b>
              {newsArticleData?.article_title ?? ''}
            </div>
            <div
              className="thirty-chars twitter-div"
              title={newsArticleData?.article_summary}
            >
              <b>Article summary: </b>
              {newsArticleData?.article_summary?.length > 120
                ? newsArticleData?.article_summary?.substring(0, 120) + '...'
                : newsArticleData?.article_summary ?? ''}
              <div>
                <b>URL</b>:
                {newsArticleData?.article_link ? (
                  <a
                    href={newsArticleData?.article_link ?? '#'}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Article URL
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="col-7 p-0">
          <div className="row table-header font-weight-bold">
            <div className="col-2 pl-3 mr-5">NewsId</div>
            <div className="col-3 pl-2 mr-5">Article Title</div>
            <div className="col-5 pl-2">Article summary</div>
          </div>
          <hr className="row mt-3" />
          <div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {newsData.map((item, index) => (
                      <Draggable
                        key={`activity-${index}`}
                        draggableId={`activity-${index}`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <div
                              className="row p-0 mx-0 my-2 align-items-center"
                              key={index}
                            >
                              <div className="col-2 row m-0 p-0">
                                <div className="col-3 p-0">
                                  <MdOutlineDragIndicator className="default-icon" />
                                </div>
                                <div className="col-8 p-0 m-0">
                                  [{item?.id}]
                                </div>
                              </div>
                              <div className="col-5 row">
                                <div className="col-2">-</div>
                                <div className="col-10 p-0 m-0">
                                  {item?.article_title
                                    ? truncateString(item?.article_title, 27)
                                    : 'No Title Found'}
                                </div>
                              </div>
                              <div className="col-5 row">
                                <div className="col-10 p-0 m-0">
                                  {item?.article_summary
                                    ? truncateString(item?.article_summary, 26)
                                    : 'No Summary Found'}
                                </div>
                                <div className="col-2">
                                  <RiDeleteBin5Fill
                                    className="default-icon ml-3"
                                    onClick={() => {
                                      deleteRow(index)
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div className="input-feedback">{error.articleIds}</div>
        </div>
      </div>
    </div>
  )
}
