import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import Button from '../common/Button/Button'
import SideBar from '../common/Sidebar/Sidebar'
import EventFooter from '../common/EventFooter/EventFooter'
import { getToken, getUser } from '../Login/ManageUser'
import { notify } from '../Notify/Notify'
import './EventDataFeeds.scss'
import { Threads } from './Threads'
import { RetrivingCommentsFrom } from './RetrivingCommentsFrom'
import { RangeSlider } from './RangeSlider'
import { DataFeedHeader } from './DataFeedHeader'

const eventDataFeeds = () => {
  // * useNavigate used for redirect to login
  let navigate = useNavigate()
  // * useLocation used to get the props
  const { state } = useLocation()
  // * Component state
  const [disableAllEdit, setDisableAllEdit] = useState(false)
  const [disablePostId, setDisablePostId] = useState(false)
  const [disableEdit, setDisableEdit] = useState(false)
  const [isCommentsEnabled, setCommentsEnabled] = useState(false)
  const [redditCount, setRedditCount] = useState(100)
  const [subRedditName, setSubRedditName] = useState('')
  const [loadingAPI, setLoadingAPI] = useState(false)
  const [subredditFetchedData, setSubredditFetchedData] = useState({
    title: '',
    id: '',
    url: '',
  })
  const [postThreadList, setPostThreadList] = useState()
  const [selectedPostData, setSelectedPostData] = useState({
    title: '',
    id: '',
    url: '',
  })

  // * Authentication variables
  const BASE_URL = process.env.REACT_APP_API_BASE_URL
  const token = getToken()
  const user = getUser()

  // * set breadcrumb
  const breadcrumb = state?.eventName + ' > Data feeds'

  useEffect(() => {
    // * Fetch event details if available on component did mount
    getEventDetail()
  }, [])

  // * Fetch event details
  const getEventDetail = () => {
    if (state.eventId) {
      let url = `${BASE_URL}event/${state.eventId}`
      axios
        .get(url, {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const responseData = res?.data?.data
          const redditResponseData = responseData?.redditData
          const currentDisableAllEdit = ['LIVE', 'FINAL', 'PENDING'].includes(
            responseData?.eventStatus?.toUpperCase()
          )
          const currentDisableEdit = ['FINAL', 'PENDING'].includes(
            responseData?.eventStatus?.toUpperCase()
          )
          setDisableAllEdit(currentDisableAllEdit)
          setDisableEdit(currentDisableEdit)
          setDisablePostId(currentDisableEdit)
          // * update state if reddit data available
          if (Object.entries(redditResponseData)?.length > 0) {
            setCommentsEnabled(redditResponseData?.isEnabled)
            setSubRedditName(redditResponseData?.subReddit)
            setRedditCount(Number(redditResponseData?.commentLimit) ?? 100)
            setSubredditFetchedData({
              title: redditResponseData?.postTitle,
              id: redditResponseData?.postId,
              url: redditResponseData?.url,
            })
            setSelectedPostData({
              title: redditResponseData?.postTitle,
              id: redditResponseData?.postId,
              url: redditResponseData?.url,
            })
          }
        })
    }
  }

  // * Update reddit data in event
  function submitDataFeed() {
    setLoadingAPI(true)
    const url = `${BASE_URL}reddit-comment/`
    const params = {
      eventId: state.eventId,
      subReddit: subRedditName,
      postId:
        selectedPostData?.id.length === 6
          ? 't3_' + selectedPostData?.id
          : selectedPostData?.id,
      postTitle: selectedPostData?.title,
      url:
        'https://www.reddit.com/r/' +
        subRedditName +
        '/comments/' +
        selectedPostData?.id.slice(3),
      isEnabled: isCommentsEnabled,
      commentLimit: Number(redditCount),
    }
    const headers = {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    }

    axios
      .post(url, params, {
        headers,
      })
      .then((res) => {
        if (res?.data?.success) {
          const title = 'Success'
          const detail = res.data.message
          const type = 'success'
          setSubredditFetchedData({
            title: res?.data?.data?.postTitle,
            id: res?.data?.data?.postId,
            url: res?.data?.data?.url,
          })
          notify(title, detail, type)
        }
      })
      .finally(() => {
        setLoadingAPI(false)
      })
  }

  // * Comment display toggle
  const handleCommentEnabled = () => {
    setCommentsEnabled(!isCommentsEnabled)
    const title = 'Success'
    const detail = !isCommentsEnabled
      ? 'Reddit Streaming enabled'
      : 'Reddit Streaming disabled'
    const type = 'success'
    notify(title, detail, type)
  }

  // * Handle input change
  const handleChange = (e, name) => {
    switch (name) {
      case 'reddit':
        setSubRedditName(e?.target?.value)
        break

      case 'postId':
        setSelectedPostData({
          title: '',
          id: e?.target?.value,
          url: '',
        })
        break

      default:
        break
    }
  }

  const searchSubreddit = (e) => {
    setLoadingAPI(true)
    const url = `${BASE_URL}/subreddit-posts?subReddit=${subRedditName}`
    axios
      .get(
        url,
        {},
        {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res?.data?.success) {
          const resNewsData = res?.data?.data
          const title = 'Success'
          const detail = res.data?.message
          const type = 'success'
          notify(title, detail, type)
          setPostThreadList(resNewsData)
        }
      })
      .finally(() => {
        setLoadingAPI(false)
      })
  }

  const handleExitClick = () => {
    navigate('/events/eventdetails/')
  }

  const handleButtonClick = (page) => {
    navigate(`/events/eventdetails/edit-event/${page}`, {
      state: {
        eventId: state?.eventId,
        eventName: state?.eventName,
      },
    })
  }

  // * Handle reddit comment count
  const changeRedditCount = (e) => {
    e?.target?.value > 0 &&
      e?.target?.value < 101 &&
      setRedditCount(e?.target?.value)
  }

  return (
    <>
      {token && user ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-3 col-lg-2">
              <SideBar
                currentSelected="Events"
                currentsubSelected="Event details"
              />
            </div>
            <div className="col-8 col-lg-8">
              <div className="row breadcrumb m-0 py-4">{breadcrumb}</div>

              <div className="data-feeds p-4">
                <DataFeedHeader
                  disableAllEdit={disableAllEdit}
                  disableEdit={disableEdit}
                  isCommentsEnabled={isCommentsEnabled}
                  handleCommentEnabled={handleCommentEnabled}
                />

                <div className="row pt-4">
                  {/* Subreddit name */}
                  <div className="col-2">
                    <label
                      className="data-feed-form-label"
                      htmlFor="subreddit_name"
                    >
                      Subreddit name
                    </label>
                    <div className="input-group w-30 mb-2 mr-sm-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">r/</div>
                      </div>
                      <input
                        value={subRedditName}
                        id="subreddit_name"
                        name="subReddit"
                        type="text"
                        onChange={(e) => handleChange(e, 'reddit')}
                        onBlur={searchSubreddit}
                        className="form-control"
                        aria-describedby="inputGroupPrepend"
                        disabled={disableAllEdit}
                        required
                      />
                    </div>

                    <label
                      className="data-feed-form-label"
                      htmlFor="subreddit_name"
                    >
                      Post ID
                    </label>
                    <div className="input-group w-30 mb-2 mr-sm-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">t3_</div>
                      </div>
                      <input
                        value={
                          selectedPostData?.id.length >= 9
                            ? selectedPostData?.id.slice(3)
                            : selectedPostData?.id
                        }
                        id="subreddit_name"
                        name="subReddit"
                        type="text"
                        className="form-control"
                        onChange={(e) => handleChange(e, 'postId')}
                        aria-describedby="inputGroupPrepend"
                        disabled={disablePostId}
                      />
                    </div>
                    <label className="data-feed-form-label m-0 pt-2">
                      URL:
                    </label>
                    <div className="data-feed-form-data">
                      {selectedPostData?.url ? (
                        <a
                          href={
                            'https://www.reddit.com/r/' +
                            subRedditName +
                            '/comments/' +
                            selectedPostData?.id.slice(3)
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          {'https://www.reddit.com/r/' +
                            subRedditName +
                            '/comments/' +
                            selectedPostData?.id.slice(3)}
                        </a>
                      ) : (
                        'Post not yet selected'
                      )}
                    </div>
                  </div>

                  {/* Threads */}
                  <Threads
                    loadingAPI={loadingAPI}
                    subredditFetchedData={subredditFetchedData}
                    postThreadList={postThreadList}
                    selectedPostData={selectedPostData}
                    setSelectedPostData={setSelectedPostData}
                    searchSubreddit={searchSubreddit}
                  />

                  {/* Comments form */}
                  <RetrivingCommentsFrom
                    subredditFetchedData={subredditFetchedData}
                  />
                </div>

                {/* Range slider */}
                <RangeSlider
                  disableAllEdit={disableAllEdit}
                  redditCount={redditCount}
                  changeRedditCount={changeRedditCount}
                />
              </div>

              <EventFooter
                onBackClick={() => handleButtonClick('gamification')}
                onExitClick={() => handleExitClick()}
                onSubmitClick={() => submitDataFeed()}
                isSaveDisable={
                  (disableEdit && disableAllEdit) || loadingAPI === true
                    ? true
                    : false
                }
                isNextHidden={true}
              />
            </div>
          </div>
        </div>
      ) : (
        navigate('/login')
      )}
    </>
  )
}

export default eventDataFeeds
