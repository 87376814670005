import React from 'react'

export function LiveGamificationPreview(props) {
  return (
    <>
      {props.activeQuestion?.answerOptions?.questionTemplateId < 3 && (
        <>
          {props.optionAImg && props.optionBImg ? (
            <div className="row">
              <div className="col-6 pb-3">
                <img src={props.optionAImg} href="" />
              </div>
              <div className="col-6 pb-3">
                <img src={props.optionBImg} href="" />
              </div>
            </div>
          ) : null}
          <div>
            <div className="row">
              <div className="col-6 right-border bold-text">OptionA</div>
              <div className="col-6 bold-text">OptionB</div>
            </div>
            <div className="row overflow-handle">
              <div className="col-6 right-border">{props.AName}</div>
              <div className="col-6">{props.BName}</div>
            </div>
            <div className="row overflow-handle">
              <div className="col-6 right-border">#:{props.ACount}</div>
              <div className="col-6">#:{props.BCount}</div>
            </div>
            <div className="row overflow-handle">
              <div className="col-6 right-border">%:{props.APercent}</div>
              <div className="col-6">%:{props.BPercent}</div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
