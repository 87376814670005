import React, { useState, useEffect } from 'react'
import { MdOutlineDragIndicator, MdLens } from 'react-icons/md'
import Form from 'react-bootstrap/Form'
import { PreOptionsQuestion } from './PreOptionsQuestion'
import './CreateEditPollQuestion.scss'

const CreateEditOptions = (props) => {
  const {
    list,
    selectedPreEventTemplate,
    driverIdList,
    refDriverList,
    refTeamList,
    refTextList,
    isEdit,
    searchDriverId,
    searchTeamId,
    handleChange,
    teamIdList,
    checkedDriverList,
    setCheckedDriverList,
    checkedTeamList,
    setCheckedTeamList,
    checkedTextOption,
    setCheckedTextOption,
    setDisableSaveOptions,
  } = props

  //useState
  const [checkList, setCheckList] = useState(
    selectedPreEventTemplate?.id === 5 ? [checkedTeamList] : [checkedDriverList]
  )

  //default check all variable
  const defaultCheckAll = list.map((obj) => ({ ...obj, ischecked: true }))
  //update props states
  const updateStates = (newList) => {
    selectedPreEventTemplate?.id === 5
      ? setCheckedTeamList(newList)
      : setCheckedDriverList(newList)
  }

  useEffect(() => {
    setCheckedDriverList(list), setCheckedTeamList(list), setCheckList(list)
  }, [list])
  useEffect(() => {
    validate()
  }, [checkList])

  const validate = () => {
    const minLength = selectedPreEventTemplate?.id === 3 ? 3 : 1
    const isCheckedCount = checkList.filter(
      (obj) => obj.ischecked === true
    ).length
    setDisableSaveOptions(isCheckedCount > minLength ? false : true)
  }

  function areSpecificFieldsEqual(obj1, obj2, tabletype) {
    // Check if both arguments are objects
    if (!isObject(obj1) || !isObject(obj2)) {
      return false
    }

    // Check if the personId, position, and ischecked fields have the same values
    const fieldsToCompare = [
      tabletype === 5 ? 'teamId' : 'personId',
      'position',
      'ischecked',
    ]
    return fieldsToCompare.every((field) => obj1[field] === obj2[field])
  }

  // Helper function to check if a value is an object
  function isObject(value) {
    return typeof value === 'object' && value !== null
  }

  const checkIsUpdateFlag = (position, tabletype, optionList) => {
    if (isEdit) {
      if (tabletype === 3 || tabletype === 4 || tabletype === 6) {
        if (
          !areSpecificFieldsEqual(
            optionList[position - 1],
            refDriverList[position - 1],
            tabletype
          )
        ) {
          const newOptionList = optionList.map((obj, index) => {
            if (index === position - 1) {
              return { ...obj, isChanged: true }
            }
            return obj
          })
          setCheckedDriverList(newOptionList)
          updateStates(newOptionList)
          setCheckList(newOptionList)
        } else {
          const newOptionList = optionList.map((obj, index) => {
            if (index === position - 1) {
              return { ...obj, isChanged: false }
            }
            return obj
          })
          setCheckedDriverList(newOptionList)
          updateStates(newOptionList)
          setCheckList(newOptionList)
        }
      }
      if (tabletype === 5) {
        if (
          !areSpecificFieldsEqual(
            optionList[position - 1],
            refTeamList[position - 1],
            tabletype
          )
        ) {
          const newOptionList = optionList.map((obj, index) => {
            if (index === position - 1) {
              return { ...obj, isChanged: true }
            }
            return obj
          })
          setCheckedTeamList(newOptionList)
          updateStates(newOptionList)
          setCheckList(newOptionList)
        } else {
          const newOptionList = optionList.map((obj, index) => {
            if (index === position - 1) {
              return { ...obj, isChanged: false }
            }
            return obj
          })
          setCheckedTeamList(newOptionList)
          updateStates(newOptionList)
          setCheckList(newOptionList)
        }
      }
    }
    if (!isEdit) {
      updateStates(optionList)
      setCheckList(optionList)
    }
  }

  const checkClicked = (item) => {
    const newList = checkList.map((obj) => {
      if (
        selectedPreEventTemplate?.id === 5
          ? obj?.teamId === item?.teamId
          : obj?.personId === item?.personId
      ) {
        obj = { ...obj, ischecked: !obj.ischecked }
      } else {
        obj = { ...obj }
      }
      return obj
    })

    checkIsUpdateFlag(
      parseInt(item?.position),
      selectedPreEventTemplate?.id,
      newList
    )
  }
  const selectAll = () => {
    const newList = checkList.map((obj) => ({
      ...obj,
      ischecked: true,
    }))
    updateStates(newList)
    setCheckList(newList)
  }

  const clearAll = () => {
    const newList = checkList.map((obj) => ({
      ...obj,
      ischecked: false,
    }))
    updateStates(newList)
    setCheckList(newList)
  }

  return (
    <>
      {selectedPreEventTemplate?.id < 7 && (
        <div>
          {[3, 4, 5, 6].includes(selectedPreEventTemplate?.id) && (
            <div>
              <div
                className={`selection-label btn m-2`}
                onClick={() => selectAll(checkList)}
              >
                <div>Select All</div>
              </div>
              <div
                className={`selection-label btn m-2`}
                onClick={() => clearAll(checkList)}
              >
                <div>Clear All</div>
              </div>
            </div>
          )}
          <div className="poll-section">
            <tables>
              <thead>
                <tr className="custom-header">
                  <th></th>
                  <th>Display #</th>
                  <th>{selectedPreEventTemplate?.headings[0]}</th>
                  <th>{selectedPreEventTemplate?.headings[1]}</th>
                  <th>{selectedPreEventTemplate?.headings[2]}</th>
                  {selectedPreEventTemplate?.checkbox && (
                    <th>{selectedPreEventTemplate?.headings[3]}</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {/* need to create components for below code */}
                {[...checkList]?.map((item) => (
                  <tr
                    className={`Table_Row_Events ${
                      [3, 4, 5, 6].includes(selectedPreEventTemplate?.id) &&
                      !item?.ischecked
                        ? 'active'
                        : ''
                    }`}
                    key={item?.position}
                  >
                    <td>
                      <div className="col td-drag">
                        <MdOutlineDragIndicator className="default-icon" />
                      </div>
                    </td>
                    <td>
                      <b>{item?.position}</b>
                    </td>
                    <td>
                      <div className="d-flex">
                        <input
                          className="option-input"
                          type="text"
                          placeholder={
                            selectedPreEventTemplate?.id === 5
                              ? item?.teamId
                              : item?.personId
                          }
                          pattern="^[0-9]+$"
                          onChange={(event) =>
                            handleChange(event, item?.position)
                          }
                          value={
                            selectedPreEventTemplate?.id === 5
                              ? teamIdList[item?.position]
                              : driverIdList[item?.position]
                          }
                          onBlur={(e) =>
                            selectedPreEventTemplate?.id === 5
                              ? searchTeamId(
                                  e,
                                  item?.position,
                                  selectedPreEventTemplate?.id
                                )
                              : searchDriverId(
                                  e,
                                  item?.position,
                                  selectedPreEventTemplate?.id
                                )
                          }
                        ></input>
                      </div>
                    </td>
                    <td>
                      <div>
                        <img
                          src={
                            (selectedPreEventTemplate?.id === 5
                              ? item?.teamLogo
                              : item?.personTeamLogo) ?? null
                          }
                          alt=""
                          height={35}
                        />
                      </div>
                    </td>
                    <td>
                      {(selectedPreEventTemplate?.id === 5
                        ? item?.teamName
                        : item?.personNickname) ?? 'Not found'}
                    </td>
                    {[3, 4, 5, 6].includes(selectedPreEventTemplate?.id) && (
                      <Form.Check
                        name="group1"
                        type="checkbox"
                        value={item}
                        key={
                          selectedPreEventTemplate?.id === 5
                            ? item?.teamId
                            : item?.personId
                        }
                        id={`checkbox-${
                          selectedPreEventTemplate?.id === 5
                            ? item?.teamId
                            : item?.personId
                        }`}
                        className="pt-2 pl-4"
                        onChange={() => checkClicked(item)}
                        checked={item?.ischecked}
                      />
                    )}
                    {item?.isChanged && (
                      <td>
                        <div>
                          <MdLens className="default-icon" />
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </tables>
          </div>
        </div>
      )}

      {selectedPreEventTemplate?.id === 7 && (
        <PreOptionsQuestion
          optionsList={checkedTextOption}
          setOptionsList={setCheckedTextOption}
          selectedPreEventTemplate={selectedPreEventTemplate}
          setDisableSaveOptions={setDisableSaveOptions}
          refTextList={refTextList}
          isEdit={isEdit}
        />
      )}
    </>
  )
}
export default CreateEditOptions
