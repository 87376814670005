import React, { useEffect, useState } from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { notify } from '../../Notify/Notify'
import { getToken } from '../../Login/ManageUser'
import { arrayMoveImmutable } from 'array-move'
import { Modal } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import Button from '../../common/Button/Button'
import PreEventAnswerRow from './PreEventAnswerRow'
import axios from 'axios'
import './PreEventAnswer.scss'

const BASE_URL = process.env.REACT_APP_API_BASE_URL
const token = getToken()

const PreEventAnswer = (props) => {
  const {
    questionTypeName,
    question,
    questionId,
    fetchedOptionList,
    setPreEventAnswerShow,
    setSubmitButton,
    value,
    getPreEventQuestionList,
    preEventRowData,
  } = props

  const [driverList, setDriverList] = useState([])
  const [fastestDriver, setFastestDriver] = useState({})
  const [singleTeam, setSingleTeam] = useState({})
  const [singleDriver, setSingleDriver] = useState({})
  const [singleText, setSingleText] = useState({})
  const [disableSaveButton, setDisableSaveButton] = useState(true)
  const [isEdit, setIsEdit] = useState(false)

  useEffect(() => {
    setDriverList(
      value?.correctAnswer?.answer != null
        ? value?.correctAnswer?.answer
        : fetchedOptionList
    )
  }, [fetchedOptionList])

  useEffect(() => {
    if (value?.questionTemplate?.id === 6) {
      setSingleDriver(value?.correctAnswer?.answer?.[0])
    }
    if (value?.questionTemplate?.id === 4) {
      setFastestDriver(value?.correctAnswer?.answer?.[0])
    }
    if (value?.questionTemplate?.id === 5) {
      setSingleTeam(value?.correctAnswer?.answer?.[0])
    }
    if (value?.questionTemplate?.id === 7) {
      setSingleText(value?.correctAnswer?.answer?.[0])
    }
  }, [value])

  const SortableItem = SortableElement(({ value, indexValue }) => (
    <PreEventAnswerRow value={value} index={indexValue} />
  ))

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div>
        {items
          ?.sort((x, y) => parseInt(x.position) - parseInt(y.position))
          ?.map((value, index) => (
            <SortableItem
              key={`item-${value?.personId}`}
              index={index}
              indexValue={index}
              value={value}
            />
          ))}
      </div>
    )
  })

  const updatePosition = (driverList, oldIndex, newIndex) => {
    driverList[oldIndex].position = driverList[newIndex].position

    if (oldIndex > newIndex) {
      for (let i = newIndex; i < oldIndex; i++) {
        driverList[i].position = parseInt(driverList[i].position) + 1
      }
    } else {
      for (let i = oldIndex + 1; i <= newIndex; i++) {
        driverList[i].position = parseInt(driverList[i].position) - 1
      }
    }
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const arr1 = arrayMoveImmutable(
      driverList,
      parseInt(oldIndex),
      parseInt(newIndex)
    )

    updatePosition(driverList, parseInt(oldIndex), parseInt(newIndex))
    setDriverList(arr1)
  }

  const handleChange = (item) => {
    const { id } = value?.questionTemplate || {}
    const correctAnswer = value?.correctAnswer?.answer?.[0]
    let disableSave = false

    if (id === 6 || id === 4) {
      disableSave = correctAnswer?.personId === item?.personId
      if (id === 6) setSingleDriver(item)
      if (id === 4) setFastestDriver(item)
    }

    if (id === 5) {
      disableSave = correctAnswer?.teamId === item?.teamId
      setSingleTeam(item)
    }

    if (id === 7) {
      disableSave = correctAnswer?.optionNumber === item?.optionNumber
      setSingleText(item)
    }

    setDisableSaveButton(disableSave)
    setIsEdit(true)
  }
  // this function submits answer to PreEvent Question
  const submitAnswer = () => {
    setSubmitButton(true)
    const url = `${BASE_URL}/events/${value.eventId}/gamification/questions/${value.id}/answer/`
    let params = {
      questionTemplateId: value?.questionTemplate?.id,
    }
    const nameToAnswerMap = {
      'F1-FASTEST_LAP': fastestDriver,
      'F1-Single team': singleTeam,
      'F1-Single driver': singleDriver,
      'Text - single-select': singleText,
    }

    const correctAnswer = nameToAnswerMap[value?.questionTemplate?.name]
    params['correctAnswer'] = {
      answer:
        value?.questionTemplate?.name === 'F1-PODIUM'
          ? driverList
          : [correctAnswer],
    }
    axios
      .put(url, params, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.data?.success) {
          const title = 'Success'
          const detail = res?.data?.message
          const type = 'success'
          notify(title, detail, type)
        }
      })
      .finally(() => {
        getPreEventQuestionList()
      })
    setDriverList(driverList)
    setPreEventAnswerShow(false)
    setDisableSaveButton(true)
  }

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => {
        setPreEventAnswerShow(false)
        setDisableSaveButton(true)
        if (value?.questionTemplate?.id === 6) {
          setSingleDriver(value?.correctAnswer?.answer?.[0])
        }
        if (value?.questionTemplate?.id === 4) {
          setFastestDriver(value?.correctAnswer?.answer?.[0])
        }
        if (value?.questionTemplate?.id === 5) {
          setSingleTeam(value?.correctAnswer?.answer?.[0])
        }
        if (value?.questionTemplate?.id === 7) {
          setSingleText(value?.correctAnswer?.answer?.[0])
        }
      }}
    >
      <Modal.Body className="show-grid">
        <div className="container status-info-modal">
          <h2 className="center">{question}</h2>
          {questionTypeName === 'F1-FASTEST_LAP' ? (
            <div>
              <p className="center">
                Pick one of the drivers shown. Points will auto-calculate.
              </p>
              <div className="poll-section-PreEvent">
                <table className="center">
                  <thead>
                    <tr className="custom-header">
                      <th>Team</th>
                      <th>Nickname</th>
                      <th>Answer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fetchedOptionList?.map((item, index) => (
                      <tr className="Table_Row_Events" key={item?.position}>
                        <td>
                          <div>
                            <img
                              src={item?.personTeamLogo ?? null}
                              alt=""
                              height={35}
                            />
                          </div>
                        </td>
                        <td>{item?.personNickname ?? 'Not found'}</td>
                        <td>
                          <Form.Check
                            inline
                            name="group1"
                            value={index}
                            type="radio"
                            className="d-flex align-items-center"
                            checked={
                              isEdit
                                ? fastestDriver?.personId === item?.personId
                                : value?.correctAnswer?.answer?.[0].personId ===
                                  item?.personId
                            }
                            onClick={() => handleChange(item)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="col-3 center">
                  <Button
                    name="Ok"
                    onClick={submitAnswer}
                    disabled={disableSaveButton}
                  />
                </div>
              </div>
            </div>
          ) : null}

          {questionTypeName === 'F1-Single team' ? (
            <div>
              <p className="center">
                Pick one of the teams shown. Points will auto-calculate.
              </p>
              <div className="poll-section-PreEvent">
                <table className="center">
                  <thead>
                    <tr className="custom-header">
                      <th>Team</th>
                      <th>Team Name</th>
                      <th>Answer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fetchedOptionList?.map((item, index) => (
                      <tr className="Table_Row_Events" key={item?.position}>
                        <td>
                          <div>
                            <img
                              src={item?.teamLogo ?? null}
                              alt=""
                              height={35}
                            />
                          </div>
                        </td>
                        <td>{item?.teamName ?? 'Not found'}</td>
                        <td>
                          <Form.Check
                            inline
                            name="group1"
                            value={index}
                            type="radio"
                            className="d-flex align-items-center"
                            checked={
                              isEdit
                                ? singleTeam?.teamId === item?.teamId
                                : value?.correctAnswer?.answer?.[0].teamId ===
                                  item?.teamId
                            }
                            onChange={() => handleChange(item)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="col-3 center">
                  <Button
                    name="Ok"
                    onClick={submitAnswer}
                    disabled={disableSaveButton}
                  />
                </div>
              </div>
            </div>
          ) : null}

          {questionTypeName === 'F1-Single driver' ? (
            <div>
              <p className="center">
                Pick one of the drivers shown. Points will auto-calculate.
              </p>
              <div className="poll-section-PreEvent">
                <table className="center">
                  <thead>
                    <tr className="custom-header">
                      <th>Team</th>
                      <th>Nickname</th>
                      <th>Answer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fetchedOptionList?.map((item, index) => (
                      <tr className="Table_Row_Events" key={item?.position}>
                        <td>
                          <div>
                            <img
                              src={item?.personTeamLogo ?? null}
                              alt=""
                              height={35}
                            />
                          </div>
                        </td>
                        <td>{item?.personNickname ?? 'Not found'}</td>
                        <td>
                          <Form.Check
                            inline
                            name="group1"
                            value={index}
                            type="radio"
                            className="d-flex align-items-center"
                            checked={
                              isEdit
                                ? singleDriver?.personId === item?.personId
                                : value?.correctAnswer?.answer?.[0].personId ===
                                  item?.personId
                            }
                            onChange={() => handleChange(item)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="col-3 center">
                  <Button
                    name="Ok"
                    onClick={submitAnswer}
                    disabled={disableSaveButton}
                  />
                </div>
              </div>
            </div>
          ) : null}

          {questionTypeName === 'Text - single-select' ? (
            <div>
              <p className="center">
                Pick one of the options shown. Points will auto-calculate.
              </p>
              <div className="poll-section-PreEvent">
                <table className="center">
                  <thead>
                    <tr className="custom-header">
                      <th>Option</th>
                      <th>Answer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fetchedOptionList?.map((item, index) => (
                      <tr className="Table_Row_Events" key={item?.id}>
                        <td>{item?.text ?? 'Not found'}</td>
                        <td>
                          <Form.Check
                            inline
                            name="group1"
                            value={index}
                            type="radio"
                            className="d-flex align-items-center"
                            checked={
                              isEdit
                                ? singleText?.optionNumber ===
                                  item?.optionNumber
                                : value?.correctAnswer?.answer?.[0]
                                    .optionNumber === item?.optionNumber
                            }
                            onChange={() => handleChange(item)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="col-3 center">
                  <Button
                    name="Ok"
                    onClick={submitAnswer}
                    disabled={disableSaveButton}
                  />
                </div>
              </div>
            </div>
          ) : null}

          {questionTypeName === 'F1-PODIUM' ? (
            <>
              <p className="center">
                Drag drivers into their final positions 1-20. Points will
                auto-calculate.
              </p>
              <div className="poll-section-PreEvent">
                <table className="center">
                  <div className="row custom-header d-flex">
                    <div className="col td-pos">Pos#</div>
                    <div className="col td-Team">Team</div>
                    <div className="col td-Nickname">Nickname</div>
                  </div>
                  <tbody className="center">
                    <tr>
                      <SortableList
                        distance={1}
                        items={driverList}
                        name="preEvent"
                        onSortEnd={(e) => onSortEnd(e, 'preEvent')}
                        axis="y"
                        lockAxis="y"
                        useWindowAsScrollContainer={true}
                        disableAutoscroll={false}
                        useDragHandle
                      />
                    </tr>
                  </tbody>
                </table>
                <div className="col-3 center">
                  <Button name="Ok" onClick={submitAnswer} disabled={false} />
                </div>
              </div>
            </>
          ) : null}
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default PreEventAnswer
