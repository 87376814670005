import React, { useState, forwardRef, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { initializeApp } from 'firebase/app'
import { getFirestore, collection, getDocs } from '@firebase/firestore'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { intialObjects } from '../../constants/enumTypes.constants'
import { getToken, getUser } from '../Login/ManageUser'
import axios from 'axios'
import Spinner from 'react-bootstrap/Spinner'
import Switch from 'react-switch'
import DatePicker from 'react-datepicker'
import FormField from '../common/FormField'
import Sidebar from '../common/Sidebar'
import Button from '../common/Button/Button'
import ImageUploader from '../common/ImageUploader'
import 'react-datepicker/dist/react-datepicker.css'
import './EventInfo.scss'
import { notify } from '../Notify/Notify'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

const CreateEventInfo = (props) => {
  let navigate = useNavigate()
  const token = getToken()
  const user = getUser()
  const { state } = useLocation()

  const [EventInfoName, setEventInfoName] = useState('')
  const [eventInfoId, setEventInfoId] = useState(null)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [selectedCategory, setSelectedCategory] = useState(intialObjects)
  const [universal, setUniversal] = useState(intialObjects)
  const [categories, setCategories] = useState([])
  const [loader, setLoader] = useState(true)
  const [isHidden, setIsHidden] = useState(true)
  const [errors, setErrors] = useState({})
  const [imgState, setImgState] = useState(null)
  const [imgUrl, setImgUrl] = useState()
  const [imageName, setImageName] = useState()
  const [imageDisplay, setImageDisplay] = useState()
  const [disableNext, setDisableNext] = useState(true)
  const [disableSave, setDisableSave] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  // * Authentication token
  const BASE_URL = process.env.REACT_APP_API_BASE_URL
  const V2_URL = process.env.REACT_APP_API_V2_URL

  // imported to fetch stats's category
  const app = initializeApp(firebaseConfig)
  const auth = getAuth(app)
  const db = getFirestore(app)

  useEffect(() => {
    if (state != null && state.eventInfoId != null) {
      setDisableNext(false)
    }
    signInWithEmailAndPassword(
      auth,
      process.env.REACT_APP_FIREBASE_EMAIL,
      process.env.REACT_APP_FIREBASE_PASSWORD
    ).then((userCredential) => {
      // * Signed in to firebase
      const user = userCredential?.user
      user?.accessToken && fetchCategory()
    })
  }, [])

  const categoryListRef = collection(db, 'Categories')
  const fetchCategory = async () => {
    const data = await getDocs(categoryListRef)
    const categoryData = data.docs.map((doc) => ({
      ...doc.data(),
      ...intialObjects,
    }))
    // * Get all the stats file
    getStatsFiles([...categoryData])
  }

  useEffect(() => {
    if (categories.length > 0) {
      getEventInfoDetail()
    }
  }, [categories])

  const getEventInfoDetail = () => {
    if (state != null && state.eventInfoId != null) {
      let url = `${V2_URL}eventinfo/${state.eventInfoId}`
      axios
        .get(url, {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setEventFields(res?.data?.data)
        })
    }
  }

  const setEventFields = (data) => {
    setEventInfoId(data?.id)
    setEventInfoName(data?.name)
    setStartDate(new Date(data?.startTime))
    setEndDate(new Date(data?.endTime))
    setIsHidden(data?.isHidden)
    setImgState(data?.pageImage)
    setImgUrl(data?.pageImage)
    setCat(data?.category)
  }

  const setCat = (categoryId) => {
    const catObj = [...categories].filter(
      (obj) => parseInt(obj.id) == parseInt(categoryId)
    )
    setSelectedCategory(catObj?.[0])
  }

  const filterEndTime = (time) =>
    new Date(startDate).getTime() < new Date(time).getTime()

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" onClick={onClick} ref={ref}>
      {value + ' ' + /\((.*)\)/.exec(new Date().toString())[1]}
    </button>
  ))

  const handleStartChangeDate = (e) => {
    setStartDate(e)
    if (errors) {
      errors.startDate = ''
      setErrors(errors)
    }
  }
  const handleEndChangeDate = (e) => {
    setEndDate(e)
    if (errors) {
      errors.endDate = ''
      setErrors(errors)
    }
  }
  const handleNameChange = (e) => {
    const maxLength = 45
    const { value } = e.target

    if (value.length > maxLength) {
      setEventInfoName(value.substring(0, maxLength))
    } else {
      setEventInfoName(value)
    }
    if (errors) {
      errors.EventInfoName = ''
      setErrors(errors)
    }
  }
  const onImageChange = (picture) => {
    var maxLength = 5
    if (errors) {
      errors.imgState = ''
      setErrors(errors)
    }
    if (picture[0].file) {
      setImgState(picture[0].file)
      setImageName(picture[0].file.name)
      if (picture[0].file.name.length > maxLength) {
        setImageDisplay(picture[0].file.name.substr(0, maxLength) + '...')
      } else {
        setImageDisplay(picture[0].file.name)
      }
    }
  }

  const handleValidation = (EventInfoName, startDate, endDate, imgState) => {
    let error = {}
    if (!EventInfoName || EventInfoName == '') {
      error.EventInfoName = 'Event Info name is required'
    }
    if (startDate.toISOString() >= endDate.toISOString()) {
      error.endDate = 'End datetime should be greater than start datetime'
    }
    if (!imgState) {
      error.imgState = 'Page Image is required'
    }
    return error
  }
  const handleExit = () => {
    navigate(`/events/eventinfo/`)
  }
  const handleNextClick = (page) => {
    let parentPage =
      state?.eventInfoId || eventInfoId ? 'edit-eventinfo' : 'create-eventinfo'
    navigate(`/events/eventinfo/${parentPage}/${page}`, {
      state: {
        eventInfoId:
          state != null && state.eventInfoId != null
            ? state.eventInfoId
            : eventInfoId,
        eventinfoName: EventInfoName,
      },
    })
  }
  const handleSave = (flag) => {
    var fieldErrors = handleValidation(
      EventInfoName,
      startDate,
      endDate,
      imgState
    )
    setErrors(fieldErrors)
    if (Object.keys(fieldErrors)?.length === 0) {
      let form_data = new FormData()
      form_data.append('name', EventInfoName)
      form_data.append('startTime', startDate.toISOString())
      form_data.append('endTime', endDate.toISOString())
      form_data.append('category', selectedCategory?.id)
      form_data.append('isHidden', isHidden)
      if (imgState != imgUrl) {
        form_data.append('pageImage', imgState)
      }

      if (eventInfoId != null) {
        setDisableSave(true)
        let url = `${V2_URL}eventInfo/${eventInfoId}`
        axios
          .put(url, form_data, {
            headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (res?.data?.success) {
              const title = 'Success'
              const detail = res.data.message
              const type = 'success'
              notify(title, detail, type)
            }
            setErrorMessage('')
            setDisableSave(false)
          })
          .catch((err) => {
            setErrorMessage(
              err.response?.data?.message ?? 'Something went wrong!'
            )
            setDisableSave(false)
          })
      } else {
        let url = `${V2_URL}eventInfo/`
        setDisableSave(true)
        axios
          .post(url, form_data, {
            headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (res?.data?.success) {
              setDisableNext(!res?.data?.success)
              setEventInfoId(res.data.data.id)
              const title = 'Success'
              const detail = res.data.message
              const type = 'success'
              notify(title, detail, type)
            }
            setErrorMessage('')
            setDisableSave(false)
          })
          .catch((err) => {
            setErrorMessage(
              err.response?.data?.message ?? 'Something went wrong!'
            )
            setDisableSave(false)
          })
      }
    }
  }
  const handleRemove = () => {
    setImgState(null)
    setImageName('')
  }

  const handleCategoryClick = (id) => {
    const newSelectedCategory = [...categories].find(
      (item) => Number(item?.id) === Number(id)
    )
    setSelectedCategory(newSelectedCategory)
  }

  const getStatsFiles = (categories) => {
    const url = `${BASE_URL}stats-file-download`
    axios
      .post(
        url,
        {},
        {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res?.data?.success) {
          const resData = res?.data?.data
          // * Set universal data
          setUniversal({
            ...universal,
            fileName: resData?.universal?.fileName ?? '',
            lastUpdated: resData?.universal?.lastUpdated ?? null,
            downloadFilePath: resData?.universal?.downloadFilePath ?? '',
            isDisable: true,
          })
          // * Set category data
          let updatedResult = [...categories].map((o1) => {
            let category = resData?.category.find((o2) => {
              return o1?.id === o2?.categoryId
            })
            if (category) {
              return {
                ...o1,
                ...category,
              }
            }
            return o1
          })
          setCategories([...updatedResult])
          setSelectedCategory([...updatedResult]?.[0])
          setLoader(false)
        }
      })
  }

  return (
    <>
      {token && user ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-3 col-lg-2">
              <Sidebar
                currentSelected="Events"
                currentsubSelected="Event Info Pages"
              />
            </div>
            <div className="col-6 col-lg-8">
              <div className="row mt-4">
                <div className="breadcrumb">
                  <p className="Roboto_Black Event_Name">{`${
                    EventInfoName.length > 0
                      ? `${EventInfoName} > Main Page Details`
                      : ''
                  }  `}</p>
                </div>
                <div className="EventInfo-wrapper p-5">
                  <div className="row">
                    <div className="col-6">
                      <FormField
                        value={EventInfoName}
                        id="EventInfoName"
                        label="Name"
                        name="EventInfoName"
                        type="text"
                        placeholder="Enter Event Info Name"
                        onChange={(e) => {
                          handleNameChange(e)
                        }}
                        disabled={false}
                      />
                      {errors && (
                        <div className="input-feedback">
                          {errors.EventInfoName}
                        </div>
                      )}
                      <label htmlFor="starttime" className="Font_Black">
                        Start Time
                      </label>
                      <p className="Font_Regular">
                        {' '}
                        The beginning of the time range when the Tickets are
                        usable.This helps determine the display order amongst
                        the other tickets.
                      </p>
                      <div className="DateTime">
                        <DatePicker
                          selected={startDate}
                          onChange={handleStartChangeDate}
                          showTimeSelect
                          timeIntervals={15}
                          dateFormat="MM/dd/yyyy hh:mm:ss a"
                          timeCaption="time"
                          className="Bg_Quinary_Color"
                          customInput={<ExampleCustomInput />}
                          disabled={false}
                        />
                      </div>
                      {errors && (
                        <div className="input-feedback">{errors.startDate}</div>
                      )}
                      <label htmlFor="starttime" className="Font_Black">
                        End Time
                      </label>
                      <p className="Font_Regular">
                        {' '}
                        The end of the time range when the Tickets are usable.
                        After this time passes the corresponding informatioin
                        will no longer be visible.
                      </p>
                      <div className="DateTime">
                        <DatePicker
                          selected={endDate}
                          onChange={handleEndChangeDate}
                          showTimeSelect
                          minDate={new Date(startDate)}
                          filterTime={filterEndTime}
                          timeIntervals={15}
                          dateFormat="MM/dd/yyyy hh:mm:ss a"
                          timeCaption="time"
                          className="Bg_Quinary_Color"
                          customInput={<ExampleCustomInput />}
                          disabled={false}
                        />
                      </div>
                      {errors && (
                        <div className="input-feedback">{errors.endDate}</div>
                      )}
                    </div>
                    <div className="col-6">
                      <div>
                        <label htmlFor="hidden" className="Font_Black ms-1xl-5">
                          Event Info Page hidden?
                        </label>
                        <p className=" Error_Color Font_Regular ms-1xl-5">
                          <b>WARNING: </b>To avoid Event Info Pages being shown
                          to app users prematurely, they are hidden by
                          default.Hidden Event Info Pages are NEVER shown to
                          users, even if the Start Time is reached. Disable and
                          save to make the Event Info Pages visible.
                        </p>
                        <div className="row">
                          <div className="col-2 col-1xl-4 col-xl-4 col-xxl-3">
                            <Switch
                              onChange={() => setIsHidden(!isHidden)}
                              checked={isHidden}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                              height={30}
                              width={58}
                              className="react-switch ms-1xl-5"
                              id="material-switch"
                              disabled={false}
                            />
                          </div>
                          <div className="col-6 col-xl-3 col-xxl-4">
                            {isHidden ? 'Hidden' : 'Visible'}
                          </div>
                        </div>
                      </div>
                      <div className="mt-4">
                        <label htmlFor="image" className="Font_Black ms-1xl-5">
                          Page Image
                        </label>
                        <label
                          htmlFor="image"
                          className="h-25 ms-1xl-5 image-label-size"
                        >
                          Upload a horizontal Image with a 16*9 aspect ratio for
                          use when sharing the Page with other people
                        </label>
                        <div className="ms-1xl-5">
                          <ImageUploader
                            value={imgState}
                            imgUrl={imgUrl}
                            onChange={onImageChange}
                            imageName={imageName}
                            imageDisplay={imageDisplay}
                            onClick={handleRemove}
                          />
                          <div>
                            {errors && (
                              <div className="input-feedback w-75">
                                {errors.imgState}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {errorMessage && (
                    <div className="d-flex error-message justify-content-center align-items-center Font_Black text-center text-danger">
                      {errorMessage}
                    </div>
                  )}
                  <div className="row">
                    <label htmlFor="categories" className="Font_Black">
                      Category
                    </label>
                    <div>
                      {loader ? (
                        <div className="text-center overflow-hidden">
                          <Spinner animation="border" variant="primary" />
                        </div>
                      ) : (
                        [...categories]?.map((key) => (
                          <div
                            className={`categories-label btn ${
                              Number(selectedCategory?.id) === Number(key?.id)
                                ? 'active'
                                : ''
                            }`}
                            key={`category-${key?.id}`}
                            onClick={() => handleCategoryClick(Number(key?.id))}
                          >
                            <div>
                              <img src={key?.imgURL} alt="" height={20} />
                            </div>
                            <div className="mt-2">{key?.category}</div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="col-3 col-lg-2">
                      <Button name="Exit" onClick={() => handleExit()} />
                    </div>
                    <div className="col-3 col-lg-2 ms-auto">
                      <Button
                        name="Next"
                        disabled={disableNext}
                        onClick={() => handleNextClick('core-content')}
                      />
                    </div>
                    <div className="col-3 col-lg-2">
                      <Button
                        name="Save"
                        onClick={() => handleSave()}
                        disabled={disableSave}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        navigate('/login')
      )}
    </>
  )
}
export default CreateEventInfo
