import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'

import SideBar from './../common/Sidebar'
import { getToken, getUser } from './../Login/ManageUser'
import AssociatingProvidersRow from './AssociatingProvidersRow/AssociatingProvidersRow'
import EventFooter from '../common/EventFooter/EventFooter'
import ProviderPreviewSection from '../Events/W2WProviders/ProviderPreviewSection'
import { notify } from '../Notify/Notify'
import './AssociatingProviders.scss'

const associatingProviders = () => {
  // * useNavigate used for redirect to login.
  let navigate = useNavigate()

  // * useLocation used to get the props.
  const { state } = useLocation()

  // * Authentication variables.
  const BASE_URL = process.env.REACT_APP_API_BASE_URL
  const token = getToken()
  const user = getUser()

  // * Local state
  const [errorMessage, setErrorMessage] = useState()
  const [W2WProviders, setW2WProviders] = useState([])
  const [selectedProviderDetail, setSelectedProviderDetail] = useState([])
  const [loadingAPI, setLoadingAPI] = useState(false)
  const [disableProviderSelection, setDisableProviderSelection] =
    useState(false)

  useEffect(() => {
    // * Pupulate provider fields.
    populateProvidersField()
  }, [])

  // * Get event details from event Id
  async function getEventDetail() {
    let data = null
    if (state.eventId) {
      let url = `${BASE_URL}event/${state.eventId}`
      await axios
        .get(url, {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.data !== null) {
            data = res?.data?.data
          }
        })

      return data
    }
  }

  // * Fetch all providers from W2WProviders.
  async function getW2WProviders(includeDeleteProviders) {
    let url = `${BASE_URL}w2w-providers`
    let data = []
    await axios
      .get(url, {
        params: {
          per_page: 1000,
          is_deleted: includeDeleteProviders,
        },

        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.data?.data !== null) {
          data = res?.data?.data
        }
      })
    return data
  }

  // * Populate fields with getW2WProviders response
  async function populateProvidersField() {
    let eventDetail = await getEventDetail()

    // * Set disable providers
    const includeDeleteProviders = ['LIVE', 'FINAL', 'PENDING'].includes(
      eventDetail?.eventStatus.toUpperCase()
    )
    setDisableProviderSelection(includeDeleteProviders)

    // * Set W2W providers
    let W2WProviders = await getW2WProviders(includeDeleteProviders)
    setW2WProviders([...W2WProviders])

    // * For creating new associated providers
    let selectedProviderDetail = []
    if (
      !eventDetail ||
      eventDetail.whereToWatchProviders == null ||
      eventDetail.whereToWatchProviders.length == 0
    ) {
      W2WProviders.slice(0, 3).map(() => {
        selectedProviderDetail.push({})
      })
    } else {
      // * For editing associated providers
      eventDetail.whereToWatchProviders.map((associatedProvider) => {
        let providerDetail = W2WProviders.find(
          (x) => x.id == associatedProvider.providerID
        )
        if (providerDetail) {
          selectedProviderDetail.push(providerDetail)
        }
      })
      const remainaingLength = 3 - selectedProviderDetail?.length
      for (let i = 0; i < remainaingLength; i++) {
        selectedProviderDetail.push({})
      }
    }
    setSelectedProviderDetail(selectedProviderDetail)
  }

  // * Create W2WProvider request object
  const createW2WProvidersRequest = () => {
    let w2wProvidersRequest = []
    selectedProviderDetail.map((selectedProvider) => {
      if (Object.keys(selectedProvider).length > 0) {
        w2wProvidersRequest = [
          ...w2wProvidersRequest,
          { enable: true, providerID: selectedProvider.id },
        ]
      }
    })
    return w2wProvidersRequest
  }

  // * Submit associated providers
  async function submitAssociatedProviders() {
    setLoadingAPI(true)
    let data = await getEventDetail()
    if (data != null) {
      let W2WProvidersRequest = createW2WProvidersRequest()
      let form_data = new FormData()
      form_data.append('title', data.title)
      form_data.append('description', data.description)
      form_data.append('gamificationEnabled', true)
      form_data.append('fontAndIconColor', data.fontAndIconColor)
      form_data.append(
        'whereToWatchProviders',
        JSON.stringify(W2WProvidersRequest)
      )
      form_data.append('categories', JSON.stringify(data.categories))
      form_data.append('startTime', data.startTime)
      form_data.append('endTime', data.endTime)
      form_data.append('isHidden', data.isHidden)
      form_data.append('horizontalImage', data.eventImages.horizontalImage)
      form_data.append('verticalImage', data.eventImages.verticalImage)
      form_data.append('isHorizontalImageChange', false)
      form_data.append('isVerticalImageChange', false)
      let url = `${BASE_URL}event/${state.eventId}`
      axios
        .put(url, form_data, {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res?.data?.success) {
            const title = 'Success'
            const detail = res?.data?.message
            const type = 'success'
            notify(title, detail, type)
          }
        })
        .catch((err) => {
          setErrorMessage(
            err.response?.data?.message ?? 'Something went wrong!'
          )
        })
        .finally(() => {
          setLoadingAPI(false)
        })
    }
  }

  // * Redirect to gamification
  const handleButtonClick = (page) => {
    navigate(`/events/eventdetails/edit-event/${page}`, {
      state: {
        eventId: state.eventId,
        eventName: state.eventName,
      },
    })
  }

  // * Redirect to main event-details
  function handleExitClick() {
    navigate(`/events/eventdetails`)
  }

  // * Redirect to create/edit event
  function handleBackClick() {
    navigate(`/events/eventdetails/edit-event/${state.eventId}`, {
      state: {
        eventId: state.eventId,
        eventName: state.eventName,
      },
    })
  }

  // * Handle Dropdown Change
  const handleDropdownSelectedProvider = (dropDownItem, index) => {
    setErrorMessage('')
    let updatedSelectedProviderDetail = [...selectedProviderDetail]

    if (dropDownItem.target.value.toUpperCase() == 'SELECT') {
      updatedSelectedProviderDetail[index] = {}
    } else {
      W2WProviders.map((provider) => {
        if (dropDownItem.target.value === provider.providerName) {
          updatedSelectedProviderDetail[index] = provider
        }
      })
    }

    setSelectedProviderDetail(updatedSelectedProviderDetail)
  }

  // * Get the filtered provider list
  const filterProviderList = (name) => {
    const selectedIds = selectedProviderDetail
      .filter((i) => Object.keys(i).length)
      .map((i) => i.providerName)

    let returnList = [
      'Select',
      ...W2WProviders.filter(
        (i) => !selectedIds.includes(i.providerName) || i.providerName === name
      ).map((i) => i.providerName),
    ]
    return returnList
  }

  // * Event Name Breadcrumb
  const breadcrumb = state.eventName
    ? state.eventName + ' > Where To Watch'
    : ''

  return (
    <>
      {token && user ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-3 col-lg-2">
              <SideBar
                currentSelected="Events"
                currentsubSelected="Event details"
              />
            </div>

            <div className="col-6 col-lg-8 primary-associate-w2w ">
              <div className="row crumb mx-4">{breadcrumb}</div>
              <div className="row associate-w2w mx-4 mt-2">
                <p className="p-2 ms-1">
                  <strong>Note:</strong>{' '}
                  {`You must create and customize each provider using the Events > W2W providers tab before they will appear as an option on this
page. You may have up to 3 providers listed for each event.`}
                </p>
                {[...selectedProviderDetail].map((provider, pindex) => {
                  return (
                    <div key={pindex}>
                      <AssociatingProvidersRow
                        index={pindex}
                        dropDownValues={filterProviderList(
                          provider.providerName
                        )}
                        defaultValue={provider?.providerName ?? 'Select'}
                        onChangeDropDown={(e) =>
                          handleDropdownSelectedProvider(e, pindex)
                        }
                        valueW2W={provider.w2wLanguage}
                        valueUrl={provider.referralURL}
                        isAffiliateProgram={provider.isAffiliateProgram}
                        isTrialOffer={provider.isTrialOffer}
                        disabled={disableProviderSelection}
                      />
                    </div>
                  )
                })}
                {errorMessage && (
                  <div className="d-flex error-message justify-content-center align-items-center Font_Black text-center text-danger">
                    {errorMessage}
                  </div>
                )}
                <EventFooter
                  onSubmitClick={() => submitAssociatedProviders()}
                  onNextClick={() => handleButtonClick('gamification')}
                  onExitClick={() => handleExitClick()}
                  onBackClick={() => handleBackClick()}
                  isSaveDisable={
                    disableProviderSelection || loadingAPI === true
                      ? true
                      : false
                  }
                />
              </div>
            </div>
            <div className="col-3 col-lg-2 Preview_Container">
              <ProviderPreviewSection
                providerList={selectedProviderDetail}
                footerVisible={true}
              />
            </div>
          </div>
        </div>
      ) : (
        navigate('/login')
      )}
    </>
  )
}

export default associatingProviders
