import React from 'react';

export function EventParticipation(props) {
  return (
    <div className="row pb-3">
      <div className="preview-text py-4">Event participation</div>
      <div className="preview-helper-text">
        <b>Current:</b> {props.currentUsers}
      </div>
      <div className="preview-helper-text">
        <b>Peak:</b> {props.peakUsers}
      </div>
    </div>
  );
}
