import React, { useState, useEffect } from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { arrayMoveImmutable } from 'array-move'
import Spinner from 'react-bootstrap/Spinner'
import axios from 'axios'

import CustomHeader from './CustomHeader/CustomHeader'
import CustomRow from './CustomRow/CustomRow'
import { getToken } from '../../Login/ManageUser'
import { notify } from '../../Notify/Notify'
import './CustomTable.scss'

const token = getToken()
const BASE_URL = process.env.REACT_APP_API_BASE_URL

const customTable = (props) => {
  const {
    headerList,
    questionList,
    setQuestionList,
    changeActiveQuestion,
    activeQuestion,
    deleteRow,
    setStatusInfoModalShow,
    setPreEventAnswerShow,
    setPreEventRowData,
    setAnswerStatusInfoModal,
    editQuestion,
    eventLoader,
    setSubmitButton,
  } = props

  const [nonDraggableList, setNonDraggableList] = useState(
    [...questionList].filter((item) => ![4, 5].includes(item.questionStatus))
  )
  const [queuedList, setQueuedList] = useState(
    [...questionList].filter((item) => item.questionStatus == 4)
  )
  const [standbyList, setStandbyList] = useState(
    [...questionList].filter((item) => item.questionStatus == 5)
  )

  useEffect(() => {
    seprateDraggableItems()
  }, [questionList])

  const seprateDraggableItems = () => {
    const nonDraggableItems = [...questionList].filter(
      (item) => ![4, 5].includes(item.questionStatus)
    )
    const queuedItems = [...questionList].filter(
      (item) => item.questionStatus == 4
    )
    const standbyItems = [...questionList].filter(
      (item) => item.questionStatus == 5
    )

    setNonDraggableList(nonDraggableItems)
    setQueuedList(queuedItems)
    setStandbyList(standbyItems)
  }

  const SortableItem = SortableElement(({ value, indexValue }) => (
    <CustomRow
      value={value}
      index={indexValue}
      questionList={[...questionList]}
      setQuestionList={setQuestionList}
      addIndexLength={
        value.questionStatus === 4
          ? nonDraggableList.length
          : nonDraggableList.length + queuedList.length
      }
      draggable={value.questionStatus === 4 || value.questionStatus === 5}
      deleteRow={deleteRow}
      editQuestion={editQuestion}
      activeQuestion={activeQuestion}
      selectRow={() => changeActiveQuestion(value.id)}
      setSubmitButton={setSubmitButton}
    />
  ))

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div>
        {items
          .sort((a, b) => a.questionStatus - b.questionStatus)
          .sort((x, y) => x.position - y.position)
          .map((value, index) => (
            <SortableItem
              key={`item-${value.id}`}
              index={index}
              indexValue={index}
              value={value}
            />
          ))}
      </div>
    )
  })

  const updatePosition = (questionList, oldIndex, newIndex) => {
    questionList[oldIndex].position = questionList[newIndex].position

    if (oldIndex > newIndex) {
      for (let i = newIndex; i < oldIndex; i++) {
        questionList[i].position = questionList[i].position + 1
      }
    } else {
      for (let i = oldIndex + 1; i <= newIndex; i++) {
        questionList[i].position = questionList[i].position - 1
      }
    }
  }

  const onSortEnd = ({ oldIndex, newIndex }, name) => {
    const oldPosition =
      name == 'quequed'
        ? queuedList[oldIndex].position
        : standbyList[oldIndex].position
    const newPosition =
      name == 'quequed'
        ? queuedList[newIndex].position
        : standbyList[newIndex].position

    if (name == 'quequed') {
      const arr1 = arrayMoveImmutable(queuedList, oldIndex, newIndex)
      updatePosition(queuedList, oldIndex, newIndex)
      setQueuedList(arr1)
    }

    if (name == 'standby') {
      const arr1 = arrayMoveImmutable(standbyList, oldIndex, newIndex)
      updatePosition(standbyList, oldIndex, newIndex)
      setStandbyList(arr1)
    }
    if (newIndex != oldIndex) {
      const item =
        name == 'quequed' ? queuedList[oldIndex] : standbyList[oldIndex]

      const url = `${BASE_URL}gamification/question-order`
      const params = {
        id: item.id,
        oldPosition: oldPosition,
        newPosition: newPosition,
      }

      axios
        .post(url, params, {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res?.data?.success) {
            const title = 'Success'
            const detail = res.data.message
            const type = 'success'
            notify(title, detail, type)
          }
        })
    }
  }

  return (
    <div>
      <CustomHeader
        headerList={headerList}
        setStatusInfoModalShow={setStatusInfoModalShow}
        setAnswerStatusInfoModal={setAnswerStatusInfoModal}
      />
      <div className="table-body">
        {!eventLoader ? (
          questionList.length > 0 ? (
            <>
              {[...nonDraggableList].map((value, index) => (
                <CustomRow
                  key={`item-${value.id}`}
                  value={value}
                  index={index}
                  questionList={questionList}
                  setQuestionList={setQuestionList}
                  deleteRow={deleteRow}
                  editQuestion={editQuestion}
                  activeQuestion={activeQuestion}
                  selectRow={() => changeActiveQuestion(value.id)}
                  setPreEventAnswerShow={setPreEventAnswerShow}
                  setPreEventRowData={setPreEventRowData}
                />
              ))}
              <SortableList
                distance={1}
                items={queuedList}
                name="quequed"
                onSortEnd={(e) => onSortEnd(e, 'quequed')}
                axis="y"
                lockAxis="y"
                useWindowAsScrollContainer={true}
                disableAutoscroll={false}
                useDragHandle
              />
              <SortableList
                distance={1}
                items={standbyList}
                name="stanby"
                onSortEnd={(e) => onSortEnd(e, 'standby')}
                axis="y"
                lockAxis="y"
                useWindowAsScrollContainer={true}
                disableAutoscroll={false}
                useDragHandle
              />
            </>
          ) : (
            <div className="pt-4 text-center">No Question Added</div>
          )
        ) : (
          <div className="text-center">
            <Spinner animation="border" variant="primary" />
          </div>
        )}
      </div>
    </div>
  )
}

export default customTable
