import React, { useState, useEffect } from 'react'

import FormField from './../../common/FormField'
import Clipboard from './../../common/Clipboard/Clipboard'
import DropDown from './../../common/DropDown/DropDown'
import CheckCrossIcon from '../../common/CheckCrossIcon/CheckCrossIcon'
import './AssociatingProvidersRow.scss'

const associatingProvidersRow = (props) => {
  const [copied, setCopied] = useState(false)

  const handleCopyClick = () => {
    setCopied(true)
    setTimeout(() => setCopied(false), 1000)
  }

  return (
    <>
      <div className="row">
        <div className="col-3">
          <p className="mb-2">
            <strong>{`Provider ${props.index + 1} `}</strong>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-3 mb-2">{`Provider Name`}</div>
        <div className="col-1">Affiliate</div>
        <div className="col-1 ps-1">Offer</div>
        <div className="col-3 ps-2">W2W Language</div>
        <div className="col-3 ps-1">Referral URL</div>
      </div>
      <div className="row d-flex align-items-center me-4 me-md-0">
        <div className="col-3">
          <DropDown
            dropDownValues={props.dropDownValues}
            defaultValue={props.defaultValue}
            onChange={props.onChangeDropDown}
            disabled={props.disabled}
          />
        </div>
        <div className="col-1">
          <CheckCrossIcon isCheck={props.isAffiliateProgram} />
        </div>
        <div className="col-1">
          <CheckCrossIcon isCheck={props.isTrialOffer} />
        </div>
        <div className="col-3">
          <FormField
            readOnly={true}
            id={`w2wLanguage+${props.index}`}
            value={props.valueW2W ? props.valueW2W : ''}
            hideLabel={true}
            name="w2wLanguage"
            type="text"
            disabled={props.disabled}
          />
        </div>
        <div className="col-3">
          <FormField
            readOnly={true}
            id={`referralUrl+${props.index}`}
            value={props.valueUrl ? props.valueUrl : ''}
            hideLabel={true}
            name="referralUrl"
            type="text"
            disabled={props.disabled}
          />
        </div>
        <div className="col-1">
          <Clipboard
            urlValue={props.valueUrl}
            onCopy={() => handleCopyClick()}
            copied={copied}
          />
        </div>
      </div>
    </>
  )
}

export default associatingProvidersRow
