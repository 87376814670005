import React from 'react'
import { sortableHandle } from 'react-sortable-hoc'
import { MdOutlineDragIndicator } from 'react-icons/md'
import './PreEventAnswer.scss'

const preEventAnswerRow = (props) => {
  const { value, index } = props

  const Draggable = sortableHandle(() => (
    <MdOutlineDragIndicator className="default-icon" />
  ))
  return (
    <>
      <tr className="Table_Row_Events" key={index}>
        <div className="d-flex">
          <div className="col td-drag ">{<Draggable />}</div>
          <div className="col td-index d-flex">{parseInt(index) + 1}</div>
        </div>
        <td>
          <div>
            <img src={value?.personTeamLogo ?? null} alt="" height={35} />
          </div>
        </td>
        <td>{value?.personNickname ?? 'Not found'}</td>
      </tr>
    </>
  )
}
export default preEventAnswerRow
