import React from 'react'
import { Dropdown } from 'react-bootstrap'

const buttonDropdown = (props) => {
  const {
    itemList,
    selectedValue,
    onDropdownChange,
    isVisible,
    buttonColorList,
    style,
  } = props
  return isVisible ? (
    itemList?.length > 0 ? (
      <Dropdown>
        <Dropdown.Toggle
          style={style}
          key={itemList}
          data-test-id="custom-dropdown"
        >
          {selectedValue}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {[...itemList].map((item) => (
            <Dropdown.Item
              key={`dropDownItem- ${item.id}`}
              id={item.statusId ? item.statusId : item.id}
              onClick={(e) => onDropdownChange(e)}
              style={{
                backgroundColor: buttonColorList?.[item.statusId],
                color: item.statusId ? '#fff' : '#000',
              }}
            >
              {item.text}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    ) : (
      <button className="dropdown-custum-button" style={style}>
        {selectedValue}
      </button>
    )
  ) : (
    <></>
  )
}

export default buttonDropdown
