import React, { useState } from 'react'
import axios from 'axios'
import logo from '../../assets/img/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as EmailValidator from 'email-validator'
import { setUserSession } from './ManageUser'
import { notify } from '../Notify/Notify'

require('./Login.scss')

const BASE_URL = process.env.REACT_APP_API_BASE_URL
const Login = () => {
  const navigate = useNavigate()
  const [error, setError] = useState(null)
  const [value, setValue] = useState(false)
  const initialValues = {
    email: '',
    password: '',
  }
  const validate = (values) => {
    let errors = {}
    if (!values.email) {
      errors.email = 'Email is required.'
    } else if (!EmailValidator.validate(values.email)) {
      errors.email = 'Invalid email address.'
    }
    if (!values.password) {
      errors.password = 'Password is required.'
    }
    return errors
  }
  const handlechange = (e) => {
    formik.handleChange(e)
    setValue(true)
  }

  const onSubmit = (values, { setSubmitting }, props) => {
    setError(null)
    axios
      .post(`${BASE_URL}login/`, {
        email: values.email.toLowerCase(),
        password: values.password,
      })
      .then((response) => {
        setSubmitting(false)
        setUserSession(response.data.extra.token, response.data.data)
        navigate('/events/eventdetails')
      })
      .catch((err) => {
        setValue(false)
        setSubmitting(false)
      })
  }
  const formik = useFormik({
    initialValues,
    onSubmit: (values, props) => {
      onSubmit(values, props)
    },
    validate,
  })

  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center container">
        <form className="Login_Form" onSubmit={formik.handleSubmit}>
          <div className="text-center">
            <img src={logo} alt="logo" />{' '}
          </div>
          <div>
            <label htmlFor="email" className="mt-5 Font_Black">
              Email
            </label>
            <input
              id="email"
              name="email"
              type="text"
              placeholder="Enter your email"
              value={formik.values.email}
              onChange={handlechange}
              onBlur={formik.handleBlur}
              className={formik.errors.email && formik.touched.email && 'error'}
            />
          </div>
          <div>
            {formik.errors.email && formik.touched.email && (
              <div className="input-feedback">{formik.errors.email}</div>
            )}
          </div>
          <div>
            <label htmlFor="password" className="Font_Black">
              Password
            </label>
            <input
              id="password"
              name="password"
              type="password"
              placeholder="Enter your password"
              value={formik.values.password}
              onChange={(e) => {
                formik.handleChange(e)
              }}
              onBlur={formik.handleBlur}
              className={
                formik.errors.password && formik.touched.password && 'error'
              }
            />
          </div>
          <div>
            {formik.errors.password && formik.touched.password && (
              <div className="input-feedback">{formik.errors.password}</div>
            )}
          </div>
          {error &&
            (!value ? (
              <>
                <div className=" Font_Black text-center mt-4">
                  <div className="text-danger Error_Message">{error}</div>
                </div>
              </>
            ) : (
              <p></p>
            ))}

          <div className="text-center Font_Black Submit_Button mt-3">
            <button
              type="submit"
              disabled={formik.isSubmitting}
              className={
                !(formik.isValid && formik.dirty) || formik.isSubmitting
                  ? 'login-button Disabled'
                  : 'login-button'
              }
            >
              Login
            </button>
          </div>

          <div className="text-center mt-4 ">
            <Link
              to="/forget-password"
              className="link-dark Font_Black Forgot_Pwd"
            >
              Forgot Password ?
            </Link>
          </div>
        </form>
      </div>
    </>
  )
}
export default Login
