import React from 'react'
import FormField from '../../common/FormField/FormField'
import { GamificationLabel } from './CreateEditPollQuestion'

export function GamificationCommonHeader(props) {
  return (
    <div className="row">
      <div>&nbsp; Choose when Users see the question:</div>
      {/* Question */}
      <div className="row">
        {[...GamificationLabel]?.map((item) => (
          <div
            className={`gamification-label btn m-2 ${
              Number(props.selectedGamificationLabel?.id) ===
                Number(item?.id) &&
              (props.startTime.isBefore(props.preEventQuestionThresholdTime) ||
                item?.id === 1)
                ? 'active'
                : ''
            }`}
            key={`category-${item?.id}`}
            onClick={() =>
              (props.startTime.isBefore(props.preEventQuestionThresholdTime) ||
                item?.id === 1) &&
              props.selecteGamificationCategory(item?.id)
            }
          >
            <div className="mt-2">{item?.title}</div>
          </div>
        ))}
      </div>
      <div className="col-7 col-lg-6 question">
        <FormField
          value={props.question ?? ''}
          id="question"
          label="Question Prompt "
          para="Capped at 60 characters"
          paraVisibility={true}
          name="question"
          type="text"
          placeholder="Enter the question"
          onChange={(e) => {
            props.handleInputChange(e, 'question')
          }}
        />
        <div>
          {props.errors && (
            <div className="input-feedback">{props.errors.question}</div>
          )}
        </div>
      </div>

      {/* value */}
      <div className="col-3 points">
        {props.selectedGamificationLabel?.id === 0 ? (
          <div>
            <p>
              <b>Value</b>
              <br></br>
              <br></br>
              Automatic - points are calculated by the backend
            </p>

            <div>
              {props.errors && (
                <div className="input-feedback">{props.errors.points}</div>
              )}
            </div>
          </div>
        ) : null}
        {props.selectedGamificationLabel?.id === 1 ? (
          <div>
            <div className="d-flex">
              <FormField
                value={props.points ?? 0}
                id="points"
                label="Value"
                name="Points"
                para=" "
                paraVisibility={true}
                type="number"
                onChange={(e) => {
                  props.handleInputChange(e, 'points')
                }}
              />
              <div className="align-self-center ms-2 mt-2">Points</div>
            </div>
            <div>
              {props.errors && (
                <div className="input-feedback">{props.errors.points}</div>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}
