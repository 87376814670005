import React from 'react'
import { AiFillInfoCircle } from 'react-icons/ai'

const customHeader = (props) => {
  const { headerList, setStatusInfoModalShow, setAnswerStatusInfoModal } = props
  const headerClassNameList = [
    'drag',
    'index',
    'question',
    'template',
    'start',
    'ttc',
    'end',
    'value',
    'status',
    'correct-answer',
    'answer',
    'modify',
  ]

  const onHeaderClick = (headerText) => {
    if (headerText == 'status') {
      setStatusInfoModalShow()
    }
    if (headerText == 'answer') {
      setAnswerStatusInfoModal()
    }
  }

  const isInfoIconVisible = (headerText) => {
    if (headerText == 'status') {
      return true
    }
    if (headerText == 'answer') {
      return true
    }
    return false
  }

  return (
    <div className="row custom-header">
      {headerList.map((value, index) => (
        <div
          className={`col td-${headerClassNameList[index]}`}
          key={`item-header-${index}`}
          index={index}
        >
          {value}

          {isInfoIconVisible(headerClassNameList[index]) ? (
            <AiFillInfoCircle
              className="ps-1 default-icon"
              onClick={() => onHeaderClick(headerClassNameList[index])}
            />
          ) : (
            <></>
          )}
        </div>
      ))}
    </div>
  )
}
export default customHeader
