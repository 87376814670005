import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { IconContext } from 'react-icons'
import { initializeApp } from 'firebase/app'
import { getFirestore, collection, getDocs } from '@firebase/firestore'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { AiOutlineSearch } from 'react-icons/ai'
import { RiDeleteBin5Fill } from 'react-icons/ri'
import { MdModeEditOutline } from 'react-icons/md'
import { getToken, getUser } from '../Login/ManageUser'
import { convertLocalTimeZone } from '../../constants/helper'
import { intialObjects } from '../../constants/enumTypes.constants'
import { notify } from '../Notify/Notify'
import SideBar from '../common/Sidebar'
import Button from '../common/Button/Button'
import Spinner from 'react-bootstrap/Spinner'
import Pagination from '../common/Pagination/Pagination'
import axios from 'axios'
import DeleteEventInfoModal from './DeleteEventInfoModal'
import './EventInfo.scss'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

const EventInfo = () => {
  // *  useNavigate used for redirect to login
  let navigate = useNavigate()
  // * Authentication variables
  const BASE_URL = process.env.REACT_APP_API_BASE_URL
  const V2_URL = process.env.REACT_APP_API_V2_URL

  const token = getToken()
  const user = getUser()

  // * Component state
  const [EventInfoList, setEventInfoList] = useState([])
  const [selectedEventInfoCategory, setSelectedEventInfoCategory] =
    useState(intialObjects)
  const [categoryLoader, setcategoryLoader] = useState(true)
  const [categories, setCategories] = useState([])
  const [loader, setLoader] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteEventInfoId, setDeleteEventInfoId] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [totalRecords, setTotalRecords] = useState(10)
  const [keyWord, setKeyWords] = useState('')
  const [searchList, setSearchList] = useState([])

  const perPageRecords = 25

  // imported to fetch stats's category
  const app = initializeApp(firebaseConfig)
  const auth = getAuth(app)
  const db = getFirestore(app)

  useEffect(() => {
    signInWithEmailAndPassword(
      auth,
      process.env.REACT_APP_FIREBASE_EMAIL,
      process.env.REACT_APP_FIREBASE_PASSWORD
    ).then((userCredential) => {
      // * Signed in to firebase
      const user = userCredential?.user
      user?.accessToken && fetchCategory()
    })
  }, [])

  const categoryListRef = collection(db, 'Categories')
  const fetchCategory = async () => {
    const data = await getDocs(categoryListRef)
    const categoryData = data.docs.map((doc) => ({
      ...doc.data(),
      ...intialObjects,
    }))
    setCategories([...categoryData])
    setSelectedEventInfoCategory([...categoryData]?.[0])
    setcategoryLoader(false)
    getEventInfoList(1, [...categoryData]?.[0]?.id)
  }

  const handleCategoryClick = (id) => {
    const newSelectedCategory = [...categories].find(
      (item) => Number(item?.id) === Number(id)
    )
    setSelectedEventInfoCategory(newSelectedCategory)
    getEventInfoList(1, [newSelectedCategory]?.[0]?.id)
  }

  //get EventInfos from Search keywords
  const hanldeSearchInput = (e) => {
    setKeyWords(e.target.value)
  }

  const SearchEvents = (isSearch) => {
    if (isSearch === true && keyWord.length > 0) {
      let url = `${V2_URL}eventInfo`
      axios
        .get(url, {
          params: {
            search: keyWord,
            category: selectedEventInfoCategory?.id,
          },
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.data != null) {
            setEventInfoList(res.data?.data)
            setTotalRecords(res?.data?.extra?.total)
          }
        })
        .finally(() => {
          setLoader(false)
        })
    }
    if ((keyWord.length === 0)) {
      getEventInfoList(1, selectedEventInfoCategory?.id)
    }
  }

  // * Fetch EventInfo List
  const getEventInfoList = (page, categoryId) => {
    setLoader(true)
    let url = `${V2_URL}eventInfo`
    axios
      .get(url, {
        params: {
          page: page > 1 ? page : 1,
          perPage: perPageRecords,
          category: categoryId,
        },

        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.data != null) {
          setEventInfoList(res.data.data)
          setTotalRecords(res?.data?.extra?.total)
        }
      })
      .finally(() => {
        setLoader(false)
      })
  }

  function redirectToCreateEventInfo() {
    navigate(`/events/eventinfo/create-eventinfo`)
  }

  const deleteEventInfo = (value) => {
    setDeleteModal(true)
    setDeleteEventInfoId(value)
  }

  //Delete EventInfo
  const deleteEventInfoHandler = () => {
    setDeleteModal(false)
    setLoader(true)
    const url = `${V2_URL}eventInfo/${deleteEventInfoId}`
    axios
      .delete(url, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.data?.success) {
          const title = 'Success'
          const detail = res.data.message
          const type = 'success'
          notify(title, detail, type)
        }
      })
      .finally(() => {
        getEventInfoList(1, selectedEventInfoCategory?.id)
      })
  }

  const handlePageChange = (e) => {
    setPageNumber(e.selected + 1)
    getEventInfoList(e.selected + 1, selectedEventInfoCategory?.id)
    setKeyWords('')
  }

  const handleEditClick = (id) => {
    navigate(`/events/eventinfo/edit-eventinfo/${id}`, {
      state: {
        eventInfoId: id,
      },
    })
  }

  return (
    <>
      {token && user ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-3 col-lg-2">
              <SideBar
                currentSelected="Events"
                currentsubSelected="Event Info Pages"
              />
            </div>
            <div className="col-6 col-lg-8 Event_Container EventInfo-list">
              <p className="Roboto_Black d-flex EventInfo-list-heading">{`Event Info Pages`}</p>
              <div className="row ml-2">
                {categoryLoader ? (
                  <div className="text-center overflow-hidden">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  [...categories]?.map((key) => (
                    <div
                      className={`categories-label btn ${
                        Number(selectedEventInfoCategory?.id) ===
                        Number(key?.id)
                          ? 'activeTab'
                          : ''
                      }`}
                      key={`category-${key?.id}`}
                      onClick={() => handleCategoryClick(Number(key?.id))}
                    >
                      <div>
                        <img src={key?.imgURL} alt="" height={20} />
                      </div>
                      <div className="mt-2">{key?.category}</div>
                    </div>
                  ))
                )}
              </div>
              <div className="row mt-3 search-EventInfo">
                <div className="input-group col-md-4 EventInfo-list-search">
                  <input
                    value={keyWord}
                    className="form-control py-3 border-right-0 border EventInfo-list-search-box"
                    type="search"
                    placeholder="Search for an Event Info Pages"
                    id="example-search-input"
                    onChange={(e) => hanldeSearchInput(e)}
                    onKeyDown={(e) =>
                      SearchEvents(e.key.toLowerCase() === 'enter')
                    }
                  />
                  <span className="input-group-append Bg_Quaternary_Color EventInfo-list-search-box">
                    <button
                      className="btn Font_Quinary_Color border-left-0 border"
                      type="button"
                      onClick={() => SearchEvents(true)}
                    >
                      <IconContext.Provider
                        value={{ color: 'Font_Quinary_Color' }}
                      >
                        <AiOutlineSearch />
                      </IconContext.Provider>
                    </button>
                  </span>
                </div>
              </div>
              <div className="EventInfo-list-center-container p-3 mt-3">
                <div className="row EventInfo-list-header Font_Black Font_Quinary_Color">
                  <div className="col-1">Page ID</div>
                  <div className="col-4">Name</div>
                  <div className="col-3">Start Time</div>
                  <div className="col-3">End Time</div>
                  <div className="col-1">Modify</div>
                </div>
                <hr className="row mt-3 mb-3"></hr>
                <div className="EventInfo-list-table">
                  {loader ? (
                    <div className="text-center overflow-hidden">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  ) : (
                    EventInfoList?.length > 0 &&
                    EventInfoList.map((EventInfo, index) => {
                      return (
                        <div
                          key={index}
                          className={`EventInfo-list-table-row d-flex align-items-center`}
                        >
                          <div className="col-1">
                            <b>{EventInfo.id}</b>
                          </div>
                          <div className="col-4 EventInfo-list-table-event-name">
                            <b>{EventInfo.name}</b>
                          </div>
                          <div className="col-3">
                            {convertLocalTimeZone(EventInfo.startTime)}
                          </div>
                          <div className="col-3">
                            {convertLocalTimeZone(EventInfo.endTime)}
                          </div>
                          <div className="col-1">
                            <MdModeEditOutline
                              className="default-icon"
                              onClick={() => handleEditClick(EventInfo.id)}
                            />
                            <RiDeleteBin5Fill
                              className="default-icon ml-3"
                              onClick={() => deleteEventInfo(EventInfo.id)}
                            />
                          </div>
                        </div>
                      )
                    })
                  )}
                </div>
                <div className="row m-0 pt-4">
                  <div className="col ps-2">
                    {totalRecords > 0 && (
                      <Pagination
                        onPageChange={handlePageChange}
                        pageCount={Math.ceil(totalRecords / perPageRecords)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-3 col-lg-2 Preview_Container mt-5">
              <Button
                name="Add Page"
                btnType="add"
                onClick={() => redirectToCreateEventInfo()}
                className="EventInfo-button ms-auto me-3"
              />
              <DeleteEventInfoModal
                deleteModal={deleteModal}
                onHide={() => setDeleteModal(false)}
                deleteEventInfoHandler={deleteEventInfoHandler}
              />
            </div>
          </div>
        </div>
      ) : (
        navigate('/login')
      )}
    </>
  )
}
export default EventInfo
