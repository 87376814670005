import React from 'react'

import { IconContext } from 'react-icons'
import { TiTick } from 'react-icons/ti'
import { ImCross } from 'react-icons/im'

const CheckCrossIcon = (props) => {
  return (
    <>
      {props.isCheck == true && (
        <IconContext.Provider value={{ size: '2em', color: 'green' }}>
          <TiTick />
        </IconContext.Provider>
      )}
      {props.isCheck == false && (
        <IconContext.Provider value={{ size: '1em', color: 'red' }}>
          <ImCross />
        </IconContext.Provider>
      )}
    </>
  )
}
export default CheckCrossIcon
