import React from 'react'
import { Modal } from 'react-bootstrap'

import Button from '../../common/Button/Button'

const deleteModal = (props) => {
  const { deleteModalVisible, onHide, onDeleteUserClick } = props
  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      show={deleteModalVisible}
      onHide={onHide}
      centered
    >
      <Modal.Body className="text-center">
        <div>Are you sure you want to delete this user?</div>

        <div className="d-flex justify-content-center mt-4">
          <Button className="send-button me-4" onClick={onHide} name="No" />
          <Button
            className="send-button"
            onClick={onDeleteUserClick}
            name="Yes"
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default deleteModal
