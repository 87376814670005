import React from 'react'
import Form from 'react-bootstrap/Form'
import CreateEditOptions from '../CreateEditPollQuestion/CreateEditOptions'
import { preEventTemplate } from '../../../constants/enumTypes.constants'

export function PreEventGamification(props) {
  return (
    <div>
      <div className="row mt-4">
        <div className="col-4 col-lg-3">
          <label className="template-button-text">Question Template</label>
          <Form.Select
            value={props.selectedPreEventTemplate.name}
            onChange={(e) => props.handleInputChange(e, 'preEventTemplate')}
            className="template-dropdown"
            disabled={
              props.isEdit ? new Date(props.startTime) < new Date() : false
            }
          >
            {[...preEventTemplate].map((item) => (
              <option
                key={`template-${item.id}`}
                id={item.id}
                value={item.name}
              >
                {item.name}
              </option>
            ))}
          </Form.Select>
        </div>
        <div>
          <div className="mt-4">
            <p>
              <b>Note:</b>
              {props.selectedPreEventTemplate?.note}
            </p>
            <p>
              {props.selectedPreEventTemplate?.optionsText.length > 0 && (
                <b>{props.selectedPreEventTemplate?.optionHeading}options</b>
              )}
              <div>{props.selectedPreEventTemplate?.optionsText}</div>
            </p>
          </div>
        </div>

        <CreateEditOptions
          list={
            props.selectedPreEventTemplate?.id === 5
              ? props.teamList
              : props.driverList
          }
          selectedPreEventTemplate={props.selectedPreEventTemplate}
          searchDriverId={props.searchDriverId}
          searchTeamId={props.searchTeamId}
          driverIdList={props.driverIdList}
          refDriverList={props.refDriverList}
          refTeamList={props.refTeamList}
          refTextList={props.refTextList}
          isEdit={props.isEdit}
          teamIdList={props.teamIdList}
          handleChange={props.handleChange}
          checkedDriverList={props.checkedDriverList}
          setCheckedDriverList={props.setCheckedDriverList}
          checkedTeamList={props.checkedTeamList}
          setCheckedTeamList={props.setCheckedTeamList}
          checkedTextOption={props.checkedTextOption}
          setCheckedTextOption={props.setCheckedTextOption}
          setDisableSaveOptions={props.setDisableSaveOptions}
        />
      </div>
    </div>
  )
}
