import React from 'react'
import { Button, Modal } from 'react-bootstrap'

const PasswordReset = (props) => {
  return (
    <Modal
      {...props}
      className="delete-poll-modal"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="text-center">
        <h4 className="confirm-answer-modal pb-2">{props.title}</h4>
        <p>{props.body}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>{props.buttonlabel}</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PasswordReset
