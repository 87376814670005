import React from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './Notify.scss'

//this toast message is only for No Internet scenario
export const NoInternet = (title, detail, type) => {
  const ToastMessage = ({ title, detail, type }) => (
    <div className="toast-container">
      <div className="toast-content">
        <h2 className="no-internet">{title}</h2>
        <p>{detail}</p>
      </div>
    </div>
  )

  toast(<ToastMessage title={title} detail={detail} type={type} />, {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  })
}
