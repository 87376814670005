import React from 'react'
import Switch from 'react-switch'

const gamificationSwitch = (props) => {
  return (
    <div className="col-6">
      <label htmlFor="gamification_enabled" className="Font_Black">
        Enable Gamification?
      </label>
      <div className="d-flex">
        <Switch
          onChange={props.handleGamificationEnabled}
          checked={props.isGamificationEnabled}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          height={30}
          width={58}
          className="react-switch"
          id="gamification_enabled"
        />

        <label className="comment-switch text-start pl-2 pt-1">
          {props.isGamificationEnabled ? 'Enabled' : 'Disabled'}
        </label>
      </div>
    </div>
  )
}

export default gamificationSwitch
