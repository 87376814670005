import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner'
import axios from 'axios'
import { AiOutlineSearch } from 'react-icons/ai'
import { IconContext } from 'react-icons'
import { Modal } from 'react-bootstrap'
import { getToken, getUser } from './../../Login/ManageUser'
import Pagination from '../../common/Pagination/Pagination'
import CheckCrossIcon from '../../common/CheckCrossIcon/CheckCrossIcon'
import SideBar from '../../common/Sidebar'
import PreviewSection from '../PreviewSection'
import './DetailsEvent.scss'
import { convertLocalTimeZone } from '../../../constants/helper'
import EventUploadModal from '../EventUploadModal/EventUploadModal'
import SortingArrow from './SortingArrow'
import { notify } from '../../Notify/Notify'
import DeleteModal from './DeleteModal'

const DetailsEvent = () => {
  // Authentication variables
  const BASE_URL = process.env.REACT_APP_API_BASE_URL

  const token = getToken()
  const user = getUser()

  const perPageRecords = 50
  const [showInfoTime, setInfoTime] = useState(false)
  const [showInfoStatus, setInfoStatus] = useState(false)
  const handleInfoTime = () => setInfoTime(true)
  const handleInfoStatus = () => setInfoStatus(true)
  const handleCloseInfoTime = () => setInfoTime(false)
  const handleCloseInfoStatus = () => setInfoStatus(false)
  const [eventList, setEventList] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [enableEdit, setEnableEdit] = useState(false)
  const [eventId, setEventId] = useState()
  const [previewImgSrc, setPreviewImgSrc] = useState(null)
  const [previewVisibility, setPreviewVisibility] = useState('hidden')
  const [eventName, setEventName] = useState('')
  const [eventStartTime, setEventStartTime] = useState()
  const [eventCategoryId, setEventCategory] = useState()
  const [eventDescription, setEventDescription] = useState('')
  const [fontColor, setFontColor] = useState('#ffffff')
  const [keyWord, setKeyWords] = useState('')
  const [searchList, setSearchList] = useState([])
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [loader, setLoader] = useState(true)
  const [pageNumber, setPageNumber] = useState(1)
  const [sortedOrder, setSortedOrder] = useState({
    title: 'asc',
    startTime: 'asc',
    eventStatus: 'asc',
  })
  const [deleteModal, setDeleteModal] = useState(false)

  let navigate = useNavigate()

  const getEventList = () => {
    setLoader(true)
    let url = `${BASE_URL}events`
    axios
      .get(url, {
        params: {
          page: pageNumber,
          per_page: perPageRecords,
        },
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.data != null) {
          setEventList(res.data?.data)
          setSearchList(res.data?.data)
          selectedEvent(res.data?.data?.[0])
          setTotalRecords(res.data?.extra?.total)
        }
      })
      .finally(() => {
        setLoader(false)
      })
  }

  // * Handle table sorting
  const handleSorting = (sortField, sortOrder) => {
    if (sortField) {
      const sorted = [...eventList].sort((a, b) => {
        if (a[sortField] === null) return 1
        if (b[sortField] === null) return -1
        if (a[sortField] === null && b[sortField] === null) return 0
        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), 'en', {
            numeric: true,
          }) * (sortOrder === 'asc' ? 1 : -1)
        )
      })

      setEventList(sorted)
      setSearchList(sorted)
      selectedEvent(sorted[0])
      sortedOrder[sortField] = sortOrder === 'asc' ? 'dec' : 'asc'
      setSortedOrder(sortedOrder)
    }
  }

  const handlePageChange = (e) => {
    setEnableEdit(false)
    setEventId(null)
    setPageNumber(e.selected + 1)
    setPreviewImgSrc(null)
    setPreviewVisibility('hidden')
    setKeyWords('')
  }
  const getStatusBgColor = (status) => {
    if (status.toUpperCase() == 'LIVE') {
      return '#FF66662E'
    } else if (status.toUpperCase() == 'FINAL') {
      return ' #C4C4C44D'
    } else if (status.toUpperCase() == 'PENDING') {
      return ' #708CF12E'
    } else {
      return '#FFFFFF'
    }
  }
  const getStatusColor = (status) => {
    if (status.toUpperCase() == 'LIVE') {
      return '#F2421D'
    } else if (status.toUpperCase() == 'FINAL') {
      return ' #4B4B4B'
    } else if (status.toUpperCase() == 'PENDING') {
      return ' #708CF1'
    } else if (status.toUpperCase() == 'SCHEDULED') {
      return '#BEBEBE'
    } else if (status.toUpperCase() == 'HIDDEN') {
      return '#FFBD3C'
    }
  }
  const selectedEvent = (eventitem) => {
    setEventName(eventitem.title)
    setEventDescription(eventitem.description)
    setFontColor(eventitem.fontAndIconColor)
    setEnableEdit(true)
    setEventId(eventitem.id)
    setEventCategory(eventitem?.categories?.categories?.categoryId)
    setPreviewImgSrc(eventitem.eventImages.verticalImage['19.5x9'])
    setPreviewVisibility('visible')
    const startDateTime = convertLocalTimeZone(eventitem.startTime)
    setEventStartTime(startDateTime)
  }
  useEffect(() => {}, [previewImgSrc, previewVisibility])
  const handleEditClick = () => {
    navigate(`/events/eventdetails/edit-event/${eventId}`, {
      state: {
        eventId: eventId,
      },
    })
  }

  useEffect(() => {
    getEventList()
  }, [pageNumber])

  //get Events from Search keywords
  const hanldeSearchInput = (e) => {
    setKeyWords(e.target.value)
  }
  useEffect(() => {}, [eventList, searchList])

  const SearchEvents = (isSearch) => {
    if (isSearch === true) {
      if (keyWord.length > 0) {
        let url = `${BASE_URL}events`
        axios
          .get(url, {
            params: {
              search: keyWord,
            },
            headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (res.data.data != null) {
              setSearchList(res.data?.data)
              res.data?.data?.[0] && selectedEvent(res.data?.data?.[0])
            }
          })
          .finally(() => {
            setLoader(false)
          })
      } else {
        setSearchList(eventList)
        eventList?.[0] && updatedSearchList(eventList[0])
      }
    }
  }

  const deleteEventHandler = () => {
    setDeleteModal(false)
    setLoader(true)
    const url = `${BASE_URL}event/${eventId}`
    axios
      .delete(url, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.data?.success) {
          const title = 'Success'
          const detail = res.data.message
          const type = 'success'
          notify(title, detail, type)
          getEventList()
        }
      })
  }

  return (
    <>
      {token && user ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-3 col-lg-2">
              <SideBar
                currentSelected="Events"
                currentsubSelected="Event details"
              />
            </div>
            <div className="col-6 col-lg-8">
              <p className="row breadcrumb m-0 py-4">{`Main event list `}</p>
              <div className="row mt-1">
                <div className="input-group col-md-4">
                  <input
                    value={keyWord}
                    className="form-control py-3 border-right-0 border Search_Box"
                    type="search"
                    placeholder="Search for an event"
                    id="example-search-input"
                    onChange={(e) => hanldeSearchInput(e)}
                    onKeyDown={(e) =>
                      SearchEvents(e.key.toLowerCase() === 'enter')
                    }
                  />
                  <span className="input-group-append Bg_Quaternary_Color Search_Box_Button">
                    <button
                      className="btn Font_Quinary_Color border-left-0 border"
                      type="button"
                      onClick={() => SearchEvents(true)}
                    >
                      <IconContext.Provider
                        value={{ color: 'Font_Quinary_Color' }}
                      >
                        <AiOutlineSearch />
                      </IconContext.Provider>
                    </button>
                  </span>
                </div>
              </div>
              <div className="DetailsEvent_Center_Container m-0 mt-3">
                <div className="row m-0 p-3 table-header font-weight-bold">
                  <div className="col-1 p-0 text-center">
                    <SortingArrow
                      handleSorting={() =>
                        handleSorting('id', sortedOrder['id'])
                      }
                      type={sortedOrder['id']}
                    />
                    Event ID
                  </div>
                  <div className="col-3 p-0">
                    <SortingArrow
                      handleSorting={() =>
                        handleSorting('title', sortedOrder['title'])
                      }
                      type={sortedOrder['title']}
                    />
                    Name
                  </div>
                  <div className="col-3 p-0">
                    <SortingArrow
                      handleSorting={() =>
                        handleSorting('startTime', sortedOrder['startTime'])
                      }
                      type={sortedOrder['startTime']}
                    />
                    Start Time
                    <svg
                      onClick={handleInfoTime}
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-exclamation-circle-fill ms-1 mb-1"
                      viewBox="0 0 16 16"
                      style={{ cursor: 'pointer' }}
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                    </svg>
                  </div>
                  <div className="col-1 p-0 text-center">W2W</div>
                  <div className="col-1 p-0 text-center">Game</div>
                  <div className="col-1 p-0 text-center">Reddit</div>
                  <div className="col-2 text-center pe-0">
                    <SortingArrow
                      handleSorting={() =>
                        handleSorting('eventStatus', sortedOrder['eventStatus'])
                      }
                      type={sortedOrder['eventStatus']}
                    />
                    Status
                    <svg
                      onClick={handleInfoStatus}
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-exclamation-circle-fill ms-1 mb-1"
                      viewBox="0 0 16 16"
                      style={{ cursor: 'pointer' }}
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                    </svg>
                  </div>
                </div>
                <hr className="row m-0 mb-3" />

                <div className="event-wraper">
                  {loader ? (
                    <div className="text-center overflow-hidden">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  ) : searchList?.length > 0 ? (
                    searchList.map((eventitem, index) => {
                      return (
                        <div
                          className={`row Table_Row_Events align-items-center m-2 ${
                            Number(eventitem.id) === Number(eventId)
                              ? 'active'
                              : ''
                          }`}
                          key={index}
                          style={{
                            backgroundColor: getStatusBgColor(
                              eventitem.eventStatus
                            ),
                          }}
                          onClick={() => selectedEvent(eventitem)}
                        >
                          <div className="col-1 Font_Black text-center">
                            <div>
                              <input
                                className="form-check-input m-0 mt-1"
                                type="radio"
                                value=""
                                checked={
                                  Number(eventitem.id) === Number(eventId)
                                }
                                id={'flexCheckIndeterminate' + eventitem.id}
                                name="eventTitle"
                                onChange={() => selectedEvent(eventitem)}
                              />
                            </div>
                            <div className="ms-4">{eventitem?.id}</div>
                          </div>
                          <div className="col-3">
                            <p
                              data-toggle="tooltip"
                              data-placement="top"
                              title={eventitem.title}
                              className="Event_Title Font_Black ps-4 m-0"
                            >
                              {eventitem.title}
                            </p>
                          </div>
                          <div className="col-3">
                            {convertLocalTimeZone(eventitem.startTime)}
                          </div>
                          <div className="col-1 Font_Black text-center">
                            {eventitem?.whereToWatchProviders?.length}
                          </div>
                          <div className="col-1 p-0 p-lg-2 text-center">
                            <CheckCrossIcon
                              isCheck={eventitem.gamificationEnabled}
                            />
                          </div>

                          <div className="col-1 p-0 p-lg-2 text-center">
                            <CheckCrossIcon
                              isCheck={
                                eventitem?.redditData?.['isEnabled']
                                  ? true
                                  : false
                              }
                            />
                          </div>

                          <div className="col-2">
                            <label
                              className={`text-center m-0 mx-auto status-label ${
                                eventitem.eventStatus === 'Scheduled'
                                  ? 'Font_Tertiary_Color'
                                  : 'Font_Quaternary_Color'
                              }`}
                              style={{
                                backgroundColor: getStatusColor(
                                  eventitem.eventStatus
                                ),
                              }}
                            >
                              {eventitem.eventStatus === 'Live'
                                ? eventitem.eventStatus.toUpperCase()
                                : eventitem.eventStatus}
                            </label>
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <div className="row justify-content-center m-0">
                      No events found
                    </div>
                  )}
                </div>

                <div className="row m-0 mt-4">
                  <div className="col ps-2">
                    {totalRecords > 0 && (
                      <Pagination
                        onPageChange={handlePageChange}
                        pageCount={Math.ceil(totalRecords / perPageRecords)}
                      />
                    )}
                  </div>
                  <div className="col d-flex">
                    <button
                      type="button"
                      className="btn Submit_Btn ml-auto me-4"
                      disabled={!enableEdit}
                      onClick={() => handleEditClick()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-pen-fill mr-2"
                        viewBox="0 0 16 16"
                      >
                        <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001z"></path>
                      </svg>
                      Edit
                    </button>
                    <button
                      type="button"
                      className="btn Submit_Btn"
                      onClick={() => setDeleteModal(true)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-trash mr-2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"></path>
                        <path
                          fillRule="evenodd"
                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                        ></path>
                      </svg>
                      Delete
                    </button>
                  </div>
                </div>
              </div>
              <Modal
                show={showInfoTime}
                onHide={handleCloseInfoTime}
                animation={false}
                size="md"
              >
                <Modal.Body>
                  <div className="p-2">
                    <div className="row">
                      Recommend opening event chats 30 minutes prior to the
                      sheduled start.
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              <Modal
                show={showInfoStatus}
                onHide={handleCloseInfoStatus}
                animation={false}
                size="md"
              >
                <Modal.Body>
                  <div className="py-1">
                    <div className="row">
                      <div className={`col-3`}>
                        <span
                          className="py-1 px-4 Font_Quaternary_Color"
                          style={{ backgroundColor: '#4B4B4B' }}
                        >
                          Final
                        </span>
                      </div>
                      <div className="col-9">
                        Event complete with all correct answers registered (if
                        applicable).
                      </div>
                    </div>
                  </div>
                  <div className="py-1">
                    <div className="row">
                      <div className={`col-3 `}>
                        <span
                          className="py-1 px-4 Font_Quaternary_Color"
                          style={{ backgroundColor: '#F2421D' }}
                        >
                          LIVE
                        </span>
                      </div>
                      <div className="col-9">
                        Event in-progress. Only able to edit end time and select
                        gamification elements.
                      </div>
                    </div>
                  </div>
                  <div className="py-1">
                    <div className="row">
                      <div className={` col-3`}>
                        <span
                          className="py-1 px-1"
                          style={{ backgroundColor: '#BEBEBE' }}
                        >
                          Scheduled
                        </span>
                      </div>
                      <div className="col-9">
                        Event set up and ready to begin automatically at the
                        predetermined time.
                      </div>
                    </div>
                  </div>
                  <div className="py-1">
                    <div className="row">
                      <div className={` col-3  `}>
                        <span
                          className="py-1 ps-2 pe-3 Font_Quaternary_Color"
                          style={{ backgroundColor: '#708CF1' }}
                        >
                          {' '}
                          Pending{' '}
                        </span>
                      </div>
                      <div className="col-9">
                        Event complete but awaiting correct answers to all
                        questions.
                      </div>
                    </div>
                  </div>
                  <div className="py-1">
                    <div className="row">
                      <div className={` col-3`}>
                        <span
                          className="py-1 px-3 Font_Quaternary_Color"
                          style={{ backgroundColor: '#EAE54E' }}
                        >
                          {' '}
                          Hidden{' '}
                        </span>
                      </div>
                      <div className="col-9">
                        Event will NOT be shown to users while HIDDEN remains
                        enabled.
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              <DeleteModal
                deleteModal={deleteModal}
                onHide={() => setDeleteModal(false)}
                deleteEventHandler={deleteEventHandler}
              />
              <EventUploadModal
                showUploadModal={showUploadModal}
                setShowUploadModal={setShowUploadModal}
                eventName={eventName}
                eventId={eventId}
                eventCategoryId={eventCategoryId}
                eventStartTime={eventStartTime}
              />
            </div>
            <div className="col-3 col-lg-2 Preview_Container">
              <PreviewSection
                showCreateEventBtn={true}
                hideStatsbutton={searchList?.length > 0 ? false : true}
                eventId={eventId}
                eventName={eventName}
                eventDescription={eventDescription}
                fontColor={fontColor}
                wizardNumber={1}
                src={previewImgSrc}
                visibility={previewVisibility}
                setShowUploadModal={setShowUploadModal}
                deleteEventHandler={() => setDeleteModal(true)}
              />
            </div>
          </div>
        </div>
      ) : (
        navigate('/login')
      )}
    </>
  )
}
export default DetailsEvent
