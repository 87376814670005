import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { IconContext } from 'react-icons'
import { initializeApp } from 'firebase/app'
import { getFirestore, collection, getDocs } from '@firebase/firestore'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { AiOutlineSearch } from 'react-icons/ai'
import { RiDeleteBin5Fill } from 'react-icons/ri'
import { MdModeEditOutline } from 'react-icons/md'
import { getToken, getUser } from '../Login/ManageUser'
import { convertLocalTimeZone } from '../../constants/helper'
import { intialObjects } from '../../constants/enumTypes.constants'
import { notify } from '../Notify/Notify'
import SideBar from '../common/Sidebar'
import Button from '../common/Button/Button'
import Spinner from 'react-bootstrap/Spinner'
import Clipboard from '../common/Clipboard/Clipboard'
import Pagination from '../common/Pagination/Pagination.js'
import axios from 'axios'
import CreateEditTickets from './CreateEditTickets.js'
import DeleteTicketModal from './DeleteTicketModal'
import './Tickets.scss'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

const Tickets = () => {
  // *  useNavigate used for redirect to login
  let navigate = useNavigate()
  // * Authentication variables
  const V2_URL = process.env.REACT_APP_API_V2_URL

  const perPageRecords = 25

  const token = getToken()
  const user = getUser()

  // * Component state
  const [TicketsList, setTicketList] = useState([])
  const [selectedTicketsCategory, setSelectedTicketsCategory] = useState()
  const [categoryLoader, setcategoryLoader] = useState(true)
  const [categories, setCategories] = useState([])
  const [copied, setCopied] = useState(false)
  const [copyId, setCopyId] = useState(0)
  const [isEdit, setIsEdit] = useState(false)
  const [editData, setEditData] = useState({})
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteTicketId, setDeleteTicketId] = useState(0)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [loader, setLoader] = useState(false)
  const [totalRecords, setTotalRecords] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [keyWord, setKeyWords] = useState('')

  // imported to fetch stats's category
  const app = initializeApp(firebaseConfig)
  const auth = getAuth(app)
  const db = getFirestore(app)

  useEffect(() => {
    signInWithEmailAndPassword(
      auth,
      process.env.REACT_APP_FIREBASE_EMAIL,
      process.env.REACT_APP_FIREBASE_PASSWORD
    ).then((userCredential) => {
      // * Signed in to firebase
      const user = userCredential?.user
      user?.accessToken && fetchCategory()
    })
  }, [])
  useEffect(() => {
    setTimeout(() => setCopied(false), 1000)
    setTimeout(() => setCopyId(0), 1000)
  }, [copied])

  const categoryListRef = collection(db, 'Categories')
  const fetchCategory = async () => {
    const data = await getDocs(categoryListRef)
    const categoryData = data.docs.map((doc) => ({
      ...doc.data(),
      ...intialObjects,
    }))
    setCategories([...categoryData])
    setSelectedTicketsCategory([...categoryData]?.[0])
    getTicketsList(1, [...categoryData]?.[0]?.id)
    setcategoryLoader(false)
  }

  const handleCopyClick = (id) => {
    setCopied(true)
    setCopyId(id)
    setTimeout(() => setCopied(false), 1000)
  }

  //get Tickets from Search keywords
  const hanldeSearchInput = (e) => {
    setKeyWords(e.target.value)
  }

  const handleCategoryClick = (id) => {
    const newSelectedCategory = [...categories].find(
      (item) => Number(item?.id) === Number(id)
    )
    setSelectedTicketsCategory(newSelectedCategory)
    setKeyWords('')
    getTicketsList(1, newSelectedCategory?.id)
  }

  // * Open Create/Edit Modal
  const handleCreateEditModal = () => {
    setIsModalVisible(true)
  }

  //Edit Ticket
  const editTickets = (value) => {
    setIsEdit(true)
    setEditData(value)
    setIsModalVisible(true)
  }

  const handlePageChange = (e) => {
    setPageNumber(e.selected + 1)
    getTicketsList(e.selected + 1, selectedTicketsCategory?.id)
    setKeyWords('')
  }

  // * Fetch Season List
  const getTicketsList = (page, categoryId) => {
    setLoader(true)
    let url = `${V2_URL}tickets`
    axios
      .get(url, {
        params: {
          page: page > 1 ? page : 1,
          perPage: perPageRecords,
          category: categoryId,
        },
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.data != null) {
          setTicketList(res.data.data)
          setTotalRecords(res?.data?.extra?.total)
        }
      })
      .finally(() => {
        setLoader(false)
      })
  }

  const SearchTickets = (isSearch) => {
    if (isSearch === true && keyWord.length > 0) {
      let url = `${V2_URL}tickets`
      axios
        .get(url, {
          params: {
            search: keyWord,
            category: selectedTicketsCategory?.id,
          },
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.data != null) {
            setTicketList(res.data?.data)
            setTotalRecords(res?.data?.extra?.total)
          }
        })
        .finally(() => {
          setLoader(false)
        })
    }
    if (keyWord.length === 0) {
      getTicketsList(1, selectedTicketsCategory?.id)
    }
  }

  const deleteTicket = (value) => {
    setDeleteModal(true)
    setDeleteTicketId(value.id)
  }

  //Delete Schedule
  const deleteTicketHandler = () => {
    setDeleteModal(false)
    setLoader(true)
    const url = `${V2_URL}tickets/${deleteTicketId}`
    axios
      .delete(url, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.data?.success) {
          const title = 'Success'
          const detail = res.data.message
          const type = 'success'
          notify(title, detail, type)
        }
      })
      .finally(() => {
        getTicketsList(1, selectedTicketsCategory?.id)
      })
  }

  return (
    <>
      {token && user ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-3 col-lg-2">
              <SideBar currentSelected="Events" currentsubSelected="Tickets" />
            </div>

            {isModalVisible ? (
              <CreateEditTickets
                setIsModalVisible={setIsModalVisible}
                setSelectedTicketsCategory={setSelectedTicketsCategory}
                getTicketsList={getTicketsList}
                editData={editData}
                setEditData={setEditData}
                setIsEdit={setIsEdit}
                isEdit={isEdit}
              />
            ) : (
              <>
                <div className="col-6 col-lg-8 Event_Container Tickets-list">
                  <p className="Roboto_Black d-flex Tickets-list-heading">{`Tickets`}</p>
                  <div className="row ml-2">
                    {categoryLoader ? (
                      <div className="text-center overflow-hidden">
                        <Spinner animation="border" variant="primary" />
                      </div>
                    ) : (
                      [...categories]?.map((key) => (
                        <div
                          className={`categories-label btn ${
                            Number(selectedTicketsCategory?.id) ===
                            Number(key?.id)
                              ? 'activeTab'
                              : ''
                          }`}
                          key={`category-${key?.id}`}
                          onClick={() => handleCategoryClick(Number(key?.id))}
                        >
                          <div>
                            <img src={key?.imgURL} alt="" height={20} />
                          </div>
                          <div className="mt-2">{key?.category}</div>
                        </div>
                      ))
                    )}
                  </div>
                  <div className="row mt-3 search-Tickets">
                    <div className="input-group col-md-4 Tickets-list-search">
                      <input
                        value={keyWord}
                        className="form-control py-3 border-right-0 border Tickets-list-search-box"
                        type="search"
                        placeholder="Search for Tickets"
                        id="example-search-input"
                        onChange={(e) => hanldeSearchInput(e)}
                        onKeyDown={(e) =>
                          SearchTickets(e.key.toLowerCase() === 'enter')
                        }
                      />
                      <span className="input-group-append Bg_Quaternary_Color Tickets-list-search-box">
                        <button
                          className="btn Font_Quinary_Color border-left-0 border"
                          type="button"
                          onClick={() => SearchTickets(true)}
                        >
                          <IconContext.Provider
                            value={{ color: 'Font_Quinary_Color' }}
                          >
                            <AiOutlineSearch />
                          </IconContext.Provider>
                        </button>
                      </span>
                    </div>
                  </div>
                  <div className="Tickets-list-center-container p-3 mt-3">
                    <div className="row Tickets-list-header Font_Black Font_Quinary_Color">
                      <div className="col-1">Ticket ID</div>
                      <div className="col-3">Name</div>
                      <div className="col-1">URL</div>
                      <div className="col-2">Start Time</div>
                      <div className="col-2">End Time</div>
                      <div className="col-2">Status</div>
                      <div className="col-1">Modify</div>
                    </div>
                    <hr className="row mt-3 mb-3"></hr>
                    <div className="Tickets-list-table">
                      {loader ? (
                        <div className="text-center overflow-hidden">
                          <Spinner animation="border" variant="primary" />
                        </div>
                      ) : (
                        TicketsList?.length > 0 &&
                        TicketsList.map((Ticket, index) => {
                          return (
                            <div
                              key={index}
                              className={`Tickets-list-table-row d-flex align-items-center`}
                            >
                              <div className="col-1">
                                <b>{Ticket.id}</b>
                              </div>
                              <div className="col-3 Tickets-list-table-event-name">
                                <b>{Ticket.name}</b>
                              </div>
                              <div className="col-1">
                                <Clipboard
                                  urlValue={Ticket.url}
                                  onCopy={() => handleCopyClick(Ticket.id)}
                                  copied={false}
                                />
                                {copyId == Ticket.id ? 'copied!' : ''}
                              </div>
                              <div className="col-2">
                                {convertLocalTimeZone(Ticket.startTime)}
                              </div>
                              <div className="col-2">
                                {convertLocalTimeZone(Ticket.endTime)}
                              </div>
                              <div className="col-2">
                                {Ticket.isHidden ? (
                                  <label
                                    className={`text-center m-0 status-label Font_Quaternary_Color`}
                                    style={{
                                      backgroundColor: '#FFBD3C',
                                    }}
                                  >
                                    {'HIDDEN'}
                                  </label>
                                ) : (
                                  <></>
                                )}
                              </div>
                              <div className="col-1">
                                <MdModeEditOutline
                                  className="default-icon"
                                  onClick={() => editTickets(Ticket)}
                                />
                                <RiDeleteBin5Fill
                                  className="default-icon ml-3"
                                  onClick={() => deleteTicket(Ticket)}
                                />
                              </div>
                            </div>
                          )
                        })
                      )}
                    </div>
                    <div className="row m-0 pt-4">
                      <div className="col ps-2">
                        {totalRecords > 0 && (
                          <Pagination
                            onPageChange={handlePageChange}
                            pageCount={Math.ceil(totalRecords / perPageRecords)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-3 col-lg-2 Preview_Container mt-5">
                  <Button
                    name="Add Tickets"
                    btnType="add"
                    onClick={() => handleCreateEditModal()}
                    className="Tickets-button ms-auto me-3"
                  />
                </div>
                <DeleteTicketModal
                  deleteModal={deleteModal}
                  onHide={() => setDeleteModal(false)}
                  deleteTicketHandler={deleteTicketHandler}
                />
              </>
            )}
          </div>
        </div>
      ) : (
        navigate('/login')
      )}
    </>
  )
}
export default Tickets
