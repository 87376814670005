import React, { useEffect } from 'react'
import { MdOutlineDragIndicator, MdLens } from 'react-icons/md'
import Form from 'react-bootstrap/Form'
import './CreateEditPollQuestion.scss'

export function PreOptionsQuestion(props) {
  const {
    optionsList,
    setOptionsList,
    selectedPreEventTemplate,
    setDisableSaveOptions,
    refTextList,
    isEdit,
  } = props

  useEffect(() => {
    validate()
  }, [optionsList])

  const selectAll = () =>
    setOptionsList(
      optionsList.map((obj) => {
        if (obj.text.length > 0 && obj?.text.length < 40) {
          obj = { ...obj, ischecked: true }
        } else {
          obj = { ...obj }
        }
        return obj
      })
    )

  const clearAll = () =>
    setOptionsList(optionsList.map((obj) => ({ ...obj, ischecked: false })))

  // Helper function to check if a value is an object
  function isObject(value) {
    return typeof value === 'object' && value !== null
  }

  function areSpecificFieldsEqual(obj1, obj2) {
    // Check if both arguments are objects
    if (!isObject(obj1) || !isObject(obj2)) {
      return false
    }

    // Check if the personId, position, and ischecked fields have the same values
    const fieldsToCompare = ['position', 'ischecked']
    return fieldsToCompare.every((field) => obj1[field] === obj2[field])
  }

  const checked = (item) => {
    const newList = optionsList.map((obj) => {
      if (
        obj?.optionNumber === item?.optionNumber &&
        obj?.text.length > 0 &&
        obj?.text.length < 40
      ) {
        obj = { ...obj, ischecked: !obj.ischecked }
      } else {
        obj = { ...obj }
      }
      return obj
    })
    setOptionsList(newList)
    isEdit && checkIsUpdateFlag(parseInt(item?.optionNumber), newList)
  }

  const checkIsUpdateFlag = (position, optionList) => {
    if (isEdit) {
      if (
        !areSpecificFieldsEqual(
          optionList[position - 1],
          refTextList[position - 1]
        )
      ) {
        const newOptionList = optionList.map((obj, index) => {
          if (index === position - 1) {
            return { ...obj, isChanged: true }
          }
          return obj
        })
        setOptionsList(newOptionList)
      } else {
        const newOptionList = optionList.map((obj, index) => {
          if (index === position - 1) {
            return { ...obj, isChanged: false }
          }
          return obj
        })
        setOptionsList(newOptionList)
      }
    }
  }

  const validate = () => {
    optionsList.filter((obj) => obj?.ischecked === true).length > 1
      ? setDisableSaveOptions(false)
      : setDisableSaveOptions(true)
  }

  const textChanged = (e, item) => {
    const newList = optionsList.map((obj) => {
      if (obj?.optionNumber === item?.optionNumber) {
        obj = { ...obj, text: e.target.value }
      }

      if (obj.text.length < 1) {
        obj = { ...obj, ischecked: false }
      }
      return obj
    })
    setOptionsList(newList)
  }

  return (
    <>
      <div>
        <div className={`selection-label btn m-2`} onClick={() => selectAll()}>
          <div>Select All</div>
        </div>
        <div className={`selection-label btn m-2`} onClick={() => clearAll()}>
          <div>Clear All</div>
        </div>
      </div>
      {
        <div className="poll-section">
          <tables>
            <thead>
              <tr className="custom-header">
                <th></th>
                <th>Display #</th>
                {selectedPreEventTemplate?.headings.map((heading) => (
                  <th key={selectedPreEventTemplate?.id}>{heading}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {[...optionsList].map((item) => (
                <tr
                  className={`Table_Row_Events ${
                    !item?.ischecked ? 'active' : ''
                  }`}
                  key={item?.optionNumber}
                >
                  <td>
                    <div className="col td-drag">
                      <MdOutlineDragIndicator className="default-icon" />
                    </div>
                  </td>
                  <td>{item?.optionNumber}</td>
                  <td>
                    <div className="d-flex">
                      <input
                        className="option_question"
                        type="text"
                        value={item?.text}
                        placeholder={`Option ${item?.optionNumber}`}
                        key={item?.optionNumber}
                        onChange={(e) => textChanged(e, item)}
                      ></input>
                    </div>
                  </td>

                  <Form.Check
                    name="group1"
                    type="checkbox"
                    value={item}
                    key={item?.optionNumber}
                    id={`checkbox-${item?.optionNumber}`}
                    className="pt-2 pl-4"
                    onChange={() => checked(item)}
                    checked={item?.ischecked}
                  />
                  {item?.isChanged && (
                    <td>
                      <div>
                        <MdLens className="default-icon" />
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </tables>
        </div>
      }
    </>
  )
}
