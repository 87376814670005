import React from 'react'
import FormField from '../common/FormField'
import Button from '../common/Button/Button'

const NewsNotification = (props) => {
  const {
    articleID,
    selectedNotification,
    newsArticleData,
    handleInputChange,
    getNewsArticleData,
    newsButtonValidation,
    loadingAPI,
  } = props

  return (
    <>
      {selectedNotification?.id === 2 && (
        <div className="row mt-4">
          <div className="col-5">
            <FormField
              className="col-8 col-lg-7 m-0"
              value={articleID ?? ''}
              name="Article"
              type="text"
              label="Article"
              para="Enter the article's ID found in news database"
              placeholder="News Article ID#"
              paraVisibility={true}
              onChange={(e) => handleInputChange(e, 'articleId')}
              characterLeftVisible={false}
            />
            <Button
              type="button"
              className="send-button mt-4"
              name="Check Article"
              onClick={() => getNewsArticleData(articleID)}
              disabled={
                newsButtonValidation || loadingAPI === true ? true : false
              }
            />
          </div>
          <div className="col-7 mt-4">
            <div>
              <b>Twitter account: </b>
              {newsArticleData?.twitter_account_name ?? ''}
            </div>
            <div
              className="thirty-chars twitter-div"
              title={newsArticleData?.article_title}
            >
              <b>Article Title: </b>
              {newsArticleData?.article_title ?? ''}
            </div>
            <div
              className="thirty-chars twitter-div"
              title={newsArticleData?.article_summary}
            >
              <b>Article summary: </b>
              {newsArticleData?.article_summary ?? ''}
              <div>
                <b>URL</b>:
                {newsArticleData?.article_link ? (
                  <a
                    href={newsArticleData?.article_link ?? '#'}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Article URL
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default NewsNotification
