import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { AiOutlinePlus } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

import SideBar from '../common/Sidebar/Sidebar'
import AdminUserList from './AdminUserList/AdminUserList'
import CreateUser from './CreateUser/CreateUser'
import { getToken, getUser, removeUserSession } from '../Login/ManageUser'
import { notify } from '../Notify/Notify'
import DeleteModals from './UserModals/DeleteModals'

require('./Settings.scss')

const settings = () => {
  const BASE_URL = process.env.REACT_APP_API_BASE_URL
  const token = getToken()
  const activeUser = getUser()
  let navigate = useNavigate()

  const [userList, setUserList] = useState([])
  const [breadcrumb, setBreadCrumb] = useState('Settings')
  const [loader, setLoader] = useState(true)
  const [isCreate, setCreate] = useState(false)
  const [isEdit, setEdit] = useState(false)
  const [selectedUser, setSelectedUser] = useState({})
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)

  // Get user List
  const getUserList = () => {
    const url = `${BASE_URL}Users`
    axios
      .get(url, {
        params: {
          page: 1,
          per_page: 1000,
        },
      })
      .then((res) => {
        if (res?.data?.success) {
          const newUserList = res?.data?.data ?? []
          setUserList([...newUserList])
          setSelectedUser(newUserList?.[0] ?? {})
        }
      })
      .finally(() => {
        setLoader(false)
      })
  }

  useEffect(() => {
    getUserList()
  }, [])

  const handleCreateUser = (newUser) => {
    setLoader(true)
    const url = !isEdit ? `${BASE_URL}users` : `${BASE_URL}user/${newUser?.id}`
    const headers = {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
    const params = {
      email: newUser.email,
      first_name: newUser.firstName,
      last_name: newUser.lastName,
      type: Number(newUser.type),
    }

    if (!isEdit) {
      // * Create user
      axios
        .post(url, params, {
          headers,
        })
        .then((res) => {
          if (res?.data?.success) {
            const title = 'Success'
            const detail = res.data.message
            const type = 'success'
            notify(title, detail, type)
            getUserList()
          }
        })
        .finally(() => {
          setLoader(false)
        })
    } else {
      // * Edit user
      params['status'] = newUser.accountStatus ? 1 : 0
      axios
        .put(url, params, {
          headers,
        })
        .then((res) => {
          if (res?.data?.success) {
            const title = 'Success'
            const detail = res.data.message
            const type = 'success'
            notify(title, detail, type)
            getUserList()
          }
        })
        .finally(() => {
          setLoader(false)
        })
    }
    setCreate(false)
    setEdit(false)
  }

  const onCreateClick = () => {
    setBreadCrumb('Settings')
    setCreate(true)
    setEdit(false)
  }

  const onEditUserClick = (user) => {
    setSelectedUser(user)
    setBreadCrumb(user.first_name + ' ' + user.last_name)
    setCreate(true)
    setEdit(true)
  }

  const onDeleteUserClick = () => {
    // * Delete user
    setDeleteModalVisible(false)
    setLoader(true)
    const url = `${BASE_URL}user/${selectedUser?.id}`
    axios
      .delete(url, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.data?.success) {
          const title = 'Success'
          const detail = res.data.message
          const type = 'success'
          notify(title, detail, type)
          getUserList()
        }
      })
      .finally(() => {
        setLoader(false)
      })
  }

  const onRowChange = (id) => {
    const newSelectedUser = [...userList].find((item) => item.id === id)
    setSelectedUser(newSelectedUser)
  }

  return (
    <>
      {token && activeUser ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-3 col-lg-2">
              <SideBar currentSelected="Settings" />
            </div>
            <div className="col">
              {/* Add user Button  */}
              <div className="row ps-3 py-4 mx-2 no-gutters">
                <div className="col breadcrumb">
                  {isCreate
                    ? isEdit
                      ? breadcrumb + ' > Edit Account'
                      : breadcrumb + ' > Create Account'
                    : breadcrumb}
                </div>
                {!isCreate ? (
                  <div className="col-md-5 d-flex h-25 w-25 w-auto ml-auto mr-3">
                    {[1, 2].includes(activeUser.type) ? (
                      <button
                        type="button"
                        className="btn Display_Btn mr-3"
                        onClick={() => onCreateClick()}
                      >
                        <AiOutlinePlus />
                        <span className="ms-1">Add user</span>
                      </button>
                    ) : null}
                    <button
                      type="button"
                      className="btn Display_Btn"
                      onClick={() => {
                        removeUserSession()
                        const title = 'Success'
                        const detail = 'Logout Successfully'
                        const type = 'success'
                        notify(title, detail, type)
                        navigate('/login')
                      }}
                    >
                      <span className="ms-1">Log out</span>
                    </button>
                  </div>
                ) : null}
              </div>

              {isCreate ? (
                <CreateUser
                  isEdit={isEdit}
                  selectedUser={selectedUser}
                  setLoader={setLoader}
                  setCreate={setCreate}
                  setBreadCrumb={setBreadCrumb}
                  handleCreateUser={handleCreateUser}
                />
              ) : (
                <AdminUserList
                  userList={userList}
                  loader={loader}
                  selectedUser={selectedUser}
                  onRowChange={onRowChange}
                  onEditUserClick={onEditUserClick}
                  setDeleteModalVisible={() => setDeleteModalVisible(true)}
                />
              )}
            </div>
          </div>
        </div>
      ) : null}
      <DeleteModals
        deleteModalVisible={deleteModalVisible}
        onHide={() => setDeleteModalVisible(false)}
        onDeleteUserClick={onDeleteUserClick}
      />
    </>
  )
}

export default settings
