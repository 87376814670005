import React from 'react'
import FormField from '../common/FormField'
import Button from '../common/Button/Button'
import Spinner from 'react-bootstrap/Spinner'
import { convertLocalTimeZone } from '../../constants/helper'

const TicketsNotification = (props) => {
  const {
    ticketID,
    selectedNotification,
    ticketsLabel,
    handleInputChange,
    selectedTicket,
    selectedTicketsCategory,
    categories,
    selectedCategory,
    handleCategoryClick,
    getTicketPage,
    ticketPage,
    loader,
    eventButtonValidation,
    loadingAPI,
    categoryName,
  } = props

  return (
    <>
      {selectedNotification?.id == 5 && (
        <div className="mt-4">
          <div>
            <h6>
              <b>Ticket Page</b>
            </h6>
            <p>
              Choose where the user is directed in the app. Category goes to the
              corresponding category landing on the Tickets tab. Ticket goes to
              the Tickets tab and then opens the corresponding Ticket using the
              in-app browser.
            </p>
          </div>
          <div className="row">
            {[...ticketsLabel]?.map((item) => (
              <div
                className={`notification-label btn m-2 ${
                  Number(selectedTicket?.id) === Number(item.id) ? 'active' : ''
                }`}
                key={`category-${item.id}`}
                onClick={() => selectedTicketsCategory(item.id)}
              >
                <div className="mt-2">{item?.title}</div>
              </div>
            ))}
          </div>
          {/* category Ticket */}
          {selectedTicket?.id == 0 && (
            <div className="row mt-5">
              {loader ? (
                <div className="text-center overflow-hidden">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                [...categories]?.map((key) => (
                  <div
                    className={`notification-label btn m-2 ${
                      Number(selectedCategory?.id) === Number(key?.id)
                        ? 'active'
                        : ''
                    }`}
                    key={`category-${key?.id}`}
                    onClick={() => handleCategoryClick(Number(key?.id))}
                  >
                    <div>
                      <img src={key?.imgURL} alt="" height={20} />
                    </div>
                    <div className="mt-2">{key?.category}</div>
                  </div>
                ))
              )}
            </div>
          )}
          {/* Tickets Ticket */}
          {selectedTicket?.id == 1 && (
            <div className="mt-4">
              <div className="row">
                <div className="col-5">
                  <FormField
                    className="col-8 col-lg-7 m-0"
                    value={ticketID ?? ''}
                    type="text"
                    placeholder="Ticket ID#"
                    paraVisibility={true}
                    characterLeftVisible={false}
                    onChange={(e) => handleInputChange(e, 'ticketID')}
                  />

                  <Button
                    type="button"
                    className="send-button mt-4"
                    name="Check Ticket"
                    onClick={() => getTicketPage(ticketID)}
                    disabled={
                      eventButtonValidation || loadingAPI === true
                        ? true
                        : false
                    }
                  />
                </div>
                <div className="col-7 mt-4">
                  <div>
                    <b>Category: </b>
                    {categoryName}
                  </div>
                  <div>
                    <b>Ticket name: </b>
                    {ticketPage?.name ?? ''}
                  </div>
                  <div>
                    <b>Start time: </b>
                    {ticketPage?.startTime
                      ? convertLocalTimeZone(ticketPage.startTime)
                      : ''}
                  </div>
                  <div>
                    <b>URL: </b>
                    {ticketPage?.url ? (
                      <a
                        href={ticketPage?.url ?? '#'}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {ticketPage?.url}
                      </a>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}
export default TicketsNotification
