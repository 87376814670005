import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { getToken, getUser } from '../Login/ManageUser'
import Switch from 'react-switch'
import FormField from '../common/FormField'
import Sidebar from '../common/Sidebar'
import Button from '../common/Button/Button'
import ImageUploader from '../common/ImageUploader'
import 'react-datepicker/dist/react-datepicker.css'
import './EventInfo.scss'
import axios from 'axios'
import { notify } from '../Notify/Notify'

const CoreContent = (props) => {
  const BASE_URL = process.env.REACT_APP_API_V2_URL
  const V2_URL = process.env.REACT_APP_API_V2_URL

  // * useNavigate used for redirect to login.
  let navigate = useNavigate()

  // * useLocation used to get the props.
  const { state } = useLocation()
  const token = getToken()
  const user = getUser()

  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [isTicketEnabled, setIsTicketEnabled] = useState(true)
  const [imgState, setImgState] = useState(null)
  const [mapId, setMapId] = useState(null)
  const [trackMapName, setTrackMapName] = useState()
  const [imgUrl, setImgUrl] = useState()
  const [imageName, setImageName] = useState()
  const [imageDisplay, setImageDisplay] = useState()
  const [disableSave, setDisableSave] = useState(false)
  const [disableUpload, setDisableUpload] = useState(false)
  const [disableNext, setDisableNext] = useState(true)

  useEffect(() => {
    if (state != null && state.eventInfoId != null) {
      setDisableNext(false)
    }
    getEventInfoDetail()
  }, [])

  const getEventInfoDetail = () => {
    if (state != null && state.eventInfoId != null) {
      let url = `${V2_URL}eventinfo/${state.eventInfoId}`
      axios
        .get(url, {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setEventFields(res?.data?.data)
        })
    }
  }

  const setEventFields = (data) => {
    setIsTicketEnabled(data?.isTicketEnabled)
    setTrackMapName(data?.EventInfoMaps?.[0]?.name)
    setImgState(null)
    setImgUrl(data?.EventInfoMaps?.[0]?.mapImage)
    setMapId(data?.EventInfoMaps?.[0]?.id)
  }

  const onImageChange = (picture) => {
    var maxLength = 5
    if (picture[0].file) {
      setImgState(picture[0].file)
      setImageName(picture[0].file.name)
      if (picture[0].file.name.length > maxLength) {
        setImageDisplay(picture[0].file.name.substr(0, maxLength) + '...')
      } else {
        setImageDisplay(picture[0].file.name)
      }
    }
  }

  const handleExit = () => {
    navigate(`/events/eventinfo/`)
  }
  const handleNextClick = (page) => {
    navigate(`/events/eventinfo/edit-eventinfo/${page}`, {
      state: {
        eventInfoId: state.eventInfoId,
        eventinfoName: state.eventinfoName,
      },
    })
  }

  const handleBackClick = (page) => {
    navigate(`/events/eventinfo/edit-eventinfo/${page}`, {
      state: {
        eventInfoId: state.eventInfoId,
        eventinfoName: state.eventinfoName,
      },
    })
  }

  const uploadTrackMap = () => {
    let form_data = new FormData()
    form_data.append('name', trackMapName)
    form_data.append('eventInfoId', state.eventInfoId)
    if (imgState != null) {
      form_data.append('mapImage', imgState)
    }
    let urlMapId = 'eventInfoTrackMap'
    if (mapId != null) {
      urlMapId = urlMapId + `?eventInfoMapId=${mapId}`
    }
    setDisableUpload(true)
    let url = V2_URL + urlMapId
    axios
      .post(url, form_data, {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.data?.success) {
          const title = 'Success'
          const detail = res?.data?.message
          const type = 'success'
          notify(title, detail, type)
          setMapId(res?.data?.data?.id)
        }
      })
      .finally(() => {
        setDisableUpload(false)
      })
  }

  const handleTrackNameChange = (e) => {
    const maxLength = 45
    const { value } = e.target
    if (value.length > maxLength) {
      setTrackMapName(value.substring(0, maxLength))
    } else {
      setTrackMapName(value)
    }
  }

  const handleSave = (flag) => {
    let form_data = new FormData()
    form_data.append('isTicketEnabled', isTicketEnabled)
    setDisableSave(true)
    let url = `${V2_URL}eventInfo/${state.eventInfoId}`
    axios
      .put(url, form_data, {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.data?.success) {
          const title = 'Success'
          const detail = res?.data?.message
          const type = 'success'
          notify(title, detail, type)
        }
        setDisableSave(false)
        setDisableNext(false)
      })
      .catch((err) => {
        setDisableSave(false)
      })
  }
  const handleRemove = () => {
    setImgState(null)
    setImageName('')
  }

  return (
    <>
      {token && user ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-3 col-lg-2">
              <Sidebar
                currentSelected="Events"
                currentsubSelected="Event Info Pages"
              />
            </div>
            <div className="col-6 col-lg-8">
              <div className="row mt-4">
                <div className="breadcrumb">
                  <p className="Roboto_Black Event_Name">{`${
                    state?.eventinfoName.length > 0
                      ? `${state?.eventinfoName} > Core Content`
                      : ''
                  }  `}</p>
                </div>
                <div className="EventInfo-wrapper p-5">
                  <div className="row">
                    <div>
                      <div className="col-6 p-0">
                        <label htmlFor="hidden" className="Font_Black">
                          Enable Tickets?
                        </label>
                        <p className="Font_Regular">
                          If enabled, will display a Tickets button on the Page
                          which navigates users to the Tickets section of the
                          app for the corresponding Category
                        </p>
                        <div className="row">
                          <div className="d-flex col-2 col-1xl-4 col-xl-4 col-xxl-3">
                            <Switch
                              onChange={() =>
                                setIsTicketEnabled(!isTicketEnabled)
                              }
                              checked={isTicketEnabled}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                              height={30}
                              width={58}
                              className="react-switch"
                              id="material-switch"
                              disabled={false}
                            />
                            <div className="col-6 col-xl-3 col-xxl-4">
                              {isTicketEnabled ? 'Enabled' : 'Disabled'}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4">
                        <label htmlFor="image" className="Font_Black ms-1xl-1">
                          Track Map (optional)
                        </label>
                        <p className="Font_Regular mb-0">
                          Track name and map (optional) Upload a high resolution
                          image for Users to access. Include a name so Users
                          have context (e.g., Circuit Gilles-Villeneuve,
                          parking)
                        </p>
                        <div className="d-flex">
                          <FormField
                            value={trackMapName}
                            id="trackMapName"
                            name="trackMapName"
                            type="text"
                            placeholder="Track Map Name"
                            onChange={(e) => {
                              handleTrackNameChange(e)
                            }}
                            disabled={false}
                          />
                          <div className="my-4 mx-5">
                            <ImageUploader
                              value={imgState}
                              imgUrl={imgUrl}
                              onChange={onImageChange}
                              imageName={imageName}
                              imageDisplay={imageDisplay}
                              onClick={handleRemove}
                            />
                          </div>
                          <div className="flex-grow-1 my-4">
                            <Button
                              name="Upload"
                              className="w-25"
                              onClick={() => uploadTrackMap()}
                              disabled={disableUpload}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-6 p-0">
                        <label htmlFor="hidden" className="Font_Black">
                          Weather (optional)
                        </label>
                        <p className="Font_Regular">
                          Input coordinates to display the weather forecast for
                          a target location (today + next 4 days)
                        </p>
                        <div className="row">
                          <div className="col-4">
                            <FormField
                              value={latitude}
                              id="Latitude"
                              label="Latitude"
                              name="Latitude"
                              type="text"
                              placeholder="00.0000 N"
                              onChange={(e) => {
                                pass
                              }}
                              disabled={false}
                            />
                          </div>
                          <div className="col-4">
                            <FormField
                              value={longitude}
                              id="Longitude"
                              label="Longitude"
                              name="Longitude"
                              type="text"
                              placeholder="00.0000 W"
                              onChange={(e) => {
                                pass
                              }}
                              disabled={false}
                            />
                          </div>
                          <div className="col-4 mt-4">
                            <Button
                              name="Check"
                              disabled={false}
                              onClick={() => pass}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3 col-lg-2">
                      <Button name="Exit" onClick={() => handleExit()} />
                    </div>
                    <div className="col-3 col-lg-2 ms-auto">
                      <Button
                        name="Back"
                        onClick={() => handleBackClick(state.eventInfoId)}
                      />
                    </div>
                    <div className="col-3 col-lg-2">
                      <Button
                        name="Next"
                        onClick={() => handleNextClick('schedule')}
                        disabled={disableNext}
                      />
                    </div>
                    <div className="col-3 col-lg-2">
                      <Button
                        name="Save"
                        onClick={() => handleSave()}
                        disabled={disableSave}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        navigate('/login')
      )}
    </>
  )
}
export default CoreContent
