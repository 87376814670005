import React from 'react'
import './ImageUploader.scss'
import { IoMdCloseCircle } from 'react-icons/io'
import { IconContext } from 'react-icons'
import PropTypes from 'prop-types'
import ImageUploading from 'react-images-uploading'
import { EventsIcons } from '../../../assets/icons/events'

const ImageUploader = (props) => {
  return (
    <IconContext.Provider value={{ size: '20px' }}>
      <div className={props.className}>
        <ImageUploading
          value={props.image}
          onChange={props.onChange}
          dataURLKey="data_url"
          acceptType={
            props.acceptType
              ? props.acceptType
              : ['jpg', 'gif', 'png', 'gif', 'jpeg']
          }
          maxFileSize={10485760}
        >
          {({ onImageUpload, errors }) => (
            <div>
              <button
                onClick={onImageUpload}
                style={{
                  backgroundImage: `url(${EventsIcons.UploadIcon})`,
                }}
                className="Image_Uploader"
                disabled={props.disabled}
              ></button>
              {props.imageName ? (
                <div className="d-flex align-items-center mt-2">
                  <div
                    className="Image_Name "
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title={props.imageName}
                  >
                    {props.imageDisplay}
                  </div>
                  <div className="Remove_Icon" onClick={props.onClick}>
                    <IoMdCloseCircle />
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center mt-2">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={props.imgUrl}
                    className={`image-source ${
                      props.imgUrl ? 'd-block' : 'd-none'
                    }`}
                  >
                    Image source
                  </a>
                  {props?.isOptionalClose && (
                    <div className="Remove_Icon" onClick={props.onClick}>
                      <IoMdCloseCircle />
                    </div>
                  )}
                </div>
              )}
              {errors && (
                <div className="text-danger Error_Display">
                  {errors.acceptType && (
                    <p>Your selected file type is not allow</p>
                  )}
                  {errors.maxFileSize && (
                    <p>Selected file size exceed maxFileSize</p>
                  )}
                </div>
              )}
            </div>
          )}
        </ImageUploading>
      </div>
    </IconContext.Provider>
  )
}

ImageUploader.propTypes = {
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  imageName: PropTypes.string,
  imageDisplay: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
}

export default ImageUploader
