import React from 'react'
import { Modal } from 'react-bootstrap'

import {
  statusMapping,
  buttonColorList,
} from '../../../constants/enumTypes.constants'

const statusInfoModal = (props) => {
  const statusInfoMapping = [
    {
      id: 1,
      text: 'Question presented to audience and time expired to answer.',
    },
    {
      id: 2,
      text: 'Question currently being shown to the audience; still time to answer.',
    },
    {
      id: 3,
      text: 'Time scheduled but not yet asked (i.e., will be published automatically).',
    },
    {
      id: 4,
      text: 'Ready to go Live to users immediately following the completion of any preceding Queued question(s).',
    },
    {
      id: 5,
      text: 'Drafted but not scheduled to be published. Schedule or publish manually.',
    },
  ]

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
      <Modal.Body className="show-grid">
        <div className="container status-info-modal">
          {statusInfoMapping.map((item, index) => (
            <div className="row pt-2" key={index}>
              <div
                className="info-button col-2"
                style={{
                  backgroundColor: buttonColorList[item.id],
                }}
              >
                {statusMapping[item.id]}
              </div>
              <div className="col-10">{item.text}</div>
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default statusInfoModal
