import React from 'react'
import { HiArrowSmUp, HiArrowSmDown } from 'react-icons/hi'

const sortingArrow = (props) => {
  return (
    <>
      {props.type === 'asc' ? (
        <HiArrowSmDown onClick={props.handleSorting} />
      ) : (
        <HiArrowSmUp onClick={props.handleSorting} />
      )}
    </>
  )
}

export default sortingArrow
