import React from 'react'
import './GamificationPreview.scss'

export const FastestLapPreview = (props) => {
  const allowedQuestionIds = [4, 5, 6]
  return (
    <>
      {allowedQuestionIds.includes(
        props.activeQuestion?.questionTemplate?.id
      ) && (
        <div>
          <tables>
            <thead>
              <tr>
                <th className="custom-table-header-options">Options</th>
                <th className="custom-table-header">#</th>
                <th className="custom-table-header">%</th>
              </tr>
            </thead>
            <tbody>
              {props.activeQuestion?.answerOptions?.optionsData?.options?.map(
                (item, index) => (
                  <tr
                    key={
                      props.activeQuestion?.answerOptions
                        ?.questionTemplateId === 5
                        ? item?.teamId
                        : item?.personId
                    }
                  >
                    <td>
                      <b>{index + 1}:</b>&nbsp;
                      {props.activeQuestion?.answerOptions
                        ?.questionTemplateId === 5
                        ? item?.teamName
                        : item?.personNickname}
                    </td>
                    <td>N/A</td>
                    <td>N/A</td>
                  </tr>
                )
              )}
            </tbody>
          </tables>
        </div>
      )}
    </>
  )
}
