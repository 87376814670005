import axios from 'axios'
import { getToken } from '../components/Login/ManageUser'
import { notify } from '../components/Notify/Notify'
import { NoInternet } from '../components/Notify/NoInternet'

// * Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // * Do something before request is sent
    config.headers['Authorization'] = `Bearer ${getToken()}`
    return config
  },
  (error) => {
    // * Do something with request error
    return Promise.reject(error)
  }
)

// * Add a response interceptor
axios.interceptors.response.use(undefined, (error) => {
  // * Any status codes that falls outside the range of 2xx cause this function to trigger
  // * Do something with response error

  // for network error in offline conditions
  if ((error.message === 'Network Error')) {
    const title = 'No Internet!!'
    const detail =
      'You lost your internet connection Unfortunately any unsaved work was lost, too.'
    const type = 'error'
    NoInternet(title, detail, type)
    return Promise.reject(error)
  }

  const title = 'Error'
  const detail = error.response?.data?.message ?? 'Something went wrong!'
  const type = 'error'
  notify(title, detail, type)
  if (error?.response?.status === 401) {
    setTimeout(() => {
      // * Signing out of store if its sign out of registry
      window.open(window.location.origin + '/login', '_self')
    }, 1000)
  }
  return Promise.reject(error)
})
