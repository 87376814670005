import React from 'react'
import Settings from '../components/Settings'

const SettingsPage = () => {
  return (
    <>
      <Settings />
    </>
  )
}

export default SettingsPage
