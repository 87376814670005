import React, { createRef, useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios'

import FileUpload from '../../Statistics/FileUpload'
import { getToken } from '../../Login/ManageUser'
import { notify } from '../../Notify/Notify'

const eventUploadModal = (props) => {
  const {
    showUploadModal,
    eventId,
    eventCategoryId,
    eventName,
    eventStartTime,
    setShowUploadModal,
    ...rest
  } = props
  const eventRef = createRef()
  const BASE_URL = process.env.REACT_APP_API_BASE_URL
  const token = getToken()

  const [eventFileURL, setEventFileURL] = useState('')
  const [eventFileName, setEventFileName] = useState('')
  const [eventFile, setEventFile] = useState('')
  const [eventModifiedTs, setEventModifiedTs] = useState(null)
  const [eventButtonDisable, setEventButtonDisable] = useState(true)

  useEffect(() => {
    handleFileRemoval()
    showUploadModal && getStatsFiles()
  }, [showUploadModal])

  const handleFileClick = (event) => {
    event.target.value = ''
  }

  const getStatsFiles = () => {
    const url = `${BASE_URL}stats-file-download`

    let params = { event_id: Number(eventId) }
    axios
      .post(url, params, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.data?.success) {
          const resData = res?.data?.data
          setEventFile('')
          setEventFileURL(resData?.downloadFilePath ?? '')
          setEventFileName(resData?.fileName ?? '')
          setEventModifiedTs(resData?.lastUpdated ?? null)
          setEventButtonDisable(true)
        }
      })
  }

  const FileUploadHandle = () => {
    let form_data = new FormData()
    const url = `${BASE_URL}event-stats-data`
    form_data.append('file', eventFile)
    form_data.append('type', 'event-level')
    form_data.append('category_id', Number(eventCategoryId))
    form_data.append('event_id', Number(eventId))

    axios
      .post(url, form_data, {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.data?.success) {
          let resData = res?.data?.data
          setEventFile('')
          setEventFileURL(resData?.downloadFilePath ?? '')
          setEventFileName(resData?.fileName ?? '')
          setEventModifiedTs(resData?.lastUpdated ?? null)
          const title = 'Success'
          const detail = res?.data?.message
          const type = 'success'
          notify(title, detail, type)
        }
      })

    setEventButtonDisable(true)
  }

  const handleFileUpload = () => {
    const uploadedFile = eventRef.current?.files?.[0]
    setEventFile(uploadedFile)
    setEventFileName(uploadedFile?.name)
    setEventModifiedTs(new Date())
    setEventButtonDisable(false)
  }

  const handleFileRemoval = () => {
    setEventFileURL('')
    setEventFile('')
    setEventFileName('')
    setEventModifiedTs(null)
    setEventButtonDisable(true)
  }

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      show={showUploadModal}
      onHide={() => setShowUploadModal(false)}
      centered
    >
      <Modal.Body>
        <div className="text-center">
          <div>Upload Event Stats for:</div>
          <div>
            <b>{eventName}</b>
          </div>
          <div>{eventStartTime}</div>
          <FileUpload
            fileType="event"
            inputFileName={eventFileName}
            inputFileURL={eventFileURL}
            isUploadBtnDisable={eventButtonDisable}
            inputFileModifiedTs={eventModifiedTs}
            handleFileClick={handleFileClick}
            handleFileUpload={handleFileUpload}
            FileUploadHandle={FileUploadHandle}
            handleFileRemoval={handleFileRemoval}
            ref={eventRef}
          />
          <button
            className="text-center Display_Btn Font_Black Submit_Button w-25 mt-4"
            onClick={() => setShowUploadModal(false)}
          >
            Close
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default eventUploadModal
