import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import FormField from '../common/FormField'
import Button from '../common/Button/Button'

const sectorFinalizeModal = (props) => {
  const { finalizeModal, onHide, finalizeSector } = props
  const [confirmText, setConfirmText] = useState('')

  useEffect(() => {
    setConfirmText('')
  }, [finalizeModal])

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      show={finalizeModal}
      onHide={onHide}
      centered
    >
      <Modal.Body className="text-center">
        <div className="mt-4">
          <b>
            Are you sure you want to finalize the points for this Sector? This
            cannot be undone
          </b>
        </div>
        <div>
          <p className="mt-4">
            Type the message shown in the box below and then click “Yes” to
            continue.
          </p>
          <FormField
            className="w-50 mx-auto"
            value={confirmText ?? ''}
            hideLabel={true}
            name="Confirm Text"
            type="text"
            placeholder="finalize sector"
            autoComplete="off"
            onChange={(e) => setConfirmText(e?.target?.value)}
          />
        </div>
        <div className="d-flex justify-content-center">
          <Button
            type="button"
            className="send-button me-4"
            name="No"
            onClick={onHide}
          />
          <Button
            type="button"
            className="send-button"
            name="Yes"
            onClick={finalizeSector}
            disabled={confirmText !== 'finalize sector'}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default sectorFinalizeModal
