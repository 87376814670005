import React, { useEffect, useState } from 'react'
import { sortableHandle } from 'react-sortable-hoc'
import axios from 'axios'
import { RiDeleteBin5Fill } from 'react-icons/ri'
import { MdOutlineDragIndicator, MdModeEditOutline } from 'react-icons/md'
import ButtonDropdown from '../../../common/ButtonDropdown/ButtonDropdown'
import {
  statusMapping,
  buttonColorList,
} from '../../../../constants/enumTypes.constants'
import { getDisplayDateTime } from '../../../../constants/helper'
import ConfirmAnswerModal from '../../GamificationModals/ConfirmAnswerModal'
import { getToken } from '../../../Login/ManageUser'
import { notify } from '../../../Notify/Notify'
import './CustomRow.scss'

// Authentication variables
const BASE_URL = process.env.REACT_APP_API_BASE_URL
const token = getToken()

/* 
    Status mapping for referance 
    
    1: 'Published'
    2: 'Live'
    3: 'Scheduled'
    4: 'Queued'
    5: 'StandBy'
    6: 'Pending'
    7: 'Final'
*/

const customRow = (props) => {
  const {
    value,
    index,
    draggable,
    questionList,
    setQuestionList,
    addIndexLength,
    deleteRow,
    editQuestion,
    selectRow,
    activeQuestion,
    setPreEventAnswerShow,
    setPreEventRowData,
  } = props

  const [currentStatus, setCurrentStatus] = useState(value.questionStatus)
  const [currentCorrectAnswer, setCorrectAnswer] = useState(value.correctAnswer)
  const [currentAnswerStatus, setCurrentAnswerStatus] = useState(
    value.answerStatus
  )
  const [confirmAnswerModal, setconfirmAnswerModal] = useState(false)
  const rowColorList = {
    1: '#c4c4c4',
    2: 'rgba(255,102,102,0.17)',
    6: '#e5eafc',
  }

  useEffect(() => {
    setCurrentStatus(parseInt(value.questionStatus))
  }, [value.questionStatus])

  const checkPublishedAndFinal = (status) => {
    if (status == 1) {
      return currentAnswerStatus == 6
    }
    return false
  }

  const getRowColor = (status) =>
    rowColorList[status] !== undefined
      ? checkPublishedAndFinal(status)
        ? 'rgba(112, 140, 241, 0.18)'
        : rowColorList[status]
      : '#ffffff'

  const getButtonColor = (status) => buttonColorList[status] ?? '#ffffff'

  const statusOptionList = {
    3: [
      { id: 1000, statusId: 4, text: statusMapping[4] },
      { id: 1001, statusId: 5, text: statusMapping[5] },
    ],
    4: [{ id: 1001, statusId: 5, text: statusMapping[5] }],
    5: [{ id: 1000, statusId: 4, text: statusMapping[4] }],
    6: [{ id: 1002, statusId: 7, text: statusMapping[7] }],
  }
  const correctAnswerText = (value) => {
    var templateName = value?.questionTemplate?.name
    const answerProperties = {
      'F1-FASTEST_LAP': 'personNickname',
      'F1-Single driver': 'personNickname',
      'F1-Single team': 'teamName',
      'Text - single-select': 'text',
    }
    const answerProperty = answerProperties[templateName]
    return value?.correctAnswer?.answer?.[0]?.[answerProperty]
  }
  const getAnswerOptionList = (id) =>
    currentAnswerStatus == 7
      ? []
      : value.answerOptions.find((item) => item.id == id)
      ? value.answerOptions.filter((item) => item.id != id)
      : value.answerOptions

  const getSelectedCorrectAnswer = (id) =>
    value.answerOptions.find((item) => item.id == id)
      ? value.answerOptions.find((item) => item.id == id).text
      : 'Not Selected'

  const onStatusChange = (e) => {
    const updateList = questionList
      .filter((item) => item.questionStatus == parseInt(e.target.id))
      .sort((a, b) => a.position - b.position)

    const position =
      updateList.length > 0 ? updateList[updateList.length - 1].position + 1 : 1

    const updatedQuestionList = [...questionList].map((item) => {
      let result = item
      if (item.id == value.id) {
        result.questionStatus = parseInt(e.target.id)
        result.position = position
      }
      return result
    })

    setCurrentStatus(parseInt(e.target.id))
    setQuestionList(updatedQuestionList)

    const url = `${BASE_URL}gamification/poll-questions/${value.id}`

    const params = {
      questionStatus: parseInt(e.target.id),
      position: position,
    }

    axios
      .put(url, params, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.data?.success) {
          const title = 'Success'
          const detail = res.data.message
          const type = 'success'
          notify(title, detail, type)
        }
      })
  }

  const onCorrectAnswerChange = (e) => {
    setCorrectAnswer(e.target.id)
  }
  const optionDisplay = () => {
    setPreEventRowData({
      questionTypeName: value?.questionTemplate?.name,
      question: value?.question,
      fetchedOptionList: value?.answerOptions?.optionsData?.options,
      correctAnswer: value?.correctAnswer,
      value: value,
    })
    setPreEventAnswerShow(true)
  }

  const onAnswerStatusChange = () => {
    setconfirmAnswerModal(false)
    const isCorrectAnswer = [...value.answerOptions].filter(
      (item) => item.id == currentCorrectAnswer
    )
    if (isCorrectAnswer.length == 0) {
      const title = 'Error'
      const detail = 'Select the one of the correct answer.'
      const type = 'error'
      notify(title, detail, type)
    } else {
      setCurrentAnswerStatus(7)

      const updatedQuestionList = [...questionList].map((item) => {
        let result = item
        if (item.id == value.id) {
          result.answerStatus = 7
        }
        return result
      })
      setQuestionList(updatedQuestionList)
      const url = `${BASE_URL}gamification/finalize-answer/${value.id}`

      const params = { correctAnswer: parseInt(currentCorrectAnswer) }

      axios
        .post(url, params, {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res?.data?.success) {
            const title = 'Success'
            const detail = res.data.message
            const type = 'success'
            notify(title, detail, type)
          }
        })
    }
  }

  const Draggable = sortableHandle(() => (
    <MdOutlineDragIndicator className="default-icon" />
  ))

  return (
    <div
      className={`row custom-row ${
        activeQuestion?.id === value?.id ? 'active-row' : ''
      }`}
      key={`item-${value.id}`}
      id={`item-${value.id}`}
      style={{
        backgroundColor: getRowColor(value.questionStatus),
      }}
      onClick={(e) => {
        if (e?.target?.getAttribute('data-test-id') !== 'custom-dropdown') {
          selectRow(value.id)
        }
      }}
    >
      <div className="col td-drag">{draggable && <Draggable />}</div>
      <div className="col td-index">
        {draggable ? addIndexLength + index + 1 : index + 1}
      </div>
      <div className="col td-question"> {value.question}</div>
      <div className="col td-template"> {value.questionTemplate.name}</div>
      <div className="col td-start">
        {[1, 2, 3].includes(value.questionStatus) &&
          getDisplayDateTime(new Date(value.startTime))}
      </div>
      <div className="col td-ttc">
        {value.questionType === 1 ? 'PRE' : value.ttc}
      </div>
      <div className="col td-end">
        {[1, 2, 3].includes(value.questionStatus) &&
          getDisplayDateTime(new Date(value.endTime))}
      </div>
      <div className="col td-value">
        {value.questionType === 1 ? 'PRE' : parseInt(value.points)}
      </div>
      <div className="col td-status">
        <ButtonDropdown
          itemList={statusOptionList?.[currentStatus] ?? []}
          selectedValue={statusMapping[currentStatus]}
          onDropdownChange={onStatusChange}
          isVisible
          buttonColorList={buttonColorList}
          style={{ backgroundColor: getButtonColor(currentStatus) }}
        />
      </div>
      <div className="col td-correct-answer" onClick={optionDisplay}>
        {value?.questionStatus === 3 && value?.correctAnswer != null ? (
          value?.questionTemplate?.id == 3 ? (
            <i>Selected {() => setCurrentAnswerStatus(value.answerStatus)}</i>
          ) : (
            <i>{correctAnswerText(value)}</i>
          )
        ) : (
          <i>Not Selected {() => setCurrentAnswerStatus(value.answerStatus)}</i>
        )}

        {/* kept this for future reference <ButtonDropdown
          // itemList={getAnswerOptionList(currentCorrectAnswer)}
          // selectedValue={getSelectedCorrectAnswer(currentCorrectAnswer)}
          // onDropdownChange={onCorrectAnswerChange}
          isVisible={currentStatus === 1}
          style={{ background: 'transparent', color: '#000' }}
        /> */}
      </div>
      <div className="col td-answer">
        <button
          className="dropdown-custum-button"
          style={{ backgroundColor: getButtonColor(value?.answerStatus) }}
        >
          {statusMapping[value?.answerStatus]}
        </button>
        {/* kept this for future reference <ButtonDropdown
          itemList={statusOptionList?.[currentAnswerStatus] ?? []}
          selectedValue={statusMapping[currentAnswerStatus]}
          onDropdownChange={() => setconfirmAnswerModal(true)}
          isVisible={currentStatus === 1}
          buttonColorList={buttonColorList}
          style={{ backgroundColor: getButtonColor(currentAnswerStatus) }}
        /> */}
      </div>
      <div className="col td-modify d-flex">
        {![1, 2].includes(currentStatus) && (
          <MdModeEditOutline
            className="default-icon"
            onClick={() => editQuestion(value.id)}
          />
        )}
        {![1, 2].includes(currentStatus) && (
          <RiDeleteBin5Fill
            className="default-icon"
            onClick={() => deleteRow(value.id)}
          />
        )}
      </div>
      <ConfirmAnswerModal
        show={confirmAnswerModal}
        onHide={() => setconfirmAnswerModal(false)}
        onAnswerStatusChange={onAnswerStatusChange}
      />
    </div>
  )
}
export default customRow
