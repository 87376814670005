import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { initializeApp } from 'firebase/app'
import { getFirestore, collection, getDocs } from '@firebase/firestore'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import Button from '../common/Button/Button'
import Sidebar from '../common/Sidebar'
import FormField from '../common/FormField'
import NewsNotification from '../Notifications/NewsNotification'
import StatsNotification from '../Notifications/StatsNotification'
import EventsNotification from '../Notifications/EventsNotification'
import EventInfoNotification from './EventInfoNotification'
import TicketsNotification from './TicketsNotification'
import { checkValidInputLength } from '../../constants/helper'
import { getUser, getToken } from '../Login/ManageUser'
import { notify } from '../Notify/Notify'
import {
  intialObjects,
  newsArticleDataObject,
  statsEventObject,
  statsLabel,
  notificationLabel,
  eventInfoLabel,
  eventInfoPageObject,
  ticketsLabel,
  ticketObject,
} from '../../constants/enumTypes.constants'
import './Notifications.scss'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

const notifications = () => {
  const [notificationTitle, setNotificationTitle] = useState('')
  const [notificationBody, setNotificationBody] = useState('')
  const [confirmText, setConfirmText] = useState('')
  const [selectedNotification, setSelectedNotification] = useState(
    notificationLabel[0]
  )
  const [articleID, setArticleID] = useState('')
  const [eventID, setEventID] = useState('')
  const [eventInfoID, setEventInfoID] = useState('')
  const [ticketID, setTicketID] = useState('')
  const [selectedStats, setSelectedStats] = useState(statsLabel[0])
  const [selectedEventInfo, setSelectedEventInfo] = useState(eventInfoLabel[0])
  const [selectedTicket, setSelectedTicket] = useState(ticketsLabel[0])
  const [selectedCategory, setSelectedCategory] = useState(intialObjects)
  const [categories, setCategories] = useState([])
  const [loader, setLoader] = useState(true)
  const [universal, setUniversal] = useState(intialObjects)
  const [newsArticleData, setNewsArticleData] = useState(newsArticleDataObject)
  const [statsEvent, setStatsEvent] = useState(statsEventObject)
  const [eventInfoPage, setEventInfoPage] = useState(eventInfoPageObject)
  const [ticketPage, setTicketPage] = useState(ticketObject)
  const [categoryName, setCategoryName] = useState('')
  const [eventButtonValidation, setEventButtonValidation] = useState(true)
  const [newsButtonValidation, setNewsButtonValidation] = useState(true)
  const [loadingAPI, setLoadingAPI] = useState(false)

  //regex fro numbers only
  const expression = new RegExp('^[0-9]+$')

  // imported to fetch stats's category
  const app = initializeApp(firebaseConfig)
  const auth = getAuth(app)
  const db = getFirestore(app)

  // * Authentication token
  const BASE_URL = process.env.REACT_APP_API_BASE_URL
  const V2_URL = process.env.REACT_APP_API_V2_URL

  let navigate = useNavigate()
  let token = getToken()
  let user = getUser()

  useEffect(() => {
    if (!token || !user) {
      navigate('/login')
    }
    if (![1, 2].includes(user?.type ?? 0)) {
      navigate('/events/eventdetails')
    }
  }, [])

  useEffect(() => {
    signInWithEmailAndPassword(
      auth,
      process.env.REACT_APP_FIREBASE_EMAIL,
      process.env.REACT_APP_FIREBASE_PASSWORD
    ).then((userCredential) => {
      // * Signed in to firebase
      const user = userCredential?.user
      user?.accessToken && fetchCategory()
    })
  }, [])

  useEffect(() => {
    signInWithEmailAndPassword(
      auth,
      process.env.REACT_APP_FIREBASE_EMAIL,
      process.env.REACT_APP_FIREBASE_PASSWORD
    ).then((userCredential) => {
      // * Signed in to firebase
      const user = userCredential?.user
      user?.accessToken && fetchCategory()
    })
  }, [])

  //capitalize first letter
  const capitalizeFirstLetter = (item) => {
    return item.charAt(0).toUpperCase() + item.slice(1)
  }

  //check valid Id for different notifications
  const checkValidId = (title) => {
    switch (title) {
      case 'general':
        return false
      case 'event':
        return !(statsEvent?.title !== '')
      case 'news':
        return !(newsArticleData?.id !== '')
      case 'stats':
        return selectedStats?.id === 0 ? false : !(statsEvent?.title !== '')
      case 'event Info Pages':
        return selectedEventInfo?.id === 0
          ? false
          : !(eventInfoPage?.name !== '')
      case 'tickets':
        return selectedTicket?.id === 0 ? false : !(ticketPage?.name !== '')
      case 'picks':
        return false
      default:
        return true
    }
  }

  const getStatsFiles = (categories) => {
    const url = `${BASE_URL}stats-file-download`
    axios
      .post(
        url,
        {},
        {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res?.data?.success) {
          const resData = res?.data?.data
          // * Set universal data
          setUniversal({
            ...universal,
            fileName: resData?.universal?.fileName ?? '',
            lastUpdated: resData?.universal?.lastUpdated ?? null,
            downloadFilePath: resData?.universal?.downloadFilePath ?? '',
            isDisable: true,
          })
          // * Set category data
          let updatedResult = [...categories].map((o1) => {
            let category = resData?.category.find((o2) => {
              return o1?.id === o2?.categoryId
            })
            if (category) {
              return {
                ...o1,
                ...category,
              }
            }
            return o1
          })
          setCategories([...updatedResult])
          setSelectedCategory([...updatedResult]?.[0])
          setLoader(false)
        }
      })
  }
  // gets news-article data
  const getNewsArticleData = (articleID) => {
    setLoadingAPI(true)
    const url = `${V2_URL}newsfeeds/newshighlightsv2?highlight_ids=${articleID}`
    axios
      .get(
        url,
        {},
        {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res?.data?.data?.length > 0) {
          const resNewsData = res?.data?.data?.[0]
          const title = 'Success'
          const detail = res.data?.message
          const type = 'success'
          notify(title, detail, type)
          setNewsArticleData({
            twitter_account_name: resNewsData?.twitter_account_name ?? '',
            article_title: resNewsData?.article_title ?? '',
            article_summary: resNewsData?.article_summary ?? '',
            article_link: resNewsData?.article_link ?? '',
            id: String(resNewsData?.id) ?? '',
            preview_image_link: resNewsData?.preview_image_link ?? '',
          })
          setConfirmText('')
          setNewsButtonValidation(true)
        } else {
          const title = 'Error'
          const detail = 'News data not found'
          const type = 'error'
          notify(title, detail, type)
        }
      })
      .catch(() => {
        setArticleID('')
        setNewsArticleData({
          twitter_account_name: '',
          article_title: '',
          article_summary: '',
          article_link: '',
          id: '',
          preview_image_link: '',
        })
        setNewsButtonValidation(true)
      })
      .finally(() => {
        setLoadingAPI(false)
      })
  }
  // gets stats-event data
  const getStatsEvent = (eventID) => {
    setLoadingAPI(true)
    const url = `${BASE_URL}/event/${eventID}`
    axios
      .get(
        url,
        {},
        {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res?.data?.success != false) {
          const resEventData = res?.data?.data
          const title = 'Success'
          const detail = res.data?.message
          const type = 'success'
          notify(title, detail, type)
          setStatsEvent({
            id: eventID,
            title: resEventData?.title ?? '',
            startTime: resEventData?.startTime ?? '',
            image: resEventData?.eventImages?.verticalImage['1x1'] ?? '',
            categoryName:
              resEventData?.categories?.categories?.categoryName ?? '',
            eventChannelName: resEventData?.eventChannelName ?? '',
          })
          setConfirmText('')

          setEventButtonValidation(true)
        }
      })
      .catch(() => {
        setEventID('')
        setStatsEvent({
          id: '',
          title: '',
          startTime: '',
          image: '',
          categoryName: '',
          eventChannelName: '',
        })
        setEventButtonValidation(true)
      })
      .finally(() => {
        setLoadingAPI(false)
      })
  }
  const getCategoryName = (categoryID) => {
    const catObj = [...categories].filter(
      (obj) => parseInt(obj.id) == parseInt(categoryID)
    )
    setCategoryName(catObj?.[0]?.category)
  }

  // gets eventinfo data
  const getEventInfoPage = (eventInfoID) => {
    setLoadingAPI(true)
    const url = `${V2_URL}eventinfo/${eventInfoID}`

    axios
      .get(
        url,
        {},
        {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res?.data?.success != false) {
          const resEventData = res?.data?.data
          const title = 'Success'
          const detail = res.data?.message
          const type = 'success'
          notify(title, detail, type)
          setEventInfoPage({
            id: eventInfoID,
            name: resEventData?.name ?? '',
            startTime: resEventData?.startTime ?? '',
            endTime: resEventData?.endTime ?? '',
            pageImage: resEventData?.pageImage ?? '',
            category: resEventData?.category ?? '',
          })
          setConfirmText('')
          setEventButtonValidation(true)
          getCategoryName(resEventData?.category)
        }
      })
      .catch(() => {
        setEventInfoID('')
        setEventInfoPage({
          id: '',
          name: '',
          startTime: '',
          endTime: '',
          pageImage: '',
          category: '',
        })
        setEventButtonValidation(true)
      })
      .finally(() => {
        setLoadingAPI(false)
      })
  }

  // gets ticket data
  const getTicketPage = (ticketID) => {
    setLoadingAPI(true)
    const url = `${V2_URL}tickets/${ticketID}`

    axios
      .get(
        url,
        {},
        {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res?.data?.success != false) {
          const resEventData = res?.data?.data
          const title = 'Success'
          const detail = res.data?.message
          const type = 'success'
          notify(title, detail, type)
          setTicketPage({
            id: ticketID,
            name: resEventData?.name ?? '',
            startTime: resEventData?.startTime ?? '',
            endTime: resEventData?.endTime ?? '',
            pageImage: resEventData?.image ?? '',
            category: resEventData?.category ?? '',
            description: resEventData?.description ?? '',
            url: resEventData?.url ?? '',
          })
          setConfirmText('')
          setEventButtonValidation(true)
          getCategoryName(resEventData?.category)
        }
      })
      .catch(() => {
        setTicketID('')
        setTicketPage({
          id: '',
          name: '',
          startTime: '',
          endTime: '',
          pageImage: '',
          category: '',
        })
        setEventButtonValidation(true)
      })
      .finally(() => {
        setLoadingAPI(false)
      })
  }

  // * Get category list from the firebase
  const categoryListRef = collection(db, 'Categories')
  const fetchCategory = async () => {
    const data = await getDocs(categoryListRef)
    const categoryData = data.docs.map((doc) => ({
      ...doc.data(),
      ...intialObjects,
    }))
    // * Get all the stats file
    getStatsFiles([...categoryData])
  }

  // * Category change handler
  const handleCategoryClick = (id) => {
    const newSelectedCategory = [...categories].find(
      (item) => Number(item?.id) === Number(id)
    )
    setSelectedCategory(newSelectedCategory)
    setCategoryName('')
  }

  // used this to select state of notification
  const selecteLableCategory = (id) => {
    setSelectedNotification(notificationLabel?.[id])
  }

  // * Handle input change
  const handleInputChange = (e, name) => {
    switch (name) {
      case 'title':
        checkValidInputLength(e.target.value, 66) &&
          setNotificationTitle(e.target.value)
        break
      case 'notificationBody':
        checkValidInputLength(e.target.value, 114) &&
          setNotificationBody(e.target.value)
        break
      case 'confirmText':
        setConfirmText(e.target.value)
        break
      case 'articleId':
        if (expression.test(e.target.value) || !e.target.value?.length) {
          setArticleID(e.target.value)
          setNewsButtonValidation(true)
          e.target.value && setNewsButtonValidation(false)
        }
        break
      case 'eventID':
        if (expression.test(e.target.value) || !e.target.value?.length) {
          setEventID(e.target.value)
          setEventButtonValidation(true)
          e.target.value && setEventButtonValidation(false)
        }
        break
      case 'eventInfoID':
        if (expression.test(e.target.value) || !e.target.value?.length) {
          setEventInfoID(e.target.value)
          setEventButtonValidation(true)
          e.target.value && setEventButtonValidation(false)
        }
        break
      case 'ticketID':
        if (expression.test(e.target.value) || !e.target.value?.length) {
          setTicketID(e.target.value)
          setEventButtonValidation(true)
          e.target.value && setEventButtonValidation(false)
        }
        break
      default:
        break
    }
  }

  // used this to select category of state
  const selecteStateCategory = (id) => {
    setSelectedStats(statsLabel?.[id])
  }

  const selectedEventInfoCategory = (id) => {
    setSelectedEventInfo(eventInfoLabel?.[id])
  }

  const selectedTicketsCategory = (id) => {
    setSelectedTicket(ticketsLabel?.[id])
  }

  const sendNotification = () => {
    setLoadingAPI(true)
    const url = `${BASE_URL}custom-notification`
    let params = {
      notificationTitle: notificationTitle,
      notificationBody: notificationBody,
      type: selectedNotification?.title,
      ...(selectedNotification?.title === 'general' ? { body: {} } : {}),
      ...(selectedNotification?.title === 'event'
        ? {
            body: {
              eventId: statsEvent?.id,
              channelName: statsEvent?.eventChannelName,
              eventName: statsEvent?.title,
              eventImage: statsEvent?.image,
            },
          }
        : {}),
      ...(selectedNotification?.title === 'news'
        ? {
            body: {
              newsId: newsArticleData?.id,
              article_link: newsArticleData?.article_link,
              article_title: newsArticleData?.article_title,
              article_preview_image_link: newsArticleData?.preview_image_link,
            },
          }
        : {}),
      ...(selectedNotification?.title === 'stats' && selectedStats?.id === 0
        ? {
            type: 'stats-category',
            body: {
              categoryId: selectedCategory?.id,
              image: selectedCategory?.imgURL,
            },
          }
        : {}),
      ...(selectedNotification?.title === 'stats' && selectedStats?.id === 1
        ? {
            type: 'stats-event',
            body: {
              categoryId: selectedCategory?.id,
              image: statsEvent?.image,
              eventId: statsEvent?.id,
            },
          }
        : {}),

      ...(selectedNotification?.title === 'event Info Pages' &&
      selectedEventInfo?.id === 0
        ? {
            type: 'eventInfo-category',
            body: {
              categoryId: selectedCategory?.id,
              image: selectedCategory?.EventInfoNotificationImage,
            },
          }
        : {}),
      ...(selectedNotification?.title === 'event Info Pages' &&
      selectedEventInfo?.id === 1
        ? {
            type: 'eventInfo-Page',
            body: {
              categoryId: selectedCategory?.id,
              image: eventInfoPage?.pageImage,
              eventInfoId: eventInfoPage?.id,
            },
          }
        : {}),
      ...(selectedNotification?.title === 'tickets' && selectedTicket?.id === 0
        ? {
            type: 'tickets-category',
            body: {
              categoryId: selectedCategory?.id,
              image: selectedCategory?.TicketsNotificationImage,
            },
          }
        : {}),
      ...(selectedNotification?.title === 'tickets' && selectedTicket?.id === 1
        ? {
            type: 'tickets-ticket',
            body: {
              categoryId: selectedCategory?.id,
              image: ticketPage?.pageImage,
              ticketId: ticketPage?.id,
              ticketName: ticketPage?.name,
              ticketDesc: ticketPage?.description,
              url: ticketPage?.url,
            },
          }
        : {}),
      ...(selectedNotification?.title === 'picks' ? { body: {} } : {}),
    }
    axios
      .post(url, params, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.data?.success) {
          const title = 'Success'
          const detail = res?.data?.message
          const type = 'success'
          notify(title, detail, type)
          setNotificationTitle('')
          setNotificationBody('')
          setArticleID('')
          setEventID('')
          setEventInfoID('')
          setTicketID('')
          setConfirmText('')
          setCategoryName('')
          setStatsEvent(statsEventObject)
          setNewsArticleData(newsArticleDataObject)
          setEventInfoPage(eventInfoPageObject)
          setTicketPage(ticketObject)
          setEventButtonValidation(true)
          setNewsButtonValidation(true)
        }
      })
      .finally(() => {
        setLoadingAPI(false)
      })
  }

  return (
    <>
      {token && [1, 2].includes(user?.type ?? 0) && (
        <div className="container-fluid">
          <div className="row">
            <div className="col-3 col-2">
              <Sidebar currentSelected="Notifications" />
            </div>
            <div className="col-7">
              <div className="breadcrumb">
                Notifications &gt;
                {' ' + capitalizeFirstLetter(selectedNotification?.title) ??
                  ' General'}
              </div>
              <div className="notifications-wrapper p-5">
                <div className="mb-5">
                  <div className="notification-alert">
                    <b>
                      THIS SENDS PUSH NOTIFICATIONS TO ALL APP USERS. Use with
                      extreme caution.
                    </b>
                  </div>
                  Note: Users actively in an event at the time this notification
                  is sent will be excluded.
                  <div className="row">
                    {[...notificationLabel]?.map((item) => (
                      <div
                        className={`notification-label btn m-2 ${
                          Number(selectedNotification?.id) === Number(item?.id)
                            ? 'active'
                            : ''
                        }`}
                        key={`category-${item?.id}`}
                        onClick={() => selecteLableCategory(item?.id)}
                      >
                        <div className="mt-2">
                          {capitalizeFirstLetter(item?.title)}
                        </div>
                      </div>
                    ))}
                  </div>
                  {[...notificationLabel]?.map((item) =>
                    Number(selectedNotification?.id) === Number(item?.id) ? (
                      <p key={item?.id}>
                        <b>
                          {capitalizeFirstLetter(selectedNotification?.title) +
                            ' '}
                          notifications
                        </b>
                        {' ' + selectedNotification?.instruction}
                      </p>
                    ) : null
                  )}
                  <FormField
                    className="col-12 col-lg-7 m-0"
                    value={notificationTitle ?? ''}
                    name="Title"
                    type="text"
                    label="Title"
                    para="Capped at 65 characters."
                    paraVisibility={true}
                    characterLeft={65 - Number(notificationTitle?.length)}
                    characterLeftVisible={true}
                    onChange={(e) => handleInputChange(e, 'title')}
                  />
                  <div className="mt-4">
                    <FormField
                      className="col-12 col-lg-7 notification-textarea m-0"
                      value={notificationBody ?? ''}
                      name="Body"
                      type="textarea"
                      label="Body"
                      para="Capped at 113 characters."
                      paraVisibility={true}
                      characterLeft={113 - Number(notificationBody?.length)}
                      characterLeftVisible={true}
                      onChange={(e) => handleInputChange(e, 'notificationBody')}
                    />
                  </div>
                  {/* events component */}
                  <EventsNotification
                    selectedNotification={selectedNotification}
                    handleInputChange={handleInputChange}
                    eventID={eventID}
                    getStatsEvent={getStatsEvent}
                    statsEvent={statsEvent}
                    eventButtonValidation={eventButtonValidation}
                    loadingAPI={loadingAPI}
                  />
                  {/* news component */}
                  <NewsNotification
                    articleID={articleID}
                    selectedNotification={selectedNotification}
                    newsArticleData={newsArticleData}
                    getNewsArticleData={getNewsArticleData}
                    handleInputChange={handleInputChange}
                    newsButtonValidation={newsButtonValidation}
                    loadingAPI={loadingAPI}
                  />
                  {/* stats component */}
                  <StatsNotification
                    eventID={eventID}
                    selectedNotification={selectedNotification}
                    handleInputChange={handleInputChange}
                    statsLabel={statsLabel}
                    selectedStats={selectedStats}
                    selecteStateCategory={selecteStateCategory}
                    categories={categories}
                    selectedCategory={selectedCategory}
                    handleCategoryClick={handleCategoryClick}
                    getStatsEvent={getStatsEvent}
                    statsEvent={statsEvent}
                    loader={loader}
                    eventButtonValidation={eventButtonValidation}
                    loadingAPI={loadingAPI}
                  />
                  {/* EventInfo component */}
                  <EventInfoNotification
                    eventInfoID={eventInfoID}
                    selectedNotification={selectedNotification}
                    handleInputChange={handleInputChange}
                    eventInfoLabel={eventInfoLabel}
                    selectedEventInfo={selectedEventInfo}
                    selectedEventInfoCategory={selectedEventInfoCategory}
                    categories={categories}
                    selectedCategory={selectedCategory}
                    handleCategoryClick={handleCategoryClick}
                    getEventInfoPage={getEventInfoPage}
                    loader={loader}
                    eventInfoPage={eventInfoPage}
                    eventButtonValidation={eventButtonValidation}
                    categoryName={categoryName}
                    loadingAPI={loadingAPI}
                  />
                  {/* Tickets component */}
                  <TicketsNotification
                    ticketID={ticketID}
                    selectedNotification={selectedNotification}
                    handleInputChange={handleInputChange}
                    ticketsLabel={ticketsLabel}
                    selectedTicket={selectedTicket}
                    selectedTicketsCategory={selectedTicketsCategory}
                    categories={categories}
                    selectedCategory={selectedCategory}
                    handleCategoryClick={handleCategoryClick}
                    getTicketPage={getTicketPage}
                    ticketPage={ticketPage}
                    categoryName={categoryName}
                    loader={loader}
                    eventButtonValidation={eventButtonValidation}
                    loadingAPI={loadingAPI}
                  />
                  <p className="mt-5">
                    {' '}
                    {
                      'When you’re ready to send, type the message shown in the box below and then click the orange “Send” button.'
                    }
                  </p>
                  <FormField
                    className="col-12 col-lg-7"
                    value={confirmText ?? ''}
                    hideLabel={true}
                    name="Title"
                    type="text"
                    placeholder="notify fanamp users"
                    autoComplete="off"
                    onChange={(e) => handleInputChange(e, 'confirmText')}
                  />
                  {/* Footer */}
                  <div className="py-3">
                    <Button
                      type="button"
                      className="send-button float-right"
                      name="Send"
                      disabled={
                        confirmText !== 'notify fanamp users' ||
                        checkValidInputLength(notificationTitle, 1) ||
                        checkValidInputLength(notificationBody, 1) ||
                        checkValidId(selectedNotification?.title) ||
                        loadingAPI === true
                          ? true
                          : false
                      }
                      onClick={sendNotification}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default notifications
