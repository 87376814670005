import React from 'react'
import FormField from '../common/FormField'
import Button from '../common/Button/Button'
import Spinner from 'react-bootstrap/Spinner'
import { convertLocalTimeZone } from '../../constants/helper'

const StatsNotification = (props) => {
  const {
    eventID,
    selectedNotification,
    statsLabel,
    handleInputChange,
    selectedStats,
    selecteStateCategory,
    categories,
    selectedCategory,
    handleCategoryClick,
    getStatsEvent,
    statsEvent,
    loader,
    eventButtonValidation,
    loadingAPI,
  } = props

  return (
    <>
      {selectedNotification?.id == 3 && (
        <div className="mt-4">
          <div>
            <h6>Stats page</h6>
            <p>
              Choose where the user is directed in the app. Category goes to the
              corresponding category landing page on the Stats tab. Choosing
              event and entering an and the
            </p>
          </div>
          <div className="row">
            {[...statsLabel]?.map((item) => (
              <div
                className={`notification-label btn m-2 ${
                  Number(selectedStats?.id) === Number(item.id) ? 'active' : ''
                }`}
                key={`category-${item.id}`}
                onClick={() => selecteStateCategory(item.id)}
              >
                <div className="mt-2">{item?.title}</div>
              </div>
            ))}
          </div>
          {/* category stats */}
          {selectedStats?.id == 0 && (
            <div className="row mt-5">
              {loader ? (
                <div className="text-center overflow-hidden">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                [...categories]?.map((key) => (
                  <div
                    className={`notification-label btn m-2 ${
                      Number(selectedCategory?.id) === Number(key?.id)
                        ? 'active'
                        : ''
                    }`}
                    key={`category-${key?.id}`}
                    onClick={() => handleCategoryClick(Number(key?.id))}
                  >
                    <div>
                      <img src={key?.imgURL} alt="" height={20} />
                    </div>
                    <div className="mt-2">{key?.category}</div>
                  </div>
                ))
              )}
            </div>
          )}
          {/* Event stats */}
          {selectedStats?.id == 1 && (
            <div className="mt-4">
              <div className="row">
                <div className="col-5">
                  <FormField
                    className="col-8 col-lg-7 m-0"
                    value={eventID ?? ''}
                    type="text"
                    placeholder="Event ID#"
                    paraVisibility={true}
                    characterLeftVisible={false}
                    onChange={(e) => handleInputChange(e, 'eventID')}
                  />

                  <Button
                    type="button"
                    className="send-button mt-4"
                    name="Check Event"
                    onClick={() => getStatsEvent(eventID)}
                    disabled={
                      eventButtonValidation || loadingAPI === true
                        ? true
                        : false
                    }
                  />
                </div>
                <div className="col-7 mt-4">
                  <div>
                    <b>Category: </b>
                    {statsEvent?.categoryName ?? ''}
                  </div>
                  <div>
                    <b>Event name: </b>
                    {statsEvent?.title ?? ''}
                  </div>
                  <div>
                    <b>Start time: </b>
                    {statsEvent?.startTime
                      ? convertLocalTimeZone(statsEvent.startTime)
                      : ''}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}
export default StatsNotification
