import React from 'react';
import Switch from 'react-switch';

export function DataFeedHeader(props) {
  return (
    <div className="row">
      <div className="col-4">
        <label htmlFor="comment_enable" className="Font_Black">
          Enable Reddit Live Comment Feed?
        </label>
        <div className="d-flex">
          <Switch
            onChange={props.handleCommentEnabled}
            disabled={props.disableEdit && props.disableAllEdit}
            checked={props.isCommentsEnabled}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={30}
            width={58}
            className="react-switch"
            id="comment_enable" />

          <label className="comment-switch text-start pl-2 pt-1">
            {props.isCommentsEnabled ? 'Enabled' : 'Disabled'}
          </label>
        </div>
      </div>
      <div className="col">
        <label className="data-feed-form-label m-0 pt-2">Instructions:</label>
        <ol type="1">
          <li>
            Input a Subreddit to search for Post and then hit &apos;Enter&apos;
          </li>
          <li>
            Select the Post to pull live comments from (use &apos;Refresh&apos;
            to source latest)
          </li>
          <li>
            Click &apos;Save&apos; to confirm and pull live comments from that
            Post once event is Live
          </li>
        </ol>
      </div>
    </div>
  );
}
