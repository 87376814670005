import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import Switch from 'react-switch'

import FormField from '../../common/FormField/FormField'
import {
  userRoleMapping,
  userButtonColorList,
  statusInfoMapping,
} from '../../../constants/enumTypes.constants'
import CreateEditFooter from '../../Gamification/CreateEditPollQuestion/CreateEditFooter/CreateEditFooter'
import { handleValidation } from './validate'
import { checkValidInputLength } from '../../../constants/helper'
import { getUser } from '../../Login/ManageUser'
import './CreateUser.scss'

const createUser = (props) => {
  const activeUser = getUser()
  // Variables
  const {
    isEdit,
    selectedUser,
    setLoader,
    setCreate,
    setBreadCrumb,
    handleCreateUser,
  } = props

  // State
  const [errors, setErrors] = useState({})
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [userType, setUserType] = useState(
    Object.keys(userRoleMapping)
      .filter((item) =>
        Number(item) === 1 ||
        (isEdit && Number(item) === Number(selectedUser?.type))
          ? Number(item) >= Number(activeUser.type)
          : Number(item) > Number(activeUser.type)
      )?.[0]
      ?.toString()
  )
  const [accountStatus, setAccountStatus] = useState(true)

  // useEffect

  useEffect(() => {
    if (selectedUser && isEdit) {
      setFirstName(selectedUser?.first_name)
      setLastName(selectedUser?.last_name)
      setEmail(selectedUser?.email)
      setUserType(
        Object.keys(userRoleMapping)
          .find((item) => Number(item) === Number(selectedUser?.type))
          .toString()
      )
      setAccountStatus(selectedUser?.status === 1 ? true : false)
    }
  }, [selectedUser])

  // Handle input change
  const handleInputChange = (e, name) => {
    switch (name) {
      case 'firstName':
        checkValidInputLength(e.target.value, 61) &&
          setFirstName(e.target.value)
        e.target?.value?.length === 0 && lastName?.length === 0
          ? setBreadCrumb('Settings')
          : setBreadCrumb(e.target.value + ' ' + lastName)
        break
      case 'lastName':
        checkValidInputLength(e.target.value, 61) && setLastName(e.target.value)
        e.target?.value?.length === 0 && firstName?.length === 0
          ? setBreadCrumb('Settings')
          : setBreadCrumb(firstName + ' ' + e.target.value)

        break
      case 'email':
        setEmail(e.target.value)
        break
      case 'userType':
        setUserType(e.target.value)
        break
      case 'accountStatus':
        setAccountStatus(!accountStatus)
        break

      default:
        break
    }
  }

  const validate = () => {
    let fieldErrors = handleValidation(firstName, lastName, email)
    setErrors(fieldErrors)

    if (Object.keys(fieldErrors).length === 0) {
      setBreadCrumb('Settings')
      onSubmit()
    }
  }

  const onSubmit = () => {
    setLoader(true)
    const newUser = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      type: userType,
      accountStatus: accountStatus,
    }
    if (isEdit) {
      newUser['id'] = selectedUser?.id
    }
    handleCreateUser(newUser)
    setCreate(false)
  }

  const handleExit = () => {
    setBreadCrumb('Settings')
    setCreate(false)
  }

  return (
    <div className="create-user p-4 mx-4">
      <div className="row">
        {/* First Name */}
        <div className="col firstName">
          <FormField
            value={firstName ?? ''}
            id="firstName"
            label="First Name"
            name="firstName"
            type="text"
            placeholder="Enter the First Name"
            onChange={(e) => {
              handleInputChange(e, 'firstName')
            }}
          />
          <div>
            {errors && <div className="input-feedback">{errors.firstName}</div>}
          </div>
        </div>

        {/* Last Name */}
        <div className="col lastName">
          <FormField
            value={lastName ?? ''}
            id="lastName"
            label="Last Name"
            name="lastName"
            type="text"
            placeholder="Enter the Last Name"
            onChange={(e) => {
              handleInputChange(e, 'lastName')
            }}
          />
          <div>
            {errors && <div className="input-feedback">{errors.lastName}</div>}
          </div>
        </div>
      </div>

      <div className="row">
        {/* Email */}
        <div className="col-6">
          <FormField
            value={email ?? ''}
            id="email"
            label="Email"
            name="email"
            type="text"
            placeholder="Enter the Email Address"
            onChange={(e) => {
              handleInputChange(e, 'email')
            }}
            disabled={isEdit}
          />
          <div>
            {errors && <div className="input-feedback">{errors.email}</div>}
          </div>
        </div>
      </div>

      <label className="row pt-4">
        <b>Permission Level</b>
      </label>
      {Object.keys(userRoleMapping)
        .filter((item) =>
          Number(item) === 1 ||
          (isEdit && Number(item) === Number(selectedUser?.type))
            ? Number(item) >= Number(activeUser.type)
            : Number(item) > Number(activeUser.type)
        )
        .map((item, index) => (
          <div key={`inline-radio-${index}`} className="row">
            <Form.Check
              inline
              name="group1"
              type="radio"
              value={item}
              onChange={(e) => handleInputChange(e, 'userType')}
              checked={userType === item}
              id={`radio-${index}`}
              className="width-fit pt-1"
              disabled={
                isEdit && activeUser?.id === selectedUser?.id ? true : false
              }
            />
            <label
              htmlFor={`radio-${index}`}
              className="status-label"
              style={{ backgroundColor: userButtonColorList[item] }}
            >
              {userRoleMapping[item]}
            </label>
            <label className="width-fit col">{statusInfoMapping[item]}</label>
          </div>
        ))}
      {isEdit && (
        <div className="row pt-4">
          <label htmlFor="active_user" className="Font_Black">
            Account Status
          </label>
          <div className="d-flex">
            <Switch
              onChange={(e) => handleInputChange(e, 'accountStatus')}
              checked={accountStatus}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={30}
              width={58}
              className="react-switch"
              id="active_user"
              disabled={activeUser?.id === selectedUser?.id ? true : false}
            />

            <label className="comment-switch text-start pl-2 pt-1">
              {accountStatus
                ? 'Active - can access the admin dashboard '
                : 'Inactive - unable to access the admin dashboard'}
            </label>
          </div>
        </div>
      )}

      <CreateEditFooter
        onExitClick={handleExit}
        onSubmitClick={() => validate()}
      />
    </div>
  )
}

export default createUser
