const validateTTC = (ttc) => {
  if (ttc.length === 8) {
    const [hh, mm, ss] = ttc.split(':')
    if ([0, 1].find((item) => item == hh) == undefined) {
      return false
    }
    if (hh == 0 && mm == 0 && ss < 10) {
      return false
    }
    if (hh == 1 && (mm != 0 || ss != 0)) {
      return false
    }
    return true
  } else {
    return false
  }
}

export const handlePreEventValidation = (question) => {
  let errors = {}
  if (!question || question.trim().length == 0) {
    errors.question = 'Question is a required field.'
  }
  return errors
}

export const handleValidation = (
  question,
  points,
  ttc,
  questionStatus,
  template,
  startTime,
  endTime,
  eventStartTime,
  eventEndTime
) => {
  let errors = {}
  if (!question || question.trim().length == 0) {
    errors.question = 'Question is a required field.'
  }
  if (!points || points == '') {
    errors.points = 'Points is a required field.'
  }
  if (points < 0) {
    errors.points = 'Points value can not be negative value.'
  }
  if (points != '' && !isNaN(points) && Math.round(points) != points) {
    errors.points = 'Points value can not be float value.'
  }
  if (!validateTTC(ttc)) {
    errors.ttc = 'TTC should be between 00:00:10 - 01:00:00'
  }
  if (!ttc || ttc == '' || ttc.length != 8) {
    errors.ttc = 'TTC should be valid and not empty.'
  }
  if (template.data.length > 0) {
    template.data.map((item) => {
      if (!item.buttonText || item.buttonText.trim().length == 0) {
        errors[`template-text-${item.id}`] = 'Required field.'
      }
      if (template.image && item.imgURL == '' && item.imgName == '') {
        errors[`template-image-${item.id}`] = 'Required field'
      }
    })
  }

  if (parseInt(questionStatus) === 3) {
    if (endTime != undefined) {
      if (
        new Date(endTime).toLocaleString() >
        new Date(eventEndTime).toLocaleString()
      ) {
        errors.ttc = 'TTC is leading to invalid endTime.'
      }
    }
  }
  return errors
}
