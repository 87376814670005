import React, { useState, forwardRef, useEffect, useCallback } from 'react'
import { AiFillInfoCircle } from 'react-icons/ai'
import { MdOutlineDragIndicator } from 'react-icons/md'
import axios from 'axios'
import moment from 'moment'
import CreateEditFooter from './CreateEditFooter/CreateEditFooter'
import {
  statusMapping,
  buttonColorList,
  template,
  preEventTemplate,
  defaultOptionsList,
} from '../../../constants/enumTypes.constants'
import { initializeApp } from 'firebase/app'
import { getFirestore, collection, getDocs } from '@firebase/firestore'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { addTimeInDate, checkValidInputLength } from '../../../constants/helper'
import StartTimeStatusInfo from '../GamificationModals/StartTimeStatusInfo'
import { handleValidation, handlePreEventValidation } from './validate'
import { getToken } from '../../Login/ManageUser'
import { notify } from '../../Notify/Notify'
import './CreateEditPollQuestion.scss'
import 'react-datepicker/dist/react-datepicker.css'
import { LiveGamification } from './LiveGamification'
import { PreEventGamification } from './PreEventGamification'
import { QuestionStatusMaping } from './QuestionStatusMaping'
import { GamificationCommonHeader } from './GamificationCommonHeader'

export const GamificationLabel = [
  {
    id: 0,
    title: 'Pre-event',
  },
  {
    id: 1,
    title: 'Live',
  },
]

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

const createEditPollQuestion = (props) => {
  // Authentication variables
  const BASE_URL = process.env.REACT_APP_API_BASE_URL
  const V2_URL = process.env.REACT_APP_API_V2_URL
  const token = getToken()

  // Variables
  const {
    editQuestion,
    updateQuestionList,
    breadcrumb,
    exitCreateEdit,
    eventStartTime,
    eventEndTime,
    setEventLoader,
  } = props

  // State
  const [startTimeError, setStartTimeError] = useState('')
  const [isEdit, setIsEdit] = useState(false)
  const [errors, setErrors] = useState({})
  const [question, setQuestion] = useState('')
  const [defaultStatus, setDefaultStatus] = useState(3)
  const [startTime, setStartDate] = useState(new Date())
  const [endTime, setEndDate] = useState()
  const [points, setPoints] = useState()
  const [ttc, setTtc] = useState('00:00:10')
  const [selectedTemplate, setTemplate] = useState(template[0])
  const [selectedPreEventTemplate, setPreEventTemplate] = useState(
    preEventTemplate[0]
  )
  const [statusInfoModalShow, setStatusInfoModalShow] = useState(false)
  const [updateImage, setUpdateImage] = useState(true)
  const [selectedGamificationLabel, setSelectedGamificationLabel] = useState(
    GamificationLabel[0]
  )
  const [statusOptions, setStatusOptions] = useState(
    moment(startTime).isBefore(moment(eventStartTime).subtract(5, 'minutes'))
      ? [3, 5]
      : [3, 5]
  )
  //removed Queued with id 4: earlier[3,4,5] now[3,5]
  const [driverList, setDriverList] = useState([])
  const [driverIdList, setDriverIdList] = useState({})
  const [teamList, setTeamList] = useState([])
  const [teamIdList, setTeamIdList] = useState({})
  const [checkedDriverList, setCheckedDriverList] = useState([])
  const [checkedTeamList, setCheckedTeamList] = useState([])
  const [checkedTextOption, setCheckedTextOption] = useState(defaultOptionsList)
  const [disableSaveOptions, setDisableSaveOptions] = useState(false)
  const [refDriverList, setRefDriverList] = useState([])
  const [refTeamList, setRefTeamList] = useState([])
  const [refTextList, setRefTextList] = useState([])

  // firebase authentication
  const app = initializeApp(firebaseConfig)
  const auth = getAuth(app)
  const db = getFirestore(app)

  // useEffect
  useEffect(() => {
    if (editQuestion) {
      setIsEdit(true)
      setQuestion(editQuestion.question)
      setDefaultStatus(editQuestion.questionStatus)
      setStartDate(new Date(editQuestion.startTime))
      setEndDate(new Date(editQuestion.endTime))
      setTemplate(editQuestion.questionTemplate)
      setPreEventTemplate(editQuestion.questionTemplate)
    }
  }, [editQuestion])

  // firebase sign-in
  useEffect(() => {
    signInWithEmailAndPassword(
      auth,
      process.env.REACT_APP_FIREBASE_EMAIL,
      process.env.REACT_APP_FIREBASE_PASSWORD
    ).then((userCredential) => {
      // * Signed in to firebase
      const user = userCredential?.user
      user?.accessToken && (fetchCategory(), fetchTeamList())
    })
  }, [])

  // * Get F1 Driver list from the firebase
  const DriverListef = collection(db, 'F1DriverList')
  const fetchCategory = async () => {
    const data = await getDocs(DriverListef)
    const driverData = data?.docs?.map((doc) => ({
      ...doc.data(),
    }))
    setDriverList(
      [...driverData].sort(
        (a, b) => parseInt(a.position) - parseInt(b.position)
      )
    )
    if ([3, 4, 6].includes(editQuestion.answerOptions.questionTemplateId)) {
      setOptionsList(
        editQuestion.answerOptions.optionsData.options,
        editQuestion.questionTemplate,
        [...driverData].sort(
          (a, b) => parseInt(a.position) - parseInt(b.position)
        )
      )
    }
  }

  // * Get F1 Team list from the firebase
  const TeamListef = collection(db, 'F1TeamList')
  const fetchTeamList = async () => {
    const data = await getDocs(TeamListef)
    const teamData = data?.docs?.map((doc) => ({
      ...doc.data(),
    }))
    setTeamList(
      [...teamData].sort((a, b) => parseInt(a.position) - parseInt(b.position))
    )
    if (editQuestion.answerOptions.questionTemplateId === 5) {
      setOptionsList(
        editQuestion.answerOptions.optionsData.options,
        editQuestion.questionTemplate,
        [...teamData].sort(
          (a, b) => parseInt(a.position) - parseInt(b.position)
        )
      )
    }
    if (editQuestion.answerOptions.questionTemplateId === 7) {
      setOptionsList(
        editQuestion.answerOptions.optionsData.options,
        editQuestion.questionTemplate,
        []
      )
    }
  }

  //setting PreEvent Question Threshold Time
  const preEventQuestionThresholdTime = moment(eventStartTime)
    .utc()
    .subtract(5, 'minutes')

  const validateStartTime = (date) => {
    const url = `${BASE_URL}gamification/validate-question`

    const params = {
      eventId: props.eventId,
      startTime: date,
      questionId: Number(editQuestion?.id),
      questionType: selectedGamificationLabel?.title === 'Pre-event' ? 1 : 2,
    }

    axios
      .post(url, params, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.data?.success) {
          setStartTimeError('')
        } else {
          setStartTimeError(res.data.message)
        }
      })
  }

  const setOptionsList = (optionList, questionTemplate, firebaseList) => {
    if ([3, 4, 6].includes(questionTemplate.id)) {
      firebaseList = firebaseList.map((obj) => ({ ...obj, ischecked: false }))
      // Create a map from personId to object in A for faster lookup
      const mapA = new Map(firebaseList.map((obj) => [obj.position, obj]))
      // Iterate over array B and update array A
      optionList.forEach((objB) => {
        const objA = mapA.get(objB.position)
        if (objA) {
          // Replace fields in object A with corresponding fields from object B
          Object.assign(objA, objB)
        } else {
          // If personId from B doesn't exist in A, push the entire object
          firebaseList.push(objB)
        }
      })
      setDriverList(firebaseList)
      setRefDriverList(firebaseList)
    }
    if (questionTemplate.id === 5) {
      firebaseList = firebaseList.map((obj) => ({ ...obj, ischecked: false }))
      // Create a map from personId to object in A for faster lookup
      const mapA = new Map(firebaseList.map((obj) => [obj.position, obj]))
      // Iterate over array B and update array A
      optionList.forEach((objB) => {
        const objA = mapA.get(objB.position)
        if (objA) {
          // Replace fields in object A with corresponding fields from object B
          Object.assign(objA, objB)
        } else {
          // If personId from B doesn't exist in A, push the entire object
          firebaseList.push(objB)
        }
      })
      setTeamList(firebaseList)
      setRefTeamList(firebaseList)
    }
    if (questionTemplate.id === 7) {
      setCheckedTextOption(
        defaultOptionsList.map((obj) => {
          if (
            optionList.some((item) => item.optionNumber === obj.optionNumber)
          ) {
            return optionList.find(
              (item) => item.optionNumber === obj.optionNumber
            )
          } else {
            return obj
          }
        })
      )
      setRefTextList(
        defaultOptionsList.map((obj) => {
          if (
            optionList.some((item) => item.optionNumber === obj.optionNumber)
          ) {
            return optionList.find(
              (item) => item.optionNumber === obj.optionNumber
            )
          } else {
            return obj
          }
        })
      )
    }
  }

  // Handle input change
  const handleInputChange = (e, name) => {
    if (errors && name != 'template') {
      errors[name] = ''
      setErrors(errors)
    }

    switch (name) {
      case 'question':
        checkValidInputLength(e.target.value, 61) && setQuestion(e.target.value)
        break

      case 'points':
        setPoints(e.target.value)
        break

      case 'questionStatus':
        if (e.target.value !== 3) {
          setStartTimeError('')
          setErrors(errors)
        }
        setDefaultStatus(e.target.value)
        break

      case 'ttc': {
        setEndDate(addTimeInDate(startTime, e.target.value))
        setTtc(e.target.value)
        break
      }

      case 'startTime': {
        const changedStartTime = new Date(e.target.value)
        setEndDate(addTimeInDate(changedStartTime, ttc))
        setStartDate(changedStartTime)
        validateStartTime(changedStartTime)
        break
      }

      case 'template': {
        const changedTemplate = template.find(
          (item) => item.name === e.target.value
        )
        setTemplate(changedTemplate)
        break
      }

      case 'preEventTemplate': {
        const changedTemplate = preEventTemplate.find(
          (item) => item.name === e.target.value
        )
        setPreEventTemplate(changedTemplate)
        break
      }

      default:
        break
    }
  }

  const checkIstrue = (optionList) => {
    const updatedData = optionList.map((obj) => {
      const { isChanged, ...rest } = obj
      return rest
    })
    var onlyTrueList = updatedData.filter((obj) => obj.ischecked === true)
    return onlyTrueList
  }

  const getOptionList = (id) => {
    switch (id) {
      case 3:
        return checkIstrue(checkedDriverList)
      case 4:
        return checkIstrue(checkedDriverList)
      case 5:
        return checkIstrue(checkedTeamList)
      case 6:
        return checkIstrue(checkedDriverList)
      case 7:
        return checkIstrue(checkedTextOption)
    }
  }

  const selecteGamificationCategory = (id) => {
    setSelectedGamificationLabel(GamificationLabel?.[id])(id === 1)
      ? setStatusOptions([3, 4, 5])
      : setStatusOptions([3, 5])
  }

  const onImageUpload = (picture, id) => {
    setUpdateImage(true)
    if (errors) {
      errors[`template-image-${id}`] = ''
      setErrors(errors)
    }

    const uploadedImgURL = picture[0].file ?? ''
    const uploadedImgName = picture[0].file.name ?? ''

    updateTemplateData({ imgURL: uploadedImgURL, imgName: uploadedImgName }, id)
  }
  const updateDriverList = (value, position) => {
    let updatedDriverList = { ...driverIdList }
    updatedDriverList[position] = value
    setDriverIdList(updatedDriverList)
  }

  const updateTeamList = (value, position) => {
    let updatedTeamList = { ...teamIdList }
    updatedTeamList[position] = value
    setTeamIdList(updatedTeamList)
  }

  const handleChange = (event, position) => {
    const expr = new RegExp('^[0-9]+$')
    if (expr.test(event.target.value) || !event.target.value?.length) {
      selectedPreEventTemplate?.id === 5
        ? updateTeamList(event.target.value, position)
        : updateDriverList(event.target.value, position)
    }
  }

  function areSpecificFieldsEqual(obj1, obj2, tabletype) {
    // Check if both arguments are objects
    if (!isObject(obj1) || !isObject(obj2)) {
      return false
    }

    // Check if the personId, position, and ischecked fields have the same values
    const fieldsToCompare = [
      tabletype === 5 ? 'teamId' : 'personId',
      'position',
      'ischecked',
    ]
    return fieldsToCompare.every((field) => obj1[field] === obj2[field])
  }

  // Helper function to check if a value is an object
  function isObject(value) {
    return typeof value === 'object' && value !== null
  }

  const checkIsUpdateFlag = (e, position, tabletype, optionList) => {
    if (isEdit) {
      if (tabletype === 3 || tabletype === 4 || tabletype === 6) {
        if (
          !areSpecificFieldsEqual(
            optionList[position - 1],
            refDriverList[position - 1],
            tabletype
          )
        ) {
          const newOptionList = optionList.map((obj, index) => {
            if (index === position - 1) {
              return { ...obj, isChanged: true }
            }
            return obj
          })
          setDriverList(newOptionList)
        } else {
          const newOptionList = optionList.map((obj, index) => {
            if (index === position - 1) {
              return { ...obj, isChanged: false }
            }
            return obj
          })
          setDriverList(newOptionList)
        }
      }
      if (tabletype === 5) {
        if (
          !areSpecificFieldsEqual(
            optionList[position - 1],
            refTeamList[position - 1],
            tabletype
          )
        ) {
          const newOptionList = optionList.map((obj, index) => {
            if (index === position - 1) {
              return { ...obj, isChanged: true }
            }
            return obj
          })
          setTeamList(newOptionList)
        } else {
          const newOptionList = optionList.map((obj, index) => {
            if (index === position - 1) {
              return { ...obj, isChanged: false }
            }
            return obj
          })
          setTeamList(newOptionList)
        }
      }
    }
  }
  // searching and updating DriverId from backend
  const searchDriverId = (e, position, tabletype) => {
    if (tabletype === 3 || tabletype === 4 || tabletype === 6) {
      if (!driverList.map((obj) => obj.personId).includes(e.target.value)) {
        if (driverIdList[position]?.length) {
          let url = `${BASE_URL}/stats/drivers/${driverIdList[position]}`
          axios
            .get(url, {
              headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              if (res?.data?.success) {
                const tmpdriverData = res.data?.data
                let updatedDriverList = (
                  !isEdit ? driverList : checkedDriverList
                ).map((obj) =>
                  obj.position === position
                    ? {
                        ...obj,
                        personId: driverIdList[position],
                        personTeamLogo: tmpdriverData.teamLogo,
                        personNickname: tmpdriverData.personNickname,
                        personHeadshot: tmpdriverData.personHeadshot,
                        personFullName: tmpdriverData.personFullName,
                        teamName: tmpdriverData.teamName,
                      }
                    : {
                        ...obj,
                      }
                )
                setDriverList([...updatedDriverList])
                const title = 'Success'
                const detail = res.data?.message
                const type = 'success'
                notify(title, detail, type)
                checkIsUpdateFlag(e, position, tabletype, updatedDriverList)
              }
            })
        }
      } else {
        const title = 'Error'
        const detail =
          'Two or more drivers have the same ID. Check the list and try again'
        const type = 'error'
        notify(title, detail, type)
      }
      updateDriverList('', position)
    }
  }

  // searching and updating TeamId from backend
  const searchTeamId = (e, position, tabletype) => {
    if (!teamList.map((obj) => obj.teamId).includes(e.target.value)) {
      if (teamIdList[position]?.length) {
        let url = `${V2_URL}/stats/team/${teamIdList[position]}`
        axios
          .get(url, {
            headers: {
              'content-type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (res?.data?.success) {
              const tmpTeamData = res.data?.data
              let updatedTeamList = (!isEdit ? teamList : checkedTeamList).map(
                (obj) =>
                  obj.position === position
                    ? {
                        ...obj,
                        teamId: teamIdList[position],
                        teamLogo: tmpTeamData.teamLogo,
                        teamName: tmpTeamData.teamName,
                      }
                    : {
                        ...obj,
                      }
              )
              setTeamList([...updatedTeamList])
              const title = 'Success'
              const detail = res.data?.message
              const type = 'success'
              notify(title, detail, type)
              checkIsUpdateFlag(e, position, tabletype, updatedTeamList)
            }
          })
      }
    } else {
      const title = 'Error'
      const detail =
        'Two or more drivers have the same ID. Check the list and try again'
      const type = 'error'
      notify(title, detail, type)
    }
    updateTeamList('', position)
  }

  const handleImageRemoval = (id) =>
    updateTemplateData({ imgURL: '', imgName: '' }, id)

  const handleTemplateInputChange = (e) => {
    if (errors) {
      errors[`template-text-${e.target.id}`] = ''
      setErrors(errors)
    }
    checkValidInputLength(e.target.value, 25) &&
      updateTemplateData({ buttonText: e.target.value }, e.target.id)
  }

  const updateTemplateData = (updateFieldList, id) => {
    let updatedTemplate = { ...selectedTemplate }
    const updatedTemplateData = [...selectedTemplate.data].map((item) => {
      let result = item
      if (item.id == id) {
        result = { ...result, ...updateFieldList }
      }
      return result
    })
    updatedTemplate.data = updatedTemplateData
    setTemplate(updatedTemplate)
  }
  const validate = (selectedGamification) => {
    let fieldErrors = ''
    if (selectedGamification === 'Live') {
      fieldErrors = handleValidation(
        question,
        points,
        ttc,
        defaultStatus,
        selectedTemplate,
        startTime,
        endTime,
        eventStartTime,
        eventEndTime
      )
      setErrors(fieldErrors)
    }
    if (selectedGamification === 'Pre-event') {
      fieldErrors = handlePreEventValidation(question)
      setErrors(fieldErrors)

      if (startTime < new Date() && !isEdit) {
        fieldErrors = errors.question = 'Question is a required field.'
        setErrors(fieldErrors)
        setStartTimeError('Start Time should not be in past.')
      }
    }
    Object.keys(fieldErrors).length === 0 &&
      startTimeError.length === 0 &&
      onSubmit()
  }

  const onSubmit = () => {
    setEventLoader(true)
    exitCreateEdit(true)
    if (selectedGamificationLabel.title == 'Pre-event') {
      let updatedList = {
        eventId: props.eventId,
        question: question,
        questionTemplate: selectedPreEventTemplate,
        startTime: startTime,
        endTime: eventStartTime,
        answerOptions: {
          questionTemplateId: selectedPreEventTemplate?.id,
          optionsData: {
            options: getOptionList(selectedPreEventTemplate?.id),
          },
        },
        questionStatus: defaultStatus,
        questionType: 1,
        isEdit: isEdit,
      }
      updateQuestionList(updatedList)
    }

    if (selectedGamificationLabel.title == 'Live' && selectedTemplate.image) {
      const url = `${BASE_URL}gamification/question-image-url`
      let updatedList = {
        question: question,
        questionStatus: defaultStatus,
        startTime: startTime,
        endTime: endTime,
        points: points,
        ttc: ttc,
        questionTemplate: selectedTemplate,
      }
      let form_data = new FormData()
      form_data.append('optionImageA', selectedTemplate.data[0].imgURL)
      form_data.append('optionImageB', selectedTemplate.data[1].imgURL)
      form_data.append('optionImageAId', parseInt(selectedTemplate.data[0].id))
      form_data.append('optionImageBId', parseInt(selectedTemplate.data[1].id))

      axios
        .post(url, form_data, {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res?.data?.success) {
            selectedTemplate.data[0].imgURL = res.data.data[0].s3Url
            selectedTemplate.data[1].imgURL = res.data.data[1].s3Url
            setTemplate(selectedTemplate)
            updateQuestionList(updatedList)
          }
        })
    }
    if (selectedGamificationLabel.title == 'Live' && !selectedTemplate.image) {
      let updatedList = {
        eventId: props.eventId,
        question: question,
        questionTemplate: { selectedTemplate },
        startTime: startTime,
        answerOptions: {
          questionTemplateId: selectedTemplate?.id,
          optionsData: {
            options: [],
          },
        },
        questionStatus: defaultStatus,
        questionType: 2,
      }
      updateQuestionList(updatedList)
    }
  }

  return (
    <div className="col">
      <div className="row breadcrumb py-4 ms-1 mb-0">{breadcrumb}</div>
      <div className="create-poll p-3">
        <GamificationCommonHeader
          errors={errors}
          question={question}
          startTime={moment(startTime)}
          questionStartTime={startTime}
          isEdit={isEdit}
          points={points}
          selectedGamificationLabel={selectedGamificationLabel}
          preEventQuestionThresholdTime={preEventQuestionThresholdTime}
          handleInputChange={handleInputChange}
          selecteGamificationCategory={selecteGamificationCategory}
        />

        <div className="row start-time-label d-flex">
          <div className="col-3">
            Start Time
            <AiFillInfoCircle
              className="ps-1 default-icon"
              width={36}
              height={36}
              onClick={() => setStatusInfoModalShow(true)}
            />
          </div>
        </div>
        {statusOptions.map((item, index) => (
          <QuestionStatusMaping
            key={`inline-radio-${index}`}
            isEdit={isEdit}
            eventStartTime={eventStartTime}
            startTimeError={startTimeError}
            errors={errors}
            defaultStatus={defaultStatus}
            startTime={startTime}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            ttc={ttc}
            selectedGamificationLabel={selectedGamificationLabel}
            preEventQuestionThresholdTime={preEventQuestionThresholdTime}
            validateStartTime={validateStartTime}
            handleInputChange={handleInputChange}
            item={item}
            _item={buttonColorList[item]}
            __item={statusMapping[item]}
            index={index}
          />
        ))}

        <hr />
        {selectedGamificationLabel?.id === 0 ? (
          <PreEventGamification
            startTime={startTime}
            isEdit={isEdit}
            selectedPreEventTemplate={selectedPreEventTemplate}
            driverList={driverList}
            refDriverList={refDriverList}
            refTeamList={refTeamList}
            refTextList={refTextList}
            driverIdList={driverIdList}
            teamList={teamList}
            teamIdList={teamIdList}
            checkedDriverList={checkedDriverList}
            setCheckedDriverList={setCheckedDriverList}
            checkedTeamList={checkedTeamList}
            setCheckedTeamList={setCheckedTeamList}
            checkedTextOption={checkedTextOption}
            setCheckedTextOption={setCheckedTextOption}
            setDisableSaveOptions={setDisableSaveOptions}
            handleInputChange={handleInputChange}
            handleChange={handleChange}
            searchDriverId={searchDriverId}
            searchTeamId={searchTeamId}
          />
        ) : null}
        {/* {selectedGamificationLabel?.id === 1 ? (
          <LiveGamification
            errors={errors}
            selectedTemplate={selectedTemplate}
            updateImage={updateImage}
            handleInputChange={handleInputChange}
            onImageUpload={onImageUpload}
            handleImageRemoval={handleImageRemoval}
            handleTemplateInputChange={handleTemplateInputChange}
          />
        ) : null} */}

        <CreateEditFooter
          onExitClick={() => exitCreateEdit()}
          onDeleteClick={() => deletePollQuestion()}
          onSubmitClick={() => validate(selectedGamificationLabel.title)}
          isSaveDisable={
            [3, 4, 5, 6, 7].includes(selectedPreEventTemplate?.id) &&
            disableSaveOptions
          }
        />
      </div>

      {/* Modal */}
      <StartTimeStatusInfo
        show={statusInfoModalShow}
        onHide={() => setStatusInfoModalShow(false)}
      />
    </div>
  )
}

export default createEditPollQuestion
