import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { getToken, getUser } from '../Login/ManageUser'
import { RiDeleteBin5Fill } from 'react-icons/ri'
import { MdModeEditOutline } from 'react-icons/md'
import { Spinner } from 'react-bootstrap'
import { convertLocalTimeZone } from '../../constants/helper'
import { notify } from '../Notify/Notify'
import Sidebar from '../common/Sidebar'
import Button from '../common/Button/Button'
import Switch from 'react-switch'
import CreateEditSchedule from './CreateEditSchedule'
import axios from 'axios'
import DeleteScheduleModal from './DeleteScheduleModal'
import 'react-datepicker/dist/react-datepicker.css'
import './EventInfo.scss'

const Schedule = (props) => {
  // * useNavigate used for redirect to login.
  let navigate = useNavigate()
  // * Authentication variables
  const V2_URL = process.env.REACT_APP_API_V2_URL
  // * useLocation used to get the props.
  const { state } = useLocation()
  const token = getToken()
  const user = getUser()

  const [isEnabledSchedule, setIsEnabledSchedule] = useState(true)
  const [scheduleList, setScheduleList] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [editData, setEditData] = useState({})
  const [loader, setLoader] = useState(false)
  const [disableSave, setDisableSave] = useState(false)
  const [disableNext, setDisableNext] = useState(true)
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteScheduleId, setDeleteScheduleId] = useState(0)

  const handleExit = () => {
    navigate(`/events/eventinfo/`)
  }
  const handleNextClick = (page) => {
    navigate(`/events/eventinfo/edit-eventinfo/${page}`, {
      state: {
        eventInfoId: state.eventInfoId,
        eventinfoName: state.eventinfoName,
      },
    })
  }
  const handleBackClick = (page) => {
    navigate(`/events/eventinfo/edit-eventinfo/${page}`, {
      state: {
        eventInfoId: state.eventInfoId,
        eventinfoName: state.eventinfoName,
      },
    })
  }

  useEffect(() => {
    if (state != null && state.eventInfoId != null) {
      setDisableNext(false)
    }
    getEventInfoDetail()
    getScheduleList()
  }, [])

  //Edit Schedule
  const editSchedule = (value) => {
    setIsEdit(true)
    setEditData(value)
    setIsModalVisible(true)
  }

  // * Fetch EventInfo List
  const getScheduleList = (categoryId) => {
    setLoader(true)
    let url = `${V2_URL}eventInfo/${state.eventInfoId}`
    axios
      .get(url, {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.data != null) {
          setScheduleList(res.data.data?.EventInfoActivities)
        }
      })
      .finally(() => {
        setLoader(false)
      })
  }

  const getEventInfoDetail = () => {
    if (state != null && state.eventInfoId != null) {
      let url = `${V2_URL}eventinfo/${state.eventInfoId}`
      axios
        .get(url, {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setIsEnabledSchedule(res?.data?.data?.isScheduleEnabled)
        })
    }
  }

  const handleSave = () => {
    let form_data = new FormData()
    form_data.append('isScheduleEnabled', isEnabledSchedule)
    setDisableSave(true)
    let url = `${V2_URL}eventInfo/${state.eventInfoId}`
    axios
      .put(url, form_data, {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.data?.success) {
          const title = 'Success'
          const detail = res?.data?.message
          const type = 'success'
          notify(title, detail, type)
        }
        setDisableSave(false)
        setDisableNext(false)
      })
      .catch((err) => {
        setDisableSave(false)
      })
  }

  const deleteSchedule = (value) => {
    setDeleteModal(true)
    setDeleteScheduleId(value.id)
  }

  //Delete Schedule
  const deleteScheduleHandler = () => {
    setDeleteModal(false)
    setLoader(true)
    const url = `${V2_URL}eventInfoActivity/${deleteScheduleId}`
    axios
      .delete(url, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.data?.success) {
          const title = 'Success'
          const detail = res.data.message
          const type = 'success'
          notify(title, detail, type)
        }
      })
      .finally(() => {
        getScheduleList()
      })
  }

  return (
    <>
      {token && user ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-3 col-lg-2">
              <Sidebar
                currentSelected="Events"
                currentsubSelected="Event Info Pages"
              />
            </div>
            {isModalVisible ? (
              <CreateEditSchedule
                getScheduleList={getScheduleList}
                setIsModalVisible={setIsModalVisible}
                eventInfoName={state?.eventinfoName}
                eventInfoId={state?.eventInfoId}
                editData={editData}
                setEditData={setEditData}
                setIsEdit={setIsEdit}
                isEdit={isEdit}
              />
            ) : (
              <div className="col-6 col-lg-8 EventInfo-list">
                <div className="row mb-4">
                  <div className="breadcrumb">
                    <p className="Roboto_Black Event_Name">{`${
                      state?.eventinfoName.length > 0
                        ? `${state?.eventinfoName} > Schedule Page`
                        : ''
                    }  `}</p>
                  </div>
                  <div className="EventInfo-list-center-container p-5">
                    <div className="row">
                      <div className="col-6">
                        <div>
                          <label htmlFor="hidden" className="Font_Black">
                            Enable Schedule Page?
                          </label>
                          <p className="Font_Regular">
                            If enabled, will display a Schedule button on the
                            Event Info Page which navigates users to the
                            corresponding Schedule Page section of the app
                          </p>
                          <div className="row">
                            <div className="col-2">
                              <Switch
                                onChange={() =>
                                  setIsEnabledSchedule(!isEnabledSchedule)
                                }
                                checked={isEnabledSchedule}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={30}
                                width={58}
                                className="react-switch"
                                id="material-switch"
                                disabled={false}
                              />
                            </div>
                            <div className="col-6">
                              {isEnabledSchedule ? 'Enabled' : 'Disabled'}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-3 mt-3">
                        <div className="row EventInfo-list-header Font_Black Font_Quinary_Color">
                          <div className="col-1">ID</div>
                          <div className="col-4">Activity Group</div>
                          <div className="col-3">Start Time</div>
                          <div className="col-3">End Time</div>
                          <div className="col-1">Modify</div>
                        </div>
                        <hr className="row mt-3 mb-3" />
                        <div className="EventInfo-Schedule-list-table">
                          {loader ? (
                            <div className="text-center overflow-hidden">
                              <Spinner animation="border" variant="primary" />
                            </div>
                          ) : (
                            scheduleList?.length > 0 &&
                            scheduleList.map((schedule, index) => {
                              return (
                                <div
                                  key={index}
                                  className={`EventInfo-list-table-row d-flex align-items-center`}
                                >
                                  <div className="col-1">
                                    <b>{schedule.id}</b>
                                  </div>
                                  <div className="col-4 EventInfo-list-table-event-name">
                                    <b>{schedule.name}</b>
                                  </div>
                                  <div className="col-3">
                                    {convertLocalTimeZone(schedule.startTime)}
                                  </div>
                                  <div className="col-3">
                                    {convertLocalTimeZone(schedule.endTime)}
                                  </div>
                                  <div className="col-1">
                                    <MdModeEditOutline
                                      className="default-icon"
                                      onClick={() => editSchedule(schedule)}
                                    />
                                    <RiDeleteBin5Fill
                                      className="default-icon ml-3"
                                      onClick={() => deleteSchedule(schedule)}
                                    />
                                  </div>
                                </div>
                              )
                            })
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mb-1">
                      <div className="col-2 p-0 mb-3">
                        <Button
                          name="+ Add Activity"
                          className="col-9"
                          onClick={() => setIsModalVisible(true)}
                        />
                      </div>
                      <div className="row">
                        <div className="col-3 col-lg-2">
                          <Button name="Exit" onClick={() => handleExit()} />
                        </div>
                        <div className="col-3 col-lg-2 ms-auto">
                          <Button
                            name="Back"
                            onClick={() => handleBackClick('core-content')}
                          />
                        </div>
                        <div className="col-3 col-lg-2">
                          <Button
                            name="Next"
                            disabled={disableNext}
                            onClick={() => handleNextClick('articles')}
                          />
                        </div>
                        <div className="col-3 col-lg-2">
                          <Button
                            name="Save"
                            onClick={() => handleSave()}
                            disabled={disableSave}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <DeleteScheduleModal
              deleteModal={deleteModal}
              onHide={() => setDeleteModal(false)}
              deleteScheduleHandler={deleteScheduleHandler}
            />
          </div>
        </div>
      ) : (
        navigate('/login')
      )}
    </>
  )
}
export default Schedule
