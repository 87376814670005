import React from 'react'
import FormField from '../common/FormField'
import Button from '../common/Button/Button'
import { convertLocalTimeZone } from '../../constants/helper'

const EventsNotification = (props) => {
  const {
    eventID,
    selectedNotification,
    getStatsEvent,
    statsEvent,
    handleInputChange,
    eventButtonValidation,
    loadingAPI,
  } = props

  return (
    <>
      {selectedNotification?.id == 1 && (
        <div className="mt-4">
          <div className="row">
            <div className="col-5">
              <FormField
                className="col-8 col-lg-7 m-0"
                value={eventID ?? ''}
                type="text"
                placeholder="Event ID#"
                paraVisibility={true}
                characterLeftVisible={false}
                onChange={(e) => handleInputChange(e, 'eventID')}
              />
              <Button
                type="button"
                className="send-button mt-4"
                name="Check Event"
                onClick={() => getStatsEvent(eventID)}
                disabled={
                  eventButtonValidation || loadingAPI === true ? true : false
                }
              />
            </div>
            <div className="col-7 mt-4">
              <div>
                <b>Category: </b>
                {statsEvent?.categoryName ?? ''}
              </div>
              <div>
                <b>Event name: </b>
                {statsEvent?.title ?? ''}
              </div>
              <div>
                <b>Start time: </b>
                {statsEvent?.startTime
                  ? convertLocalTimeZone(statsEvent.startTime)
                  : ''}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default EventsNotification
