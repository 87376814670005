import React, { useState, useEffect } from 'react'
import { getToken } from '../Login/ManageUser'
import { notify } from '../Notify/Notify'
import {
  ModerationList,
  moderationStatusCode,
} from '../../constants/enumTypes.constants'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import Spinner from 'react-bootstrap/Spinner'
import Select from 'react-select'
import FormField from '../common/FormField'
import ImageUploader from '../common/ImageUploader/ImageUploader'
import Button from '../common/Button/Button'
import DeleteModal from './DeleteModal'
import StatusModal from './StatusModal'
import './Moderation.scss'

const EditUser = (props) => {
  const {
    setIsModalVisible,
    editData,
    setEditData,
    setIsEdit,
    isEdit,
    getUserList,
    selectedModerationLabel,
    setKeyWords,
  } = props
  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [userName, setUserName] = useState('')
  const [userBio, setUserBio] = useState('')
  const [imgUrl, setImgUrl] = useState()
  const [imageName, setImageName] = useState()
  const [imageDisplay, setImageDisplay] = useState()
  const [nameEdit, setNameEdit] = useState('')
  const [userNameEdit, setUserNameEdit] = useState('')
  const [userBioEdit, setUserBioEdit] = useState('')
  const [imgUrlEdit, setImgUrlEdit] = useState()
  const [isSaved, setIsSaved] = useState(false)
  const [isCanceled, setIsCanceled] = useState(false)
  const [errors, setErrors] = useState({})
  const [imgState, setImgState] = useState(null)
  const [disableEdit, setDisableEdit] = useState(true)
  const [disableSave, setDisableSave] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState({})
  const [deleteModal, setDeleteModal] = useState(false)
  const [statusModal, setStatusModal] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const [statusData, setStatusData] = useState()
  const handleCloseInfo = () => setShowInfo(false)
  const handleInfo = () => setShowInfo(true)

  // * Authentication token
  const V2_URL = process.env.REACT_APP_API_V2_URL
  let token = getToken()

  useEffect(() => {
    if (isEdit) {
      fetchUserData(editData)
    }
  }, [])

  const onImageChange = (picture) => {
    var maxLength = 5
    if (!disableEdit) {
      if (errors) {
        errors.imgState = ''
        setErrors(errors)
      }
      if (picture[0].file) {
        setImgState(picture[0].file)
        setImageName(picture[0].file.name)
        if (picture[0].file.name.length > maxLength) {
          setImageDisplay(picture[0].file.name.substr(0, maxLength) + '...')
        } else {
          setImageDisplay(picture[0].file.name)
        }
      }
    }
  }

  const handleRemove = () => {
    if (!disableEdit) {
      setImgState(null)
      setImageName('')
    }
  }

  const handleUserBio = (e) => {
    var maxLengthArea = 250

    if (!disableEdit) {
      if (e.target.value.length >= 0) {
        if (
          e.target.value.length > maxLengthArea &&
          e.target.value.length > 0
        ) {
          e.target.value = e.target.value.substr(0, maxLengthArea)
        }
        setUserBio(e.target.value)
      }
    }
  }
  const handleSelectStatus = (data) => {
    setStatusData(data);
    if (data?.label !== selectedStatus?.label) {
      setStatusModal(true);
    }
  }

  const handleNameChange = (e) => {
    const maxLength = 30
    const { value } = e.target

    if (!disableEdit) {
      if (value.length > maxLength) {
        setName(value.substring(0, maxLength))
      } else {
        //regex:  Can only contain letters and spaces. and should be start with letter and also can be empty.
        const regex = /^(?:[a-zA-Z][a-zA-Z\s]*)?$/
        if (regex.test(value)) {
          setName(value)
        }
      }
      if (errors) {
        errors.name = ''
        setErrors(errors)
      }
    }
  }

  // * Hanlde UserName
  const handleUserName = (e) => {
    var maxLength = 24
    const { value } = e.target

    if (!disableEdit) {
      if (value.length > maxLength) {
        setUserName(value.substring(0, maxLength))
      } else {
        //regex: Can only contain letters, numbers, underscores, hyphens, and periods.
        const regex = /^(?:[a-zA-Z0-9_.-]*)?$/
        if (regex.test(value)) {
          setUserName(value)
        }
      }
      if (errors) {
        errors.userName = ''
        setErrors(errors)
      }
    }
  }
  const handleValidation = (name, userName) => {
    let error = {}
    if (!name || name == '') {
      error.name = 'Name is required'
    }
    if (!userName || userName === '') {
      error.userName = 'User Name is required'
    }
    return error
  }

  const statusMapping = (id) => {
    const moderationStatus = moderationStatusCode.find((item) => item.id === id)
    setSelectedStatus({
      label:
        moderationStatus.status.charAt(0).toUpperCase() +
        moderationStatus.status.slice(1),
      value: moderationStatus.status,
    })
  }

  const fetchUserData = (editData) => {
    let url = `${V2_URL}user/${editData.id}`
    axios
      .get(url, {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.data != null) {
          setId(editData.id)
          setName(res.data.data?.name)
          setUserBio(res.data.data?.about_me ? res.data.data?.about_me : '')
          setUserName(res.data.data?.username)
          setImgState(res.data.data?.profile_image)
          setImgUrl(res.data.data?.profile_image)
          setNameEdit(res.data.data?.name)
          setUserBioEdit(res.data.data?.about_me ? res.data.data?.about_me : '')
          setUserNameEdit(res.data.data?.username)
          setImgUrlEdit(res.data.data?.profile_image)
          statusMapping(res.data.data?.status)
        }
      })
  }

  const cancelClicked = () => {
    setDisableEdit(!disableEdit)
    setIsCanceled(true)

    setName(nameEdit)
    setUserBio(userBioEdit)
    setUserName(userNameEdit)
    setImgUrl(imgUrlEdit)
    setErrors({})
  }

  const handleCheck = (username) => {
    let url = `${V2_URL}checkUsername/${id}`
    setDisableSave(true)
    let params = { username: username }
    axios
      .get(
        url,
        { params },
        {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res?.data?.success === true) {
          const title = 'Success'
          const detail = res?.data?.message
          const type = 'success'
          notify(title, detail, type)
        }
      })
      .finally(() => {
        setDisableSave(false)
      })
  }

  const handleSave = () => {
    var fieldErrors = handleValidation(name, userName)
    setErrors(fieldErrors)
    if (Object.keys(fieldErrors)?.length === 0) {
      let form_data = new FormData()
      form_data.append('name', name)
      form_data.append('username', userName)
      form_data.append('about_me', userBio)
      if (imgState != imgUrl) {
        form_data.append('image', imgState)
      }
      let url = `${V2_URL}user/${id}`
      setDisableSave(true)
      axios
        .put(url, form_data, {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res?.data?.success === true) {
            const title = 'Success'
            const detail = res?.data?.message
            const type = 'success'
            notify(title, detail, type)
            setEditData({})
            setIsEdit(false)
            setIsSaved(true)
            setNameEdit(name)
            setUserBioEdit(userBio ? userBio : '')
            setUserNameEdit(userName)
            setImgUrlEdit(imgUrl)
          }
        })
        .finally(() => {
          setDisableSave(false)
          setDisableEdit(!disableEdit)
        })
        .catch(() => {
          setName(nameEdit)
          setUserBio(userBioEdit)
          setUserName(userNameEdit)
          setImgUrl(imgUrlEdit)
        })
    }
  }

  const editClicked = () => {
    setDisableEdit(!disableEdit)
    setIsCanceled(false)
  }

  const handleExit = () => {
    setEditData({})
    setIsEdit(false)
    setIsModalVisible(false)
    getUserList(1, selectedModerationLabel?.type)
    setKeyWords('')
  }

  return (
    <div className="col-6 col-lg-8">
      <div className="row mt-4">
        <div className="breadcrumb">
          Moderation &gt; {userNameEdit}({id})
        </div>
        <div className="moderation-wrapper p-5">
          <div className="row d-flex mb-3">
            <div className="breadcrumb-profile col-3 mr-5 ml-2 ">
              Profile
              <svg
                onClick={handleInfo}
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-exclamation-circle-fill ms-1 mb-1"
                viewBox="0 0 16 16"
                style={{ cursor: 'pointer' }}
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
              </svg>
            </div>

            {disableEdit && (
              <div className="col-5">
                <Button
                  name="Edit"
                  className="col-3 ml-5"
                  onClick={() => editClicked()}
                />
              </div>
            )}
          </div>
          <div className="d-flex tickets-top-section">
            <div className="col-6 pe-5">
              <FormField
                value={name}
                id="name"
                label="Name"
                name="name"
                type="text"
                placeholder="Enter Name"
                onChange={(e) => {
                  handleNameChange(e)
                }}
                characterLeft={30 - Number(name?.length)}
                characterLeftVisible={true}
                disabled={disableEdit}
              />
              {errors && !isCanceled && (
                <div className="input-feedback">{errors.name}</div>
              )}
              <div className="d-flex">
                <div className=" row flex-grow-1">
                  <div className="col-8">
                    <FormField
                      id="userName"
                      value={userName}
                      label="Username"
                      name="userName"
                      type="text"
                      onChange={(e) => handleUserName(e)}
                      characterLeft={24 - Number(userName?.length)}
                      characterLeftVisible={true}
                      disabled={disableEdit}
                    />
                  </div>
                  <div className="col-3 ml-3 mt-4">
                    <Button
                      name="Check"
                      onClick={() => handleCheck(userName)}
                      disabled={disableEdit}
                    />
                  </div>
                </div>
              </div>
              <div>
                {errors && !isCanceled && (
                  <div className="input-feedback">{errors.userName}</div>
                )}
              </div>
              <div>
                <FormField
                  value={userBio}
                  id="userBio"
                  label="User Bio"
                  name="event_description"
                  type="textarea"
                  placeholder="Enter Bio"
                  onChange={handleUserBio}
                  characterLeft={250 - Number(userBio?.length)}
                  characterLeftVisible={true}
                  disabled={disableEdit}
                />
              </div>

              <div>
                <label htmlFor="image" className="Font_Black d-flex">
                  User Profile Image
                </label>
                <ImageUploader
                  value={imgState}
                  imgUrl={imgUrl}
                  onChange={onImageChange}
                  imageName={imageName}
                  imageDisplay={imageDisplay}
                  onClick={handleRemove}
                  disabled={disableSave}
                />
              </div>
              {!disableEdit && (
                <div className="d-flex">
                  <div className="col-5"></div>
                  <div className="col-3">
                    <Button name="Cancel" onClick={() => cancelClicked()} />
                  </div>
                  <div className="col-4 pr-4">
                    <Button
                      name="Save Details"
                      onClick={() => handleSave()}
                      disabled={disableSave}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="row col-6 ticket-bottom-section">
              <div className="status">
                <label className="Font_Black d-flex mt-3">User Status</label>
                <div className="">
                  <Select
                    options={ModerationList}
                    placeholder="Select User Status"
                    value={selectedStatus}
                    onChange={handleSelectStatus}
                    isOptionSelected={true}
                  />
                </div>
                <label className="Font_Black d-flex mt-5">User Delete</label>
                <p>
                  WARNING: the following action deletes the User immediately
                </p>
                <Button
                  name="Delete Account"
                  className="w-25"
                  onClick={() => setDeleteModal(true)}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6"></div>
          </div>
          <div className="d-flex mt-5 mb-1">
            <div className="flex-grow-1">
              <Button
                name="Exit"
                className="w-25"
                onClick={() => handleExit()}
              />
            </div>
          </div>
        </div>
        <Modal
          show={showInfo}
          onHide={handleCloseInfo}
          animation={false}
          size="md"
        >
          <Modal.Body>
            <div className="p-2">
              <div className="row">
                <p>
                  <b>Name</b> - Can only contain letters and spaces. Capped at
                  30 characters and must start with a letter.
                </p>
              </div>
              <div className="row">
                <p>
                  <b>Username</b> - Must be unique. Can only contain letters,
                  numbers, underscores, hyphens, and periods. Capped at 24
                  characters.
                </p>
              </div>
              <div className="row">
                <p>
                  <b>Bio</b> - Capped at 250 characters.
                </p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <DeleteModal
          deleteModal={deleteModal}
          onHide={() => setDeleteModal(false)}
          userId={id}
          setEditData={setEditData}
          setIsEdit={setIsEdit}
          setIsModalVisible={setIsModalVisible}
          selectedModerationLabel={selectedModerationLabel}
          getUserList={getUserList}
          setKeyWords={setKeyWords}
        />
        <StatusModal
          statusModal={statusModal}
          onHide={() => setStatusModal(false)}
          data={statusData}
          id={id}
          editData={editData}
          setSelectedStatus={setSelectedStatus}
          setStatusModal={setStatusModal}
        />
      </div>
    </div>
  )
}
export default EditUser
