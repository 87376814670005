import React, { useState } from 'react'
import Spinner from 'react-bootstrap/Spinner'
import { AiFillInfoCircle } from 'react-icons/ai'
import { MdModeEditOutline, MdDelete } from 'react-icons/md'
import { getUser } from '../../Login/ManageUser'
import {
  userButtonColorList,
  userStatusMapping,
  userStatusColorList,
  userRoleMapping,
} from '../../../constants/enumTypes.constants'
import PermissionModel from '../UserModals/PermissionModal'
import UserStatusModal from '../UserModals/UserStatusModal'

require('./AdminUserList.scss')

const adminUserList = (props) => {
  const activeUser = getUser()
  const {
    userList,
    loader,
    onRowChange,
    onEditUserClick,
    selectedUser,
    setDeleteModalVisible,
  } = props
  const [permissionModal, setPermissionModal] = useState(false)
  const [userStatusModal, setUserStatusModal] = useState(false)

  const showDeleteButton = (user) => {
    if (activeUser.id === user.id) {
      return false
    }
    return activeUser?.id === 1
      ? user?.type >= activeUser?.type
      : user?.type > activeUser?.type
  }

  return (
    <div className="user-list-container mx-4">
      <div className="row user-list-header p-4">
        <div className="col-3 col-lg-2">Username</div>
        <div className="col-4 col-lg-3">Email</div>
        <div className="col-2 col-lg-3 ps-0">
          Permission Level
          <AiFillInfoCircle
            className="ps-1 default-icon"
            onClick={() => setPermissionModal(true)}
          />
        </div>
        <div className="col-2 col-lg-3 px-0">
          Account Status
          <AiFillInfoCircle
            className="ps-1 default-icon"
            onClick={() => setUserStatusModal(true)}
          />
        </div>
        <div className="col-1 ps-0">Modify</div>
      </div>

      <hr className="row mx-2" />
      {loader ? (
        <div className="text-center">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div className="user-body p-4">
          {[...userList]?.length > 0 &&
            [...userList].map((user, index) => (
              <div
                key={index}
                className={`row py-2 ${
                  activeUser?.id === user?.id ? 'active-user' : ''
                } ${selectedUser?.id === user?.id ? 'active-row' : ''}`}
                onClick={() => onRowChange(user?.id)}
              >
                {/* Username */}
                <div className="col-3 col-lg-2">
                  {user.first_name + ' ' + user.last_name + ' '}
                </div>

                {/* Email */}
                <div className="col-4 col-lg-3">{user.email}</div>

                {/* Permisison */}
                <div className="col-2 col-lg-3 pb-1">
                  <div
                    className="badge"
                    style={{
                      backgroundColor: userButtonColorList[user.type],
                    }}
                  >
                    {userRoleMapping[user.type]}
                  </div>
                </div>
                {/* Account Staus */}
                <div className="col-2 col-lg-3 pb-1">
                  <div
                    className="badge"
                    style={{
                      backgroundColor: userStatusColorList[user.status],
                    }}
                  >
                    {userStatusMapping[user.status]}
                  </div>
                </div>
                {/* Modify */}
                <div className="col-1">
                  {(activeUser?.type === 1 ||
                    activeUser.id === user.id ||
                    user?.type > activeUser?.type) && (
                    <MdModeEditOutline
                      className="default-icon"
                      onClick={() => onEditUserClick(user)}
                    />
                  )}
                  {showDeleteButton(user) && (
                    <MdDelete
                      className="default-icon"
                      onClick={() => setDeleteModalVisible(true)}
                    />
                  )}
                </div>
              </div>
            ))}
        </div>
      )}

      {/* Modals */}
      <PermissionModel
        show={permissionModal}
        onHide={() => setPermissionModal(false)}
      />
      <UserStatusModal
        show={userStatusModal}
        onHide={() => setUserStatusModal(false)}
      />
    </div>
  )
}
export default adminUserList
