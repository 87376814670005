import React, { useState, useEffect } from 'react'
require('./ColorPicker.scss')

const ColorPicker = (props) => {
  const [color, setColor] = useState('#ffffff')
  const handleChange = (e) => {
    if (e.target.value) {
      setColor(e.target.value)
      props.passFontColor(e.target.value)
    }
  }
  useEffect(() => {
    setColor(props.fontColor)
  }, [props.fontColor])
  return (
    <div className="mt-1xl-5 mt-xl-0">
      <input
        className="Color_Picker"
        style={{ backgroundColor: color }}
        type="color"
        id="favcolor"
        name="favcolor"
        value={color}
        onChange={handleChange}
        disabled={props.disabled}
      />
    </div>
  )
}

export default ColorPicker
