import moment from 'moment-timezone'
export const formatAMPM = (formateDate) => {
  /* 
    Argument:   date: date object -> (Wed Feb 02 2022 11:50:02 GMT+0530 (India Standard Time))               
    Return:     String -> '11:50:02 AM' (hh:mm:ss AM/PM)
  */
  let date = new Date(formateDate)
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let seconds = date.getSeconds()
  const ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours ? hours : 12
  hours = hours < 10 ? '0' + hours : hours
  minutes = minutes < 10 ? '0' + minutes : minutes
  seconds = seconds < 10 ? '0' + seconds : seconds
  const strTime = hours + ':' + minutes + ':' + seconds + ' ' + ampm
  return strTime
}

export const addTimeInDate = (startDate, ttc) => {
  /* 
    Argument:   startDate : Date object -> (Wed Feb 02 2022 12:00:00 GMT+0530 (India Standard Time))
                ttc: String -> "00:10:30" (hh:mm:ss)   
    Return:     endDate : Date object -> (Wed Feb 02 2022 12:10:30 GMT+0530 (India Standard Time))
  */

  if (ttc.length === 8) {
    const [hh, mm, ss] = ttc.split(':')
    const addedSecond = new Date(new Date(startDate).getTime() + ss * 1000)
    const addedMinute = new Date(addedSecond.getTime() + mm * 60 * 1000)
    const endDate = new Date(addedMinute.getTime() + hh * 60 * 60 * 1000)
    return endDate
  }
  return new Date(startDate)
}

export const getDisplayDateTime = (date) => {
  /* 
    Argument:   date: date object -> (Wed Feb 02 2022 11:50:02 GMT+0530 (India Standard Time))       
    Return:     String -> '11:50:02 AM IST' (hh:mm:ss AM/PM Timezone)
  */
  return formatAMPM(date) + ' ' + abbrTimeZone(new Date())
}

export const abbrTimeZone = (date) => {
  /* 
    Argument:   date: date object -> (Wed Feb 02 2022 11:50:02 GMT+0530 (India Standard Time))       
    Return:     String -> 'IST' (Timezone)
  */
  let abbr = ''
  const timezone = String(String(date).split('(')[1]).split(')')[0].split(' ')
  timezone.map((x) => (abbr = abbr + x[0].toUpperCase()))
  return abbr
}

export const convertLocalTimeZone = (date) => {
  const userTimeZone = moment.tz.guess()
  const formattedDate = moment
    .utc(date)
    .tz(userTimeZone)
    .format('YYYY-MM-DD [at] hh:mm:ss A z')

  return formattedDate
}

export const checkValidInputLength = (text, maxLength) => {
  /* 
    Argument:   text : String -> 'I am testing the length of this string is it greater then maxlength'
                maxLength: Int -> 40    
    Return:     Boolean : false (Because length is more then 40)
  */
  return text.length >= 0 ? (text.length < maxLength ? true : false) : false
}

export const sortenStringName = (text, maxLength) => {
  /* 
    Argument:   text : String -> 'I am testing the length of this string is it greater then maxlength'
                maxLength: Int -> 40    
    Return:     String : 'I am testing the length...'
  */
  return text.length > 0 ? `${text.substr(0, maxLength)}...` : text
}

export const getStatusColor = (status = '') => {
  if (status.toUpperCase() == 'LIVE') {
    return '#F2421D'
  } else if (status.toUpperCase() == 'FINAL') {
    return ' #4B4B4B'
  } else if (status.toUpperCase() == 'PENDING') {
    return ' #708CF1'
  } else if (status.toUpperCase() == 'SCHEDULED') {
    return '#BEBEBE'
  } else if (status.toUpperCase() == 'HIDDEN') {
    return '#FFBD3C'
  }
}
