import React, { useState, useEffect } from 'react'
import { initializeApp } from 'firebase/app'
import { getFirestore, collection, getDocs } from '@firebase/firestore'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { intialObjects } from '../../constants/enumTypes.constants'
import { getToken } from '../Login/ManageUser'
import { notify } from '../Notify/Notify'
import axios from 'axios'
import Switch from 'react-switch'
import Select from 'react-select'
import ImageUploader from '../common/ImageUploader/ImageUploader'
import FormField from '../common/FormField'
import Button from '../common/Button/Button'
import SectorFinalizeModal from './SectorFinalizeModal'
import './Sectors.scss'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

const CreateEditSectors = (props) => {
  const {
    setIsModalVisible,
    getSectorList,
    setPageNumber,
    editData,
    setEditData,
    setIsEdit,
    isEdit,
  } = props
  const [seasonsList, setSeasonsList] = useState([])
  const [seasonId, setSeasonId] = useState()
  const [sectorName, setSectorName] = useState('')
  const [isRunning, setIsRunning] = useState(false)
  const [selectedSeason, setSelectedSeason] = useState('')
  const [imgUrl, setImgUrl] = useState()
  const [imgState, setImgState] = useState(null)
  const [imageName, setImageName] = useState()
  const [imageDisplay, setImageDisplay] = useState()
  const [selectedCategory, setSelectedCategory] = useState(intialObjects)
  const [universal, setUniversal] = useState(intialObjects)
  const [categories, setCategories] = useState([])
  const [disableSave, setDisableSave] = useState(false)
  const [buttonClass, setButtonClass] = useState({})
  const [errors, setErrors] = useState({})
  const [finalizeModal, setFinalizeModal] = useState(false)

  // * Authentication token
  const BASE_URL = process.env.REACT_APP_API_BASE_URL
  const V2_URL = process.env.REACT_APP_API_V2_URL
  let token = getToken()

  // imported to fetch stats's category
  const app = initializeApp(firebaseConfig)
  const auth = getAuth(app)
  const db = getFirestore(app)

  useEffect(() => {
    signInWithEmailAndPassword(
      auth,
      process.env.REACT_APP_FIREBASE_EMAIL,
      process.env.REACT_APP_FIREBASE_PASSWORD
    ).then((userCredential) => {
      // * Signed in to firebase
      const user = userCredential?.user
      user?.accessToken && fetchCategory()
    })
  }, [])

  useEffect(() => {
    getSeasonsList(seasonId)
  }, [seasonId])

  useEffect(() => {
    if (editData?.isLocked === true) {
      setButtonClass({ class: 'final', name: 'Finalized' })
    }
    if (editData?.isLocked === false) {
      setButtonClass({ class: '', name: 'Finalize Sector' })
    }
  }, [editData])

  useEffect(() => {
    getSeasonsList(selectedCategory?.id)
  }, [selectedCategory])

  useEffect(() => {
    if (isEdit) {
      setSectorName(editData?.name)
      setSeasonId(editData?.seasonId)
      setIsRunning(editData?.isRunning)
      if (editData?.image) {
        setImgUrl(editData?.image)
        setImgState(editData?.image)
      }
    }
  }, [categories])

  const categoryListRef = collection(db, 'Categories')
  const fetchCategory = async () => {
    const data = await getDocs(categoryListRef)
    const categoryData = data.docs.map((doc) => ({
      ...doc.data(),
      ...intialObjects,
    }))
    // * Get all the stats file
    getStatsFiles([...categoryData])
  }

  const getSeasonsList = (categoryId) => {
    let url = `${V2_URL}eventSeasons`
    if (categoryId) {
      axios
        .get(url, {
          params: {
            categoryId: 35,
          },
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.data != null) {
            const SeasonsList = res.data.data.map((res) => ({
              label: res.name,
              value: res.id.toString(),
            }))
            setSeasonsList(SeasonsList)
            setSelectedSeason(
              [...SeasonsList].filter(
                (obj) => parseInt(obj.value) == parseInt(editData?.seasonId)
              )?.[0]
            )
          }
        })
    }
  }

  const handleSelectSeason = (data) => {
    setSelectedSeason(data)
    if (errors) {
      errors.selectedSeason = ''
      setErrors(errors)
    }
  }

  const handleValidation = (sectorName, selectedSeason) => {
    let error = {}
    if (!sectorName || sectorName == '') {
      error.sectorName = 'Sector name is required'
    }
    if (!selectedSeason?.value | (selectedSeason?.value == '')) {
      error.selectedSeason = 'Season Selection is required'
    }

    return error
  }

  const handleExit = () => {
    setEditData({})
    setIsEdit(false)
    setIsModalVisible(false)
    getSectorList(1, selectedCategory?.id)
    setPageNumber(1)
  }

  const handleRemove = () => {
    setImgState(null)
    setImgUrl('')
    setImageName('')
  }

  const onImageChange = (picture) => {
    var maxLength = 5
    if (picture[0].file) {
      setImgState(picture[0].file)
      setImageName(picture[0].file.name)
      if (picture[0].file.name.length > maxLength) {
        setImageDisplay(picture[0].file.name.substr(0, maxLength) + '...')
      } else {
        setImageDisplay(picture[0].file.name)
      }
    }
  }

  const FinalizeSector = () => {
    setFinalizeModal(false)
    let url = `${V2_URL}sector/finalize`
    const params = {
      sectorId: editData.id,
    }
    axios
      .post(url, params, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setButtonClass({ class: 'final', name: 'Finalized' })
        const title = 'Success'
        const detail = res?.data?.message
        const type = 'success'
        notify(title, detail, type)
      })
  }

  const handleSave = (flag) => {
    var fieldErrors = handleValidation(sectorName, selectedSeason)
    setErrors(fieldErrors)
    if (Object.keys(fieldErrors)?.length === 0) {
      if (!isEdit) {
        let url = `${V2_URL}sector`
        setDisableSave(true)
        let form_data = new FormData()
        form_data.append('name', sectorName)
        form_data.append('seasonId', parseInt(selectedSeason?.value))
        form_data.append('description', null)
        form_data.append('isRunning', isRunning)
        form_data.append('image', imgState)
        axios
          .post(url, form_data, {
            headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (res?.data?.success === true) {
              const title = 'Success'
              const detail = res?.data?.message
              const type = 'success'
              notify(title, detail, type)
              setIsModalVisible(false)
            }
          })
          .finally(() => {
            setDisableSave(false)
            getSectorList(1, selectedCategory?.id)
            setPageNumber(1)
          })
      } else {
        let form_data = new FormData()
        form_data.append('name', sectorName)
        form_data.append('seasonId', parseInt(selectedSeason?.value))
        form_data.append('description', null)
        form_data.append('isRunning', isRunning)
        if (imgState != imgUrl) {
          form_data.append('image', imgState)
        }

        let url = `${V2_URL}sector/${editData?.id}`
        setDisableSave(true)
        axios
          .put(url, form_data, {
            headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (res?.data?.success === true) {
              const title = 'Success'
              const detail = res?.data?.message
              const type = 'success'
              notify(title, detail, type)
              setIsModalVisible(false)
            }
          })
          .finally(() => {
            setEditData({})
            setIsEdit(false)
            setDisableSave(false)
            getSectorList(1)
            setPageNumber(1)
          })
      }
    }
  }

  const handleNameChange = (e) => {
    const maxLength = 45
    const { value } = e.target
    if (value.length > maxLength) {
      setSectorName(value.substring(0, maxLength))
    } else {
      setSectorName(value)
    }
    if (errors) {
      errors.sectorName = ''
      setErrors(errors)
    }
  }

  const handleCategoryClick = (id) => {
    const newSelectedCategory = [...categories].find(
      (item) => Number(item?.id) === Number(id)
    )
    setSelectedCategory(newSelectedCategory)
    getSeasonsList(newSelectedCategory?.id)
    setSelectedSeason([])
  }

  const getStatsFiles = (categories) => {
    const url = `${BASE_URL}stats-file-download`
    axios
      .post(
        url,
        {},
        {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res?.data?.success) {
          const resData = res?.data?.data
          // * Set universal data
          setUniversal({
            ...universal,
            fileName: resData?.universal?.fileName ?? '',
            lastUpdated: resData?.universal?.lastUpdated ?? null,
            downloadFilePath: resData?.universal?.downloadFilePath ?? '',
            isDisable: true,
          })
          // * Set category data
          let updatedResult = [...categories].map((o1) => {
            let category = resData?.category.find((o2) => {
              return o1?.id === o2?.categoryId
            })
            if (category) {
              return {
                ...o1,
                ...category,
              }
            }
            return o1
          })
          setCategories([...updatedResult])
          setSelectedCategory([...updatedResult]?.[0])
        }
      })
  }

  return (
    <div className="col-6 col-lg-8">
      <div className="row mt-4">
        <div className="breadcrumb">Sectors &gt; {sectorName}</div>
        <div className="eventGroup-wrapper p-5">
          <div className="row mb-5">
            <div className="col-6">
              <div className="col-9 p-0">
                <FormField
                  value={sectorName}
                  id="sectorName"
                  label="Name"
                  name="sectorName"
                  type="text"
                  placeholder="Enter Sector Name"
                  onChange={(e) => {
                    handleNameChange(e)
                  }}
                  disabled={false}
                />
                {errors && (
                  <div className="input-feedback">{errors.sectorName}</div>
                )}
              </div>
            </div>
            <div className="col-6 p-0">
              <label htmlFor="image" className="Font_Black d-flex">
                Gamification Image (Optional)
              </label>
              <p>
                Upload a horizontal image 315x250 for use when viewing the
                Sector in the app
              </p>
              <ImageUploader
                value={imgState}
                imgUrl={imgUrl}
                imageName={imageName}
                imageDisplay={imageDisplay}
                onChange={onImageChange}
                onClick={handleRemove}
                isOptionalClose={imgUrl?.length > 0 ? true : false}
              />
            </div>

            <div className="row">
              <div className="col-6">
                {/* <label htmlFor="categories" className="Font_Black">
                  Category
                </label>
                <div>
                  {loader ? (
                    <div className="text-center overflow-hidden">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  ) : (
                    [...categories]?.map((key) => (
                      <div
                        className={`categories-label btn ${
                          Number(selectedCategory?.id) === Number(key?.id)
                            ? 'active'
                            : ''
                        }`}
                        key={`category-${key?.id}`}
                        onClick={() => handleCategoryClick(Number(key?.id))}
                      >
                        <div>
                          <img src={key?.imgURL} alt="" height={20} />
                        </div>
                        <div className="mt-2">{key?.category}</div>
                      </div>
                    ))
                  )}
                </div> */}
                <div className="col-9 p-0">
                  <label className="Font_Black d-flex">Season</label>
                  <Select
                    options={seasonsList}
                    placeholder="Select Season"
                    value={selectedSeason}
                    isSearchable={true}
                    onChange={handleSelectSeason}
                    isOptionSelected={true}
                  />
                  {errors && (
                    <div className="input-feedback">
                      {errors.selectedSeason}
                    </div>
                  )}
                </div>
                {Object.keys(editData).length > 0 && (
                  <div>
                    <label htmlFor="hidden" className="Font_Black mt-3">
                      Gamification
                    </label>
                    <div className="col-4 p-0">
                      <Button
                        className={buttonClass.class}
                        name={buttonClass.name}
                        onClick={() =>
                          buttonClass?.name === 'Finalize Sector' &&
                          setFinalizeModal(true)
                        }
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="col-6">
                <label htmlFor="hidden" className="Font_Black mt-3">
                  Gamification Running?
                </label>
                <p className="Font_Regular ">
                  If ENABLED, this Sector will appear in the app.
                  <br></br>
                </p>
                <p className=" Error_Color Font_Regular ">
                  <b>Note: </b>There can only be one Sector running across ALL
                  Categories at a time (this will be addressed in a future
                  update).
                </p>
                <div className="row">
                  <div className="col-2">
                    <Switch
                      onChange={() => setIsRunning(!isRunning)}
                      checked={isRunning}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={30}
                      width={58}
                      className="react-switch"
                      id="material-switch"
                      disabled={false}
                    />
                  </div>
                  <div className="col-4 col-xl-3 col-xxl-4">
                    {isRunning ? 'Running' : 'Not Running'}
                  </div>
                </div>
              </div>
            </div>
            <div className="row col-10 mt-4 d-flex"></div>
            <div></div>
          </div>
          <div className="d-flex mt-5 mb-1">
            <div className="flex-grow-1">
              <Button
                name="Exit"
                className="w-25"
                onClick={() => handleExit()}
              />
            </div>
            <div className="w-25 ms-3">
              <Button
                name="Save"
                onClick={() => handleSave()}
                disabled={disableSave}
              />
            </div>
          </div>
        </div>
      </div>
      <SectorFinalizeModal
        finalizeModal={finalizeModal}
        onHide={() => setFinalizeModal(false)}
        finalizeSector={FinalizeSector}
      />
    </div>
  )
}
export default CreateEditSectors
