import React from 'react'
import './GamificationPreview.scss'

export const OptionQuestionPreview = (props) => {
  const allowedQuestionIds = [7]
  return (
    <>
      {allowedQuestionIds.includes(
        props.activeQuestion?.questionTemplate?.id
      ) && (
        <div>
          <tables>
            <thead>
              <tr>
                <th className="custom-table-header-options">Options</th>
                <th className="custom-table-header">#</th>
                <th className="custom-table-header">%</th>
              </tr>
            </thead>
            <tbody>
              {props.activeQuestion?.answerOptions?.optionsData?.options?.map(
                (options, index) => (
                  <tr key={options?.id}>
                    <td className="pb-4">
                      <b>{index + 1}:</b>&nbsp;{options?.text}
                    </td>
                    <td className="pb-3">N/A</td>
                    <td className="pb-3">N/A</td>
                  </tr>
                )
              )}
            </tbody>
          </tables>
        </div>
      )}
    </>
  )
}
