import React, { useState } from 'react'
import './Clipboard.scss'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { ProvidersIcons } from '../../../assets/icons/providers'

const Clipboard = (props) => {
  return (
    <>
      <CopyToClipboard text={props.urlValue} onCopy={props.onCopy}>
        <button
          style={{
            backgroundImage: `url(${ProvidersIcons.CopyIcon})`,
          }}
          className="Copy_Icon"
        ></button>
      </CopyToClipboard>
      {props.copied?'Copied!':''}
    </>
  )
}

export default Clipboard
