import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { IconContext } from 'react-icons'
import { initializeApp } from 'firebase/app'
import { getFirestore, collection, getDocs } from '@firebase/firestore'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { TiTick } from 'react-icons/ti'
import { AiOutlineSearch } from 'react-icons/ai'
import { RiDeleteBin5Fill } from 'react-icons/ri'
import { MdModeEditOutline } from 'react-icons/md'
import { getToken, getUser } from '../Login/ManageUser'
import { convertLocalTimeZone } from '../../constants/helper'
import { intialObjects } from '../../constants/enumTypes.constants'
import { notify } from '../Notify/Notify'
import Pagination from '../common/Pagination/Pagination'
import SideBar from '../common/Sidebar'
import Button from '../common/Button/Button'
import Spinner from 'react-bootstrap/Spinner'
import axios from 'axios'
import CreateEditSeasons from './CreateEditSeasons.js'
import DeleteSeasonModal from './DeleteSeasonModal'
import './Seasons.scss'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

const seasons = () => {
  // *  useNavigate used for redirect to login
  let navigate = useNavigate()
  // * Authentication variables
  const BASE_URL = process.env.REACT_APP_API_BASE_URL
  const V2_URL = process.env.REACT_APP_API_V2_URL

  const token = getToken()
  const user = getUser()

  // * Component state
  const [seasonsList, setSeasonsList] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [totalRecords, setTotalRecords] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  const [selectedSeasonCategory, setSelectedSeasonCategory] =
    useState(intialObjects)
  const [universal, setUniversal] = useState(intialObjects)
  const [categoryLoader, setcategoryLoader] = useState(true)
  const [categories, setCategories] = useState([])
  const [loader, setLoader] = useState(true)
  const [isEdit, setIsEdit] = useState(false)
  const [editData, setEditData] = useState({})
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteSeasonId, setDeleteSeasonId] = useState()

  const perPageRecords = 10

  // imported to fetch stats's category
  const app = initializeApp(firebaseConfig)
  const auth = getAuth(app)
  const db = getFirestore(app)

  useEffect(() => {
    signInWithEmailAndPassword(
      auth,
      process.env.REACT_APP_FIREBASE_EMAIL,
      process.env.REACT_APP_FIREBASE_PASSWORD
    ).then((userCredential) => {
      // * Signed in to firebase
      const user = userCredential?.user
      user?.accessToken && fetchCategory()
    })
  }, [])

  const categoryListRef = collection(db, 'Categories')
  const fetchCategory = async () => {
    const data = await getDocs(categoryListRef)
    const categoryData = data.docs.map((doc) => ({
      ...doc.data(),
      ...intialObjects,
    }))
    // * Get all the stats file
    getStatsFiles([...categoryData])
  }

  // * Fetch Season List
  const getSeasonsList = (page, categoryId) => {
    setLoader(true)
    let url = `${V2_URL}eventSeasons`
    axios
      .get(url, {
        params: {
          page: page > 1 ? page : 1,
          perPage: perPageRecords,
          categoryId: categoryId,
        },
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.data != null) {
          setSeasonsList(res.data.data)
          setTotalRecords(res?.data?.extra?.total)
        }
      })
      .finally(() => {
        setLoader(false)
      })
  }

  // * Open Create/Edit Modal
  const handleCreateEditModal = () => {
    setIsModalVisible(true)
  }

  //Edit Season
  const editSeason = (value) => {
    setIsEdit(true)
    setEditData(value)
    setIsModalVisible(true)
  }
  const deleteSeason = (value) => {
    setDeleteModal(true)
    setDeleteSeasonId(value?.id)
  }

  //Delete Season
  const deleteEventHandler = () => {
    setDeleteModal(false)
    setLoader(true)
    const url = `${V2_URL}eventSeason/${deleteSeasonId}`
    axios
      .delete(url, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.data?.success) {        
          const title = 'Success'
          const detail = res.data.message
          const type = 'success'
          notify(title, detail, type)
        }
      })
      .finally(() => {
        getSeasonsList(1, selectedSeasonCategory?.id)
      })
  }

  const handlePageChange = (e) => {
    setPageNumber(e.selected + 1)
    getSeasonsList(e.selected + 1, selectedSeasonCategory?.id)
  }

  const handleCategoryClick = (id) => {
    const newSelectedCategory = [...categories].find(
      (item) => Number(item?.id) === Number(id)
    )
    setSelectedSeasonCategory(newSelectedCategory)
    getSeasonsList(1, newSelectedCategory?.id)
  }

  const getStatsFiles = (categories) => {
    const url = `${BASE_URL}stats-file-download`
    axios
      .post(
        url,
        {},
        {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res?.data?.success) {
          const resData = res?.data?.data
          // * Set universal data
          setUniversal({
            ...universal,
            fileName: resData?.universal?.fileName ?? '',
            lastUpdated: resData?.universal?.lastUpdated ?? null,
            downloadFilePath: resData?.universal?.downloadFilePath ?? '',
            isDisable: true,
          })
          // * Set category data
          let updatedResult = [...categories].map((o1) => {
            let category = resData?.category.find((o2) => {
              return o1?.id === o2?.categoryId
            })
            if (category) {
              return {
                ...o1,
                ...category,
              }
            }
            return o1
          })
          setCategories([...updatedResult])
          setSelectedSeasonCategory([...updatedResult]?.[0])
          setcategoryLoader(false)
          getSeasonsList(1, [...updatedResult]?.[0]?.id)
        }
      })
  }

  return (
    <>
      {token && user ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-3 col-lg-2">
              <SideBar currentSelected="Events" currentsubSelected="Seasons" />
            </div>

            {isModalVisible ? (
              <CreateEditSeasons
                setIsModalVisible={setIsModalVisible}
                getSeasonsList={getSeasonsList}
                setPageNumber={setPageNumber}
                setSelectedSeasonCategory={setSelectedSeasonCategory}
                editData={editData}
                setEditData={setEditData}
                setIsEdit={setIsEdit}
                isEdit={isEdit}
              />
            ) : (
              <>
                <div className="col-6 col-lg-8 Event_Container seasons-list">
                  <p className="Roboto_Black d-flex seasons-list-heading">{`Seasons `}</p>
                  <div className="row ml-2">
                    {categoryLoader ? (
                      <div className="text-center overflow-hidden">
                        <Spinner animation="border" variant="primary" />
                      </div>
                    ) : (
                      [...categories]?.map((key) => (
                        <div
                          className={`categories-label btn ${
                            Number(selectedSeasonCategory?.id) ===
                            Number(key?.id)
                              ? 'activeTab'
                              : ''
                          }`}
                          key={`category-${key?.id}`}
                          onClick={() => handleCategoryClick(Number(key?.id))}
                        >
                          <div>
                            <img src={key?.imgURL} alt="" height={20} />
                          </div>
                          <div className="mt-2">{key?.category}</div>
                        </div>
                      ))
                    )}
                  </div>
                  <div className="row mt-3 search-seasons">
                    <div className="input-group col-md-4 seasons-list-search">
                      <input
                        className="form-control py-3 border-right-0 border seasons-list-search-box"
                        type="search"
                        placeholder="Search for an Season"
                        id="example-search-input"
                      />
                      <span className="input-group-append Bg_Quaternary_Color seasons-list-search-box">
                        <button
                          className="btn Font_Quinary_Color border-left-0 border"
                          type="button"
                        >
                          <IconContext.Provider
                            value={{ color: 'Font_Quinary_Color' }}
                          >
                            <AiOutlineSearch />
                          </IconContext.Provider>
                        </button>
                      </span>
                    </div>
                  </div>
                  <div className="seasons-list-center-container p-3 mt-3">
                    <div className="row seasons-list-header Font_Black Font_Quinary_Color">
                      <div className="col-1">ID</div>
                      <div className="col-3">Name</div>
                      <div className="col-3">Start Time</div>
                      <div className="col-3">End Time</div>
                      <div className="col-1">Priority</div>
                      <div className="col-1">Modify</div>
                    </div>
                    <hr className="row mt-3 mb-3"></hr>
                    <div className="seasons-list-table">
                      {loader ? (
                        <div className="text-center overflow-hidden">
                          <Spinner animation="border" variant="primary" />
                        </div>
                      ) : (
                        seasonsList?.length > 0 &&
                        seasonsList.map((season, index) => {
                          return (
                            <div
                              key={index}
                              className={`seasons-list-table-row d-flex align-items-center`}
                            >
                              <div className="col-1">
                                <b>{season.id}</b>
                              </div>
                              <div className="col-3 seasons-list-table-event-name">
                                <b>{season.name}</b>
                              </div>
                              <div className="col-3">
                                {convertLocalTimeZone(season.startDateTime)}
                              </div>
                              <div className="col-3">
                                {convertLocalTimeZone(season.endDateTime)}
                              </div>
                              <div className="col-1">
                                {season.isPriority ? (
                                  <IconContext.Provider
                                    value={{ size: '30px', color: 'green' }}
                                  >
                                    <TiTick />
                                  </IconContext.Provider>
                                ) : null}
                              </div>
                              <div className="col-1">
                                <MdModeEditOutline
                                  className="default-icon"
                                  onClick={() => editSeason(season)}
                                />
                                <RiDeleteBin5Fill
                                  className="default-icon ml-3"
                                  onClick={() => deleteSeason(season)}
                                />
                              </div>
                            </div>
                          )
                        })
                      )}
                    </div>
                    <div className="row m-0 pt-4">
                      <div className="col ps-2">
                        {totalRecords > 0 && (
                          <Pagination
                            onPageChange={handlePageChange}
                            pageCount={Math.ceil(totalRecords / perPageRecords)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-3 col-lg-2 Preview_Container mt-5">
                  <Button
                    name="Add Seasons"
                    btnType="add"
                    onClick={() => handleCreateEditModal()}
                    className="seasons-button ms-auto me-3"
                  />
                </div>
                <DeleteSeasonModal
                  deleteModal={deleteModal}
                  onHide={() => setDeleteModal(false)}
                  deleteEventHandler={deleteEventHandler}
                />
              </>
            )}
          </div>
        </div>
      ) : (
        navigate('/login')
      )}
    </>
  )
}
export default seasons
