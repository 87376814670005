import React, { useState, forwardRef, useEffect } from 'react'
import { getToken } from '../Login/ManageUser'
import { RiDeleteBin5Fill } from 'react-icons/ri'
import { MdOutlineDragIndicator } from 'react-icons/md'
import { notify } from '../Notify/Notify'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import DatePicker from 'react-datepicker'
import FormField from '../common/FormField'
import Button from '../common/Button/Button'
import axios from 'axios'
import 'react-datepicker/dist/react-datepicker.css'
import './EventInfo.scss'

const CreateEditSchedule = (props) => {
  const token = getToken()
  const {
    getScheduleList,
    setIsModalVisible,
    eventInfoName,
    eventInfoId,
    editData,
    setEditData,
    setIsEdit,
    isEdit,
  } = props
  const [activityGroupName, setActivityGroupName] = useState('')
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [errors, setErrors] = useState({})
  const [disableSave, setDisableSave] = useState(false)
  const [schedule, setSchedule] = useState([])

  // * Authentication token
  const V2_URL = process.env.REACT_APP_API_V2_URL

  useEffect(() => {
    if (isEdit) {
      setActivityGroupName(editData?.name)
      setStartDate(new Date(editData?.startTime))
      setEndDate(new Date(editData?.endTime))
      setSchedule(editData?.activities)
    }
  }, [])

  const filterEndTime = (time) =>
    new Date(startDate).getTime() < new Date(time).getTime()

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" onClick={onClick} ref={ref}>
      {value + ' ' + /\((.*)\)/.exec(new Date().toString())[1]}
    </button>
  ))

  const handleStartChangeDate = (e) => {
    setStartDate(e)
    if (errors) {
      errors.startDate = ''
      setErrors(errors)
    }
  }
  const handleEndChangeDate = (e) => {
    setEndDate(e)
    if (errors) {
      errors.endDate = ''
      setErrors(errors)
    }
  }
  const handleNameChange = (e) => {
    const maxLength = 45
    const { value } = e.target

    if (value.length > maxLength) {
      setActivityGroupName(value.substring(0, maxLength))
    } else {
      setActivityGroupName(value)
    }
    if (errors) {
      errors.activityGroupName = ''
      setErrors(errors)
    }
  }
  const handleValidation = (activityGroupName, startDate, endDate) => {
    let error = {}
    if (!activityGroupName || activityGroupName == '') {
      error.activityGroupName = 'Activity Group name is required'
    }
    if (startDate.toISOString() >= endDate.toISOString()) {
      error.endDate = 'End datetime should be greater than start datetime'
    }
    return error
  }
  const handleExit = () => {
    setIsModalVisible(false)
    getScheduleList()
    setEditData({})
    setIsEdit(false)
  }
  const handleSave = () => {
    var fieldErrors = handleValidation(activityGroupName, startDate, endDate)
    setErrors(fieldErrors)

    let params = {
      eventInfoId: eventInfoId,
      name: activityGroupName,
      startTime: startDate.toISOString(),
      endTime: endDate.toISOString(),
      activities: schedule,
    }

    if (!isEdit) {
      let url = `${V2_URL}eventInfoActivity/`
      setDisableSave(true)
      axios
        .post(url, params, {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res?.data?.success) {
            const title = 'Success'
            const detail = res.data.message
            const type = 'success'
            notify(title, detail, type)
          }
        })
        .finally(() => {
          setDisableSave(false)
          setIsModalVisible(false)
          getScheduleList()
        })
    } else {
      let url = `${V2_URL}eventInfoActivity?eventInfoActivityId=${editData?.id}`
      setDisableSave(true)
      axios
        .post(url, params, {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res?.data?.success) {
            const title = 'Success'
            const detail = res.data.message
            const type = 'success'
            notify(title, detail, type)
          }
        })
        .finally(() => {
          setDisableSave(false)
          setIsModalVisible(false)
          getScheduleList()
          setIsEdit(false)
        })
    }
  }

  const addRow = () => {
    if (schedule.length < 50) {
      setSchedule((prevSchedule) => [
        ...prevSchedule,
        {
          startTime: '',
          endTime: '',
          activity: '',
        },
      ])
    }
  }

  const deleteRow = (indexToDelete) => {
    setSchedule((prevSchedule) =>
      prevSchedule.filter((row, index) => index !== indexToDelete)
    )
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    ...draggableStyle,
  })

  const getListStyle = (isDraggingOver) => ({})

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const updatedItems = reorder(
      schedule,
      result.source.index,
      result.destination.index
    )

    setSchedule(updatedItems)
  }

  const handleScheduleInputChange = (index, field, value) => {
    setSchedule((prevSchedule) => {
      const updatedSchedule = [...prevSchedule]
      updatedSchedule[index][field] = value
      return updatedSchedule
    })
  }

  return (
    <div className="col-6 col-lg-8">
      <div className="row my-4">
        <div className="breadcrumb">
          {' '}
          {eventInfoName} &gt; Schedule Page &gt; {activityGroupName}
        </div>
        <div className="EventInfo-wrapper p-5">
          <div className="row">
            <div className="col-6 ml-0">
              <label htmlFor="starttime" className="Font_Black">
                Activity Group name
              </label>
              <p className="Font_Regular">
                {' '}
                This will set the header value for the Activity Group on the
                Schedule page in the application.
              </p>
              <FormField
                value={activityGroupName}
                id="activityGroupName"
                label=""
                name="activityGroupName"
                type="text"
                placeholder="Enter Activity Group Name"
                onChange={(e) => {
                  handleNameChange(e)
                }}
                disabled={false}
              />
              {errors && (
                <div className="input-feedback">{errors.activityGroupName}</div>
              )}
            </div>
          </div>
          <div className="row">
            <p className="Error_Color Font_Regular">
              <b>NOTE: </b>Be sure to offset the venue time zone to your local
              time when setting Start and End Times.
            </p>
          </div>
          <div className="row">
            <div className="col-6">
              <div>
                <label htmlFor="starttime" className="Font_Black">
                  Start Time
                </label>
                <p className="Font_Regular">
                  {' '}
                  The time when this set of activities begins. This helps
                  determine the display order amongst the other Activity Groups.
                </p>
              </div>
              <div className="DateTime">
                <DatePicker
                  selected={startDate}
                  onChange={handleStartChangeDate}
                  showTimeSelect
                  timeIntervals={15}
                  dateFormat="MM/dd/yyyy hh:mm:ss a"
                  timeCaption="time"
                  className="Bg_Quinary_Color"
                  customInput={<ExampleCustomInput />}
                  disabled={false}
                />
              </div>
              {errors && (
                <div className="input-feedback">{errors.startDate}</div>
              )}
            </div>
            <div className="col-6">
              <label htmlFor="starttime" className="Font_Black">
                End Time
              </label>
              <p className="Font_Regular">
                {' '}
                The end of the time range when the Activity Group is live. After
                this time passes the next Activity Group will be highlighted by
                default (if applicable)
              </p>
              <div className="DateTime">
                <DatePicker
                  selected={endDate}
                  onChange={handleEndChangeDate}
                  showTimeSelect
                  minDate={new Date(startDate)}
                  filterTime={filterEndTime}
                  timeIntervals={15}
                  dateFormat="MM/dd/yyyy hh:mm:ss a"
                  timeCaption="time"
                  className="Bg_Quinary_Color"
                  customInput={<ExampleCustomInput />}
                  disabled={false}
                />
              </div>
              {errors && <div className="input-feedback">{errors.endDate}</div>}
            </div>
            <div className="row m-0 pt-4">
              <div className="row m-0 p-0">
                <div className="row table-header font-weight-bold">
                  <div className="col-3">Venue Time</div>
                  <div className="col-8">Activity</div>
                  <div className="col-1">Modify</div>
                </div>
                <hr className="row mt-3" />

                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {schedule.map((item, index) => (
                          <Draggable
                            key={`activity-${index}`}
                            draggableId={`activity-${index}`}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <div className="row m-0" key={index}>
                                <div className="row col-3 p-0 align-items-center">
                                    <div className="col-1 mr-3">
                                      <MdOutlineDragIndicator className="default-icon" />
                                    </div>
                                    <div className="col-4 p-0 m-0">
                                      <FormField
                                        value={item.startTime}
                                        id={'startTime' + index}
                                        label=""
                                        placeholder="Start Time"
                                        name="activityGroupName"
                                        type="text"
                                        onChange={(e) =>
                                          handleScheduleInputChange(
                                            index,
                                            'startTime',
                                            e.target.value
                                          )
                                        }
                                        disabled={false}
                                      />
                                    </div>
                                    <div className="col-1 d-flex align-items-center">
                                      -
                                    </div>
                                    <div className="col-4 p-0 m-0">
                                      <FormField
                                        value={item.endTime}
                                        id={'endTime' + index}
                                        label=""
                                        placeholder="End Time"
                                        name="endTime"
                                        type="text"
                                        onChange={(e) =>
                                          handleScheduleInputChange(
                                            index,
                                            'endTime',
                                            e.target.value
                                          )
                                        }
                                        disabled={false}
                                      />
                                    </div>
                                  </div>
                                  <div className="row col-8">
                                    <FormField
                                      value={item.activity}
                                      onChange={(e) =>
                                        handleScheduleInputChange(
                                          index,
                                          'activity',
                                          e.target.value
                                        )
                                      }
                                      type="text"
                                      id={'activity' + index}
                                      label=""
                                      placeholder="Activity Description"
                                      name="activity"
                                      disabled={false}
                                    />
                                  </div>
                                  <div className="col-1 ml-1 d-flex justify-content-center align-items-center">
                                    <RiDeleteBin5Fill
                                      className="default-icon ml-3"
                                      onClick={() => {
                                        deleteRow(index)
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>

                <div className="row col-6 mx-auto">
                  <Button
                    name="+"
                    className="mt-4"
                    onClick={addRow}
                    disabled={schedule.length >= 50}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex mt-5 mb-1">
            <div className="flex-grow-1">
              <Button
                name="Exit"
                className="w-25"
                onClick={() => handleExit()}
              />
            </div>
            <div className="w-25 ms-3">
              <Button
                name="Save"
                onClick={() => handleSave()}
                disabled={disableSave}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CreateEditSchedule
