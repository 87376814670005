import React, { useState } from 'react'
import { previewPodiumLabel } from '../../../constants/enumTypes.constants'
import './GamificationPreview.scss'


export const PodiumPreview = (props) => {
  const [selectedPodiumPreview, setSelectedPodiumPreview] = useState(
    previewPodiumLabel[0]
  )
  const selecteLableCategory = (id) => {
    setSelectedPodiumPreview(previewPodiumLabel?.[id])
  }

  return (
    <>
      {props.activeQuestion?.questionTemplate?.id === 3 && (
        <div>
          <div className="row pl-2">
            {[...previewPodiumLabel]?.map((item) => (
              <div
                className={`podium-label btn mx-1 mb-2 ${
                  Number(selectedPodiumPreview?.id) === Number(item?.id)
                    ? 'selected'
                    : ''
                }`}
                key={`category-${item?.id}`}
                onClick={() => selecteLableCategory(item?.id)}
              >
                <div >{item?.title}</div>
              </div>
            ))}
          </div>
          <tables>
            <thead>
              <tr>
                <th className="custom-table-header">Options</th>
                <th className="custom-table-header">#</th>
                <th className="custom-table-header">%</th>
              </tr>
            </thead>
            <tbody>
              {props.activeQuestion?.answerOptions?.optionsData?.options?.map(
                (drivers, index) => (
                  <tr key={drivers?.personId}>
                    <td>
                      <b>{index + 1}:</b>&nbsp;{drivers?.personNickname}
                    </td>
                    <td>N/A</td>
                    <td>N/A</td>
                  </tr>
                )
              )}
            </tbody>
          </tables>
        </div>
      )}
    </>
  )
}
