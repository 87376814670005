import React, { useState, forwardRef, useEffect } from 'react'
import { initializeApp } from 'firebase/app'
import { getFirestore, collection, getDocs } from '@firebase/firestore'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import {
  PrioritySeasonObj,
  intialObjects,
} from '../../constants/enumTypes.constants'
import { getToken } from '../Login/ManageUser'
import { notify } from '../Notify/Notify'
import axios from 'axios'
import Spinner from 'react-bootstrap/Spinner'
import Switch from 'react-switch'
import DatePicker from 'react-datepicker'
import FormField from '../common/FormField'
import Button from '../common/Button/Button'
import PriorityModal from './PriorityModal'
import ImageUploader from '../common/ImageUploader/ImageUploader'
import SeasonFinalizeModal from './SeasonFinalizeModal'
import 'react-datepicker/dist/react-datepicker.css'
import './Seasons.scss'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

const CreateEditSeasons = (props) => {
  const {
    setIsModalVisible,
    getSeasonsList,
    setPageNumber,
    setSelectedSeasonCategory,
    editData,
    setEditData,
    setIsEdit,
    isEdit,
  } = props
  const [seasonsName, setSeasonsName] = useState('')
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [selectedCategory, setSelectedCategory] = useState(intialObjects)
  const [universal, setUniversal] = useState(intialObjects)
  const [categories, setCategories] = useState([])
  const [loader, setLoader] = useState(true)
  const [isPriority, setIsPriority] = useState(false)
  const [errors, setErrors] = useState({})
  const [disableSave, setDisableSave] = useState(false)
  const [priorityModal, setPriorityModal] = useState(false)
  const [modalData, setModalData] = useState(PrioritySeasonObj)
  const [overwrite, setOverwrite] = useState(false)
  const [categoryName, setCategoryName] = useState('')
  const [imgUrl, setImgUrl] = useState()
  const [imgState, setImgState] = useState(null)
  const [imageName, setImageName] = useState()
  const [imageDisplay, setImageDisplay] = useState()
  const [finalizeModal, setFinalizeModal] = useState(false)
  const [buttonClass, setButtonClass] = useState({})

  // * Authentication token
  const BASE_URL = process.env.REACT_APP_API_BASE_URL
  const V2_URL = process.env.REACT_APP_API_V2_URL
  let token = getToken()

  // imported to fetch stats's category
  const app = initializeApp(firebaseConfig)
  const auth = getAuth(app)
  const db = getFirestore(app)

  useEffect(() => {
    if (editData?.isLocked === true) {
      setButtonClass({ class: 'final', name: 'Finalized' })
    }
    if (editData?.isLocked === false) {
      setButtonClass({ class: '', name: 'Finalize Season' })
    }
  }, [editData])

  useEffect(() => {
    signInWithEmailAndPassword(
      auth,
      process.env.REACT_APP_FIREBASE_EMAIL,
      process.env.REACT_APP_FIREBASE_PASSWORD
    ).then((userCredential) => {
      // * Signed in to firebase
      const user = userCredential?.user
      user?.accessToken && fetchCategory()
    })
  }, [])

  useEffect(() => {
    handlePriority()
  }, [isPriority])

  useEffect(() => {
    if (isEdit) {
      setSeasonsName(editData?.name)
      setStartDate(new Date(editData?.startDateTime))
      setEndDate(new Date(editData?.endDateTime))
      setSelectedCategory(
        [...categories].filter(
          (obj) => parseInt(obj.id) == parseInt(editData?.category)
        )?.[0]
      )
      setIsPriority(editData?.isPriority)
      if (editData?.image) {
        setImgUrl(editData?.image)
        setImgState(editData?.image)
      }
    }
  }, [categories])

  const categoryListRef = collection(db, 'Categories')
  const fetchCategory = async () => {
    const data = await getDocs(categoryListRef)
    const categoryData = data.docs.map((doc) => ({
      ...doc.data(),
      ...intialObjects,
    }))
    // * Get all the stats file
    getStatsFiles([...categoryData])
  }

  const filterEndTime = (time) =>
    new Date(startDate).getTime() < new Date(time).getTime()

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" onClick={onClick} ref={ref}>
      {value + ' ' + /\((.*)\)/.exec(new Date().toString())[1]}
    </button>
  ))

  const handleStartChangeDate = (e) => {
    setStartDate(e)
    if (errors) {
      errors.startDate = ''
      setErrors(errors)
    }
  }
  const handleEndChangeDate = (e) => {
    setEndDate(e)
    if (errors) {
      errors.endDate = ''
      setErrors(errors)
    }
  }
  const handleNameChange = (e) => {
    const maxLength = 45
    const { value } = e.target

    if (value.length > maxLength) {
      setSeasonsName(value.substring(0, maxLength))
    } else {
      setSeasonsName(value)
    }
    if (errors) {
      errors.seasonsName = ''
      setErrors(errors)
    }
  }
  const handleValidation = (seasonsName, startDate, endDate) => {
    let error = {}
    if (!seasonsName || seasonsName == '') {
      error.seasonsName = 'Season name is required'
    }
    if (startDate.toISOString() >= endDate.toISOString()) {
      error.endDate = 'End datetime should be greater than start datetime'
    }
    return error
  }
  const handleExit = () => {
    setEditData({})
    setIsEdit(false)
    setIsModalVisible(false)
    getSeasonsList(1, selectedCategory?.id)
    setSelectedSeasonCategory(selectedCategory)
    setPageNumber(1)
  }
  const handleSave = (flag) => {
    var fieldErrors = handleValidation(seasonsName, startDate, endDate)
    setErrors(fieldErrors)
    if (Object.keys(fieldErrors)?.length === 0) {
      if (overwrite === false || flag === false) {
        let form_data = new FormData()
        form_data.append('name', seasonsName)
        form_data.append('category', parseInt(selectedCategory?.id)) 
        form_data.append('startDateTime', startDate)
        form_data.append('endDateTime', endDate)
        form_data.append('isPriority', isPriority)
        if (!isEdit) {
          form_data.append('image', imgState)
          let url = `${V2_URL}eventSeasons`
          setDisableSave(true)
          axios
            .post(url, form_data, {
              headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              if (res?.data?.success === true) {
                const title = 'Success'
                const detail = res?.data?.message
                const type = 'success'
                notify(title, detail, type)
                setIsModalVisible(false)
              }
            })
            .finally(() => {
              setDisableSave(false)
              setSelectedSeasonCategory(selectedCategory)
              getSeasonsList(1, selectedCategory?.id)
              setPageNumber(1)
              setEditData({})
              setIsEdit(false)
            })
        } else {
          if (imgState != imgUrl) {
            form_data.append('image', imgState)
          }
          let url = `${V2_URL}eventSeason/${editData?.id}`
          setDisableSave(true)
          axios
            .put(url, form_data, {
              headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              if (res?.data?.success === true) {
                const title = 'Success'
                const detail = res?.data?.message
                const type = 'success'
                notify(title, detail, type)
                setIsModalVisible(false)
              }
            })
            .finally(() => {
              setDisableSave(false)
              setSelectedSeasonCategory(selectedCategory)
              getSeasonsList(1, selectedCategory?.id)
              setPageNumber(1)
              setEditData({})
              setIsEdit(false)
            })
        }
      }
      if (overwrite === true && isPriority === true) {
        setPriorityModal(true)
      }
    }
  }
  const handleCategoryName = (categoryId) => {
    const catObj = [...categories].filter(
      (obj) => parseInt(obj.id) == parseInt(categoryId)
    )
    setCategoryName(catObj?.[0]?.category)
  }
  const handlePriority = () => {
    if (isPriority === true && selectedCategory) {
      const url = `${V2_URL}prioritySeason`
      axios
        .get(url, {
          params: {
            categoryId: selectedCategory?.id,
          },
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res?.data?.success === false) {
            const title = 'error'
            const detail = res.data.message
            const type = 'error'
            notify(title, detail, type)
          }
          res?.data?.data == null ? setOverwrite(false) : setOverwrite(true)
          setModalData({
            seasonName: res?.data?.data?.name,
            startTime: res?.data?.data?.startDateTime,
            endTime: res?.data?.data?.endDateTime,
          })
          handleCategoryName(res?.data?.data?.category)
        })
    } else {
      setOverwrite(false)
    }
  }

  const handleCategoryClick = (id) => {
    const newSelectedCategory = [...categories].find(
      (item) => Number(item?.id) === Number(id)
    )
    setSelectedCategory(newSelectedCategory)
    setIsPriority(false)
    setOverwrite(false)
  }

  const handleRemove = () => {
    setImgState(null)
    setImgUrl('')
    setImageName('')
  }

  const onImageChange = (picture) => {
    var maxLength = 5
    if (picture[0].file) {
      setImgState(picture[0].file)
      setImageName(picture[0].file.name)
      if (picture[0].file.name.length > maxLength) {
        setImageDisplay(picture[0].file.name.substr(0, maxLength) + '...')
      } else {
        setImageDisplay(picture[0].file.name)
      }
    }
  }

  const FinalizeSeason = () => {
    setFinalizeModal(false)
    let url = `${V2_URL}eventSeason/finalize`
    const params = {
      seasonId: editData.id,
    }
    axios
      .post(url, params, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setButtonClass({ class: 'final', name: 'Finalized' })
        const title = 'Success'
        const detail = res?.data?.message
        const type = 'success'
        notify(title, detail, type)
      })
  }

  const getStatsFiles = (categories) => {
    const url = `${BASE_URL}stats-file-download`
    axios
      .post(
        url,
        {},
        {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res?.data?.success) {
          const resData = res?.data?.data
          // * Set universal data
          setUniversal({
            ...universal,
            fileName: resData?.universal?.fileName ?? '',
            lastUpdated: resData?.universal?.lastUpdated ?? null,
            downloadFilePath: resData?.universal?.downloadFilePath ?? '',
            isDisable: true,
          })
          // * Set category data
          let updatedResult = [...categories].map((o1) => {
            let category = resData?.category.find((o2) => {
              return o1?.id === o2?.categoryId
            })
            if (category) {
              return {
                ...o1,
                ...category,
              }
            }
            return o1
          })
          setCategories([...updatedResult])
          setSelectedCategory([...updatedResult]?.[0])
          setLoader(false)
        }
      })
  }

  return (
    <div className="col-6 col-lg-8">
      <div className="row mt-4">
        <div className="breadcrumb">Seasons &gt; {seasonsName}</div>
        <div className="seasons-wrapper p-5">
          <div className="row">
            <div className="col-6">
              <FormField
                value={seasonsName}
                id="seasonsName"
                label="Name"
                name="seasonsName"
                type="text"
                placeholder="Enter Seasons Name"
                onChange={(e) => {
                  handleNameChange(e)
                }}
                disabled={false}
              />
              {errors && (
                <div className="input-feedback">{errors.seasonsName}</div>
              )}
              <label htmlFor="starttime" className="Font_Black">
                Start Time
              </label>
              <div className="DateTime">
                <DatePicker
                  selected={startDate}
                  onChange={handleStartChangeDate}
                  showTimeSelect
                  timeIntervals={15}
                  dateFormat="MM/dd/yyyy hh:mm:ss a"
                  timeCaption="time"
                  className="Bg_Quinary_Color"
                  customInput={<ExampleCustomInput />}
                  disabled={false}
                />
              </div>
              {errors && (
                <div className="input-feedback">{errors.startDate}</div>
              )}
              <label htmlFor="starttime" className="Font_Black">
                End Time
              </label>
              <div className="DateTime">
                <DatePicker
                  selected={endDate}
                  onChange={handleEndChangeDate}
                  showTimeSelect
                  minDate={new Date(startDate)}
                  filterTime={filterEndTime}
                  timeIntervals={15}
                  dateFormat="MM/dd/yyyy hh:mm:ss a"
                  timeCaption="time"
                  className="Bg_Quinary_Color"
                  customInput={<ExampleCustomInput />}
                  disabled={false}
                />
              </div>
              {errors && <div className="input-feedback">{errors.endDate}</div>}
              {Object.keys(editData).length > 0 && (
                <div>
                  <label htmlFor="hidden" className="Font_Black mt-3">
                    Gamification
                  </label>
                  <div className="col-4 p-0">
                    <Button
                      className={buttonClass.class}
                      name={buttonClass.name}
                      onClick={() =>
                        buttonClass?.name === 'Finalize Season' &&
                        setFinalizeModal(true)
                      }
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="col-6">
              <label htmlFor="hidden" className="Font_Black ms-1xl-5">
                Priority Season?
              </label>
              <p className="Font_Regular ms-1xl-5">
                If ENABLED, this Season has its Events and Event Groups shown by
                default on the Stats tab.
                <br></br>
              </p>
              <p className=" Error_Color Font_Regular ms-1xl-5">
                <b>Note: </b>There can only be one Priority Season per Category
                at a time.
              </p>

              <div className="row">
                <div className="col-2 col-1xl-4 col-xl-4 col-xxl-3">
                  <Switch
                    onChange={() => setIsPriority(!isPriority)}
                    checked={isPriority}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={30}
                    width={58}
                    className="react-switch ms-1xl-5"
                    id="material-switch"
                    disabled={false}
                  />
                </div>
                <div className="col-6 col-xl-3 col-xxl-4">
                  {isPriority ? 'Priority' : 'Basic'}
                </div>
                <PriorityModal
                  priorityModalVisible={priorityModal}
                  setPriorityModal={setPriorityModal}
                  setIsPriority={setIsPriority}
                  setOverwrite={setOverwrite}
                  modalData={modalData}
                  categoryName={categoryName}
                  handleSave={handleSave}
                />
              </div>

              <div className="pl-5 pt-1">
                <label htmlFor="image" className="Font_Black d-flex">
                  Gamification Image (Optional)
                </label>
                <p>
                  Upload a horizontal image 315x250 for use when viewing the
                  Sector in the app
                </p>
                <ImageUploader
                  value={imgState}
                  imgUrl={imgUrl}
                  imageName={imageName}
                  imageDisplay={imageDisplay}
                  onChange={onImageChange}
                  onClick={handleRemove}
                  isOptionalClose={imgUrl?.length > 0 ? true : false}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <label htmlFor="categories" className="Font_Black">
              Category
            </label>
            <div>
              {loader ? (
                <div className="text-center overflow-hidden">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                [...categories]?.map((key) => (
                  <div
                    className={`categories-label btn ${
                      Number(selectedCategory?.id) === Number(key?.id)
                        ? 'active'
                        : ''
                    }`}
                    key={`category-${key?.id}`}
                    onClick={() => handleCategoryClick(Number(key?.id))}
                  >
                    <div>
                      <img src={key?.imgURL} alt="" height={20} />
                    </div>
                    <div className="mt-2">{key?.category}</div>
                  </div>
                ))
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-6"></div>
          </div>
          <div className="d-flex mt-5 mb-1">
            <div className="flex-grow-1">
              <Button
                name="Exit"
                className="w-25"
                onClick={() => handleExit()}
              />
            </div>
            <div className="w-25 ms-3">
              <Button
                name="Save"
                onClick={() => handleSave()}
                disabled={disableSave}
              />
            </div>
          </div>
        </div>
      </div>
      <SeasonFinalizeModal
        finalizeModal={finalizeModal}
        onHide={() => setFinalizeModal(false)}
        finalizeSeason={FinalizeSeason}
      />
    </div>
  )
}
export default CreateEditSeasons
